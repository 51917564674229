import { message } from 'antd';
import { onGetSupport, onGetSupportReplies, onSetSupportReply } from 'data/support/support.service';
import { useDispatch } from 'react-redux';
import activeSupportSlice from 'redux/slice/activeSupport.slice';
import supportSlice from 'redux/slice/support.slice';
import { SupportResponseModel } from '../../../data/support/support.model';

// eslint-disable-next-line import/prefer-default-export
export const useSupport = () => {
  const dispatch = useDispatch();

  const fetchSupport = async (): Promise<void> => {
    dispatch(supportSlice.actions.setLoading());
    try {
      const res = await onGetSupport();
      console.log(res);
      const data: { replied: SupportResponseModel[]; notReplied: SupportResponseModel[] } = {
        replied: [],
        notReplied: [],
      };
      res.forEach((item) => {
        // eslint-disable-next-line no-underscore-dangle
        if (item.__replies__?.length === 0) {
          data.notReplied.push(item);
        } else {
          data.replied.push(item);
        }
      });
      dispatch(supportSlice.actions.setData(data));
    } catch (err) {
      dispatch(supportSlice.actions.setError(err));
    }
  };

  const postReply = async (id: string, description: string): Promise<void> => {
    try {
      const data = await onSetSupportReply({ id, description });

      dispatch(activeSupportSlice.actions.setNewReply(data));
    } catch (error) {
      dispatch(activeSupportSlice.actions.setError(error));
    }
  };

  const selectSupport = async (support: SupportResponseModel): Promise<void> => {
    dispatch(activeSupportSlice.actions.setLoading(support));
    try {
      const { __replies__ } = await onGetSupportReplies(support.id);
      dispatch(
        activeSupportSlice.actions.setData({
          ...support,
          __replies__,
        }),
      );
    } catch (error) {
      message.error(
        `Messages could not be loaded for '${support.subject}' at the moment. Please, try again.`,
      );
      dispatch(activeSupportSlice.actions.setError(error));
    }
  };

  const clearSupportSelection = (): void => {
    dispatch(activeSupportSlice.actions.setData(null));
  };

  return { fetchSupport, postReply, selectSupport, clearSupportSelection };
};
