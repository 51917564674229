import React, { useState } from 'react';
import { Form, Button, Input, Select, Descriptions } from 'antd';
import { AddClassWrapper } from './class.style';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { formatTime } from 'common/helper/timeFormatter';
import moment from 'moment';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Option } from 'antd/lib/mentions';
import { useSection } from 'features/routine/hooks/useSection';

export const EditClassDetails = (props: any) => {
  const { onUpdateGrade, onUpdateGradeSection } = useGrade();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadGrade } = props;
  const section = useSelector((state: RootState) => state.section);
  const [size, setSize] = useState<SizeType>('middle');

  const [sectionValues, setSectionValues] = useState(props?.sections.map((item: any) => item.id));

  const { fetchSections } = useSection();

  const handleChange = (value: string | string[]) => {
    // console.log(value);
    setSectionValues(value);
  };

  const handleSubmit = async () => {
    await onUpdateGrade(
      props.value,
      {
        // ...form.getFieldsValue(),
        startTime: form.getFieldsValue().startTime,
        endTime: form.getFieldsValue().endTime,
      },
      form,
      onVisibleChange,
      onSetReloadGrade,
    );
  };
  const handleUpdateSection = async () => {
    await onUpdateGradeSection(
      props.value,
      {
        sectionIds: sectionValues,
      },
      form,
      onVisibleChange,
      onSetReloadGrade,
    );
  };
  React.useEffect(() => {
    fetchSections();
  }, []);

  return (
    <AddClassWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="value" label="Grade">
          <Input defaultValue={props?.value} disabled />
        </Form.Item>
        <Form.Item name="startTime" label="Start Time">
          <Input type="time" placeholder="09:00:00 AM" defaultValue={props?.startTime} />
        </Form.Item>
        <Form.Item name="endTime" label="End Time">
          <Input type="time" defaultValue={props?.endTime} placeholder="03:00:00 PM" />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            Update Time
          </Button>
        </Form.Item>

        {/* <br />
        <br />
        <Descriptions title="Update Sections"></Descriptions>
        <Select
          mode="multiple"
          size={size}
          placeholder="Please select"
          defaultValue={sectionValues}
          value={sectionValues}
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          {section.data.map((item: any) => (
            <Option key={item.id}>{item.id}</Option>
          ))}
        </Select> */}

        {/* <Form.Item>
          <Button htmlType="submit" onClick={handleUpdateSection}>
            Update Section
          </Button>
        </Form.Item> */}
      </Form>
    </AddClassWrapper>
  );
};

export default EditClassDetails;
