import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';

export const KButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      style={{
        background: 'var(--bg)',
        borderColor: 'var(--link)',
        borderRadius: 'var(--br)',
        color: 'var(--link)',
        fontWeight: 500,
      }}
    >
      {props.children}
    </Button>
  );
};
