import { onGetExamType, onGetExamYear } from 'data/exam/exam.service';
import { onGetExamRoutine, onPostExamRoutine } from 'data/exam/examRoutine.service';
import { CreateExamRoutineModel, ExamRoutineModel } from 'data/exam/examRoutine.model';
import { useDispatch } from 'react-redux';
import examRoutineSlice from 'redux/slice/examRoutine.slice';
import examTypeSlice from 'redux/slice/examType.slice';
import examYearSlice from 'redux/slice/examYear.slice';
import React from 'react';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form';
import { toast } from 'react-hot-toast';

export const useExamRoutine = () => {
  const [error, setError] = React.useState<string | null | boolean>(null);
  const dispatch = useDispatch();
  const fetchExamYear = async () => {
    dispatch(examYearSlice.actions.setLoading());
    try {
      const res = await onGetExamYear();
      dispatch(examYearSlice.actions.setData(res));
    } catch (err) {
      setError(err);
      dispatch(examYearSlice.actions.setError(err));
    }
  };
  const fetchExamType = async () => {
    dispatch(examTypeSlice.actions.setLoading());
    try {
      const res = await onGetExamType();
      dispatch(examTypeSlice.actions.setData(res));
    } catch (err) {
      setError(err);
      dispatch(examTypeSlice.actions.setError(err));
    }
  };

  const publishExamRoutine = async (
    payload: CreateExamRoutineModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadData: any,
  ) => {
    toast.promise(
      onPostExamRoutine(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadData(true);
      }),
      {
        loading: 'Adding new exam routine',
        success: 'Exam Routine successfully published.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onPostExamRoutine(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadData(true);
    //   });
    //   notifySuccess('Success', 'Exam Routine successfully published.');
    // } catch (err) {
    //   setError(err);
    //   onVisibleChange(true);
    //   setReloadData(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  const fetchExamRoutine = async (examYear: string, examType: string) => {
    dispatch(examRoutineSlice.actions.setLoading());
    try {
      const res = await onGetExamRoutine(examYear, examType);

      dispatch(examRoutineSlice.actions.setData(res));
    } catch (err) {
      setError(err);
      dispatch(examRoutineSlice.actions.setError(err));
    }
  };
  return { error, fetchExamYear, fetchExamType, publishExamRoutine, fetchExamRoutine };
};
