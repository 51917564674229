import React, { useEffect, useState } from 'react';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import Papa from 'papaparse';
import { useSave } from 'features/users/hooks/useSave';
import { Button } from 'antd';
import { ExportToCsv } from 'export-to-csv';
import { onGetSubjects } from 'data/subject/subject.service';
import { cleanObj } from 'common/utils/functions';
import { useSubject } from 'features/result/hooks/useSubject';

export function BulkSubject() {
  const [csvData, setCsvData] = useState<any>(null);
  const [jsonData, setJsonData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { onBulkSaveSubjects } = useSubject();

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setCsvData(e.target.result);
      };
      reader.readAsText(file);

      //   Papa.parse(csvData, {
      //     header: true,
      //     dynamicTyping: true,
      //     complete: (result) => {
      //       console.log({ result: result.data });
      //       setJsonData(result.data);
      //     },
      //     error: (error) => {
      //       console.error('CSV parsing error:', error);
      //     },
      //   });
    }
  };

  useEffect(() => {
    if (!csvData) return;
    convertCsvToJson();
  }, [csvData]);

  const convertCsvToJson = () => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        setJsonData(result.data);
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
      },
    });
  };

  const handleDownloadCsv = async () => {
    setLoading(true);
    const res = await onGetSubjects();
    const subjects = res.map((item) =>
      cleanObj({
        ...item,
        created_at: undefined,
        updated_at: undefined,
        optionalSubjects: item.optionalSubjects.map((optSub) => optSub.code).join(', '),
      }),
    );
    console.log(subjects);
    csvExporter.generateCsv(subjects);
    setLoading(false);
  };

  const handleBulkUpload = async () => {
    if (!csvData) return;

    console.log({ jsonData });

    const data = jsonData.map((item: any) => ({
      ...item,
      optionalSubjects: null,
      attachedOptionalSubjects: item?.optionalSubjects?.split(',').map((code: any) => code),
    }));
    // return;

    return await onBulkSaveSubjects(data);
  };

  return (
    <div style={{ padding: '15px' }}>
      <KPageHeader title="Bulk Subjects" />
      <KProTip title="Page Hint" description="This page is for Bulk Subject" />
      <div style={{ marginTop: '20px', display: 'flex' }}>
        <div style={{}}>
          <h2>Download subjects</h2>
          <Button onClick={handleDownloadCsv} loading={loading}>
            Download
          </Button>
        </div>

        <div style={{ marginLeft: '50px' }}>
          <h2>Bulk upload subjects</h2>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          {jsonData && <Button onClick={handleBulkUpload}>Bulk upload</Button>}
        </div>
      </div>
    </div>
  );
}
