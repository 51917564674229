import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface Props {
  gradeId: string;
  value?: string;
  onChange?: (val: string) => void;
}

function SelectSection({ gradeId, value, onChange }: Props): JSX.Element {
  const gradeRedux = useSelector((state: RootState) => state.grade);
  const [localSectionSelected, setSelectedSection] = React.useState<string | undefined>(undefined);

  const sectionList = React.useMemo(() => {
    const gradeData = gradeRedux.data.find((item) => item.id === gradeId);

    if (!gradeData) return [];

    return gradeData.sections.map((item) => (
      <Select.Option key={`atten-class${item.id}`} value={item.id}>
        {item.id}
      </Select.Option>
    ));
  }, [gradeRedux.data, gradeId]);

  // if value exists, pre-populate
  React.useEffect(() => {
    if (value) setSelectedSection(value);
  }, [value]);

  // reset state value
  React.useEffect(() => {
    if (onChange) onChange('');
    setSelectedSection(undefined);
  }, [gradeId]);

  return (
    <>
      <Select
        loading={gradeRedux.status === 'loading'}
        style={{ width: '100%' }}
        value={localSectionSelected}
        placeholder="Select Section"
        className="grade-select-field"
        onSelect={(val): void => {
          setSelectedSection(val);
          if (onChange) onChange(val);
        }}
      >
        {sectionList}
      </Select>
    </>
  );
}

export default SelectSection;
