import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  AggregateResultCreateModel,
  AggregateStatusResponseModel,
  ApprovalResultUpdateRequestModel,
  FinalAggregatedResultModel,
  FinalResultCreateModel,
  ResultGetModel,
  ResultModel,
  ResultStatusResponseModel,
  ResultTestModel,
  Root,
} from './result.model';

export const onGetResult = (
  gradeId: string,

  examYearId: string,
  testTypeId: string,
): Promise<ResultGetModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/result?gradeId=${gradeId}&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};

export const onGetResultForApproval = (
  gradeId: string,
  sectionId: string,
  examYearId: string,
  testTypeId: string,
): Promise<ResultTestModel> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/test-result/for-approval?gradeId=${gradeId}&sectionId=${sectionId}&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};
export const onGetResultForOldApproval = (
  gradeId: string,
  sectionId: string,
  examYearId: string,
  testTypeId: string,
): Promise<ResultTestModel> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/test-result/old-for-approval?gradeId=${gradeId}&sectionId=${sectionId}&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};

export const onGetApprovedResults = (
  gradeId: string,

  examYearId: string,
  testTypeId: string,
): Promise<ResultGetModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/test-result?gradeId=${gradeId}&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};

export const onGetResultByStudentId = (studentId: string): Promise<ResultGetModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/result/${studentId}`);
};

export const onPostResult = (payload: ResultModel): Promise<ResultModel> => {
  return axiosInstance.post(`${http_config.BASE_URL}/test-result`, payload);
};

export const onPutResultForApprovalSave = (
  payload: ApprovalResultUpdateRequestModel,
): Promise<{ message: string }> => {
  return axiosInstance.put(`${http_config.BASE_URL}/test-result`, payload);
};

export const onPutApproveResult = (
  gradeId: string,
  sectionId: string,
  examYearId: string,
  testTypeId: string,
): Promise<{ message: string; success: boolean }> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/test-result/approve-publish?gradeId=${gradeId}&sectionId=${sectionId}&examYearId=${examYearId}&testTypeId=${testTypeId}&action=Approved`,
  );
};

export const onPutPublishResult = (
  gradeId: string,
  sectionId: string,
  examYearId: string,
  testTypeId: string,
): Promise<{ message: string; success: boolean }> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/test-result/approve-publish?gradeId=${gradeId}&sectionId=${sectionId}&examYearId=${examYearId}&testTypeId=${testTypeId}&action=Published`,
  );
};

export const onGetResultStatus = (
  examYearId: string,
  testTypeId: string,
): Promise<ResultStatusResponseModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/result-status?type=test-type&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};

export const onGetAggregatedResultStatus = (
  examYearId: string,
  terminalExamId: string,
): Promise<ResultStatusResponseModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/result-status?type=term-aggregated&examYearId=${examYearId}&terminalExamId=${terminalExamId}`,
  );
};
export const onGetFinalResultStatus = (
  examYearId: string,
  finalExamId: string,
): Promise<ResultStatusResponseModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/result-status?type=final-exam&examYearId=${examYearId}&finalExamId=${finalExamId}`,
  );
};

export const onGetResultAggregationStatus = (
  academicYear: string,
  classId: string,
): Promise<AggregateStatusResponseModel> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/mobile-exams?academic_year=${academicYear}&gradeId=${classId}`,
  );
};

export const onPostApproveAggregate = (
  payload: AggregateResultCreateModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(`${http_config.BASE_URL}/terminal-result`, payload);
};

export const onPutAggregationApproval = (
  examYearId: string,
  terminalExamId: string,
  classId: string,
): Promise<{
  message: string;
  success: boolean;
}> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/terminal-result/approve-publish?examYearId=${examYearId}&gradeId=${classId}&terminalExamId=${terminalExamId}&action=Approved`,
  );
};

export const onPutAggregationPublish = (
  examYearId: string,
  terminalExamId: string,
  classId: string,
): Promise<{
  message: string;
  success: boolean;
}> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/terminal-result/approve-publish?examYearId=${examYearId}&gradeId=${classId}&terminalExamId=${terminalExamId}&action=Published`,
  );
};

export const onPostCreateFinalResult = (
  payload: FinalResultCreateModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(`${http_config.BASE_URL}/final-result`, payload);
};

export const onPutFinalApproval = (
  examYearId: string,
  finalExamId: string,
  classId: string,
): Promise<{
  message: string;
  success: boolean;
}> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/final-result/approve-publish?examYearId=${examYearId}&gradeId=${classId}&finalExamId=${finalExamId}&action=Approved`,
  );
};

export const onPutFinalPublish = (
  examYearId: string,
  finalExamId: string,
  classId: string,
): Promise<{
  message: string;
  success: boolean;
}> => {
  return axiosInstance.put(
    `${http_config.BASE_URL}/final-result/approve-publish?examYearId=${examYearId}&gradeId=${classId}&finalExamId=${finalExamId}&action=Published`,
  );
};

export const onGetTerminalResultForView = (
  examYearId: string,
  terminalExamId: string,
  classId: string,
): Promise<Root> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/terminal-result/for-approval?examYearId=${examYearId}&terminalExamId=${terminalExamId}&gradeId=${classId}`,
  );
};

export const onGetFinalResultForView = (
  examYearId: string,
  terminalExamId: string,
  classId: string,
): Promise<FinalAggregatedResultModel> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/final-result/for-approval?examYearId=${examYearId}&terminalExamId=${terminalExamId}&gradeId=${classId}`,
  );
};
