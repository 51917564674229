import React from 'react';
import {
  onEditStudent,
  onSetStudents,
  onStudentBulkUpload,
  onStudentCodeDownload,
  onStudentIdCodeUpdate,
  onUnlinkParent,
} from '../../../data/student/student.service';
import { BalanceModel, StudentEditPayload, StudentResponseModel } from 'data/student/student.model';
import { ParentResponseModel } from 'data/parent/parent.model';
import {
  onSetParents,
  onUpdateParent,
  onUpdateStaff,
  onUpdateTeacher,
} from 'data/parent/parent.service';
import { IssueBookModel } from 'data/library/issueBook/issueBook.model';
import { onSetIssueBook } from 'data/library/issueBook/issueBook.service';
import { CardModel } from 'data/card/card.model';
import { onUpdateBalance } from 'data/balance/balance.service';
import { onSetCard } from 'data/card/card.service';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import {
  AssignParentToStudentModel,
  CreateStudentAndAssignNewParentModel,
  CreateStudentAndAssignParentModel,
  ManagerResponseModel,
} from 'data/manager/manager.model';
import {
  onAssignParentToStudent,
  onAssignParentsToStudent,
  onSetManagers,
  onSetStudentAndAssignNewParent,
  onSetStudentAndAssignParent,
} from 'data/manager/manager.service';
import { SupportPersonResponseModel } from 'data/supportPerson/supportPerson.model';
import { onSetSupportPerson } from 'data/supportPerson/supportPerson.service';
import { TeacherResponseModel } from 'data/teacher/teacher.model';
import { onSetTeachers } from 'data/teacher/teacher.service';
import { AlertNoticeModel } from 'data/alert/alert.model';
import { onSetAlertNotice } from 'data/alert/alert.service';
import { FormInstance } from 'antd/lib/form';
import { useUser } from './useUser';
import toast from 'react-hot-toast';

import { FaDoorClosed } from 'react-icons/fa';
import { onSetOtherStaffs } from 'data/otherStaff/otherStaff.service';

export const useSave = () => {
  const [error, seterror] = React.useState<string | null | boolean>(null);
  const { fetchBalance } = useUser();

  const onBulkUpload = async (payload: any) => {
    toast.promise(
      onStudentBulkUpload(payload).then(() => {}),
      {
        loading: 'Uploading students',
        success: 'Success',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const onCreateStudentAndAssignParent = async (
    payload: CreateStudentAndAssignParentModel,
    form: FormInstance,
    onVisibleChange: any,
    afterFuncs: any,
  ) => {
    toast.promise(
      onSetStudentAndAssignParent(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        afterFuncs();
      }),
      {
        loading: 'Creating new student and assigning parent',
        success: 'Student Created and new parent assigned',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  //   const onAssignParentToStudent = async (
  //     payload: { studentId: string; parentId: string },
  //     form: FormInstance,
  //     onVisibleChange: any,
  //     afterFuncs: any,
  //   ) => {
  //     toast.promise(
  //       onAssignParent(payload).then(() => {
  //         form.resetFields();
  //         onVisibleChange(false);
  //         afterFuncs();
  //       }),
  //       {
  //         loading: 'Assigning parent',
  //         success: 'Parent assigned',
  //         error: (error) => decodeApiMessage(error),
  //       },
  //     );
  //   };
  const onCreateStudentAndAssignNewParent = async (
    payload: CreateStudentAndAssignNewParentModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onSetStudentAndAssignNewParent(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Creating new student and assigning new parent',
        success: 'Student Created and new parent assigned',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onSetStudentAndAssignNewParent(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Student Created and new parent assigned');
    // } catch (error) {
    //   seterror(error);
    //   onVisibleChange(true);
    //   setReloadAfterDrawer(false);
    //   notifyError('Error', decodeApiMessage(error));
    // }
  };

  const onStudentUserCodeDownload = async () => {
    return await onStudentCodeDownload();
  };

  const onStudentEdit = async (
    payload: StudentEditPayload,
    form: FormInstance,
    handleAfterMath: Function,
  ) => {
    toast.promise(
      onEditStudent(payload).then(() => {
        form.resetFields();
        handleAfterMath();
      }),
      {
        loading: 'Editing student',
        success: 'Student successfully edited.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const onStudentCodeUpdate = async (payload: Array<{ userId: string; code: string }>) => {
    toast.promise(
      onStudentIdCodeUpdate(payload).then(() => {}),
      {
        loading: 'Updating codes',
        success: 'Codes successfully updated.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const onStudentParentUnlink = async (
    parentUserId: string,
    studentId: string,
    form: FormInstance,
    handleAfterMath: Function,
  ) => {
    toast.promise(
      onUnlinkParent({
        parentIds: [parentUserId],
        studentId: studentId,
      }).then(() => {
        form.resetFields();
        handleAfterMath();
      }),
      {
        loading: 'Unlinking parents',
        success: 'Parents unlinked.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const onStudentSave = async (
    payload: StudentResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onSetStudents(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Creating new student',
        success: 'Student successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onSetStudents(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Student successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   setReloadAfterDrawer(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onParentSave = async (
    payload: ParentResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onSetParents(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Creating new parent',
        success: 'Parent successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onSetParents(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Parent successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   setReloadAfterDrawer(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onParentEdit = async (
    id: string,
    payload: ParentResponseModel,
    form: FormInstance,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onUpdateParent(id, payload).then(() => {
        form.resetFields();
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Updating parent',
        success: 'Parent successfully updated.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  const onTeacherEdit = async (
    id: string,
    payload: ParentResponseModel,
    form: FormInstance,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onUpdateTeacher(id, payload).then(() => {
        form.resetFields();
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Updating teacher',
        success: 'Teacher successfully updated.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  const onOtherStaffEdit = async (
    id: string,
    payload: ParentResponseModel,
    form: FormInstance,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onUpdateStaff(id, payload).then(() => {
        form.resetFields();
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Updating staff',
        success: 'Staff successfully updated.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  const onManagerSave = async (
    payload: ManagerResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    // try {
    //   await onSetManagers(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Manager successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   setReloadAfterDrawer(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetManagers(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Adding manager.',
        success: 'Manager successfully added.',
        error: (error) => {
          onVisibleChange(true);
          setReloadAfterDrawer(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onSupportPersonSave = async (
    payload: SupportPersonResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    // try {
    //   await onSetSupportPerson(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Support Person successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   setReloadAfterDrawer(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetSupportPerson(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Adding support person.',
        success: 'Support Person successfully added.',
        error: (error) => {
          onVisibleChange(true);
          setReloadAfterDrawer(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onTeacherSave = async (
    payload: TeacherResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onSetTeachers(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Adding teacher.',
        success: 'Teacher successfully added.',
        error: (error) => {
          onVisibleChange(true);
          setReloadAfterDrawer(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onNonTeachingSave = async (
    payload: TeacherResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    toast.promise(
      onSetOtherStaffs(payload as any).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Adding non teaching staff.',
        success: 'Non teaching staff successfully added.',
        error: (error) => {
          onVisibleChange(true);
          setReloadAfterDrawer(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const onIssueBook = async (payload: IssueBookModel, form: FormInstance, onVisibleChange: any) => {
    // try {
    //   await onSetIssueBook(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //   });
    //   notifySuccess('Success', 'Successfully book issued.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetIssueBook(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
      }),
      {
        loading: 'Issuing book.',
        success: 'Successfully book issued.',
        error: (error) => {
          onVisibleChange(true);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const onLoadBalance = async (
    cardNumber: string,
    payload: BalanceModel,
    form: FormInstance,
    onVisibleChange: any,
    setReloadAfterDrawer: any,
  ) => {
    // try {
    //   await onUpdateBalance(cardNumber, payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setReloadAfterDrawer(true);
    //   });
    //   notifySuccess('Success', 'Successfully balance added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(false);
    //   setReloadAfterDrawer(false);

    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onUpdateBalance(cardNumber, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setReloadAfterDrawer(true);
      }),
      {
        loading: 'Adding balance.',
        success: 'Successfully balance added.',
        error: (error) => {
          onVisibleChange(false);
          setReloadAfterDrawer(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const onCardSave = async (userId: string, payload: CardModel, onFinish: VoidFunction) => {
    // try {
    //   await onSetCard(userId, payload);
    //   onFinish();
    //   notifySuccess('Success', 'Card successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetCard(userId, payload).then(() => {
        onFinish();
      }),
      {
        loading: 'Adding card.',
        success: 'Card successfully added.',
        error: (error) => {
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onAssignPToS = async (
    payload: AssignParentToStudentModel,
    form: FormInstance,
    onVisibleChange: any,
    setSearchClicked: any,
  ) => {
    // try {
    //   await onAssignParentToStudent(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setSearchClicked(false);
    //   });
    //   notifySuccess('Success', 'Parent successfully assigned to Student.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onAssignParentToStudent(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setSearchClicked(false);
      }),
      {
        loading: 'Assigning parent to student.',
        success: 'Parent successfully assigned to Student.',
        error: (error) => {
          onVisibleChange(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onAssignManyParentsToStudent = async (
    payload: { studentId: string; parentIds: string[] },
    form: FormInstance,
    onVisibleChange: any,
    setSearchClicked: any,
  ) => {
    // try {
    //   await onAssignParentToStudent(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     setSearchClicked(false);
    //   });
    //   notifySuccess('Success', 'Parent successfully assigned to Student.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onAssignParentsToStudent(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        setSearchClicked(false);
      }),
      {
        loading: 'Assigning parents to student.',
        success: 'Parent successfully assigned to Student.',
        error: (error) => {
          onVisibleChange(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onAlertNoticeSave = async (
    payload: AlertNoticeModel,
    form: FormInstance,
    onVisibleChange: any,
  ) => {
    // try {
    //   await onSetAlertNotice(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //   });
    //   notifySuccess('Success', 'Alert Notice successfully added.');
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(false);

    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetAlertNotice(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
      }),
      {
        loading: 'Adding alert notice.',
        success: 'Alert Notice successfully added.',
        error: (error) => {
          onVisibleChange(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  return {
    onStudentSave,
    onParentSave,
    onManagerSave,
    onSupportPersonSave,
    onTeacherSave,
    onIssueBook,
    onLoadBalance,
    onCardSave,
    onAssignPToS,
    onAlertNoticeSave,
    onCreateStudentAndAssignParent,
    onCreateStudentAndAssignNewParent,
    onStudentEdit,
    onStudentParentUnlink,
    onStudentUserCodeDownload,
    onStudentCodeUpdate,
    onBulkUpload,
    onParentEdit,
    onAssignManyParentsToStudent,
    onAssignParentToStudent,
    onTeacherEdit,
    onOtherStaffEdit,
    onNonTeachingSave,
  };
};
