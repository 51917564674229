import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Drawer } from 'antd';
import AddBookDetails from './addBookDetails';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useBook } from '../hooks/useBook';

interface Props {}

const BookView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const book = useSelector((state: RootState) => state.book);
  const [reloadBook, setReloadBook] = React.useState(true);
  const { fetchBook } = useBook();

  const TableColumn = () => [
    {
      name: 'Book Title',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Author',
      selector: 'author',
      sortable: true,
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const memoBookReload = useMemo(() => reloadBook, [reloadBook]);

  React.useEffect(() => {
    if (memoBookReload) {
      fetchBook();
      setReloadBook(false);
    }
  }, [memoBookReload]);

  return (
    <BookViewWrapper>
      <KPageHeader title="Book" rightButtonTitle="Add Book" onRightButtonClick={showDrawer} />
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Add Book</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        {<AddBookDetails onVisibleChange={setVisible} onSetReloadBook={setReloadBook} />}
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the books that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive={true}
          data={book.data}
          columns={TableColumn()}
        />
      </section>
    </BookViewWrapper>
  );
};

export const BookViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

export default BookView;
