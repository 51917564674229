import React from 'react';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { Button, Col, Drawer, Row, Tag } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import styled from 'styled-components';
import KProTip from 'common/component/KProTip';
import KPageHeader from 'common/component/KPageHeader';
import DataTable, { TableColumn } from 'react-data-table-component';
import AddAcademicYear from './AddAcademicYear';
import { useAcademicYear } from '../hooks/useAcademicYear';

interface Props {}

const DataTableColumn = (
  handleActivateYear: (year: string) => void,
): TableColumn<{ year: string; active: number }>[] => [
  {
    name: 'AcademicYear',
    selector: 'year',
    sortable: true,
  },
  {
    name: 'Active',
    cell: (row): JSX.Element => (
      <>
        <Tag color={row.active ? 'teal' : 'lightgrey'}>{row.active ? 'Yes' : 'No'}</Tag>
      </>
    ),
  },
  {
    name: 'Actions',
    cell: (row): JSX.Element => (
      <Row gutter={16}>
        <Col>
          <Button
            disabled={!!row.active}
            icon={<BulbOutlined />}
            onClick={(): void => handleActivateYear(row.year)}
          >
            Activate
          </Button>
        </Col>
      </Row>
    ),
  },
];

const AcademicYearView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const academicYears = useSelector((state: RootState) => state.academicYear);
  const [reloadAcademicYear, setReloadAcademicYear] = React.useState(true);
  const { fetchAcademicYear, onSetAcademicYearActive } = useAcademicYear();

  const handleActivateYear = (year: string): void => {
    onSetAcademicYearActive({ year }, setReloadAcademicYear);
    window.location.reload();
  };

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  React.useEffect(() => {
    if (reloadAcademicYear) {
      fetchAcademicYear();
      setReloadAcademicYear(false);
    }
  }, [reloadAcademicYear]);

  return (
    <AcademicYearViewWrapper>
      <KPageHeader
        title="AcademicYear"
        rightButtonTitle="Add AcademicYear"
        onRightButtonClick={showDrawer}
      />
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Add AcademicYear</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddAcademicYear
          onVisibleChange={setVisible}
          onSetReloadAcademicYear={setReloadAcademicYear}
        />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the academic years that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive
          data={academicYears?.data.map((item) => ({
            year: item.year,
            active: item.active ? 1 : 0,
          }))}
          columns={DataTableColumn(handleActivateYear)}
        />
      </section>
    </AcademicYearViewWrapper>
  );
};

export const AcademicYearViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

export default AcademicYearView;
