import React from 'react';
import { Form, Button, Input, Select } from 'antd';
import { AddBookWrapper } from './book.style';
import { useBook } from '../hooks/useBook';
import { grade_type } from 'common/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';

export const AddBookDetails = (props: any) => {
  const { onSaveBook } = useBook();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadBook } = props;
  const grade = useSelector((state: RootState) => state.grade);
  const { fetchGrade } = useGrade();
  const handleSubmit = () => {
    onSaveBook({ ...form.getFieldsValue() }, form, onVisibleChange, onSetReloadBook);
  };
  React.useEffect(() => {
    fetchGrade();
  }, []);
  return (
    <AddBookWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="grade" label="Grade">
          <Select defaultValue=" Select Grade">
            {grade.data.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="author" label="Author">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddBookWrapper>
  );
};

export default AddBookDetails;
