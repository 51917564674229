import { FormInstance } from 'antd/lib/form';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import {
  handleDeleteSection,
  handleUpdateSection,
  onGetSections,
  onSetSection,
} from 'data/section/section.service';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import sectionSlice from 'redux/slice/section.slice';

export const useSection = () => {
  const dispatch = useDispatch();
  const fetchSections = async () => {
    dispatch(sectionSlice.actions.setLoading());
    try {
      const res = await onGetSections();
      dispatch(sectionSlice.actions.setData(res));
    } catch (err) {
      dispatch(sectionSlice.actions.setError(err));
    }
  };

  const onSaveSection = async (
    payload: { value: string },
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onSetSection(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Section successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetSection(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding section.',
        success: 'Section successfully added.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onUpdateSection = async (
    payload: { id: string; name: string },
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await handleUpdateSection(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Section successfully updated.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      handleUpdateSection(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating section.',
        success: 'Section successfully updated.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onDeleteSection = async (payload: { id: string }, onReload: any) => {
    // try {
    //   await handleDeleteSection(payload).then(() => {
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Section successfully deleted.');
    // } catch (err) {
    //   onReload(true);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      handleDeleteSection(payload).then(() => {
        onReload(true);
      }),
      {
        loading: 'Deleting section.',
        success: 'Section successfully deleted.',
        error: (error) => {
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  return { fetchSections, onSaveSection, onDeleteSection, onUpdateSection };
};
