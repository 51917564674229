export const grade_type = [
  { id: 1, title: 'Class 1', value: '1' },
  { id: 2, title: 'Class 2', value: '2' },
  { id: 3, title: 'Class 3', value: '3' },
  { id: 4, title: 'Class 4', value: '4' },
  { id: 5, title: 'Class 5', value: '5' },
  { id: 6, title: 'Class 6', value: '6' },
  { id: 7, title: 'Class 7', value: '7' },
  { id: 8, title: 'Class 8', value: '8' },
  { id: 9, title: 'Class 9', value: '9' },
  { id: 10, title: 'Class 10 ', value: '10 ' },
];

export const month_type = [
  { id: 1, title: 'Baishakh', value: '01' },
  { id: 2, title: 'Jestha', value: '02' },
  { id: 3, title: 'Ashadh', value: '03' },
  { id: 4, title: 'Shrawan', value: '04' },
  { id: 5, title: 'Bhadra', value: '05' },
  { id: 6, title: 'Ashoj', value: '06' },
  { id: 7, title: 'Kartik', value: '07' },
  { id: 8, title: 'Mangsir', value: '08' },
  { id: 9, title: 'Poush', value: '09' },
  { id: 10, title: 'Magh', value: '10' },
  { id: 11, title: 'Falgun', value: '11' },
  { id: 12, title: 'Chaitra', value: '12' },
];

export const year_type = ['2019', '2020', '2021'];

export const day_type = [
  { id: 1, title: 'Sunday', value: 1 },
  { id: 2, title: 'Monday', value: 2 },
  { id: 3, title: 'Tuesday', value: 3 },
  { id: 4, title: 'Wednesday', value: 4 },
  { id: 5, title: 'Thursday', value: 5 },
  { id: 6, title: 'Friday', value: 6 },
  { id: 7, title: 'Saturday', value: 7 },
];

export const weekDays = [
  { id: 1, title: 'SUN', value: '1' },
  { id: 2, title: 'MON', value: '2' },
  { id: 3, title: 'TUE', value: '3' },
  { id: 4, title: 'WED', value: '4' },
  { id: 5, title: 'THU', value: '5' },
  { id: 6, title: 'FRI', value: '6' },
  { id: 7, title: 'SAT', value: '7' },
];

export const EXAM_TYPES = [
  { label: 'TEST TYPE', value: 'test-type' },
  { label: 'AGGREGATE', value: 'term-aggregated' },
  { label: 'FINAL', value: 'final-exam' },
];
