import React from 'react';
import {
  AlertOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Modal } from 'antd';
import SelectExamYear from 'common/component/Inputs/SelectExamYear';
import SelectFinalExamType from 'common/component/Inputs/SelectFinalExamType';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { ResultStatusText } from 'data/result/result.model';
import DataTable, { TableColumn } from 'react-data-table-component';
import { KButton } from 'common/component/KButton';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import { useResult } from '../hooks/useResult';
import ResultFinalAggregationForm from './ResultFinalAggregationForm';
import ViewFinalResults from './ViewFinalResults';

export const ResultWrapper = styled.div`
  padding-right: 20px;
  height: 100%;

  .section-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
  }

  .page-controls {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  .page-tabs {
    width: 100%;
    display: grid;
    grid-template-columns: 200px 200px;
    gap: 20px;
  }

  .viewField {
    width: 100%;
    height: calc(100% - 200px - 36px);
    overflow-y: auto;
    border-radius: 10px;
    background: white;

    .noContent {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;
    }
  }
`;

const CustomButton = styled(Button)`
  background: ${({ disabled }): string => (!disabled ? '#763535' : 'var(--gray700)')};
  color: ${({ disabled }): string => (!disabled ? 'white' : 'black')};
  border-color: ${({ disabled }): string => (!disabled ? '#763535' : 'var(--gray700)')};
  padding: 0.75em 2.2em;
  margin: 0.5em 0;
  height: auto;
  width: auto;
`;

type ActionFunction = (classId: string) => void;

const colors: Record<ResultStatusText, string> = {
  Preparing: '#FF0000',
  Prepared: '#FFA500',
  Approved: '#008000',
  Published: '#008000',
};
const statusToInfo: Record<ResultStatusText, string> = {
  Published: 'Print Ready, Available in App',
  Approved: 'Ready to Print & Publish',
  Prepared: 'Ready for Approval',
  Preparing: 'Collecting Results',
};

const ResultListTableModel = (
  onApprove: ActionFunction,
  onView: ActionFunction,
  onPrint: ActionFunction,
  onPublish: ActionFunction,
  onPrepare: ActionFunction,
): TableColumn<{
  class: string;
  status: ResultStatusText;
}>[] => [
  {
    name: 'Class',
    cell: (row): JSX.Element => <>{row.class}</>,
    grow: 0,
  },
  {
    name: '',
    cell: (row): JSX.Element => (
      <>
        {['Approved', 'Published'].includes(row.status) ? (
          <i>Already {row.status}</i>
        ) : (
          <CustomButton onClick={(): void => onApprove(row.class)}>Approve</CustomButton>
        )}
      </>
    ),
  },
  // {
  //   name: '',
  //   cell: (row): JSX.Element => (
  //     <CustomButton
  //       disabled={!['Approved', 'Published'].includes(row.status)}
  //       onClick={(): void => onPrint(row.class)}
  //     >
  //       Print
  //     </CustomButton>
  //   ),
  // },
  {
    name: '',
    cell: (row): JSX.Element => (
      <CustomButton
        disabled={row.status !== 'Approved'}
        onClick={(): void => onPublish(row.class)}
        style={{ width: 132 }}
      >
        {row.status === 'Published' ? <span style={{ color: 'green' }}>Published</span> : 'Publish'}
      </CustomButton>
    ),
  },
  {
    name: 'Status',
    cell: (row): JSX.Element => (
      <span
        style={{
          color: colors[row.status],
          fontWeight: 600,
        }}
      >
        {statusToInfo[row.status]}
      </span>
    ),
  },
  {
    name: 'Action',
    cell: (row): JSX.Element => {
      // eslint-disable-next-line
      return row.status === 'Preparing' ? (
        <KButton onClick={(): void => onPrepare(row.class)}>Prepare Aggregation</KButton>
      ) : (
        <Button type="text" icon={<EyeOutlined />} onClick={(): void => onView(row.class)}>
          View
        </Button>
      );
    },
  },
];

const FinalResultView = (): JSX.Element => {
  const [selected, setSelected] = React.useState({
    selectedExamYear: '',
    selectedFinalExamId: '',
  });
  const [resultAggregation, setResultAggregation] = React.useState<{
    examYearId: string;
    finalExamId: string;
    classId: string;
  } | null>(null);

  const [resultView, setResultView] = React.useState<{
    examYearId: string;
    finalExamId: string;
    classId: string;
  } | null>(null);

  const resultStatus = useSelector((state: RootState) => state.resultStatus);
  const examFinalTypes = useSelector((state: RootState) => state.examFinalType);

  const { fetchFinalResultStatus, approveFinalResult, publishFinalResult } = useResult();

  const showResultAggregation: ActionFunction = (classId): void => {
    setResultAggregation({
      examYearId: selected.selectedExamYear,
      finalExamId: selected.selectedFinalExamId,
      classId,
    });
  };

  const showResultView: ActionFunction = (classId): void => {
    setResultView({
      examYearId: selected.selectedExamYear,
      finalExamId: selected.selectedFinalExamId,
      classId,
    });
  };
  const closeResultAggregation = (): void => {
    setResultAggregation(null);
  };

  const closeResultView = (): void => {
    setResultView(null);
  };

  React.useEffect(() => {
    if (selected.selectedExamYear.length && selected.selectedFinalExamId.length) {
      fetchFinalResultStatus(selected.selectedExamYear, selected.selectedFinalExamId);
    }
  }, [selected]);

  const reload = (): void => {
    fetchFinalResultStatus(selected.selectedExamYear, selected.selectedFinalExamId);
  };

  const classes = React.useMemo(() => {
    if (!selected.selectedFinalExamId.length) return [];

    const tp = examFinalTypes.data.find((t) => t.id === selected.selectedFinalExamId);
    if (!tp) return [];

    return tp.grades;
  }, [selected.selectedFinalExamId, examFinalTypes.data]);

  const onView = (classId: string): void => {
    showResultView(classId);
  };
  const onVerify = (classId: string): void => {
    console.log('Verify: ', classId);
    Modal.confirm({
      title: 'Are you sure of approval?',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      keyboard: false,
      closable: true,
      width: 600,
      content: 'This action cannot be undone.',
      onOk() {
        approveFinalResult(
          selected.selectedExamYear,
          selected.selectedFinalExamId,
          classId,
          reload,
        );
      },
      okText: 'Yes, Approve!',
    });
  };
  const onPrint = (classId: string): void => {
    console.log('Print: ', classId);
  };
  const onPublish = (classId: string): void => {
    Modal.confirm({
      title: 'Confirm Result Publish',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      keyboard: false,
      closable: true,
      okText: 'Yes, Publish!',
      width: 600,
      content: (
        <>
          <ul style={{ padding: 0 }}>
            <li>This action will publish results to mobile application.</li>
            <li>This action cannot be undone.</li>
          </ul>
        </>
      ),
      onOk() {
        publishFinalResult(
          selected.selectedExamYear,
          selected.selectedFinalExamId,
          classId,
          reload,
        );
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const fields = React.useMemo(() => {
    const flds: Array<{
      class: string;
      status: ResultStatusText;
    }> = [];
    if (resultStatus.status === 'data') {
      classes.map((cls) => {
        const status = resultStatus.data.find((rs) => rs.grade.id === cls.id);

        if (status) {
          flds.push({
            class: cls.id,
            status: status.status,
          });
        }
      });
    }

    return flds;
  }, [classes, resultStatus]);

  return (
    <>
      <ResultWrapper>
        {/* header */}
        <KPageHeader title="Final Results" />
        <KProTip
          title="Page Hint"
          description="This page is for viewing and managing all the final results of students that are currently in this system"
        />

        <section className="section-wrapper">
          {/* controls */}
          <section className="page-tabs">
            <SelectExamYear
              onChange={(value): void => {
                setSelected((prevSelected) => ({ ...prevSelected, selectedExamYear: value }));
              }}
            />
            <SelectFinalExamType
              examYear={selected.selectedExamYear}
              onChange={(value): void => {
                setSelected((prevSelected) => ({ ...prevSelected, selectedFinalExamId: value }));
              }}
            />
          </section>

          <section className="page-controls">
            <Button
              type="link"
              loading={resultStatus.status === 'loading'}
              onClick={reload}
              icon={<ReloadOutlined />}
            />
          </section>
        </section>

        {/* content */}
        <main className="viewField">
          {!selected.selectedExamYear.length || !selected.selectedFinalExamId.length ? (
            <div className="noContent">
              <AlertOutlined style={{ fontSize: 32 }} />
              <p>Please, select an exam year and type to get started.</p>
            </div>
          ) : (
            <>
              <DataTable
                data={fields}
                columns={ResultListTableModel(
                  onVerify,
                  onView,
                  onPrint,
                  onPublish,
                  showResultAggregation,
                )}
                progressPending={resultStatus.status === 'loading'}
              />
            </>
          )}
        </main>
      </ResultWrapper>

      {/* portals */}
      <Drawer
        title={<h2>Aggregate Results</h2>}
        width={800}
        visible={!!resultAggregation}
        closable
        onClose={closeResultAggregation}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {resultAggregation ? (
          <ResultFinalAggregationForm
            onFinish={closeResultAggregation}
            // eslint-disable-next-line
            {...resultAggregation}
          />
        ) : (
          <></>
        )}
      </Drawer>
      <Drawer
        title={<h2>View Final Aggregated Results</h2>}
        width={1300}
        visible={!!resultView}
        closable
        onClose={closeResultView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {resultView ? (
          <ViewFinalResults
            // eslint-disable-next-line
            {...resultView}
          />
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};

export default FinalResultView;
