import React from 'react';
import { Form } from 'antd';
import { useResult } from '../hooks/useResult';
import { ResultStyle } from './result.style';
import { useUser } from '../../users/hooks/useUser';
import NewSubjectAndMarksForm from './NewSubjectAndMarksForm';

interface StudentMarks {
  studentId: string;
  obtained_mark: string;
  studentName: string;
  gpa: string;
}

interface ResultUpload {
  examYearId: string;
  testTypeId: string;
  classId: string;
  sectionId: string;
}

export const ResultPrepareForm = (props: any) => {
  const { onFinish, resultIdentifiers } = props;
  const [form] = Form.useForm();

  const { fetchGradeStudent } = useUser();
  const { publishResult } = useResult();
  const [formDetails, setFormDetails] = React.useState<ResultUpload>({
    classId: '',
    testTypeId: '',
    examYearId: '',
    sectionId: '',
  });

  React.useEffect(() => {
    setFormDetails({ ...resultIdentifiers });
  }, [resultIdentifiers]);

  React.useEffect(() => {
    if (formDetails.sectionId.length) fetchGradeStudent(formDetails.classId, formDetails.sectionId);
  }, [formDetails.sectionId]);

  const onResultSave = (
    marks: StudentMarks[],
    subjectData: { subjectId: string; optionalSubjectId?: string },
  ): void => {
    const { classId, ...rest } = formDetails;

    publishResult(
      {
        ...rest,
        ...subjectData,
        gradeId: classId,
        marksEntries: marks.map((item) => ({
          studentId: item.studentId,
          obtained_mark: Number(item.obtained_mark),
        })),
      },
      form,
      onFinish,
    );
  };

  return (
    <ResultStyle>
      <Form form={form} size="large" layout="vertical">
        <main style={{ marginTop: 10 }}>
          <NewSubjectAndMarksForm values={formDetails} onSubmit={onResultSave} />
        </main>
      </Form>
    </ResultStyle>
  );
};

export default ResultPrepareForm;
