import RoleConfig from 'common/config/role_config';

export default (role: number) => {
  switch (role) {
    case RoleConfig.SchoolManager:
      return 'manager';
    case RoleConfig.SchoolAdmin:
      return 'admin';
    case RoleConfig.SupportPerson:
      return 'support';
    default:
      return '';
  }
};
