import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StaffAttendanceResponseModel } from 'data/attendance/attendance.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: StaffAttendanceResponseModel[];
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: [],
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<StaffAttendanceResponseModel[]>> = (
  _,
  action,
) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: [],
  status: 'error',
});

const staffAttendanceSlice = createSlice({
  name: 'staff-attendance',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default staffAttendanceSlice;
