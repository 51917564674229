export const cleanGrade = (grade: string) => {
  return grade.split('-')[0];
};

export const cleanSection = (section: string) => {
  const sectionArray = section.split('-');

  return `${sectionArray[0]}-${sectionArray[2]}`;
};

export const cleanGradeSection = (gradeSection: string | number) => {
  // 1-2080 => 1
  // 1-2080-A-2080 => 1-A
  console.log({ gradeSection });

  const gradeSectionArray = `${gradeSection}`.split('-');

  return gradeSectionArray[0] + (gradeSectionArray.length > 2 ? '-' + gradeSectionArray[2] : '');
};

// export const cleanSubjectTitle = (subjectTitle = '') => {
//   // MATH-BM-6 -> MATH-BM
//   if (subjectTitle === '') return null;

//   const subjectTitleArray = subjectTitle.split('-');
//   return subjectTitleArray[0] + '-' + subjectTitleArray[1] ? subjectTitleArray[1] : '';
// };

export const cleanObj = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined || obj[key] === null ? delete obj[key] : {},
  );
  return obj;
};
