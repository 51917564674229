import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { CreateMultipleRoutines, RoutineModel } from './routine.model';

export const onGetRoutine = (grade: string, section: string): Promise<RoutineModel[]> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/routine?grade=' + grade + '&section=' + section,
  );
};

export const onSetRoutine = (payload: CreateMultipleRoutines): Promise<{ message: string }> => {
  return axiosInstance.post(http_config.BASE_URL + '/routine', payload);
};

export const onEditRoutine = (id: string, payload: RoutineModel): Promise<RoutineModel> => {
  return axiosInstance.put(http_config.BASE_URL + '/routine/' + id, payload);
};

export const onDeleteRoutine = (id: string): Promise<RoutineModel> => {
  return axiosInstance.delete(http_config.BASE_URL + '/routine/' + id);
};
