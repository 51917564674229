import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectExamType from 'common/component/Inputs/SelectExamType';
import SelectExamYear from 'common/component/Inputs/SelectExamYear';
import SelectMultipleSection from 'common/component/Inputs/SelectMultipleClassSection';
import SelectSubjectCascaded from 'common/component/Inputs/SelectSubjectCascaded';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import { useExamRoutine } from '../hooks/useExamRoutine';

interface ExamRoutineFields {
  examYearId: string;
  testTypeId: string;
  classId: string;
  sectionIds: string[];
  start: string;
  end: string;
}

interface ExamSubjectRoutine {
  subjectId: string;
  optionalSubjectId?: string;
  date: string;
  note?: string;
}

const RoutineFormWrapper = styled.div`
  width: 100%;
`;
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1em;

  input,
  .ant-select,
  .ant-select-selector,
  button {
    height: 38px !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-select-selection-search-input {
    margin-top: 2px;
  }

  .routine-field {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 3fr) 2fr;
    gap: 0.4em;
    margin-top: 0.5em;
  }
`;

// eslint-disable-next-line
const AddExamRoutines = (props: any): JSX.Element => {
  // eslint-disable-next-line
  const { onVisibleChange, onSetReloadExamRoutine, grade, examType } = props;

  const { publishExamRoutine } = useExamRoutine();

  const [routineValues, setRoutineValues] = React.useState<ExamRoutineFields>({
    classId: '',
    examYearId: '',
    testTypeId: '',
    start: '',
    end: '',
    sectionIds: [],
  });

  const [subjectRoutines, setSubjectRoutines] = React.useState<ExamSubjectRoutine[]>([
    {
      date: '',
      subjectId: '',
    },
  ]);

  const addSubjectRoutine = (): void => {
    setSubjectRoutines([
      ...subjectRoutines,
      {
        date: '',
        subjectId: '',
      },
    ]);
  };

  const updateRoutineField = (index: number) => (field: 'date' | 'note') => (
    value: string,
  ): void => {
    const copied_routines = [...subjectRoutines];
    const routine_field = copied_routines[index];

    routine_field[field] = value;
    copied_routines[index] = routine_field;

    setSubjectRoutines(copied_routines);
  };

  const updateRoutineSubjectField = (index: number) => (value: string[]): void => {
    const [main, opt] = value;

    const copied_routines = [...subjectRoutines];
    let routine_field = copied_routines[index];

    // eslint-disable-next-line
    const { optionalSubjectId, ...rest } = routine_field;

    routine_field = { ...rest, subjectId: main };
    if (opt) routine_field = { ...routine_field, optionalSubjectId: opt };

    copied_routines[index] = routine_field;

    setSubjectRoutines(copied_routines);
  };

  const [form] = Form.useForm();
  const examYear = useSelector((state: RootState) => state.examYear);

  const updateRoutineValues = (
    field: 'classId' | 'examYearId' | 'testTypeId' | 'start' | 'end',
  ) => (value: string): void => {
    setRoutineValues({
      ...routineValues,
      [field]: value,
    });
  };
  const updateRoutineEventValues = (field: 'start' | 'end') => (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRoutineValues({
      ...routineValues,
      [field]: event.target.value,
    });
  };
  const updateRoutineArrayValues = (field: 'sectionIds') => (value: string[]): void => {
    setRoutineValues({
      ...routineValues,
      [field]: value,
    });
  };

  const respective_exam_type = React.useMemo(() => {
    const year = examYear.data.find((yr) => yr.id === routineValues.examYearId);
    if (!year) return null;

    const tp = year.test_types.find((t) => t.id === routineValues.testTypeId);
    if (!tp) return null;

    return tp;
  }, [routineValues, examYear.data]);

  const routineFields = React.useMemo(() => {
    const fields = subjectRoutines.map((rt, index) => (
      <div className="routine-field">
        <Form.Item required label="Date">
          <DatePicker
            style={{ width: '100%', height: 38 }}
            disabledDate={(dt): boolean => dt.isBefore(moment(new Date()).subtract(1, 'day'))}
            value={rt.date.length ? moment(rt.date) : undefined}
            onChange={(val): void => updateRoutineField(index)('date')(val?.toString() || '')}
          />
        </Form.Item>
        <Form.Item required label="Subject">
          <SelectSubjectCascaded
            value={[rt.subjectId, rt.optionalSubjectId || '']}
            gradeId={routineValues.classId}
            onChange={(val): void => updateRoutineSubjectField(index)(val)}
          />
        </Form.Item>
        <Form.Item label="Note (Optional)">
          <Input
            value={rt.note || undefined}
            placeholder="Any note or remarks"
            onChange={(val): void => updateRoutineField(index)('note')(val.target.value)}
          />
        </Form.Item>
      </div>
    ));

    return fields;
  }, [subjectRoutines, routineValues]);

  const onSubmit = (): void => {
    const {
      examYearId,
      testTypeId,
      classId,
      sectionIds,
      start,
      end,
    } = form.getFieldsValue() as ExamRoutineFields;

    publishExamRoutine(
      {
        start,
        end,
        examYearId,
        testTypeId,
        gradeId: classId,
        sectionIds,
        examRoutines: subjectRoutines,
      },
      form,
      onVisibleChange,
      onSetReloadExamRoutine,
    );
  };

  return (
    <RoutineFormWrapper>
      <StyledForm form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item name="examYearId" label="Exam Year" required>
              <SelectExamYear onChange={updateRoutineValues('examYearId')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="testTypeId" label="Exam Type" required>
              <SelectExamType
                examYear={routineValues.examYearId}
                onChange={updateRoutineValues('testTypeId')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15} style={{ marginTop: 0 }}>
          <Col span={12}>
            <Form.Item name="classId" label="Class" required>
              <SelectClass
                examType={respective_exam_type}
                onChange={updateRoutineValues('classId')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="sectionIds" label="Section(s)">
              <SelectMultipleSection
                gradeId={routineValues.classId}
                onChange={updateRoutineArrayValues('sectionIds')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15} style={{ marginTop: 0 }}>
          <Col span={12}>
            <Form.Item name="start" label="Start Time" required>
              <Input type="time" onChange={updateRoutineEventValues('start')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="end" label="End Time" required>
              <Input type="time" onChange={updateRoutineEventValues('end')} />
            </Form.Item>
          </Col>
        </Row>

        <div className="fields">{routineFields}</div>

        <Button icon={<PlusOutlined />} onClick={addSubjectRoutine}>
          Add Another Routine Field
        </Button>

        <hr />

        <Button htmlType="submit" type="primary">
          Save Routines
        </Button>
      </StyledForm>
    </RoutineFormWrapper>
  );
};

export default AddExamRoutines;
