import { FormInstance } from 'antd/lib/form';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import { SubjectCreateModel } from 'data/subject/subject.model';
import {
  onBulkSetSubject,
  onGetResultSubject,
  onGetSubjects,
  onPutAssignStudents,
  onSetSubject,
  onUpdateSubject,
} from 'data/subject/subject.service';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import resultSubjectStatusSlice from 'redux/slice/resultSubjectStatus.slice';
import subjectSlice from 'redux/slice/subject.slice';

export const useSubject = () => {
  const dispatch = useDispatch();

  const fetchSubject = async () => {
    dispatch(subjectSlice.actions.setLoading());
    try {
      const res = await onGetSubjects();
      dispatch(subjectSlice.actions.setData(res));
    } catch (err) {
      dispatch(subjectSlice.actions.setError(err));
    }
  };

  //   const downloadSubjectCsv = async () => {
  //     try {
  //       const res = await onGetSubjects();
  //       const csvData = res.map((item) => ({
  //         SubjectId: item.subjectId,
  //         SubjectName: item.subjectName,
  //         SubjectCode: item.subjectCode,
  //         SubjectType: item.subjectType,
  //       }));
  //       return csvData;
  //     } catch (err) {
  //       dispatch(subjectSlice.actions.setError(err));
  //     }
  //   }

  const fetchResultSubjectStatus = async (
    examYearId: string,
    testTypeId: string,
    classId: string,
    sectionId: string,
  ): Promise<void> => {
    dispatch(resultSubjectStatusSlice.actions.setLoading());
    try {
      const res = await onGetResultSubject(examYearId, testTypeId, classId, sectionId);
      dispatch(resultSubjectStatusSlice.actions.setData(res));
    } catch (err) {
      dispatch(resultSubjectStatusSlice.actions.setError(err));
    }
  };

  const onSaveSubject = async (
    payload: SubjectCreateModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      onSetSubject(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding subject',
        success: 'Subject successfully added.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onBulkSaveSubjects = async (payload: any) => {
    toast.promise(onBulkSetSubject(payload), {
      loading: 'Adding subjects',
      success: 'Subjects successfully added.',
      error: (error) => {
        return decodeApiMessage(error);
      },
    });
  };
  const onEditSubject = async (
    subjectId: string,
    payload: SubjectCreateModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      onUpdateSubject(subjectId, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Editing subject',
        success: 'Subject successfully edited.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const onAssignStudents = async (
    optSubId: string,
    studenIds: string[],
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   await onPutAssignStudents(optSubId, studenIds).then(onFinish);
    //   notifySuccess('Success', 'Students assigned successfully.');
    // } catch (err) {
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onPutAssignStudents(optSubId, studenIds).then(() => {
        onFinish();
      }),
      {
        loading: 'Assigning students.',
        success: 'Students assigned successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  return {
    fetchResultSubjectStatus,
    fetchSubject,
    onAssignStudents,
    onSaveSubject,
    onEditSubject,
    onBulkSaveSubjects,
  };
};
