import React from 'react';
import { Button, Form, Input, Steps, Tabs } from 'antd';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import CreateStudentAndAssignNewParent from './components/CreateStudentAndAssignNewParent';
import CreateStudentAndAssignParent from './components/CreateStudentAndAssignParent';

interface StudentDetails {
  full_name: string;
  email: string;
  phone_number: string;
  password: string;
  address: string;
  grade: string;
  section: string;
}

type StudentDetailsUpdater = (
  field: 'address' | 'email' | 'full_name' | 'grade' | 'password' | 'phone_number' | 'section',
) => (ev: React.ChangeEvent<HTMLInputElement>) => void;

const StudentForm = ({
  formDetails,
  onFormDetailsChange,
  onSubmit,
}: {
  formDetails: StudentDetails;
  onFormDetailsChange: StudentDetailsUpdater;
  onSubmit: (values: StudentDetails) => void;
}): JSX.Element => {
  const canSubmit = React.useMemo(() => {
    const { full_name, address, grade, section, password } = formDetails;

    if (!full_name.length) return false;
    if (!address.length) return false;
    if (!grade.length) return false;
    if (!section.length) return false;
    if (!password.length) return false;

    return true;
  }, [formDetails]);

  return (
    <Form
      layout="vertical"
      style={{ gap: '2em', display: 'flex', flexDirection: 'column' }}
      onFinish={onSubmit}
    >
      <Form.Item label="Full Name" name="full_name" required>
        <Input value={formDetails.full_name} onChange={onFormDetailsChange('full_name')} />
      </Form.Item>
      <Form.Item label="Address" name="address" required>
        <Input value={formDetails.address} onChange={onFormDetailsChange('address')} />
      </Form.Item>
      <Form.Item
        label="Email (optional)"
        name="email"
        rules={[{ type: 'email', message: 'Please input a valid email address.' }]}
      >
        <Input value={formDetails.email} onChange={onFormDetailsChange('email')} />
      </Form.Item>
      <Form.Item label="Phone Number (optional)" name="phone_number">
        <Input value={formDetails.phone_number} onChange={onFormDetailsChange('phone_number')} />
      </Form.Item>

      <div style={{ display: 'grid', gridTemplateColumns: '5fr 5fr', gap: '2em' }}>
        <Form.Item label="Class" name="grade" style={{ marginRight: 20 }} required>
          <div style={{ width: '100%' }}>
            <SelectClass
              value={formDetails.grade}
              onChange={(value: string): void => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onFormDetailsChange('grade')({ target: { value } } as any);
              }}
            />
          </div>
        </Form.Item>
        <Form.Item label="Section" name="section" required>
          <div style={{ width: '100%' }}>
            <SelectSection
              gradeId={formDetails.grade}
              value={formDetails.section}
              onChange={(value: string): void => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onFormDetailsChange('section')({ target: { value } } as any);
              }}
            />
          </div>
        </Form.Item>
      </div>

      <Form.Item label="Password" name="password" required>
        <Input.Password value={formDetails.password} onChange={onFormDetailsChange('password')} />
      </Form.Item>

      <Button disabled={!canSubmit} htmlType="submit">
        Add Parent Details Now
      </Button>
    </Form>
  );
};

const ParentForm = ({ values, ...props }: { values: StudentDetails }): JSX.Element => {
  return (
    <Tabs centered>
      <Tabs.TabPane key="student" tab="Add New Parent">
        <CreateStudentAndAssignNewParent
          studentDetails={{ ...values }}
          onSetReloadAfterDrawer={(props as any).onSetReloadAfterDrawer as any}
          onVisibleChange={(props as any).onVisibleChange as any}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="parent" tab="Assign Existing Parent">
        <CreateStudentAndAssignParent
          studentDetails={{ ...values }}
          onSetReloadAfterDrawer={(props as any).onSetReloadAfterDrawer as any}
          onVisibleChange={(props as any).onVisibleChange as any}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

const AddStudentFormStepped = (props: any): JSX.Element => {
  const { onVisibleChange, onSetReloadAfterDrawer } = props;

  const [stepPosition, setStepPosition] = React.useState<'student' | 'parent'>('student');

  const [formDetails, setFormDetails] = React.useState<StudentDetails>({
    address: '',
    email: '',
    full_name: '',
    grade: '',
    password: '',
    phone_number: '',
    section: '',
  });

  const onProceed = (): void => {
    setStepPosition('parent');
  };

  const onFormDetailsChange: StudentDetailsUpdater = (field) => (ev): void => {
    console.log({ [field]: ev.target.value });

    setFormDetails({
      ...formDetails,
      [field]: ev.target.value,
    });
  };

  return (
    <>
      <Steps
        type="default"
        direction="horizontal"
        labelPlacement="vertical"
        onChange={(step): void => setStepPosition(step ? 'parent' : 'student')}
        current={Number(stepPosition === 'parent')}
      >
        <Steps.Step title="Student Details" key="step-student-details" />
        <Steps.Step
          title="Parent's Details"
          key="step-parent-details"
          disabled={!formDetails.address.length}
        />
      </Steps>

      <main style={{ margin: '3em 0' }}>
        {stepPosition === 'student' ? (
          <StudentForm
            onSubmit={onProceed}
            formDetails={formDetails}
            onFormDetailsChange={onFormDetailsChange}
          />
        ) : (
          <ParentForm values={formDetails} {...props} />
        )}
      </main>
    </>
  );
};

export default AddStudentFormStepped;
