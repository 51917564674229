import { FormInstance } from 'antd/lib/form';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import { cleanGradeSection } from 'common/utils/functions';
import { GradePostModel, GradeUpdateModel, GradeUpdateSectionModel } from 'data/grade/grade.model';
import {
  onGetGrade,
  onGetGradeSubjects,
  onSetGrade,
  onEditGrade,
  onEditSectionGrade,
} from 'data/grade/grade.service';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import gradeSlice from 'redux/slice/grade.slice';
import gradeSubjectsSlice from 'redux/slice/gradeSubject.slice';

export const useGrade = () => {
  const dispatch = useDispatch();
  const fetchGrade = async () => {
    dispatch(gradeSlice.actions.setLoading());
    try {
      const res = await onGetGrade();
      const sortedRes = res.sort((a, b) => {
        if (isNaN(+cleanGradeSection(a.id))) {
          return -1;
        }
        return +cleanGradeSection(a.id) - +cleanGradeSection(b.id);
      });

      dispatch(gradeSlice.actions.setData(sortedRes));
    } catch (err) {
      dispatch(gradeSlice.actions.setError(err));
    }
  };

  const fetchGradeSubjects = async (grade: string) => {
    dispatch(gradeSubjectsSlice.actions.setLoading());
    try {
      const res = await onGetGradeSubjects(grade);
      dispatch(gradeSubjectsSlice.actions.setData(res));
    } catch (err) {
      dispatch(gradeSubjectsSlice.actions.setError(err));
    }
  };

  const onSaveGrade = async (
    payload: GradePostModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onSetGrade(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Grade successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetGrade(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding grade',
        success: 'Grade successfully added.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onUpdateGrade = async (
    grade: string,
    payload: GradeUpdateModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onEditGrade(grade, payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Grade successfully updated.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onEditGrade(grade, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating grade',
        success: 'Grade successfully updated.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const onUpdateGradeSection = async (
    grade: string,
    payload: GradeUpdateSectionModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onEditSectionGrade(grade, payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Grade successfully updated.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onEditSectionGrade(grade, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating grade section',
        success: 'Grade section successfully updated.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  return { fetchGrade, fetchGradeSubjects, onSaveGrade, onUpdateGrade, onUpdateGradeSection };
};
