import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reply, SupportResponseModel } from 'data/support/support.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: SupportResponseModel | null;
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: null,
  error: false,
};

const setLoading: CaseReducer<SliceState, PayloadAction<SupportResponseModel>> = (_, action) => ({
  ...initState,
  data: action.payload,
  status: 'loading',
});

const setData: CaseReducer<SliceState, PayloadAction<SupportResponseModel | null>> = (
  _,
  action,
) => ({
  error: false,
  data: action.payload,
  status: action.payload ? 'data' : 'idle',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: state.data,
  status: 'error',
});

const setNewReply: CaseReducer<SliceState, PayloadAction<Reply>> = (state, action) => {
  if (!state.data) return initState;

  return {
    error: false,
    status: 'data',
    data: {
      ...state.data,
      __replies__: [...state.data.__replies__, action.payload],
    },
  };
};

const activeSupportSlice = createSlice({
  name: 'activeSupport',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
    setNewReply,
  },
});

export default activeSupportSlice;
