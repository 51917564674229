import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

interface RichTextViewProps {
  value: ReactQuill.Value;
}

const RichTextView = ({ value }: RichTextViewProps): JSX.Element => {
  return <ReactQuill readOnly theme="bubble" defaultValue={value} />;
};

export default RichTextView;
