import React from 'react';

const DigitalClock = (): JSX.Element => {
  const [currentTime, setCurrentTime] = React.useState(new Date().toLocaleTimeString());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return (): void => {
      clearInterval(timer);
    };
  }, []);

  return (
    <section className="date-time">
      <p>{currentTime}</p>
    </section>
  );
};

export default DigitalClock;
