import React from 'react';
import { Button, Collapse, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import SelectSubjectCascaded from 'common/component/Inputs/SelectSubjectCascaded';
import { useSubject } from '../hooks/useSubject';
import KSpinner from 'common/component/KSpinner';

interface StudentMarks {
  studentId: string;
  obtained_mark: string;
  studentName: string;
  gpa: string;
}

interface ResultUpload {
  examYearId: string;
  testTypeId: string;
  classId: string;
  sectionId: string;
}

type ResultDetailsUpdater = (
  field: 'examYear' | 'testTypeId' | 'class' | 'section' | 'subject',
) => (value: string) => void;

type CollapseKeys = 'subject' | 'marks';

interface NewSubjectAndMarksFormProps {
  values: ResultUpload;
  onSubmit?: (
    marks: StudentMarks[],
    subjectData: {
      subjectId: string;
      optionalSubjectId?: string;
    },
  ) => void;
}

const nf = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

const NewSubjectAndMarksForm = ({ values, onSubmit }: NewSubjectAndMarksFormProps): JSX.Element => {
  const gradeSubjects = useSelector((state: RootState) => state.gradeSubjects);
  const studentGrade = useSelector((state: RootState) => state.student);
  const resultSubjectsStatus = useSelector((state: RootState) => state.resultSubjectStatus);

  const [selectedSubject, setSelectedSubject] = React.useState<{
    subjectId: string;
    optionalSubjectId?: string;
  }>({ subjectId: '' });

  const [examFullMark, setExamFullMark] = React.useState<number>(0);
  const [trackCollapse, setTrackCollapse] = React.useState<CollapseKeys>('subject');
  const [result, setResult] = React.useState<StudentMarks[]>([]);

  const { configData } = useSchoolConfig();
  const { fetchResultSubjectStatus } = useSubject();

  const [form] = Form.useForm();

  const updateRoutineSubjectField = (value: string[]): void => {
    const [main, opt] = value;

    if (!main) {
      setSelectedSubject({ subjectId: '' });
      return;
    }

    let subjectData: {
      subjectId: string;
      optionalSubjectId?: string;
    } = { subjectId: main };

    if (opt) subjectData = { ...subjectData, optionalSubjectId: opt };

    setSelectedSubject(subjectData);
  };

  const sortedStudents = React.useMemo(() => {
    // eslint-disable-next-line
    return [...result].sort((a, b) => (a.studentName < b.studentName ? -1 : 1));
  }, [result]);

  const sortedGpaConfig = React.useMemo(() => {
    return [...configData.data.gpaConfig].sort((a, b) => a.max - b.max);
  }, [configData.data.gpaConfig]);

  const selectedSubjectObject = React.useMemo(() => {
    return gradeSubjects.data.subjects.find((s) => s.id === selectedSubject.subjectId);
  }, [gradeSubjects.data.subjects, selectedSubject.subjectId]);

  const subjectMultiplier = React.useMemo(() => {
    const sfm = Number(selectedSubjectObject?.full_mark || '0');
    return examFullMark ? sfm / examFullMark : 1;
  }, [selectedSubjectObject, examFullMark]);

  const updateObtainedMarks = (studentId: string) => (
    ev: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const local_result = [...result];
    const inst = local_result.findIndex((item: StudentMarks) => item.studentId === studentId);
    if (inst < 0) return;
    local_result[inst] = {
      ...local_result[inst],
      obtained_mark: ev.target.value,
    } as StudentMarks;
    setResult(local_result);
  };

  const subject_full_mark = React.useMemo(() => Number(selectedSubjectObject?.full_mark || '100'), [
    selectedSubjectObject,
  ]);

  const subject_pass_mark = React.useMemo(() => Number(selectedSubjectObject?.pass_mark || '0'), [
    selectedSubjectObject,
  ]);

  const getGPA = React.useCallback(
    (adjusted: string, subjectFM: number) => {
      const perc = (Number(adjusted) * 100) / subjectFM;

      //   fail condition
      if (perc < sortedGpaConfig[0]?.min) return 'NG';

      const gradeConfig = sortedGpaConfig?.find((gc) => gc.min <= perc && perc <= gc.max);

      //   invalid condition
      if (!gradeConfig) return '-';

      //   valid & pass condition
      return gradeConfig.gpa;
    },
    [sortedGpaConfig],
  );

  const adjustedLocalMarks = React.useMemo(() => {
    return sortedStudents.map((item) => {
      const adjusted = nf.format(Number(item.obtained_mark) * subjectMultiplier);
      const gpa = getGPA(adjusted, subject_full_mark);

      return {
        ...item,
        gpa: `${adjusted} [${gpa}]`,
        obtained_mark: adjusted,
      };
    });
  }, [sortedStudents, subjectMultiplier, subject_full_mark]);

  const validateMarks = React.useCallback((mark: string, _subject_full_mark: number) => {
    const nObtained = Number(mark);
    return nObtained >= 0 && nObtained <= _subject_full_mark;
  }, []);

  const conditionalStyle = React.useCallback(
    (adjusted: string) => {
      if (!validateMarks(adjusted, subject_full_mark)) {
        return { color: 'red' } as React.CSSProperties;
      }
      if (Number(adjusted) < subject_pass_mark) return { color: 'black' } as React.CSSProperties;
      return { color: 'green' } as React.CSSProperties;
    },
    [subject_pass_mark, subject_full_mark, examFullMark],
  );

  React.useEffect(() => {
    if (selectedSubjectObject) {
      setExamFullMark(Number(selectedSubjectObject.full_mark));
    }
  }, [selectedSubjectObject]);

  React.useEffect(() => {
    if (studentGrade.data?.length) {
      const loc = [
        ...studentGrade.data.map(
          (item): StudentMarks => ({
            obtained_mark: '0',
            studentId: item?.__user__.studentId || '',
            gpa: '',
            studentName: item?.__user__.full_name || '',
          }),
        ),
      ];

      setResult(loc);
    }
  }, [studentGrade.data]);

  React.useEffect(() => {
    if (!values.examYearId.length) return;
    if (!values.testTypeId.length) return;
    if (!values.classId.length) return;
    if (!values.sectionId.length) return;

    fetchResultSubjectStatus(
      values.examYearId,
      values.testTypeId,
      values.classId,
      values.sectionId,
    );
  }, [values]);

  const subjectsWithResults = React.useMemo(() => {
    return resultSubjectsStatus.data
      .flatMap((item) => {
        if (!item.subject.optional) {
          return item.already_entry ? item.subject.id : '';
        }

        return (
          item.subject.optionalSubjects?.map((oItem) => {
            return oItem.already_entry ? oItem.opt_subject.id : '';
          }) || []
        );
      })
      .filter((item) => item.length);
  }, [resultSubjectsStatus.data]);

  const allAdjustedMarksOkay = React.useMemo(() => {
    let allGood: boolean = true;
    adjustedLocalMarks.forEach((adj): void => {
      if (!validateMarks(adj.obtained_mark, subject_full_mark)) {
        allGood = false;
      }
    });
    return allGood;
  }, [adjustedLocalMarks, subject_full_mark]);

  const chosenSubject = React.useMemo(() => {
    if (!selectedSubject.subjectId?.length) return null;
    return (
      gradeSubjects.data.subjects.find((item) => item.id === selectedSubject.subjectId) ?? null
    );
  }, [gradeSubjects.data, selectedSubject]);

  return (
    <>
      <Form form={form}>
        <Collapse
          accordion
          bordered={false}
          activeKey={trackCollapse}
          onChange={(key): void => {
            if (typeof key === 'string') {
              setTrackCollapse(key as CollapseKeys);
            }
          }}
        >
          <Collapse.Panel
            key="subject"
            header={
              trackCollapse === 'marks'
                ? `[${chosenSubject?.code || ''}] ${chosenSubject?.title ||
                    ''} / [F.M.: ${examFullMark}]`
                : 'Select Subject and Exam Full Mark'
            }
          >
            <Form.Item name="subjectId" label="Subject">
              <div className="subjectInput">
                <div style={{ width: '100%' }}>
                  <SelectSubjectCascaded
                    gradeId={values.classId}
                    value={[selectedSubject.subjectId, selectedSubject.optionalSubjectId || '']}
                    onChange={updateRoutineSubjectField}
                    disabled={subjectsWithResults}
                  />
                </div>
                <div className="threshold_mark">
                  <span>Pass Mark</span>
                  <input
                    type="text"
                    readOnly
                    disabled={!selectedSubject.subjectId?.length}
                    value={parseInt(selectedSubjectObject?.pass_mark || '0', 10)}
                    style={{ border: 'none', background: 'transparent' }}
                  />
                </div>
                <div className="threshold_mark">
                  <span>Full Mark</span>
                  <input
                    type="text"
                    readOnly
                    disabled={!selectedSubject.subjectId?.length}
                    value={parseInt(selectedSubjectObject?.full_mark || '0', 10)}
                    style={{ border: 'none', background: 'transparent' }}
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item label="Exam Full Mark">
              <input
                type="number"
                inputMode="numeric"
                value={examFullMark}
                onChange={(ev): void => setExamFullMark(Number(ev.target.value))}
              />
            </Form.Item>

            <div
              className="controls"
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Button
                disabled={!selectedSubject.subjectId?.length || !examFullMark}
                onClick={(): void => {
                  setTrackCollapse('marks');
                }}
                style={{ margin: 0 }}
              >
                Add Marks
              </Button>
              <small style={{ color: '#339900' }}>
                <b>
                  {!selectedSubject.subjectId?.length || !examFullMark
                    ? 'All fields required.'
                    : ''}
                </b>
              </small>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key="marks"
            header="Enter marks"
            disabled={!selectedSubject.subjectId?.length}
            extra={
              !allAdjustedMarksOkay ? (
                <small style={{ color: 'red' }}>Some marks are invalid.</small>
              ) : (
                <></>
              )
            }
          >
            <div className="markList">
              <div className="studentMarks">
                <div className="studentName">
                  <b>Student Names</b>
                </div>
                <div className="studentObtainedMarks">
                  <b>Obt. Mark</b>
                </div>
                <div className="studentObtainedMarks">
                  <b>Adj. Mark</b>
                </div>
              </div>

              <div className="marks">
                {!selectedSubject.subjectId.length ? (
                  <pre>
                    <i>Select a subject first.</i>
                  </pre>
                ) : (
                  sortedStudents.map((item, index) => {
                    return (
                      <div key={item.studentId} className="studentMarks">
                        <div
                          className="studentName"
                          style={conditionalStyle(adjustedLocalMarks[index].obtained_mark)}
                        >
                          {item.studentName}
                        </div>
                        <div className="studentObtainedMarks">
                          <Input
                            type="number"
                            inputMode="numeric"
                            value={item.obtained_mark}
                            style={conditionalStyle(adjustedLocalMarks[index].obtained_mark)}
                            min={0}
                            onChange={updateObtainedMarks(item.studentId)}
                          />
                        </div>
                        <div className="studentObtainedMarks">
                          <Input
                            type="text"
                            value={adjustedLocalMarks[index].gpa}
                            style={{
                              ...conditionalStyle(adjustedLocalMarks[index].obtained_mark),
                              border: 'none',
                              background: 'transparent',
                            }}
                            min={0}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div
              className="controls"
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Button
                disabled={!allAdjustedMarksOkay}
                onClick={(): void => {
                  if (onSubmit) onSubmit(adjustedLocalMarks, selectedSubject);
                }}
                style={{ margin: 0 }}
              >
                Add Marks
              </Button>
              <Button
                onClick={(): void => {
                  setTrackCollapse('subject');
                }}
                style={{ margin: 0 }}
              >
                Previous
              </Button>
            </div>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </>
  );
};

export default NewSubjectAndMarksForm;
