import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import styled from 'styled-components';
import { Button, Drawer, Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useSubject } from 'features/result/hooks/useSubject';
import { OptionalSubjectModel, SubjectModel } from 'data/subject/subject.model';
import AddNewOptionalSubject from './AddNewOptionalSubject';
import AddSubjectDetails from './AddSubjectDetails';
import useOptionalSubject from '../hooks/useOptionalSubject';
import AssignStudents from './AssignStudents';
import SelectClass from 'common/component/Inputs/SelectClass';
import EditSubjectDetails from './EditSubjectDetails';

// eslint-disable-next-line
interface Props {}

const MainTableColumn = (
  onAction: (oSubId: string) => void,
  onEdit: (subejct: any) => void,
): TableColumn<SubjectModel>[] => [
  {
    name: 'Subject',
    selector: 'title',
  },
  {
    name: 'Code',
    selector: 'code',
  },
  {
    name: 'Class',
    selector: 'gradeId',
  },
  {
    name: 'Full Mark',
    selector: 'full_mark',
  },
  {
    name: 'Pass Mark',
    selector: 'pass_mark',
  },
  {
    name: 'Optional',
    cell: (row): JSX.Element => {
      return row.optional ? <>{row.optionalSubjects.map((oSub) => oSub.code).join(', ')}</> : <></>;
    },
  },
  {
    name: 'Action',
    cell: (row): JSX.Element => {
      return row.optional ? (
        <div>
          <Button type="link" onClick={(): void => onAction(row.id)}>
            Assign Students
          </Button>
          <Button type="link" onClick={(): void => onEdit(row)}>
            Edit
          </Button>
        </div>
      ) : (
        <>
          <Button type="link" onClick={(): void => onEdit(row)}>
            Edit
          </Button>
        </>
      );
    },
  },
];

const OptionalTableColumn = (): TableColumn<OptionalSubjectModel>[] => [
  {
    name: 'Opt. Subject',
    selector: 'title',
  },
  {
    name: 'Code',
    selector: 'code',
  },
];

const SubjectView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const [editVisible, setEditVisible] = React.useState(false);
  const [showAddOptional, setShowAddOptional] = React.useState<boolean>(false);
  const subjects = useSelector((state: RootState) => state.subject);
  const optionalSubject = useSelector((state: RootState) => state.optionalSubject);
  const [reloadSubject, setReloadSubject] = React.useState(true);
  const { fetchSubject } = useSubject();
  const { fetchOptionalSubject } = useOptionalSubject();

  const [activeSubId, setActiveSubId] = React.useState<string | null>(null);
  const [selectedClass, setSelectedClass] = React.useState<string>('');
  const [selectedSubject, setSelectedSubject] = React.useState<string>('');

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };
  const onEditClose = (): void => {
    setEditVisible(false);
  };
  const openStudentAssignment = (stdId: string): void => {
    setActiveSubId(stdId);
  };
  const handleSubjectEdit = (subject: any): void => {
    setEditVisible(true);
    setSelectedSubject(subject);
  };
  const closeStudentAssignment = (): void => {
    setActiveSubId(null);
  };

  const memoSubjectReload = useMemo(() => reloadSubject, [reloadSubject]);

  const onNewOptionalSubjectAdded = (): void => {
    setShowAddOptional(false);
  };

  const subjectsData = React.useMemo(() => {
    if (!selectedClass.length) return subjects.data;
    return subjects.data.filter((sub) => sub.gradeId === selectedClass);
  }, [subjects.data, selectedClass]);

  React.useEffect(() => {
    if (memoSubjectReload) {
      fetchSubject();
      fetchOptionalSubject();
      setReloadSubject(false);
    }
  }, [memoSubjectReload]);

  return (
    <>
      <SubjectViewWrapper>
        <KPageHeader
          title="Subject"
          rightButtonTitle="Add Subject"
          onRightButtonClick={showDrawer}
          additionalButtonTitle="Add Optional Subject"
          onAdditionButtonClick={(): void => {
            setShowAddOptional(true);
          }}
        />
        <Drawer
          destroyOnClose
          width={705}
          title={<h2 style={{ fontWeight: 700 }}>Add Subject</h2>}
          placement="right"
          onClose={onClose}
          visible={visible}
          closable={false}
        >
          <AddSubjectDetails onVisibleChange={setVisible} onSetReloadSubject={setReloadSubject} />
        </Drawer>
        <Drawer
          destroyOnClose
          width={705}
          title={<h2 style={{ fontWeight: 700 }}>Edit Subject</h2>}
          placement="right"
          onClose={onEditClose}
          visible={editVisible}
          closable={false}
        >
          <EditSubjectDetails
            onVisibleChange={setEditVisible}
            onSetReloadSubject={setReloadSubject}
            selectedSubject={selectedSubject}
          />
        </Drawer>
        <KProTip
          title="Page Hint"
          description="This page is for viewing and managing all the subjects that are currently in this system"
        />
        <Tabs>
          <Tabs.TabPane key="main-subjects" tab="Main Subjects">
            <div className="filters">
              <h4>Filters:</h4>
              <div className="control">
                <SelectClass onChange={(classId) => setSelectedClass(classId)} />
              </div>
              {selectedClass.length && !subjectsData.length ? (
                <small style={{ color: 'red' }}>
                  <i>Sorry, no result found.</i>
                </small>
              ) : (
                <></>
              )}
            </div>

            <section className="dataTable">
              <DataTable
                customStyles={tableCustomStyles}
                className="data-table"
                noHeader
                highlightOnHover
                pagination
                responsive
                progressPending={subjects.status === 'loading'}
                data={subjectsData}
                columns={MainTableColumn(openStudentAssignment, handleSubjectEdit)}
              />
            </section>
          </Tabs.TabPane>
          <Tabs.TabPane key="optional-subjects" tab="Optional Subjects">
            <section className="dataTable">
              <DataTable
                customStyles={tableCustomStyles}
                className="data-table"
                noHeader
                highlightOnHover
                pagination
                responsive
                progressPending={optionalSubject.status === 'loading'}
                data={optionalSubject.data}
                columns={OptionalTableColumn()}
              />
            </section>
          </Tabs.TabPane>
        </Tabs>
      </SubjectViewWrapper>
      <Drawer
        destroyOnClose
        visible={!!activeSubId}
        onClose={closeStudentAssignment}
        title="Assign Students to Optional Subject"
        width={700}
      >
        {activeSubId ? (
          <AssignStudents subjectId={activeSubId} onFinish={closeStudentAssignment} />
        ) : (
          <></>
        )}
      </Drawer>
      <Modal
        centered
        destroyOnClose
        title="Add New Optional Subject"
        visible={showAddOptional}
        onCancel={(): void => {
          setShowAddOptional(false);
        }}
        footer={null}
      >
        <AddNewOptionalSubject onFinish={onNewOptionalSubjectAdded} />
      </Modal>
    </>
  );
};

export const SubjectViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }

  .filters {
    width: 100%;
    margin: 0 0 1em 0;
    padding: 0 1em;

    gap: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .control {
      width: 250px;
    }

    h4 {
      margin: 0;
    }
  }
`;

// const SubjectView = () => <h1>Hellop</h1>;

export default SubjectView;
