import KSpinner from 'common/component/KSpinner';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import TestMarksheet from '.';

const BulkPrintWrapper = styled.div`
  display: none;

  @media print {
    display: block;

    @page {
      size: A4 portrait;
    }
  }
`;
interface Subject {
  subjectId: string;
  title: string;
  full_mark: string;
  pass_mark: string;
  obtained_mark: string;
  gpa: string;
}

interface RecordType {
  studentName: string;
  overAllGpa: string;
  studentRoll: number;
  position: string;
  percentage: string;
  remarks: string;
  totalFM: string;
  totalOM: string;
  section: string;
  subjects: Subject[];
}

interface PrintBulk {
  issueDate: string;
  examYearId: string;
  terminalExamId?: string;
  testExamId?: string;
  metaSchool: {
    schoolName: string;
    principal: string;
    principalSignature: string;
    examCoordinator: string;
    examCoordinatorSignature: string;
    schoolLogo: string;
    schoolAddress: string;
  };
  records: RecordType[];
}

interface PrintBulkProps {
  data: PrintBulk;
  onFinish: () => void;
}

const PrintBulk = ({ data, onFinish }: PrintBulkProps) => {
  const tableRef = React.useRef(null);
  const print = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: onFinish,
  });
  React.useEffect(() => {
    print();
  }, []);
  const { issueDate, examYearId, testExamId } = data;
  return (
    <>
      <div className="loader">
        <KSpinner />
      </div>
      <BulkPrintWrapper ref={tableRef}>
        {data.records.map((record) => {
          console.log({ record });
          const tempRecord = {
            subjects: record.subjects.map((subject) => {
              return {
                subjectId: subject.subjectId,
                title: subject.title,
                full_mark: subject.full_mark,
                pass_mark: subject.pass_mark,
                obtained_mark: subject.obtained_mark,
                gpa: subject.gpa,
              };
            }),
            totalFM: record.totalFM,
            totalOM: record.totalOM,
            overAllGpa: record.overAllGpa,
            position: record.position,
            percentage: Number(record.percentage)?.toFixed(2),
            remarks: record.remarks,
          };
          return (
            <TestMarksheet
              metaSchool={data.metaSchool}
              issueDate={issueDate}
              examYearId={examYearId}
              record={tempRecord}
              sectionId={record.section}
              studentName={record.studentName}
              testExamId={testExamId!!}
              studentRoll={record.studentRoll.toString()}
            />
          );
        })}
      </BulkPrintWrapper>
    </>
  );
};

export default PrintBulk;
