import { DatePicker, Empty, Input, Popover, Select } from 'antd';
import { KButton } from 'common/component/KButton';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import KSpinner from 'common/component/KSpinner';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import SelectMonth from 'common/component/Inputs/SelectMonth';
import { useGrade } from '../../routine/hooks/useGrade';
import { useUser } from 'features/users/hooks/useUser';
import useDebounce from 'common/hooks/useDebounce';
import { SearchOutlined } from '@ant-design/icons';
import { StudentResponseModel } from 'data/student/student.model';
import usePagination from 'features/users/hooks/usePagination';
import { useAcademicYear } from 'features/academic-year/hooks/useAcademicYear';
import { useUpgradeStudents } from '../hooks/useUpgradeStudents';

interface Props {}

const studentViewTable = (): TableColumn<StudentResponseModel>[] => [
  {
    name: 'Full name',
    cell: (row): string => row?.__user__.full_name || '',
    grow: 1,
    maxWidth: '500px',
  },
  {
    name: 'Class/Section',
    cell: (row): JSX.Element => <>{row.sectionId.replace('-', ', ')}</>,
  },
];
const UpgradeStudents: React.FC<Props> = () => {
  //   const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const handleRowSelected = React.useCallback((state) => {
    // setSelectedRows(state.selectedRows);
    handleFieldValueUpdate(
      'studentIds',
      state.selectedRows.map((item: any) => item.id),
    );
  }, []);
  const { fetchAcademicYear } = useAcademicYear();
  const { onUpgrade } = useUpgradeStudents();
  const [filters, setFilters] = React.useState<{
    toGrade: string;
    toSection: string;
    fromGrade: string;
    fromSection: string;
  }>({
    toGrade: '',
    toSection: '',
    fromGrade: '',
    fromSection: '',
  });

  const [searchValue, setSearchValue] = React.useState<string>('');
  const [isDisabled, setIsDisabled] = useState(true);

  const students = useSelector((state: RootState) => state.studentData);
  const { fetchStudent } = useUser();
  const academicYears = useSelector((state: RootState) => state.academicYear);
  const handleFiltersUpdate = (field?: any, bodyField?: string) => (value: string): void => {
    setFilters({
      ...filters,
      [field]: value,
    });
    if (bodyField) {
      handleFieldValueUpdate(bodyField, value);
    }
  };
  const [body, setBody] = useState({
    studentIds: [],
    fromGrade: null,
    fromSection: null,
    toGrade: null,
    toSection: null,
    toAcademicYear: null,
  });

  const handleFieldValueUpdate = (field: string, value: any) => {
    setBody((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpgrade = async () => {
    // console.log(body);
    await onUpgrade(body, reload);
  };

  const db_search = useDebounce<string>(searchValue.toLowerCase(), 500);
  const searchFilteredStudents = React.useMemo(() => {
    if (!db_search.length) return students.data.data;

    return [...students.data.data].filter(
      (item) => item?.__user__.full_name.toLowerCase().search(db_search) > -1,
    );
  }, [students.data.data, db_search]);

  const filteredStudents = React.useMemo(() => {
    return [...searchFilteredStudents].sort((a, b) => {
      // eslint-disable-next-line
      //   return a?.__user__.full_name < b?.__user__.full_name ? -1 : 1;
      return a.roll - b.roll;
    });
  }, [searchFilteredStudents]);

  const { handlePageChange, handlePerRowsChange, paginatedData, rowsPerPage } = usePagination(
    filteredStudents,
  );

  const reload = (): void => {
    fetchStudent(1, 10000, filters.fromGrade, filters.fromSection);
  };
  React.useEffect(() => {
    fetchAcademicYear();
  }, []);
  React.useEffect(() => {
    fetchStudent(1, 10000, filters.fromGrade, filters.fromSection);
    handlePageChange(1);
  }, [filters.fromGrade, filters.fromSection]);

  React.useEffect(() => {
    if (
      body.fromGrade &&
      body.fromSection &&
      body.studentIds?.length > 0 &&
      body.toGrade &&
      body.toSection &&
      body.toAcademicYear
    ) {
      setIsDisabled(true);
    }
  }, [
    body.fromGrade,
    body.fromSection,
    body.toAcademicYear,
    body.toGrade,
    body.toSection,
    body.studentIds?.length,
  ]);

  return (
    <UpgradeStudentsViewWrapper>
      <KPageHeader title="Upgrade Students" />
      <KProTip
        title="Page Hint"
        description="This page is for upgrading students from grades to same as well as different grades and sections"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <section style={{ marginRight: 35 }}>
            <h2 style={{ margin: 0 }}>Students</h2>
          </section>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
            <h4 style={{ marginRight: 10 }}>From:</h4>
            <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
              <SelectClass onChange={handleFiltersUpdate('fromGrade', 'fromGrade')} />
            </section>
            <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
              <SelectSection
                gradeId={filters.fromGrade}
                onChange={handleFiltersUpdate('fromSection', 'fromSection')}
              />
            </section>
            <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
              <Input
                placeholder="Search By Name"
                value={searchValue}
                onChange={(ev): void => setSearchValue(ev.target.value)}
                addonAfter={<SearchOutlined />}
              />
            </section>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ marginRight: 29 }}>To:</h4>
            <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
              <SelectClass onChange={handleFiltersUpdate('toGrade', 'toGrade')} />
            </section>
            <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
              <SelectSection
                gradeId={filters.toGrade}
                onChange={handleFiltersUpdate('toSection', 'toSection')}
              />
            </section>
            <section className="page-tabs" style={{ margin: 0, marginRight: 15 }}>
              <Select
                onChange={(value) => handleFieldValueUpdate('toAcademicYear', value)}
                placeholder="Select A Year"
                loading={academicYears.status === 'loading'}
                notFoundContent={
                  // eslint-disable-next-line
                  <Empty>
                    <p>
                      <b>First, add an academic year.</b>
                    </p>
                  </Empty>
                }
              >
                {academicYears.data.map((item) => (
                  <Select.Option key={item.year} value={item.year}>
                    {item.year}
                  </Select.Option>
                ))}
              </Select>
            </section>
          </div>
        </div>
        <div className="relaod">
          <KButton onClick={handleUpgrade}>Upgrade Students</KButton>

          {/* <KButton onClick={reload}>Reload</KButton> */}
        </div>
      </div>
      {students.data?.data ? (
        <>
          <DataTable
            noHeader
            responsive
            pointerOnHover
            highlightOnHover
            pagination
            paginationServer
            customStyles={tableCustomStyles}
            data={paginatedData}
            columns={studentViewTable()}
            // onRowClicked={openViewDetails}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={students.data.data.length}
            progressPending={students.status === 'loading'}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            selectableRows
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
          />
        </>
      ) : (
        <KSpinner />
      )}
    </UpgradeStudentsViewWrapper>
  );
};

export const UpgradeStudentsViewWrapper = styled.div`
  padding-right: 20px;

  .page-table {
    /* width: 80%; */
  }

  .page-tabs {
    margin: 16px 0;
    display: flex;
    justify-content: space-evenly;
    .grade-select-field {
      min-width: 200px;
    }
  }

  .section-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export default UpgradeStudents;
