import React from 'react';
import KSpinner from 'common/component/KSpinner';
import { ViewTransactionsWrapper, Label } from './user.style';
import { StudentResponseModel } from 'data/student/student.model';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import { useUser } from '../hooks/useUser';
import { TransactionModel } from 'data/transactions/transaction.model';
import moment from 'moment';

const ViewTransactions = (props: any) => {
  const { value } = props;
  const data: StudentResponseModel = value;
  const transactions = useSelector((state: RootState) => state.transaction);
  const { fetchTransactions } = useUser();
  const usersTransactions: any = [];

  const TableColumn = () => [
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      cell: (row: TransactionModel) => <div>{row.amount}</div>,
    },
    {
      name: 'Transaction Date',
      selector: 'created_at',
      sortable: true,
      cell: (row: TransactionModel) => <div>{row.created_at}</div>,
    },
  ];

  if (transactions.status === 'data') {
    console.log('from data');
    console.log(transactions.data);
    transactions.data.forEach((item) => {
      console.log(item);
      console.log(item.id);

      console.log(data.userId);

      if (item.userId === data.userId) {
        console.log(item);
        usersTransactions.push({
          ...item,
          created_at: moment(item.created_at).format('YYYY-MM-DD'),
        });
      }
    });
  }

  React.useEffect(() => {
    fetchTransactions(data.__user__.id, data.__user__.card_number);

    // eslint-disable-next-line
  }, []);

  return (
    <ViewTransactionsWrapper>
      <label>Student Name</label>
      <Label>{data.__user__.full_name}</Label>

      <section className="dataTable">
        {transactions.status === 'loading' && <KSpinner />}
        {transactions.status === 'data' && (
          <DataTable
            customStyles={tableCustomStyles}
            highlightOnHover
            responsive={true}
            data={usersTransactions}
            columns={TableColumn()}
          />
        )}
      </section>
    </ViewTransactionsWrapper>
  );
};

export default ViewTransactions;
