import styled from 'styled-components';

export const AddAnnouncementFormWrapper = styled.div`
  input {
    width: 100%;
    padding: 10px;
    height: 38px;
    display: inline-block;
    background: var(--bg);
    border-radius: 4px;
    border: 1px solid var(--gray900);
  }

  button {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }
`;

export const AnnouncementDetailWrapper = styled.div`
  .header {
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--gray900);
    padding: 10px;
    box-shadow: var(--shadow-base);
    margin-bottom: 10px;

    .title {
      font-size: var(--font-base);
      font-weight: 700;
    }
    .date {
      font-size: var(--font-sm);
      font-weight: 500;
      color: var(--grey);
      margin-top: 5px;
    }
  }

  .description {
    width: 100%;
    height: 500px;
    border-radius: 4px;
    border: 1px solid var(--gray900);
    padding: 10px;
    box-shadow: var(--shadow-base);
    margin-top: 10px;
    font-size: var(--font-base);
  }
`;
