import React from 'react';
import { AssignParentFormWrapper } from './user.style';

import AssignParentUI from './AssignParentUI';

export const AssignParent = (props: any) => {
  const { value, onVisibleChange } = props;

  return (
    <AssignParentFormWrapper>
      <AssignParentUI value={value} onVisibleChange={onVisibleChange} showAssignedParents={true} />
    </AssignParentFormWrapper>
  );
};

export default AssignParent;
