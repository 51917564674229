import React from 'react';
import { AddStudentFormWrapper } from './user.style';
import AddStudentFormStepped from './AddStudentForm';

export const AddStudentForm = (props: any) => {
  const { onVisibleChange, onSetReloadAfterDrawer } = props;

  return (
    <AddStudentFormWrapper>
      <AddStudentFormStepped {...props} />
    </AddStudentFormWrapper>
  );
};

export default AddStudentForm;
