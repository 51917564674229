import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoutineModel } from 'data/routine/routine.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: RoutineModel[];
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: [],
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<RoutineModel[]>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: [],
  status: 'error',
});

const resetState = () => initState;

const routineSlice = createSlice({
  name: 'routine',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
    resetState,
  },
});

export default routineSlice;
