import React from 'react';
import { Form, Button, Select, Empty } from 'antd';
import { AddHolidayWrapper } from 'features/holiday/view/holiday.style';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { useExamYear } from '../hooks/useExamYear';
import { useAcademicYear } from 'features/academic-year/hooks/useAcademicYear';

export const AddExamYear = (props: any): JSX.Element => {
  const { onVisibleChange, onSetReloadExamYear } = props;

  const academicYears = useSelector((state: RootState) => state.academicYear);
  const examYears = useSelector((state: RootState) => state.examYear);

  const { onSaveExamYear } = useExamYear();
  const { fetchAcademicYear } = useAcademicYear();
  const [form] = Form.useForm();

  const freeExamYear = React.useMemo(() => {
    // find academic-years that are not already exam-years
    return academicYears.data.filter((ac) => !examYears.data.find((item) => item.id === ac.year));
  }, [academicYears.data, examYears.data]);

  const handleSubmit = (): void => {
    onSaveExamYear(
      {
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadExamYear,
    );
  };

  React.useEffect(() => {
    fetchAcademicYear();
  }, []);

  return (
    <AddHolidayWrapper>
      <Form form={form} size="large" layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="value"
          label="Name"
          required
          rules={[
            {
              required: true,
              message: 'Please select a year.',
            },
          ]}
        >
          <Select
            placeholder="Select A Year"
            loading={academicYears.status === 'loading'}
            notFoundContent={
              // eslint-disable-next-line
              <Empty>
                <p>All academic years are exam years.</p>
                <p>
                  <b>First, add an academic year to create an exam year.</b>
                </p>
              </Empty>
            }
          >
            {freeExamYear.map((item) => (
              <Select.Option key={item.year} value={item.year}>
                {item.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit">SAVE</Button>
        </Form.Item>
      </Form>
    </AddHolidayWrapper>
  );
};

export default AddExamYear;
