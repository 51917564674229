import { CreateMultipleRoutines, RoutineModel } from 'data/routine/routine.model';
import {
  onGetRoutine,
  onSetRoutine,
  onEditRoutine,
  onDeleteRoutine,
} from 'data/routine/routine.service';
import React from 'react';
import { useDispatch } from 'react-redux';
import routineSlice from 'redux/slice/routine.slice';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { onGetTeacherBySearch } from 'data/user/user.service';
import userSlice from 'redux/slice/userByPhoneNumber.slice';
import { FormInstance } from 'antd/lib/form';
import { toast } from 'react-hot-toast';

export const useRoutine = () => {
  const [error, setError] = React.useState<string | null | boolean>(null);
  const dispatch = useDispatch();

  const publishRoutine = async (
    payload: CreateMultipleRoutines,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onSetRoutine(payload).then(() => {
    //     form.resetFields();

    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Routine successfully added.');
    // } catch (err) {
    //   setError(err);
    //   onVisibleChange(true);
    //   onReload(false);

    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetRoutine(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding routine.',
        success: 'Routine successfully added.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const editRoutine = async (
    payload: RoutineModel,
    id: string,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // try {
    //   await onEditRoutine(id, payload).then(() => {
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Routine successfully edited.');
    // } catch (err) {
    //   setError(err);
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onEditRoutine(id, payload).then(() => {
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating routine.',
        success: 'Routine successfully updated.',
        error: (error) => {
          onVisibleChange(true);
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };
  const fetchRoutine = async (grade: string, section: string) => {
    try {
      const res = await onGetRoutine(grade, section);
      dispatch(routineSlice.actions.setData(res));
    } catch (err) {
      setError(err);
    }
  };
  const deleteRoutine = async (payload: RoutineModel, onReload: any) => {
    // try {
    //   await onDeleteRoutine(payload.id);
    //   onReload(true);
    //   notifySuccess('Success', 'Successfully deleted');
    // } catch (err) {
    //   setError(err);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onDeleteRoutine(payload.id).then(() => {
        onReload(true);
      }),
      {
        loading: 'Deleting routine.',
        success: 'Routine successfully deleted.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const fetchTeacherBySearch = async (value: string) => {
    try {
      const res = await onGetTeacherBySearch(value);
      dispatch(userSlice.actions.setData(res));
    } catch (err) {
      setError(err);
    }
  };

  return {
    publishRoutine,
    fetchRoutine,
    editRoutine,
    deleteRoutine,
    fetchTeacherBySearch,
    error,
  };
};
