import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TeacherDataModel } from 'data/teacher/teacher.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: TeacherDataModel;
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: { totalCount: 0, page: 0, limit: 0, data: [] },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<TeacherDataModel>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: { totalCount: 0, page: 0, limit: 0, data: [] },
  status: 'error',
});

const teacherSlice = createSlice({
  name: 'teacher',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default teacherSlice;
