import React from 'react';
import { Form, Button, Input, Checkbox, Row, Col, Select } from 'antd';
import { AddHolidayWrapper } from 'features/holiday/view/holiday.style';
import { useExamType } from 'features/exam-type/hooks/useExamType';
import { useGrade } from 'features/routine/hooks/useGrade';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EXAM_TYPES } from 'common/constants';

interface AddExamYearTypeProps {
  examYear: string;
  onVisibleChange: React.Dispatch<React.SetStateAction<boolean>>;
  onSetReloadExamYear: VoidFunction;
}

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 20px;

  .ant-checkbox {
    margin-right: 5px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 20px;
  margin-bottom: 16px;

  .ant-checkbox {
    margin-right: 5px;
  }
`;

export const AddExamYearType = ({
  examYear,
  onVisibleChange,
  onSetReloadExamYear,
}: AddExamYearTypeProps): JSX.Element => {
  const { onSaveGenericExamType } = useExamType();
  const { fetchGrade } = useGrade();

  const classes = useSelector((state: RootState) => state.grade);
  const [selectedClasses, setSelectedClasses] = React.useState<string[]>([]);

  const [form] = Form.useForm();

  const handleSubmit = (): void => {
    onSaveGenericExamType(
      {
        value: form.getFieldsValue().value,
        type: form.getFieldsValue().type,
        exam_year: examYear,
        grades: selectedClasses,
      },
      form,
      onVisibleChange,
      onSetReloadExamYear,
    );
  };

  const onSelectedClassesChange = (checked: CheckboxValueType[]): void => {
    setSelectedClasses(checked.map((chk) => chk.toString()));
  };
  const onAllClassCheckChange = (checked: CheckboxChangeEvent): void => {
    setSelectedClasses(checked.target.checked ? classes.data.map((cls) => cls.id) : []);
  };

  React.useEffect(() => {
    fetchGrade();
  }, []);

  return (
    <AddHolidayWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item label="Exam Year">
          <Input value={examYear} readOnly />
        </Form.Item>

        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Exam Type"
              required
              rules={[{ required: true, message: 'Select an exam type.' }]}
            >
              <Select placeholder="Select Exam Type">
                {EXAM_TYPES.map((typ) => (
                  <Select.Option value={typ.value} key={typ.value}>
                    {typ.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="value" label="Exam Type Name" required>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Associated Classes">
          <StyledCheckbox
            checked={selectedClasses.length === classes.data.length}
            onChange={onAllClassCheckChange}
          >
            All Classes
          </StyledCheckbox>
          <StyledCheckboxGroup
            value={selectedClasses}
            options={classes.data.map((cls) => cls.id)}
            onChange={onSelectedClassesChange}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddHolidayWrapper>
  );
};

export default AddExamYearType;
