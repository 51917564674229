import {
  AggregateResultCreateModel,
  ApprovalResultUpdateRequestModel,
  FinalResultCreateModel,
  ResultModel,
} from 'data/result/result.model';
import {
  onPostResult,
  onGetResult,
  onGetResultByStudentId,
  onGetResultStatus,
  onGetResultForApproval,
  onPutResultForApprovalSave,
  onPutApproveResult,
  onPutPublishResult,
  onPostApproveAggregate,
  onGetResultAggregationStatus,
  onGetAggregatedResultStatus,
  onGetFinalResultStatus,
  onPutAggregationApproval,
  onPutAggregationPublish,
  onPostCreateFinalResult,
  onGetResultForOldApproval,
  onPutFinalApproval,
  onPutFinalPublish,
} from 'data/result/result.service';
import { useDispatch } from 'react-redux';
import resultSlice from 'redux/slice/result.slice';
import oldResultSlice from 'redux/slice/oldresult.slice';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import studentResultSlice from 'redux/slice/studentResult.slice';
import { FormInstance } from 'antd/lib/form';
import resultStatusSlice from 'redux/slice/resultStatus.slice';
import resultAggregationStatusSlice from 'redux/slice/resultAggregationStatus.slice';
import { toast } from 'react-hot-toast';

export const useResult = () => {
  const dispatch = useDispatch();

  const resetStates = (): void => {
    dispatch(resultStatusSlice.actions.reset());
  };

  // const fetchResult = async (
  //   gradeId: string,
  //   examYearId: string,
  //   testTypeId: string,
  // ): Promise<void> => {
  //   dispatch(resultSlice.actions.setLoading());
  //   try {
  //     const res = await onGetResult(gradeId, examYearId, testTypeId);
  //     dispatch(resultSlice.actions.setData(res));
  //   } catch (err) {
  //     dispatch(resultSlice.actions.setError(err));
  //   }
  // };

  const fetchAggregateStatus = async (examYearId: string, gradeId: string): Promise<void> => {
    dispatch(resultAggregationStatusSlice.actions.setLoading());
    try {
      const res = await onGetResultAggregationStatus(examYearId, gradeId);
      dispatch(resultAggregationStatusSlice.actions.setData(res));
    } catch (err) {
      dispatch(resultAggregationStatusSlice.actions.setError(err));
    }
  };

  const fetchResultForApproval = async (
    gradeId: string,
    sectionId: string,
    examYearId: string,
    testTypeId: string,
  ): Promise<void> => {
    dispatch(resultSlice.actions.setLoading());
    try {
      const res = await onGetResultForApproval(gradeId, sectionId, examYearId, testTypeId);
      dispatch(resultSlice.actions.setData(res));
    } catch (err) {
      dispatch(resultSlice.actions.setError(err));
    }
  };
  const fetchResultForOldApproval = async (
    gradeId: string,
    sectionId: string,
    examYearId: string,
    testTypeId: string,
  ): Promise<void> => {
    dispatch(resultSlice.actions.setLoading());
    try {
      const res = await onGetResultForOldApproval(gradeId, sectionId, examYearId, testTypeId);
      dispatch(oldResultSlice.actions.setData(res));
    } catch (err) {
      dispatch(oldResultSlice.actions.setError(err));
    }
  };

  const saveResultForApproval = async (
    payload: ApprovalResultUpdateRequestModel,
    onFinish: VoidFunction,
  ): Promise<void> => {
    dispatch(resultSlice.actions.setLoading());
    // try {
    //   const { message } = await onPutResultForApprovalSave(payload);
    //   onFinish();
    //   notifySuccess('Results', message);
    //   fetchResultForApproval(
    //     payload.gradeId,
    //     payload.sectionId,
    //     payload.examYearId,
    //     payload.testTypeId,
    //   );
    // } catch (err) {
    //   notifyError('Results', decodeApiMessage(err));
    //   dispatch(resultSlice.actions.setError(err));
    // }
    toast.promise(
      onPutResultForApprovalSave(payload).then(() => {
        onFinish();
        fetchResultForApproval(
          payload.gradeId,
          payload.sectionId,
          payload.examYearId,
          payload.testTypeId,
        );
      }),
      {
        loading: 'Updating result',
        success: 'Result updated',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const approveResult = async (
    gradeId: string,
    sectionId: string,
    examYearId: string,
    testTypeId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // dispatch(resultSlice.actions.setLoading());
    // try {
    //   const { message } = await onPutApproveResult(gradeId, sectionId, examYearId, testTypeId);
    //   notifySuccess('Approval', message);

    //   onFinish();
    // } catch (err) {
    //   notifyError('Approval', decodeApiMessage(err));
    //   dispatch(resultSlice.actions.setError(err));
    // }
    toast.promise(
      onPutApproveResult(gradeId, sectionId, examYearId, testTypeId).then(() => {
        onFinish();
      }),
      {
        loading: 'Approving result',
        success: 'Result Approved',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const approveAggregateResult = async (
    payload: AggregateResultCreateModel,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // dispatch(resultSlice.actions.setLoading());
    // try {
    //   const { message } = await onPostApproveAggregate(payload);
    //   notifySuccess('Aggregate Approval', message);

    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Approval', decodeApiMessage(err));
    //   dispatch(resultSlice.actions.setError(err));
    // }
    toast.promise(
      onPostApproveAggregate(payload).then(() => {
        onFinish();
      }),
      {
        loading: 'Updating aggregate result',
        success: 'Result updated',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const publishResultForApp = async (
    gradeId: string,
    sectionId: string,
    examYearId: string,
    testTypeId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // dispatch(resultSlice.actions.setLoading());
    // try {
    //   const { message } = await onPutPublishResult(gradeId, sectionId, examYearId, testTypeId);
    //   notifySuccess('Publish', message);
    //   onFinish();
    // } catch (err) {
    //   notifyError('Publish', decodeApiMessage(err));
    //   dispatch(resultSlice.actions.setError(err));
    // }
    toast.promise(
      onPutPublishResult(gradeId, sectionId, examYearId, testTypeId).then(() => {
        onFinish();
      }),
      {
        loading: 'Publishing result',
        success: 'Result published',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const fetchResultByStudentId = async (studentId: string): Promise<void> => {
    dispatch(studentResultSlice.actions.setLoading());
    try {
      const res = await onGetResultByStudentId(studentId);
      dispatch(studentResultSlice.actions.setData(res));
    } catch (err) {
      dispatch(studentResultSlice.actions.setError(err));
    }
  };

  const fetchResultStatus = async (examYearId: string, testTypeId: string): Promise<void> => {
    dispatch(resultStatusSlice.actions.setLoading());
    try {
      const res = await onGetResultStatus(examYearId, testTypeId);
      dispatch(resultStatusSlice.actions.setData(res));
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch(resultStatusSlice.actions.setError(err));
    }
  };
  const fetchAggregatedResultStatus = async (
    examYearId: string,
    terminalExamId: string,
  ): Promise<void> => {
    dispatch(resultStatusSlice.actions.setLoading());
    try {
      const res = await onGetAggregatedResultStatus(examYearId, terminalExamId);
      dispatch(resultStatusSlice.actions.setData(res));
    } catch (err) {
      dispatch(resultStatusSlice.actions.setError(err));
    }
  };

  const approveAggregationResult = async (
    examYearId: string,
    terminalExamId: string,
    classId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   dispatch(resultAggregationStatusSlice.actions.setLoading());
    //   await onPutAggregationApproval(examYearId, terminalExamId, classId);
    //   notifySuccess('Aggregate Approval', 'Successfuly approved terminal aggregate results.');
    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Approval', decodeApiMessage(err));
    //   dispatch(resultAggregationStatusSlice.actions.setError(err));
    // }
    toast.promise(
      onPutAggregationApproval(examYearId, terminalExamId, classId).then(() => {
        onFinish();
      }),
      {
        loading: 'Approving aggregate result',
        success: 'Result Approved',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const publishAggregationResult = async (
    examYearId: string,
    terminalExamId: string,
    classId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   dispatch(resultAggregationStatusSlice.actions.setLoading());
    //   await onPutAggregationPublish(examYearId, terminalExamId, classId);
    //   notifySuccess('Aggregate Publish', 'Successfuly published terminal aggregate results.');
    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Publish', decodeApiMessage(err));
    //   dispatch(resultAggregationStatusSlice.actions.setError(err));
    // }
    toast.promise(
      onPutAggregationPublish(examYearId, terminalExamId, classId).then(() => {
        onFinish();
      }),
      {
        loading: 'Publishing aggregate result',
        success: 'Result Published',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const fetchFinalResultStatus = async (examYearId: string, finalExamId: string): Promise<void> => {
    dispatch(resultStatusSlice.actions.setLoading());
    try {
      const res = await onGetFinalResultStatus(examYearId, finalExamId);
      dispatch(resultStatusSlice.actions.setData(res));
    } catch (err) {
      dispatch(resultStatusSlice.actions.setError(err));
    }
  };

  const publishResult = async (
    payload: ResultModel,
    form: FormInstance,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   dispatch(resultStatusSlice.actions.setLoading());
    //   await onPostResult(payload).then(() => {
    //     form.resetFields();
    //     onFinish();
    //   });
    //   notifySuccess('Success', 'Result successfully published.');
    //   fetchResultStatus(payload.examYearId, payload.testTypeId);
    // } catch (err) {
    //   dispatch(resultStatusSlice.actions.setError(err));
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onPostResult(payload).then(() => {
        form.resetFields();
        onFinish();
      }),
      {
        loading: 'Publishing result',
        success: 'Result successfully published.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const approveFinalResult = async (
    examYearId: string,
    finalExamId: string,
    classId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   dispatch(resultAggregationStatusSlice.actions.setLoading());
    //   await onPutFinalApproval(examYearId, finalExamId, classId);
    //   notifySuccess('Aggregate Approval', 'Successfuly approved final aggregate results.');
    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Approval', decodeApiMessage(err));
    //   dispatch(resultAggregationStatusSlice.actions.setError(err));
    // }
    toast.promise(
      onPutFinalApproval(examYearId, finalExamId, classId).then(() => {
        onFinish();
      }),
      {
        loading: 'Approving result',
        success: 'Successfuly approved final aggregate results.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const publishFinalResult = async (
    examYearId: string,
    finalExamId: string,
    classId: string,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // try {
    //   dispatch(resultAggregationStatusSlice.actions.setLoading());
    //   await onPutFinalPublish(examYearId, finalExamId, classId);
    //   notifySuccess('Aggregate Publish', 'Successfuly published final aggregate results.');
    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Publish', decodeApiMessage(err));
    //   dispatch(resultAggregationStatusSlice.actions.setError(err));
    // }
    toast.promise(
      onPutFinalPublish(examYearId, finalExamId, classId).then(() => {
        onFinish();
      }),
      {
        loading: 'Publishing results',
        success: 'Successfuly published final aggregate results.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  const prepareFinalResult = async (
    payload: FinalResultCreateModel,
    onFinish: VoidFunction,
  ): Promise<void> => {
    // dispatch(resultSlice.actions.setLoading());
    // try {
    //   const { message } = await onPostCreateFinalResult(payload);
    //   notifySuccess('Aggregate Approval', message);

    //   onFinish();
    // } catch (err) {
    //   notifyError('Aggregate Approval', decodeApiMessage(err));
    //   dispatch(resultSlice.actions.setError(err));
    // }
    toast.promise(
      onPostCreateFinalResult(payload).then(() => {
        onFinish();
      }),
      {
        loading: 'Preparing results',
        success: 'Result prepared',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  return {
    publishResult,
    // fetchResult,
    fetchResultForApproval,
    fetchResultByStudentId,
    fetchAggregateStatus,
    fetchAggregatedResultStatus,
    fetchFinalResultStatus,
    approveAggregationResult,
    fetchResultStatus,
    saveResultForApproval,
    approveResult,
    approveAggregateResult,
    approveFinalResult,
    publishResultForApp,
    publishAggregationResult,
    publishFinalResult,
    prepareFinalResult,
    resetStates,
    fetchResultForOldApproval,
  };
};

export default useResult;
