import http_config from 'common/config/http_config';
import axiosInstance from 'common/helper/axiosInterceptor';
import { SchoolConfigResponseModel } from './school.model';

const onGetSchoolConfig = (): Promise<SchoolConfigResponseModel> => {
  return axiosInstance.get(`${http_config.BASE_URL}/school-config`);
};

export default {
  onGetSchoolConfig,
};
