import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { AbsentModel } from './absent.model';

export const onGetAbsentStudentByGrade = (
  grade: string,
  section: string,
): Promise<AbsentModel[]> => {
    // base url
  return axiosInstance.get(http_config.BASE_URL + '/absent?grade=' + grade + '&section=' + section);
};

export const onGetAbsentStudent = (): Promise<AbsentModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/absent');
};
