import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FinalAggregatedResultModel } from 'data/result/result.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: FinalAggregatedResultModel;
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: { terminalExamAndContributionPair: [], subjects: [], class: '', sections: [] },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<FinalAggregatedResultModel>> = (
  _,
  action,
) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: {
    terminalExamAndContributionPair: [],
    subjects: [],
    class: '',
    sections: [],
  },
  status: 'error',
});

const finalAggregatedResultsSlice = createSlice({
  name: 'finalAggregatedResults',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default finalAggregatedResultsSlice;
