import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    :root{
        --bg: #F5F5F5;

        --grey:#575556;
        --green:#61BD4F;
        --orange: #FF9F1A;
        --red: #EB5A46;
        --purple: #C377E0;
        --blue: #0079BF;
        
        --greyDark: #5a555c;
        --yellow: #FF9F1A;
        --shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        --gap: 1.1rem;

        --black: #000000;
        --white: #fff;

        --primary: #763535;
        --primary-hover: #00315d;
        --primary-alt: #00315d;
        --primary-light: #00315d;

        --link: #2A6DAE;
        --green: #009E7F;

        --secondary: #ff5b60;
        --secondary-hover: #FF282F;
        --secondary-alt: #fc5c63;

        --gray100: #f9f9f9;
        --gray200: #F7F7F7;
        --gray300: #f4f4f4;
        --gray400: #F3F3F3;
        --gray500: #f1f1f1; 
        --gray600: #EdEdEd;
        --gray700: #E6E6E6; 
        --gray800: #C2C3CC;
        --gray900: #bdbdbd;

        --text-bold: #0D1136;
        --text-medium: #424561;
        --text-regular: #77798C;
        --text-light: #909090;
        --text-label: #767676;

        --font-xxs: 11px;
        --font-xs: 12px;
        --font-sm: 13px;
        --font-base: 15px;
        --font-md: 18px;
        --font-lg: 21px;
        --font-xl: 24px;
        --font-2xl: 30px;
        --font-3xl: 36px;
        --font-4xl: 42px;
        --font-5xl: 48px;

        --br: 6px;
        --gap: 10px;
        --gap-md: 15px;
        --gap-xl: 20px;
        --gap-2xl: 40px;

        --ff: Poppins, sans-serif;
        --ff-body: Poppins, sans-serif;
        --ff-heading: Poppins, sans-serif;
        --ff-monospace: Poppins, monospace;

        --shadow-base: 0 3px 6px rgba(0, 0, 0, 0.16);
        --shadow-medium: 0 6px 12px rgba(0, 0, 0, 0.16);
        --shadow-big: 0 21px 36px rgba(0, 0, 0, 0.16);
        --shadow-light: 0 21px 36px rgba(0, 0, 0, 0.05);
        --shadow-header: 0 1px 2px rgba(0, 0, 0, 0.06);

        --mobile: 767px;
        --tab: 998px;
        --tsn: 0.2s ease-in-out;        
    }

    h1, h2, h3, h4, h5, h6, p{
        margin: 0;
        font-family: var(--ff);
    }
    body {
        margin: 0;
        padding: 0;
        font-family: var(--ff);
        background: var(--bg);
        width: 100% !important;
        /* overflow-x: hidden !important; */
        overflow-y: auto !important;
    }      
    .wrap{
        width: 90%;
        margin: auto;
    }

    .flex{
        display: flex;
        &.jcsb{
            justify-content: space-between;
        }
        &.ci{
            align-items: center;
        }
        &.fg{
            &>div:first-child, &>p:first-child{
                margin-right: var(--gap);
            }
        }
    }

    .card{
        background: var(--white);
        border-radius: var(--br);
        box-shadow: var(--shadow-light);
    }

    .scrollbar__track__vertical{
        right: 0px;
        top: 0;
        bottom: 0;
        width: 3px;
        position: absolute;
        background-color: var(--gray700);
        transition: var(--tsn);
        border-radius: 10px !important;
        &:hover{
            width: 8px;
        }
    }

  .quill__field {
    .ql-editor {
      min-height: 230px;
    }

    button {
      margin-top: 0;

      &:hover {
        box-shadow: none;
        background: transparent;
      }
    }
  }

    /* .ant-checkbox-inner {
   width: 22px;
   height: 22px;
}
    .ant-checkbox-inner::after {

        width: 8px;
    height: 15px;

} */
.ant-checkbox-inner, .ant-checkbox-input {
    transform: scale(1.5)
  }

`;

export default GlobalStyle;
