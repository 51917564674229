import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarMenu = styled(Menu)`
  ul {
    padding-left: 1em;
  }

  li {
    padding: 0 !important;
    border-radius: var(--br);
    overflow: hidden;
  }
`;

export const SidebarMenuItem = styled(Menu.Item)``;

export const SidebarItemWrapper = styled(NavLink)`
  padding: 0 16px;
  height: 44px;
  display: flex;
  background: var(--white);
  align-items: center;
  margin-bottom: var(--gap);
  border-radius: var(--br);
  cursor: pointer;

  .left {
    line-height: 0;
    font-size: 16px;
    color: #575556;
    margin-right: 16px;
    svg {
      stroke-width: 2.5px;
    }
  }
  .right {
    color: #575556;
    font-size: var(--font-base);
    font-weight: 500;
  }
  &.active,
  &:hover {
    background: var(--bg);
    .left,
    .right {
      color: var(--link);
    }
  }
`;

export const SidebarWrapper = styled.div`
  height: calc(100vh - var(--gap-xl) * 2);
  margin-top: var(--gap-xl);
  margin-left: var(--gap-xl);
  width: 300px;
  background: var(--white);
  border-radius: var(--br);
  overflow-y: auto;

  .sidebar-item-list {
    padding: 0 24px;
    padding-top: 24px;
  }

  header {
    padding: var(--gap-md) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-md);

    img {
      /* margin-right: var(--gap); */
      height: 30px;
    }
    p {
      font-size: var(--font-base);
      font-weight: 700;
    }
  }

  .user-selected-role {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--gap-md);
    p {
      cursor: pointer;
      background: var(--green);
      padding: 5px 20px;
      color: var(--white);
      border-radius: 100px;
      font-size: var(--font-sm);
      font-weight: 700;
    }
  }
  .academic-year {
    margin-top: -5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    small {
      margin: 0;
    }

    p {
      font-size: 20px;
      margin: 0;
      color: var(--green);
      font-weight: 500;
    }
  }
  .date-time {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--gap-md);
    p {
      cursor: pointer;
      /* background: var(--green); */
      padding: 5px 20px;
      color: var(--black);
      border-radius: 100px;
      font-size: var(--font-md);
      font-weight: 500;
    }
  }

  .user-details {
    display: flex;
    border: 1px solid var(--bg);
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;

    .user-details-left {
      display: flex;
      align-items: center;

      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 12px;
      }

      .name {
        font-size: var(--font-sm);
        color: var(--text-bold);
        font-weight: 700;
      }
      .email {
        font-size: var(--font-xs);
        color: var(--text-regular);
      }
    }
    .user-details-right {
      .user-logout {
        color: var(--link);
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
`;
