import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BalanceModel } from 'data/balance/balance.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: BalanceModel;
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: { balance: 0 },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<BalanceModel>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: { balance: 0 },
  status: 'error',
});

const balanceSlice = createSlice({
  name: 'balance',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default balanceSlice;
