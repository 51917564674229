import { BookResponseModel } from 'data/library/book/book.model';
import { onSetBook, onGetBook } from 'data/library/book/book.service';
import { useDispatch } from 'react-redux';
import bookSlice from 'redux/slice/book.slice';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

export const useBook = () => {
  const dispatch = useDispatch();

  const fetchBook = async () => {
    dispatch(bookSlice.actions.setLoading());
    try {
      const res = await onGetBook();
      dispatch(bookSlice.actions.setData(res));
    } catch (err) {
      dispatch(bookSlice.actions.setError(err));
    }
  };

  const onSaveBook = async (
    payload: BookResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    try {
      await onSetBook(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      });
      notifySuccess('Success', 'Book successfully added.');
    } catch (err) {
      onVisibleChange(true);
      onReload(false);
      notifyError('Error', decodeApiMessage(err));
    }
  };

  return {
    fetchBook,
    onSaveBook,
  };
};
