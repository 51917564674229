import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultSubjectModel } from 'data/subject/subject.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: ResultSubjectModel[];
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: [],
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<ResultSubjectModel[]>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: [],
  status: 'error',
});

const resetState = () => initState;

const resultSubjectStatusSlice = createSlice({
  name: 'resultSubjectStatus',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
    resetState,
  },
});

export default resultSubjectStatusSlice;
