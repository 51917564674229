import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  StudentDataModel,
  StudentEditPayload,
  StudentResponseModel,
  UnlinkParentPayload,
} from './student.model';

export const onGetStudents = (
  page: number,
  limit: number,
  grade: string = '',
  section: string = '',
  searchTerm: string = '',
): Promise<StudentDataModel> => {
  return axiosInstance.get(
    http_config.BASE_URL +
      '/student?page=' +
      page +
      '&limit=' +
      limit +
      '&grade=' +
      grade +
      '&section=' +
      section +
      '&searchTerm=' +
      searchTerm,
  );
};
export const onSetStudents = (payload: StudentResponseModel): Promise<StudentResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/student', payload);
};

export const onEditStudent = (payload: StudentEditPayload): Promise<void> => {
  return axiosInstance.put(http_config.BASE_URL + `/student/${payload.id}`, payload);
};
export const onStudentIdCodeUpdate = (
  payload: Array<{ userId: string; code: string }>,
): Promise<void> => {
  return axiosInstance.put(http_config.BASE_URL + `/student/update-codes`, payload);
};
export const onStudentBulkUpload = (payload: any): Promise<void> => {
  return axiosInstance.post(http_config.BASE_URL + `/student/bulk`, { list: payload });
};
export const onStudentCodeDownload = (): Promise<void> => {
  return axiosInstance.post(http_config.BASE_URL + `/student/download-idcard-codes`);
};
export const onUnlinkParent = (payload: UnlinkParentPayload): Promise<void> => {
  return axiosInstance.put(http_config.BASE_URL + `/student/unlink/${payload.studentId}`, {
    parentIds: payload.parentIds,
  });
};

export const onDeleteStudent = (payload: StudentResponseModel): Promise<StudentResponseModel> => {
  return axiosInstance.delete(http_config.BASE_URL + '/student/{id}', { data: payload.id });
};

export const onGetStudentByGrade = (
  grade: string,
  sectionId?: string,
): Promise<StudentResponseModel[]> => {
  let url = `${http_config.BASE_URL}/student/grade/${grade}`;

  if (sectionId) url += `/section/${sectionId}`;

  return axiosInstance.get(url);
};

export const onGetStudentById = (id: string): Promise<StudentResponseModel> => {
  return axiosInstance.get(`${http_config.BASE_URL}/student/${id}`);
};
