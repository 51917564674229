import React, { useState } from 'react';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';

import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import { Button, Input } from 'antd';
import { useSave } from 'features/users/hooks/useSave';
import { ExportToCsv } from 'export-to-csv';

export function BulkCodes() {
  const { onStudentUserCodeDownload } = useSave();
  const [loading, setLoading] = useState(false);

  const handleDownloadCsv = async () => {
    setLoading(true);
    const res = await onStudentUserCodeDownload();
    csvExporter.generateCsv(res);
    setLoading(false);
  };

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  return (
    <div style={{ padding: '15px' }}>
      <KPageHeader title="Bulk Upload" />
      <KProTip title="Page Hint" description="This page is for bulk upload" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <section style={{ marginRight: 35 }}>
            <h2 style={{ margin: 0 }}>Students</h2>
          </section>

          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <Button onClick={handleDownloadCsv} loading={loading}>
              Download
            </Button>
          </section>
        </div>
      </div>
    </div>
  );
}
