import React, { useState } from 'react';
import moment from 'moment';
import { Button, Input, Modal, Tabs } from 'antd';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import KSpinner from 'common/component/KSpinner';
import { SupportResponseModel } from 'data/support/support.model';
import { KButton } from 'common/component/KButton';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SystemUserWrapper } from 'features/users/view/user.style';
import { ReloadOutlined } from '@ant-design/icons';
import { useSupport } from '../hooks/useSupport';
import SupportCards from '../components/SupportCards';
import MessageBox from '../components/MessageBox';

const SupportView = (): JSX.Element => {
  const { fetchSupport, postReply, selectSupport, clearSupportSelection } = useSupport();
  const supportQueries = useSelector((state: RootState) => state.support);
  const { data: activeSupport, status: activeSupportStatus } = useSelector(
    (state: RootState) => state.activeSupport,
  );
  const [replyText, setReplyText] = useState<string>('');

  React.useEffect(() => {
    fetchSupport();
  }, []);

  const loader = React.useMemo(() => {
    return (
      supportQueries.status === 'loading' && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <KSpinner />
        </div>
      )
    );
  }, [supportQueries.status]);

  // const repliedSubjects = React.useMemo(() => {
  //   return Array.from(new Set(supportQueries.data.replied.map((item) => item.subject)).values());
  // }, [supportQueries.data.replied]);
  // const notRepliedSubjects = React.useMemo(() => {
  // eslint-disable-next-line
  //   return Array.from(new Set(supportQueries.data.notReplied.map((item) => item.subject)).values());
  // }, [supportQueries.data.replied]);

  const openModal = (support: SupportResponseModel): void => {
    selectSupport(support);
  };
  const closeModal = (): void => {
    clearSupportSelection();
  };
  const onReply = (): void => {
    if (!activeSupport) return;
    console.log({ replyText });
    postReply(activeSupport.id, replyText)
      .then(() => {
        setReplyText('');
      })
      .catch((ex): void => {
        console.log({ ex });
      });
  };

  const tabBarReload = React.useMemo(() => {
    return (
      <Button
        icon={<ReloadOutlined />}
        onClick={fetchSupport}
        disabled={supportQueries.status === 'loading'}
        loading={supportQueries.status === 'loading'}
      />
    );
  }, [supportQueries.status]);

  const modalHeader = React.useMemo(() => {
    if (!activeSupport) return 'Support Request';

    return (
      <>
        <h3>{activeSupport.subject}</h3>
        <div className="meta" style={{ fontWeight: 400 }}>
          <small>
            {moment(activeSupport.createdAt).format('MMM DD, YYYY')}
            &nbsp; &#8209; &nbsp;
            {/* eslint-disable-next-line */}
            <a href={`mailto:${activeSupport.__user__.email}`}>{activeSupport.__user__.email}</a>
          </small>
          <p>{activeSupport.description}</p>
        </div>
      </>
    );
  }, [activeSupport]);

  const modalFooter = React.useMemo(() => {
    return (
      <div className="reply" style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
        <Input.Group compact>
          <Input
            style={{ width: 'calc(100% - 71.6px)' }}
            placeholder="Reply with suggestion"
            value={replyText}
            onChange={(ev): void => setReplyText(ev.target.value)}
          />
          <KButton onClick={onReply}>Reply</KButton>
        </Input.Group>
      </div>
    );
  }, [replyText]);

  return (
    <>
      <SystemUserWrapper>
        <KPageHeader title="Support" rightButtonTitle="Create New" />

        <KProTip
          title="Page Hint"
          description="This page is for tickets management and for communication with users."
        />

        <Tabs centered type="line" tabBarExtraContent={tabBarReload}>
          <Tabs.TabPane tab="Not-replied" key="item-1">
            {loader || <SupportCards list={supportQueries.data.notReplied} onAction={openModal} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Replied" key="item-2">
            {loader || <SupportCards list={supportQueries.data.replied} onAction={openModal} />}
          </Tabs.TabPane>
        </Tabs>
      </SystemUserWrapper>
      <Modal
        centered
        destroyOnClose
        visible={activeSupportStatus === 'loading' || activeSupportStatus === 'data'}
        onCancel={closeModal}
        title={modalHeader}
        footer={modalFooter}
        bodyStyle={{ paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
      >
        {activeSupportStatus === 'loading' ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <KSpinner />
          </div>
        ) : (
          activeSupport && <MessageBox support={activeSupport} />
        )}
      </Modal>
    </>
  );
};

export default SupportView;
