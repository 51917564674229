import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import eventSlice from 'redux/slice/event.slice';
import {
  handleEventDelete,
  handleEventUpdate,
  onCreateEvent,
  onGetAllEvents,
  onGetUpcomingEvents,
} from 'data/event/event.service';
import { EventPost } from 'data/event/event.modle';
import upComingEventSlice from 'redux/slice/upcoming-events.slice';
import { toast } from 'react-hot-toast';

export const useEvent = () => {
  const dispatch = useDispatch();

  const fetchEvent = async () => {
    dispatch(eventSlice.actions.setLoading());
    try {
      const res = await onGetAllEvents();
      dispatch(eventSlice.actions.setData(res));
    } catch (err) {
      dispatch(eventSlice.actions.setError(err));
    }
  };

  const fetchUpComingEvents = async () => {
    dispatch(upComingEventSlice.actions.setLoading());
    try {
      const res = await onGetUpcomingEvents();
      dispatch(upComingEventSlice.actions.setData(res));
    } catch (err) {
      dispatch(upComingEventSlice.actions.setError(err));
    }
  };

  const onSaveEvent = async (
    payload: EventPost,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      onCreateEvent(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new event',
        success: 'Event successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onCreateEvent(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Event successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onUpdateEvent = async (
    id: string,
    payload: Partial<EventPost>,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleEventUpdate(id, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating new event',
        success: 'Event updated successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleEventUpdate(id, payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Event updated successfully.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onDeleteEvent = async (id: string, onVisibleChange: any, onReload: any) => {
    toast.promise(
      handleEventDelete(id).then(() => {
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Deleting new event',
        success: 'Event deleted successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleEventDelete(id).then(() => {
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Event deleted successfully.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  return {
    fetchEvent,
    onSaveEvent,
    onUpdateEvent,
    onDeleteEvent,
    fetchUpComingEvents,
  };
};
