import styled from 'styled-components';

export const CustomHeader = styled.div`
  h4 {
    font-size: 16px;
    margin: 0.3em 0;
  }
`;

export const ObtainedMarkInput = styled.input`
  width: 90px !important;
  border: none !important;
  background: transparent !important;
  font-weight: 600 !important;
`;

export const ResultStyle = styled.div`
  width: 100%;
  position: relative;

  .steps {
    padding-bottom: 10px;
    position: sticky;
    top: -23px;
    left: 0;
    z-index: 99;
    background: #edeff6;
  }

  input {
    width: 100%;
    padding: 10px;
    height: 38px;
    display: inline-block;
    background: var(--bg);
    border-radius: 2px;
    border: 1px solid var(--gray900);
  }

  .gradeExamYearType {
    display: flex;
    justify-content: space-between;
  }

  .subjectInput {
    width: 100%;
    display: grid;
    grid-template-columns: 7fr 80px 80px;
    gap: 1em;
    align-items: flex-end;
  }

  .threshold_mark {
    margin-top: -30px;
    text-align: center;
  }
  .threshold_mark input {
    margin-top: 5px;
    text-align: center;
  }
  .threshold_mark span {
    font-size: 0.8em;
  }

  .markList {
    width: 100%;
    margin-top: 30px;
  }

  .studentMarks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    gap: 1em;
  }

  .studentMarks .studentObtainedMarks input {
    text-align: center;
  }

  .students,
  .marks {
    font-size: var(--font-base);
    font-weight: 700;
    // margin-Right: 50px;
    // margin-Left: 50px;
  }
  .studentName {
    flex: 1 1 200px;
    font-size: var(--font-base);
    font-weight: 500;
    // margin-left: 10px;
  }
  .studentObtainedMarks {
    flex: 0 0 115px;
    text-align: center;
    font-size: var(--font-base);
    font-weight: 500;
    // margin-right: 10px;
  }

  button {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }

  .dropDownButton {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--gray900);
    background: var(--white);
    color: var(--black);
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin: 0px;
    font-weight: none;
    font-size: var(--font-sm);
    :hover {
      background: none;
      color: var(--black);
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;

  .controls {
    display: flex;
    gap: 2em;
    align-items: center;
  }

  .meta {
    width: 100%;
    display: flex;
    gap: 4em;
  }

  .info__item {
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    .info__value {
      font-weight: 500;
      font-size: 1.2em;
    }
    .info__title {
      &:after {
        content: ':';
        margin-left: 0.2em;
      }
    }
  }
`;

export const TestInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 2em;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 1em;
`;
