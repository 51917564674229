import React from 'react';
import { Button, Form, Select } from 'antd';
import { AddIssueBookFormWrapper, Label } from './user.style';
import { useSave } from '../hooks/useSave';
import { useBook } from 'features/book/hooks/useBook';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import moment from 'moment';
import { StudentResponseModel } from 'data/student/student.model';

export const IssueBookForm = (props: any) => {
  const { value, onVisibleChange } = props;
  const data: StudentResponseModel = value;
  const [form] = Form.useForm();
  const { fetchBook } = useBook();
  const { onIssueBook } = useSave();
  const book = useSelector((state: RootState) => state.book);

  React.useEffect(() => {
    fetchBook();
    // eslint-disable-next-line
  }, []);

  return (
    <AddIssueBookFormWrapper>
      <Form form={form} layout="vertical" size="large">
        <Form.Item label="Student Name">
          <Label>{data.__user__.full_name}</Label>
        </Form.Item>
        <div className="books">
          <Form.Item className="eachBook" label="Book" name={'bookId'}>
            <Select defaultValue="Select Book">
              {book.data.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {/* <div className="books">
          {state.map((item) => (
            <Form.Item key={item.key} className="eachBook" label={item.label} name={'bookId'}>
              <Select defaultValue="Select Book">
                {book.data.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
              <CloseOutlined style={{ padding: '10px' }} />
            </Form.Item>
          ))}
        </div> */}
        <div className="footer">
          <div className="issuedAt">
            <label className="footerLabel">
              <h4>Issued At: </h4>
            </label>
            <Form.Item name="issuedAt" initialValue={moment().format('Do MMM, YYYY')}>
              {moment().format('Do MMM, YYYY')}
            </Form.Item>
          </div>

          <div className="dueDate">
            <label className="footerLabel">
              <h4>Due Date: </h4>
            </label>
            <Form.Item
              name="dueDate"
              initialValue={moment()
                .add(7, 'days')
                .format('YYYY-MM-DD')}
            >
              {moment()
                .add(7, 'days')
                .format('Do MMM, YYYY')}
            </Form.Item>
          </div>

          <div className="issuedBy">
            <label className="footerLabel">
              <h4>Issued By: </h4>
            </label>
            <Form.Item>{data.__user__.full_name}</Form.Item>
          </div>
        </div>
        <Form.Item hidden={true} name="studentId" initialValue={data.id} />
        <Form.Item>
          <Button
            htmlType="submit"
            onClick={() => {
              onIssueBook({ ...form.getFieldsValue() }, form, onVisibleChange);
            }}
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddIssueBookFormWrapper>
  );
};

export default IssueBookForm;
