import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  GradeModel,
  GradePostModel,
  GradeSubjectModel,
  GradeUpdateModel,
  GradeUpdateSectionModel,
} from './grade.model';

export const onGetGrade = (): Promise<GradeModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/grade');
};

export const onGetGradeSubjects = (grade: string): Promise<GradeSubjectModel> => {
  return axiosInstance.get(http_config.BASE_URL + '/grade/' + grade);
};

export const onSetGrade = (payload: GradePostModel): Promise<{ message: string }> => {
  return axiosInstance.post(http_config.BASE_URL + '/grade', payload);
};
export const onEditGrade = (
  grade: string,
  payload: GradeUpdateModel,
): Promise<{ message: string }> => {
  return axiosInstance.put(http_config.BASE_URL + `/grade/${grade}/time`, payload);
};
export const onEditSectionGrade = (
  grade: string,
  payload: GradeUpdateSectionModel,
): Promise<{ message: string }> => {
  return axiosInstance.put(http_config.BASE_URL + `/grade/${grade}/section`, payload);
};
