import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useExamType } from 'features/exam-type/hooks/useExamType';

interface Props {
  examYear: string;
  value?: string;
  onChange?: (val: string) => void;
}

function SelectTerminalExamType({ examYear, value, onChange }: Props): JSX.Element {
  const terminalExamTypes = useSelector((state: RootState) => state.examTerminalType);

  const { fetchTerminalTestType } = useExamType();

  const [localSectionSelected, setSelectedSection] = React.useState<string | undefined>(undefined);

  const typeList = React.useMemo(() => {
    return terminalExamTypes.data
      .filter((t) => t.exam_year.id === examYear)
      .map((item) => (
        <Select.Option key={`atten-class${item.id}`} value={item.id}>
          {item.id.split('|')[1]}
        </Select.Option>
      ));
  }, [examYear, terminalExamTypes]);

  // if value exists, pre-populate
  React.useEffect(() => {
    if (value) setSelectedSection(value);
  }, [value]);

  // reset state value
  React.useEffect(() => {
    if (onChange) onChange('');
    setSelectedSection(undefined);
  }, [examYear]);

  React.useEffect(() => {
    if (terminalExamTypes.status === 'idle') fetchTerminalTestType();
  }, [terminalExamTypes.status]);

  return (
    <>
      <Select
        loading={terminalExamTypes.status === 'loading'}
        style={{ width: '100%' }}
        value={localSectionSelected}
        placeholder="Select Exam Terminal"
        className="grade-select-field"
        onSelect={(val): void => {
          setSelectedSection(val);
          if (onChange) onChange(val);
        }}
      >
        {typeList}
      </Select>
    </>
  );
}

export default SelectTerminalExamType;
