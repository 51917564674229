import { onGetTeachers } from 'data/teacher/teacher.service';
import { onGetManagers } from 'data/manager/manager.service';
import { onGetParents } from 'data/parent/parent.service';
import { onGetSupportPerson } from 'data/supportPerson/supportPerson.service';
import { onGetStudentByGrade, onGetStudents } from 'data/student/student.service';
import { useDispatch } from 'react-redux';
import studentSlice from 'redux/slice/student.slice';
import teacherSlice from 'redux/slice/teacher.slice';
import managerSlice from 'redux/slice/manager.slice';
import parentSlice from 'redux/slice/parent.slice';
import supportPersonSlice from 'redux/slice/supportPerson.slice';
import IssuedBooksSlice from 'redux/slice/issuedBooks.slice';
import { onGetIssuedBooks } from 'data/library/issueBook/issueBook.service';
import transactionSlice from 'redux/slice/transaction.slice';
import { onGetTransactions } from 'data/transactions/transaction.service';
import studentDataSlice from 'redux/slice/studentData.slice';
import {
  onGetUserByPhoneNumber,
  onGetUsers,
  onUpdateLeaveApplication,
  onUpdateUserRole,
} from 'data/user/user.service';
import userSlice from 'redux/slice/userByPhoneNumber.slice';
import balanceSlice from 'redux/slice/balance.slice';
import { getBalance } from 'data/balance/balance.service';
import React from 'react';
import { toast } from 'react-hot-toast';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { onGetOtherStaffs } from 'data/otherStaff/otherStaff.service';
import otherstaffSlice from 'redux/slice/otherStaffs.slice';

export const useUser = () => {
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    dispatch(userSlice.actions.setLoading());
    try {
      const res = await onGetUsers();
      dispatch(userSlice.actions.setData(res));
    } catch (err) {
      dispatch(userSlice.actions.setError(err));
    }
  };
  const fetchStudent = async (
    page: number,
    limit: number,
    grade?: string,
    section?: string,
    searchTerm?: string,
  ) => {
    dispatch(studentDataSlice.actions.setLoading());
    try {
      const res = await onGetStudents(page, limit, grade, section, searchTerm);
      dispatch(studentDataSlice.actions.setData(res));
    } catch (err) {
      dispatch(studentDataSlice.actions.setError(err));
    }
  };

  const fetchParent = async (page: number, limit: number) => {
    dispatch(parentSlice.actions.setLoading());
    try {
      const res = await onGetParents(page, limit);
      dispatch(parentSlice.actions.setData(res));
    } catch (err) {
      dispatch(parentSlice.actions.setError(err));
    }
  };

  const fetchTeacher = async (page: number, limit: number) => {
    dispatch(teacherSlice.actions.setLoading());
    try {
      const res = await onGetTeachers(page, limit);
      dispatch(teacherSlice.actions.setData(res));
    } catch (err) {
      dispatch(teacherSlice.actions.setError(err));
    }
  };
  const fetchOtherStaff = async (page: number, limit: number) => {
    dispatch(teacherSlice.actions.setLoading());
    try {
      const res = await onGetOtherStaffs(page, limit);
      dispatch(otherstaffSlice.actions.setData(res as any));
    } catch (err) {
      dispatch(otherstaffSlice.actions.setError(err));
    }
  };

  const fetchManager = async () => {
    dispatch(managerSlice.actions.setLoading());
    try {
      const res = await onGetManagers();
      dispatch(managerSlice.actions.setData(res));
    } catch (err) {
      dispatch(managerSlice.actions.setError(err));
    }
  };
  const fetchSupportPerson = async () => {
    dispatch(supportPersonSlice.actions.setLoading());
    try {
      const res = await onGetSupportPerson();
      dispatch(supportPersonSlice.actions.setData(res));
    } catch (err) {
      dispatch(supportPersonSlice.actions.setError(err));
    }
  };

  const fetchGradeStudent = async (grade: string, sectionId?: string) => {
    dispatch(studentSlice.actions.setLoading());
    try {
      const res = await onGetStudentByGrade(grade, sectionId);
      dispatch(studentSlice.actions.setData(res));
    } catch (err) {
      dispatch(studentSlice.actions.setError(err));
    }
  };

  const fetchIssuedBooks = async () => {
    dispatch(IssuedBooksSlice.actions.setLoading());
    try {
      const res = await onGetIssuedBooks();
      dispatch(IssuedBooksSlice.actions.setData(res));
    } catch (err) {
      dispatch(IssuedBooksSlice.actions.setError(err));
    }
  };
  const fetchTransactions = async (userId: string, cardNumber: string) => {
    dispatch(transactionSlice.actions.setLoading());
    try {
      const res = await onGetTransactions(userId, cardNumber);

      dispatch(transactionSlice.actions.setData(res));
    } catch (err) {
      dispatch(transactionSlice.actions.setError(err));
    }
  };
  const fetchUserByPhonenumber = async (phone_number: string) => {
    dispatch(userSlice.actions.setLoading());
    try {
      const res = await onGetUserByPhoneNumber(phone_number);

      dispatch(userSlice.actions.setData(res));
    } catch (err) {
      dispatch(userSlice.actions.setError(err));
    }
  };

  const fetchBalance = async (userId: string) => {
    dispatch(balanceSlice.actions.setLoading());
    try {
      const res = await getBalance(userId);

      dispatch(balanceSlice.actions.setData(res));
    } catch (err) {
      dispatch(balanceSlice.actions.setError(err));
    }
  };

  const updateUserRole = async (
    payload: { userId: string; newRoleCode: number[] },
    onReload: any,
  ) => {
    toast.promise(onUpdateUserRole(payload.userId, payload.newRoleCode).then(onReload), {
      loading: 'Updating user role',
      success: 'User role updated sucessfully',
      error: (error) => decodeApiMessage(error),
    });
  };

  const updateLeaveApplication = async (
    payload: { applicationId: string; response: string },
    onReload: any,
  ) => {
    toast.promise(
      onUpdateLeaveApplication(payload.applicationId, payload.response).then(onReload),
      {
        loading: 'Updating leave application',
        success: 'leave application succesfully updated',
        error: (error) => decodeApiMessage(error),
      },
    );
  };
  return {
    fetchUsers,
    fetchGradeStudent,
    fetchStudent,
    fetchParent,
    fetchTeacher,
    fetchManager,
    fetchSupportPerson,
    fetchIssuedBooks,
    fetchTransactions,
    fetchUserByPhonenumber,
    fetchBalance,
    updateUserRole,
    updateLeaveApplication,
    fetchOtherStaff,
  };
};
