import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
  onChange: (value: string) => void;
  defaultValue?: ReactQuill.Value;
  value?: ReactQuill.Value;
}

const TextEditor = ({ defaultValue, onChange, value }: TextEditorProps): JSX.Element => {
  return (
    <ReactQuill
      theme="snow"
      defaultValue={defaultValue}
      value={value}
      modules={{
        toolbar: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ script: 'sub' }, { script: 'super' }],
          ['code-block'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          ['clean'],
        ],
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true,
        },
      }}
      onChange={onChange}
    />
  );
};

export default TextEditor;
