import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AssignParentToStudentModel } from 'data/manager/manager.model';
import { getStudentParents } from 'data/parent/parent.service';
import KSpinner from 'common/component/KSpinner';
import FilterComponent from './components/FilterComponent';
import { useUser } from '../hooks/useUser';
import ParentTable from '../component/ParentTable';
import { useSave } from '../hooks/useSave';
import { Label } from './user.style';

interface AssignParentProps {
  value: {
    id: string;
    __user__: {
      full_name: string;
    };
  };
  onVisibleChange: any;
  showAssignedParents?: boolean;
}

export default function AssignParentUI(props: AssignParentProps): JSX.Element {
  const [isSearchClicked, setSearchClicked] = React.useState(false);

  const [form] = Form.useForm();
  const { fetchUserByPhonenumber } = useUser();
  const { onAssignPToS } = useSave();
  const users = useSelector((state: RootState) => state.user);
  const [parentId, setParentId] = React.useState('');
  const [assignedParents, setAssignedParents] = useState<any>(null);

  const handleOnFilter = (value: string) => {
    if (!value.trim()) return;
    fetchUserByPhonenumber(value);
    setSearchClicked(true);
  };
  const handleAssign = () => {
    // console.log('Assign ' + value.id + ' ' + parentId);
    const payload: AssignParentToStudentModel = {
      studentId: props.value.id,
      parentId,
    };
    onAssignPToS(payload, form, props.onVisibleChange, setSearchClicked);
  };
  const TableColumn = () => [
    {
      name: 'Full Name',
      selector: 'full_name',
      sortable: true,
      cell: (row: any) => <div>{row.full_name}</div>,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      cell: (row: any) => <div>{row.email}</div>,
    },
    {
      name: 'Phone Number',
      selector: 'phone_number',
      sortable: true,
      cell: (row: any) => <div>{row.phone_number}</div>,
    },
  ];

  console.log({ users });

  React.useEffect(() => {
    // fetch assigned parents if showAssignedParents is true
    if (props.showAssignedParents) {
      getStudentParents(props.value.id).then((res) => {
        setAssignedParents({
          data: res,
          error: false,
          status: 'data',
        });
      });
    }
  }, []);
  return (
    <Form form={form} layout="vertical" size="large">
      <Form.Item label="Student Name">
        <Label>{props.value.__user__.full_name}</Label>
      </Form.Item>
      <Form.Item label="Search Parent" className="search">
        <FilterComponent onFilter={handleOnFilter} />
      </Form.Item>
      <h2 style={{ margin: '10px 0' }}>Assigned Parents</h2>
      {props.showAssignedParents && assignedParents ? (
        <ParentTable
          table={TableColumn()}
          data={assignedParents}
          eachData={assignedParents.data}
          // selectable
          parentId={setParentId}
          pagination={false}
        />
      ) : (
        <KSpinner />
      )}
      {isSearchClicked && (
        <div className="parentTable">
          <ParentTable
            table={TableColumn()}
            data={users}
            eachData={users.data}
            selectable
            parentId={setParentId}
            pagination={false}
          />
        </div>
      )}

      <Form.Item>
        <Button className="saveButton" onClick={handleAssign}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}
