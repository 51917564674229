import {
  AnnouncementCategoryResponseModel,
  AnnouncementResponseModel,
} from 'data/announcement/announcement.model';
import {
  onGetAnnouncement,
  onGetAnnouncementCategory,
  onSetAnnouncement,
  onSetAnnouncementCategory,
} from 'data/announcement/announcement.service';
import { useDispatch } from 'react-redux';
import announcementSlice from 'redux/slice/announcement.slice';
import announcementCategorySlice from 'redux/slice/announcementCategory.slice';
import React from 'react';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form';
import { toast } from 'react-hot-toast';

export const useAnnouncement = () => {
  const dispatch = useDispatch();
  const [error, seterror] = React.useState<string | null | boolean>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchAnnouncement = async (page: number, limit: number) => {
    dispatch(announcementSlice.actions.setLoading());
    try {
      const res = await onGetAnnouncement(page, limit);
      dispatch(announcementSlice.actions.setData(res));
    } catch (err) {
      dispatch(announcementSlice.actions.setError(err));
    }
  };

  const fetchAnnouncementCategory = async () => {
    dispatch(announcementCategorySlice.actions.setLoading());
    try {
      const res = await onGetAnnouncementCategory();
      dispatch(announcementCategorySlice.actions.setData(res));
    } catch (err) {
      dispatch(announcementCategorySlice.actions.setError(err));
    }
  };

  const onSaveAnnouncement = async (
    payload: AnnouncementResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    // setLoading(true);
    toast.promise(
      onSetAnnouncement(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Creating new Announcement.',
        success: 'Announcement successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onSetAnnouncement(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //     notifySuccess('Success', 'Announcement successfully added.');
    //     setLoading(false);
    //   });
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    //   setLoading(false);
    // }
  };
  const onSaveAnnouncementCategory = async (
    payload: AnnouncementCategoryResponseModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      onSetAnnouncementCategory(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Creating new Announcement category',
        success: 'New Announcement category successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // setLoading(true);
    // try {
    //   await onSetAnnouncementCategory(payload);
    //   form.resetFields();
    //   onVisibleChange(false);
    //   onReload(true);
    //   setLoading(false);
    // } catch (err) {
    //   seterror(err);
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    //   setLoading(false);
    // }
  };

  return {
    fetchAnnouncement,
    fetchAnnouncementCategory,
    onSaveAnnouncement,
    onSaveAnnouncementCategory,
    error,
    loading,
  };
};
