import React from 'react';
import { Button, Form, Input } from 'antd';
import { CardModel } from 'data/card/card.model';
import { StudentResponseModel } from 'data/student/student.model';
import { TeacherResponseModel } from 'data/teacher/teacher.model';
import { ParentResponseModel } from 'data/parent/parent.model';
import { AddCardWrapper, Label } from './user.style';
import { useSave } from '../hooks/useSave';

interface AddCardFormProps {
  user: StudentResponseModel | TeacherResponseModel | ParentResponseModel;
  onFinish: VoidFunction;
}

export const AddCardForm = ({ onFinish, user }: AddCardFormProps): JSX.Element => {
  const [form] = Form.useForm();

  const { onCardSave } = useSave();

  const handleSubmit = (): void => {
    const payload: CardModel = {
      card_number: form.getFieldValue('card_number'),
      card_minimum_limit: 0,
      card_pin_number: form.getFieldValue('card_pin_number'),
    };

    onCardSave(user?.id || '', payload, onFinish);
  };

  return (
    <AddCardWrapper>
      <Form form={form} name="card-form" layout="vertical" size="large">
        <Form.Item label="Name">
          <Label>{(user as any)?.__user__.full_name || ''}</Label>
        </Form.Item>
        <Form.Item label="Enter Card Number" name="card_number">
          <Input />
        </Form.Item>
        <Form.Item label="Enter Card Pin Number" name="card_pin_number">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddCardWrapper>
  );
};

export default AddCardForm;
