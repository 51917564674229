import styled from 'styled-components';

export const HomeViewWrapper = styled.div`
  .main-container {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 20px;
  }
  .content-area {
    margin-top: var(--gap-xl);
    height: calc(100vh - 20px);
    overflow-y: auto;
  }
`;
