import React from 'react';
import styled from 'styled-components';
import { KButton } from './KButton';

interface Props {
  title: string;
  rightButtonTitle?: string;
  additionalButtonTitle?: string;
  onRightButtonClick?: Function;
  onAdditionButtonClick?: Function;
}

const KPageHeader: React.FC<Props> = ({ title, rightButtonTitle, onRightButtonClick, additionalButtonTitle, onAdditionButtonClick }) => {
  return (
    <MainPageHeaderWrapper>
      <div className="title">{title}</div>
      <div className="button">
        <div className="additionalButton">
      {!!additionalButtonTitle && (
        <KButton onClick={() => onAdditionButtonClick && onAdditionButtonClick()}>
          {additionalButtonTitle}
        </KButton>
      )}
      </div>
      <div className="rightButton"></div>
      {!!rightButtonTitle && (
        <KButton onClick={() => onRightButtonClick && onRightButtonClick()}>
          {rightButtonTitle}
        </KButton>
      )}
      </div>
    </MainPageHeaderWrapper>
  );
};

export const MainPageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  .title {
    font-size: var(--font-md);
    font-weight: 700;
  }
  .button{
    display: flex;
  justify-content: space-between;
  }
  .additionalButton{
   margin-right: 10px;
  }
`;

export default KPageHeader;
