import React from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Modal, Popover } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import DataTable, { TableColumn } from 'react-data-table-component';
import {
  AggregateResultCreateModel,
  TestTypeAndContributionPair,
  TestTypeStatus,
} from 'data/result/result.model';
import { InfoWrapper, ResultStyle } from './result.style';
import useResult from '../hooks/useResult';

interface ResultAggregationFormProps {
  examYearId: string;
  terminalExamId: string;
  classId: string;
  onFinish: VoidFunction;
}

const ContributionsWrapper = styled.section`
  margin: 1em 0;
  width: 100%;

  .sum {
    width: 100%;
    margin-top: 0.5em;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.value {
      margin-right: 1em;
      font-size: 1.2em;
    }
  }
`;
const StyledInput = styled(Input)`
  border-width: 0px !important;
  width: 80px !important;

  &:focus {
    border-width: 0px !important;
  }

  &:hover {
    border-right-width: 0px !important;
  }
`;

type LocalUpdater = (terminalExamId: string) => React.ChangeEventHandler<HTMLInputElement>;

const aggregateTableModel = (onContribUpdate: LocalUpdater): TableColumn<TestTypeStatus>[] => [
  {
    name: 'Test Type',
    cell: (row): string => row.id.split('|')[1],
    grow: 2,
  },
  {
    name: '',
    cell: (row): JSX.Element => {
      return !['Approved', 'Published'].includes(row.status) ? (
        <small>
          <i>Result not approved.</i>
        </small>
      ) : (
        <></>
      );
    },
  },
  {
    name: 'Contribution (%)',
    right: true,
    cell: (row): JSX.Element => {
      return (
        <StyledInput
          onChange={onContribUpdate(row.id)}
          type="number"
          inputMode="numeric"
          step={1}
          max={100}
          min={0}
          suffix="%"
          disabled={!['Approved', 'Published'].includes(row.status)}
        />
      );
    },
  },
];

// eslint-disable-next-line
const ResultAggregationForm = ({
  examYearId,
  terminalExamId,
  classId,
  onFinish,
}: ResultAggregationFormProps): JSX.Element => {
  const [form] = Form.useForm();

  const aggregateTermsStatus = useSelector((state: RootState) => state.resultAggregationStatus);

  const { approveAggregateResult, fetchAggregateStatus } = useResult();

  const [resultTitle, setResultTitle] = React.useState<string>('');
  const [contributions, setContributions] = React.useState<TestTypeAndContributionPair>({});

  const udpateContributions = (
    localTerminalExamId: string,
  ): React.ChangeEventHandler<HTMLInputElement> => (ev): void => {
    setContributions({
      ...contributions,
      [localTerminalExamId]: Number(ev.target.value),
    });
  };

  const concerned_test_types = React.useMemo(() => {
    return aggregateTermsStatus.data.test_types;
  }, [aggregateTermsStatus.data.test_types]);

  const aggregation_sum = React.useMemo(() => {
    return Object.values(contributions)
      .map((v) => Number(v))
      .reduce((prev, curr) => prev + curr, 0);
  }, [contributions]);

  const examIdName = React.useMemo(() => {
    const tp = 'terminal'.length;
    const xm = examYearId.length;

    return terminalExamId.slice(tp + xm + 2);
  }, [terminalExamId, examYearId]);

  const onApprove = (): void => {
    form.validateFields().then((v): void => {
      Modal.confirm({
        centered: true,
        title: 'Confirm Approval!!!',
        content: 'Ensure that all the information are correct. This action cannot be reverted.',
        okText: 'Yes, Approve!',
        cancelText: 'Cancel and Verify',
        onOk() {
          const payload: AggregateResultCreateModel = {
            examYearId,
            terminalExamId,
            gradeId: classId,
            title: resultTitle,
            testTypeAndContributionPair: contributions,
          };

          // eslint-disable-next-line
          approveAggregateResult(payload, onFinish);
        },
      });
    });
  };

  React.useEffect(() => {
    if (examYearId && classId) {
      fetchAggregateStatus(examYearId, classId);
    }
  }, [examYearId, classId]);

  React.useEffect(() => {
    setResultTitle(examIdName);
  }, [examIdName]);

  return (
    <ResultStyle>
      <main>
        <Form form={form} layout="vertical" onFinish={onApprove}>
          <InfoWrapper>
            <div className="info__item">
              <div className="info__title">Year</div>
              <div className="info__value">{examYearId}</div>
            </div>
            <div className="info__item">
              <div className="info__title">Term</div>
              <div className="info__value">{examIdName}</div>
            </div>
            <div className="info__item">
              <div className="info__title">Class</div>
              <div className="info__value">Class {classId}</div>
            </div>
          </InfoWrapper>

          <Form.Item label="Aggregate Result Name" required style={{ marginBottom: 15 }}>
            <Input
              placeholder="Final_Aggregation_2023"
              value={resultTitle}
              onChange={(e): void => setResultTitle(e.target.value)}
            />
          </Form.Item>

          <ContributionsWrapper>
            <DataTable
              progressPending={aggregateTermsStatus.status === 'loading'}
              data={concerned_test_types}
              columns={aggregateTableModel(udpateContributions)}
            />
            <div className="sum">
              <span>Total</span>
              <Popover
                content="Total aggregate must be 100% for approval"
                placement="left"
                arrowPointAtCenter
              >
                <span
                  className="value"
                  style={{ color: aggregation_sum === 100 ? 'green' : 'black' }}
                >
                  {aggregation_sum} %
                </span>
              </Popover>
            </div>
          </ContributionsWrapper>

          <Form.Item>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button disabled={aggregation_sum !== 100 || !resultTitle.length} htmlType="submit">
                Approve
              </Button>
            </div>
          </Form.Item>
        </Form>
      </main>
    </ResultStyle>
  );
};

export default ResultAggregationForm;
