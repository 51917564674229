import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import systemSlice from 'redux/slice/system.slice';

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    console.log('logging user out');
    dispatch(systemSlice.actions.resetState());
    localStorage.removeItem('token');
    localStorage.removeItem('user_details');
    history.replace('/');
  };
  return { onLogout };
};
