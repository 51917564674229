import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { TransactionModel } from './transaction.model';

export const onGetTransactions = (
  userId: string,
  cardNumber: string,
): Promise<TransactionModel[]> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/transaction?userId=' + userId + '&card_number=' + cardNumber,
  );
};
