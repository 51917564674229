import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface Props {
  examYear: string;
  value?: string;
  onChange?: (val: string) => void;
}

function SelectExamType({ examYear, value, onChange }: Props): JSX.Element {
  const exams = useSelector((state: RootState) => state.examYear);

  const [localSectionSelected, setSelectedSection] = React.useState<string | undefined>(undefined);

  const typeList = React.useMemo(() => {
    const examData = exams.data.find((item) => item.id === examYear);

    if (!examData) return [];

    return examData.test_types.map((item) => (
      <Select.Option key={`atten-class${item.id}`} value={item.id}>
        {item.id.split('|')[1]}
      </Select.Option>
    ));
  }, [exams.data, examYear]);

  // if value exists, pre-populate
  React.useEffect(() => {
    if (value) setSelectedSection(value);
  }, [value]);

  // reset state value
  React.useEffect(() => {
    if (onChange) onChange('');
    setSelectedSection(undefined);
  }, [examYear]);

  return (
    <>
      <Select
        loading={exams.status === 'loading'}
        style={{ width: '100%' }}
        value={localSectionSelected}
        placeholder="Select Exam Type"
        className="grade-select-field"
        onSelect={(val): void => {
          setSelectedSection(val);
          if (onChange) onChange(val);
        }}
      >
        {typeList}
      </Select>
    </>
  );
}

export default SelectExamType;
