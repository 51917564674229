import { Drawer } from 'antd';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AnnouncementForm } from './announementForm';
import AnnouncementCategoryForm from './announcementCategoryForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useAnnouncement } from '../hooks/useAnnouncement';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import DataTable from 'react-data-table-component';
import TableColumn from './announcement.table';
import _ from 'lodash';

interface Props {}

let chooseTitle: string;
let chooseForm: any;
const AnnouncementView: React.FC<Props> = () => {
  const { fetchAnnouncementCategory, fetchAnnouncement } = useAnnouncement();
  const [visible, setVisible] = React.useState(false);
  const announcementCategory = useSelector((state: RootState) => state.announcementCategory);
  const announcement = useSelector((state: RootState) => state.announcement);
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [reloadCategory, setReloadCategory] = React.useState(true);
  const [reloadAnnouncement, setReloadAnnouncement] = React.useState(true);

  const category: string[] = [];
  category.push('All');
  announcementCategory.data.map((item) => {
    category.push(item.title);
  });

  let grouped_data = _(announcement.data.data)
    .groupBy((announcement) => announcement.category.title)
    .map((value, key) => ({ category: key, data: value }))
    .value();

  const showDrawer = (value: string) => {
    setVisible(true);
    if (value === 'AnnouncementButton') {
      chooseTitle = 'Add Announcement';
      chooseForm = (
        <AnnouncementForm
          onVisibleChange={setVisible}
          category={announcementCategory.data}
          onReload={setReloadAnnouncement}
        />
      );
    }
    if (value === 'CategoryButton') {
      chooseTitle = 'Add Announcement Category';
      chooseForm = (
        <AnnouncementCategoryForm onVisibleChange={setVisible} onReload={setReloadCategory} />
      );
    }
  };

  const onClose = () => {
    setVisible(false);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const handlePerRowsChange = (newLimit: number) => {
    setLimit(newLimit);
  };
  const memoAnnouncementReload = useMemo(() => reloadAnnouncement, [reloadAnnouncement]);
  const memoCategoryReload = useMemo(() => reloadCategory, [reloadCategory]);

  React.useEffect(() => {
    if (memoAnnouncementReload) {
      fetchAnnouncement(page, limit);
      setReloadAnnouncement(false);
    }
  }, [memoAnnouncementReload]);

  React.useEffect(() => {
    if (!memoAnnouncementReload) {
      fetchAnnouncement(page, limit);
    }
  }, [page, limit]);

  React.useEffect(() => {
    if (memoCategoryReload) {
      fetchAnnouncementCategory();
      setReloadCategory(false);
    }
  }, [memoCategoryReload]);

  return (
    <AnnouncementViewWrapper>
      <KPageHeader
        title="Sytstem Users"
        rightButtonTitle="Add Announcement"
        additionalButtonTitle="Add Announcement Category"
        onAdditionButtonClick={() => showDrawer('CategoryButton')}
        onRightButtonClick={() => showDrawer('AnnouncementButton')}
      />
      <Drawer
        destroyOnClose
        width={780}
        visible={visible}
        closable={false}
        onClose={onClose}
        title={<h2>{chooseTitle}</h2>}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {chooseForm}
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the announcement in this system"
      />

      <section className="page-tabs">
        {category.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setSelectedCategory(item);
            }}
            className={`page-tabs-item ${item === selectedCategory ? 'active' : ''}`}
          >
            {item}
          </div>
        ))}
      </section>

      <section className="page-table">
        {selectedCategory === 'All' && announcement.status === 'data' && (
          <DataTable
            customStyles={tableCustomStyles}
            className="data-table"
            noHeader
            highlightOnHover
            data={announcement.data.data}
            columns={TableColumn()}
            pagination
            paginationServer
            paginationTotalRows={announcement.data.totalCount}
            paginationDefaultPage={page}
            onChangePage={(page) => handlePageChange(page)}
            onChangeRowsPerPage={(page, newLimit) => handlePerRowsChange(newLimit)}
            paginationComponentOptions={{ noRowsPerPage: true }}
            responsive={true}
          />
        )}
        {grouped_data.map(
          (item) =>
            item.category === selectedCategory &&
            announcement.status === 'data' && (
              <DataTable
                customStyles={tableCustomStyles}
                className="data-table"
                noHeader
                highlightOnHover
                data={item.data}
                columns={TableColumn()}
                pagination
                paginationTotalRows={item.data.length}
                paginationDefaultPage={page}
                onChangePage={(page) => handlePageChange(page)}
                onChangeRowsPerPage={(newLimit) => handlePerRowsChange(newLimit)}
                paginationComponentOptions={{ noRowsPerPage: true }}
                responsive={true}
              />
            ),
        )}
      </section>
    </AnnouncementViewWrapper>
  );
};

export const AnnouncementViewWrapper = styled.div`
  padding-right: 20px;
  .page-tabs {
    display: flex;
    align-items: center;
    margin: var(--gap-xl) 0;

    .page-tabs-item {
      font-size: var(--font-base);
      font-weight: 500;
      margin-right: var(--gap-xl);
      color: var(--text-regular);
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--link);
      }
    }
  }
  .section-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default AnnouncementView;
