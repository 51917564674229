import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { Event, EventPost } from './event.modle';

export const onGetAllEvents = (): Promise<Event[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/events');
};

export const onGetUpcomingEvents = (): Promise<Event[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/events/upcoming');
};

export const onCreateEvent = (payload: EventPost): Promise<unknown> => {
  return axiosInstance.post(http_config.BASE_URL + '/events', payload);
};
export const handleEventUpdate = (id: string, payload: Partial<EventPost>): Promise<unknown> => {
  return axiosInstance.put(http_config.BASE_URL + `/events/${id}`, payload);
};
export const handleEventDelete = (id: string): Promise<unknown> => {
  return axiosInstance.delete(http_config.BASE_URL + `/events/${id}`);
};
