import React from 'react';
import AlertNoticeForm from '../view/alertNotice';
import AssignParent from '../view/assignParent';
import IssueBookForm from '../view/issueBookForm';
import LoadAmountForm from '../view/loadAmountForm';
import AddCardForm from '../view/useCardForm';
import AddParentForm from '../view/useParentForm';
import AddManagerForm from '../view/userManagerForm';
import AddStudentForm from '../view/userStudentForm';
import AddSupportPersonForm from '../view/userSupportPersonForm';
import AddTeacherForm from '../view/userTeacherForm';
import ViewIssuedBook from '../view/viewIssuedBooks';
import ViewTransactions from '../view/viewTransaction';
import AddNonTeachingForm from '../view/userNonTeacherForm';

const ViewDrawer = (props: any) => {
  const { view, title, setTitle, eachRow, setVisibleDrawer, setReloadAfterDrawer } = props;
  return (
    <>
      {view === 'students' && (
        <AddStudentForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {view === 'teachers' && (
        <AddTeacherForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {view === 'other-staffs' && (
        <AddNonTeachingForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {/* {view === 'managers' && (
        <AddManagerForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {view === 'supports' && (
        <AddSupportPersonForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )} */}
      {view === 'parents' && (
        <AddParentForm
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}

      {title === 'View Issued Books' && <ViewIssuedBook value={eachRow} />}
      {title === 'Issue Book' && (
        <IssueBookForm
          value={eachRow}
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {title === 'Load Amount' && (
        <LoadAmountForm
          value={eachRow}
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
          onSetTitle={setTitle}
        />
      )}
      {title === 'View Transactions' && <ViewTransactions value={eachRow} />}
      {/* {title === 'Assign Card' && (
        <AddCardForm
          value={eachRow}
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )} */}
      {title === 'Assign Parent' && (
        <AssignParent
          value={eachRow}
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
      {title === 'Alert Notice' && (
        <AlertNoticeForm
          value={eachRow}
          onVisibleChange={setVisibleDrawer}
          onSetReloadAfterDrawer={setReloadAfterDrawer}
        />
      )}
    </>
  );
};

export default ViewDrawer;
