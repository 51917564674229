import React from 'react';
import { HiOutlineLightBulb } from 'react-icons/hi';
import styled from 'styled-components';

interface ProTipsProps {
  title: string;
  description: string;
}

const KProTip = ({ title, description }: ProTipsProps) => {
  return (
    <ProTipWrapper>
      <header>
        <p className="title">{title}</p>
        <span>
          <HiOutlineLightBulb />
        </span>
      </header>
      <p className="description">{description}</p>
    </ProTipWrapper>
  );
};

export const ProTipWrapper = styled.div`
  background: #fff;
  padding: var(--gap-md);
  border-radius: var(--br);

  header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .title {
      font-size: var(--font-base);
      font-weight: 700;
      margin-right: var(--gap);
    }
    span {
      font-size: 20px;
      path {
        stroke-width: 3px !important;
      }
    }
  }
  .description {
    font-size: var(--font-base);
  }
`;

export default KProTip;
