import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GradeAttendanceDetail } from 'data/attendance/attendance.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: { list: GradeAttendanceDetail[]; grade: number };
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: { list: [], grade: 0 },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<
  SliceState,
  PayloadAction<{ list: GradeAttendanceDetail[]; grade: number }>
> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: { list: [], grade: 0 },
  status: 'error',
});

const clear = (): SliceState => initState;

const gradeAttendanceCheckSlice = createSlice({
  name: 'gradeAttendanceCheck',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
    clear,
  },
});

export default gradeAttendanceCheckSlice;
