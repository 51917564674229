import styled from 'styled-components';

export const MarkSheetWrapper = styled.section`
  height: 297mm;
  width: 210mm;

  .wrapper {
    width: 100%;
    height: 100%;
    padding: 32px;
    position: relative;

    .dates {
      width: 100%;
      height: 3px;
      transform: translateY(-21px);
      font-size: 11px;

      display: flex;
      justify-content: space-between;
    }

    .printed {
      z-index: 1;
    }
    .watermark__logo {
      display: block;

      position: fixed;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -999;
      img {
        opacity: 0.1;
        z-index: -999;
      }
    }
  }

  @page {
    size: A4 portrait;
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 0.5rem 2rem;

    border: 1px double #555555;
    outline: 3px double #555555;
    outline-offset: 3px;

    .result__remarks {
      .f-container {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        padding-bottom: 5px;
      }
    }

    footer {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .signature {
        text-align: center;

        .line {
          width: 150px;
          height: 5px;
          border-top: 1px dashed black;
        }

        .act {
          font-size: 12.5px;
        }
      }
    }
  }

  header {
    width: 100%;
    text-align: center;

    .school__logo {
      height: 60px;
      width: 60px;
      margin-bottom: 10px;
    }

    h1,
    h2,
    h3 {
      margin: 0 0;
    }
    h1 {
      text-transform: uppercase;
    }
  }
  main {
    width: 100%;

    table {
      margin: 10px 0;
      width: 100%;

      thead,
      tbody {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      thead {
        font-weight: 500;
        border: 1px solid black;
      }

      tbody {
        border: 1px solid black;
        border-top-width: 0px;

        tr {
          border-bottom: 1px solid grey;

          &:last-child {
            border-bottom-width: 0;
          }
        }
      }

      td {
        border-right: 1px solid black;

        &.marks {
          width: 60px;
        }

        &.code {
          width: 140px;
        }

        &:last-child {
          border: none;
        }
      }

      tr {
        width: 100%;
        display: flex;
        flex-direction: row;

        td {
          padding: 2px;
          width: 75px;
          text-align: left;

          &:first-child {
            width: 35px;
          }

          &.code {
            width: 140px;
          }

          &.subject {
            width: 200px;
            flex: 1;
            text-align: left;
          }

          &.remarks {
            text-align: left;
            width: minmax(150px, 300px);
          }

          &.marks {
            width: 60px;
          }
        }
      }

      .footer {
        border: 1px solid black;
        border-top-width: 0px;
      }
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 800;
      margin: 0.4em 0;
    }

    .declaration {
      width: 100%;
      display: flex;
      flex-direction: column;

      .line {
        width: 100%;
        display: flex;
        gap: 0.5em;

        .value {
          font-weight: 600;
          flex: 1 0 auto;
          border-bottom: 1px dashed black;
          text-align: center;
        }
      }
    }
  }
`;

export default {
  MarkSheetWrapper,
};
