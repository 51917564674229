import styled from 'styled-components';

export const AcademicYearWrapper = styled.div`
  font-size: var(--font-base);

  input {
    width: 100%;
    padding: 10px;
    height: 38px;
    display: inline-block;
    background: var(--bg);
    border-radius: 4px;
    border: 1px solid var(--gray900);
  }

  .title {
    margin-bottom: 10px;
  }

  .grade {
    margin-top: 20px;
    label {
      margin-right: 20px;
    }
    select {
      padding: 10px;
      height: 38px;
    }
  }

  button {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }
`;
