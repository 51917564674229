import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  padding-right: 20px;
`;

export const AbsentViewWrapper = styled.div`
  background: #fff;
  padding: var(--gap) 0px var(--gap) 0px;
  border-radius: var(--br);
  width: 500px;
  box-shadow: var(--shadow-light);

  .title {
    font-size: var(--font-base);
    font-weight: 700;
    padding-left: var(--gap-md);
    padding-right: var(--gap-md);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sub-title {
    font-size: var(--font-sm);
    font-weight: 500;
    margin-right: 40px;
    margin-left: 20px;
  }

  .data-list {
    background: var(--gray500);
    margin: 10px 20px 10px 20px;
    border-radius: var(--br);
    display: flex;
    justify-content: space-between;
    padding: var(--gap);
    overflow: overlay;
    &:hover {
      background: var(--gray700);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  .sub-header {
    font-size: var(--font-base);
    font-weight: 500;
    margin-right: 40px;
    margin-left: 30px;
  }
`;
