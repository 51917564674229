import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  GradeAttendanceDetail,
  GradeWiseAttendanceResponseModel,
  NewAttendanceResponseModel,
  StaffAttendanceResponseModel,
} from './attendance.model';

export const onGetAttendance = (
  grade: string,
  section: string,
  month: string,
  year: string,
): Promise<NewAttendanceResponseModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/attendance/grade/${grade}/section/${section}/month/${year}-${month}-01`,
  );
};
export const onGetAttendanceByRange = (
  grade: string,
  section: string,
  from: string,
  to: string,
): Promise<NewAttendanceResponseModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/attendance/grade/${grade}/section/${section}/${from}/${to}`,
  );
};

export const onGetStaffAttendance = (
  from: string,
  to: string,
): Promise<StaffAttendanceResponseModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/attendance/staff/${from}/${to}`);
};

export const onGetGradeWiseAttendance = (
  academicYear: string,
): Promise<GradeWiseAttendanceResponseModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/attendance/all-attendances/${academicYear}`);
};

export const onGradeAttendanceCheck = (gradeId: number): Promise<GradeAttendanceDetail[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/attendance/today/${gradeId}`);
};
