import React from 'react';
import { Form, Button, Input, DatePicker } from 'antd';
import TextEditor from 'common/component/Inputs/TextEditor';
import { useEvent } from '../hooks/useEvent';
import { AddEventWrapper } from './event.style';
import Calendar from 'common/component/KCalendar';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';

export const AddEvent = (props: any) => {
  const { onSaveEvent } = useEvent();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadEvent } = props;

  const [description, setDescription] = React.useState<string>('');
  const [date, setDate] = React.useState<string>('');

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  const handleSubmit = () => {
    onSaveEvent(
      {
        ...form.getFieldsValue(),
        description,
        date,
      },
      form,
      onVisibleChange,
      onSetReloadEvent,
    );
  };

  return (
    <AddEventWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="title" label="Event">
          <Input />
        </Form.Item>
        <Form.Item label="Description">
          <div className="quill__field">
            <TextEditor onChange={onDescriptionChange} value={description} />
          </div>
        </Form.Item>
        <Form.Item name="date" label="Date">
          <Calendar
            onChange={({ adDate }: any) => {
              setDate(new Date(adDate).toISOString());
            }}
            style={{ width: 250 }}
            language="en"
            placeholder="Select Date of Issue"
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddEventWrapper>
  );
};

export default AddEvent;
