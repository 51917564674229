import { Dispatch, SetStateAction, useMemo, useState } from 'react';

interface Pagination {
  page: number;
  rowsPerPage: number;
}

interface UsePaginationReturnType<T> extends Pagination {
  paginatedData: T[];
  handlePerRowsChange: (rowsPerPage: number) => void;
  handlePageChange: (page: number) => void;
  setPagination: Dispatch<SetStateAction<Pagination>>;
}

export const usePagination = <T>(data: T[]): UsePaginationReturnType<T> => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    rowsPerPage: 10,
  });

  const handlePerRowsChange = (rowsPerPage: number): void => {
    setPagination({
      ...pagination,
      rowsPerPage,
    });
  };

  const handlePageChange = (page: number): void => {
    setPagination({
      ...pagination,
      page,
    });
  };

  const paginatedData = useMemo(() => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = Math.min(startIndex + pagination.rowsPerPage, data.length);

    return [...data].slice(startIndex, endIndex);
  }, [pagination, data.length]);

  return {
    ...pagination,
    paginatedData,
    handlePageChange,
    handlePerRowsChange,
    setPagination,
  };
};

export default usePagination;
