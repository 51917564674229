import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError } from 'common/helper/notifications';
import { Root } from 'data/result/result.model';
import { onGetFinalResultForView, onGetTerminalResultForView } from 'data/result/result.service';
import { useDispatch } from 'react-redux';
import finalAggregatedResultsSlice from 'redux/slice/finalAggregatedResults.slice';
import termAggregatedResultsSlice from 'redux/slice/termAggregatedResults.slice';

const useTermResult = () => {
  const dispatch = useDispatch();

  const fetchAggregatedResultForView = async (
    examYearId: string,
    terminalExamId: string,
    classId: string,
  ): Promise<void> => {
    try {
      dispatch(termAggregatedResultsSlice.actions.setLoading());
      let res = await onGetTerminalResultForView(examYearId, terminalExamId, classId);
      console.log(res);
      dispatch(termAggregatedResultsSlice.actions.setData(res));
    } catch (err) {
      notifyError('Fetching Results', decodeApiMessage(err));
      dispatch(termAggregatedResultsSlice.actions.setError(err));
    }
  };

  const fetchFinalAggregatedResultForView = async (
    examYearId: string,
    terminalExamId: string,
    classId: string,
  ): Promise<void> => {
    try {
      dispatch(finalAggregatedResultsSlice.actions.setLoading());
      const res = await onGetFinalResultForView(examYearId, terminalExamId, classId);
      dispatch(finalAggregatedResultsSlice.actions.setData(res));
    } catch (err) {
      notifyError('Fetching Results', decodeApiMessage(err));
      dispatch(finalAggregatedResultsSlice.actions.setError(err));
    }
  };

  return { fetchAggregatedResultForView, fetchFinalAggregatedResultForView };
};

export default useTermResult;
