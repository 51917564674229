import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { AcademicYear } from './academic-year.modle';

export const onGetAllAcademicYear = (): Promise<AcademicYear[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/academic-year');
};

export const onGetCurrentAcademicYear = (): Promise<{ current: AcademicYear }> => {
  return axiosInstance.get(http_config.BASE_URL + '/academic-year/current');
};

export const handleAcademicYearSave = (payload: { year: string }): Promise<void> => {
  return axiosInstance.post(http_config.BASE_URL + '/academic-year', payload);
};

export const handleSetAcademicYearActive = (payload: { year: string }): Promise<void> => {
  return axiosInstance.patch(http_config.BASE_URL + `/academic-year/set-active/${payload.year}`);
};
