import { Drawer, Input, Select } from 'antd';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import KSpinner from 'common/component/KSpinner';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import { ExamRoutineWrapper } from './examRoutine.style';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AddExamRoutineForm } from './examRoutineForm';
import { useGrade } from '../../routine/hooks/useGrade';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { ExamRoutineModel } from 'data/exam/examRoutine.model';
import { useExamRoutine } from '../hooks/useExamRoutine';
import { GradeModel } from 'data/grade/grade.model';
import { KButton } from 'common/component/KButton';
import moment from 'moment';
import AddExamRoutines from './AddExamRoutines';

interface Props {}

const ExamRoutineView: React.FC<Props> = () => {
  const examRoutine = useSelector((state: RootState) => state.examRoutine);
  const grade = useSelector((state: RootState) => state.grade);
  const examYear = useSelector((state: RootState) => state.examYear);
  const examType = useSelector((state: RootState) => state.examType);
  const [visible, setVisible] = React.useState(false);
  const [selectedExamType, setSelectedExamType] = React.useState('');
  const [selectedExamYear, setSelectedExamYear] = React.useState('');
  const { fetchGrade } = useGrade();
  const { fetchExamRoutine, fetchExamType, fetchExamYear } = useExamRoutine();
  const [reloadExamRoutine, setReloadExamRoutine] = React.useState(false);
  const [isShowRoutineButtonClicked, setShowRoutineButtonClicked] = React.useState(false);

  let displaySubject: string;
  let displayStartDate: string;
  let displayEndDate: string;

  var selectedExamRoutineByDate: any = [];

  if (isShowRoutineButtonClicked) {
    console.log(examRoutine.data);
    selectedExamRoutineByDate = _.chain(examRoutine.data)
      .sortBy('date')
      .groupBy('date')
      .map((value, key) => ({ Date: key, routine: value }))
      .value();
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showSubject = (row: ExamRoutineModel[], gradeItem: GradeModel) => {
    let testCondition = false;
    row.map((eachRoutine) => {
      if (eachRoutine.gradeId === gradeItem.id) {
        displaySubject = eachRoutine.subject.title;
        displayStartDate = moment(eachRoutine.start, 'HH:mm a').format('HH:mm a');
        displayEndDate = moment(eachRoutine.end, 'HH:mm a').format('HH:mm a');
        testCondition = true;
      } else if (eachRoutine.gradeId !== gradeItem.id && !testCondition) {
        displaySubject = '-';
      }
    });

    return (
      <div>
        <div>{displaySubject}</div>
        {displaySubject !== '-' && (
          <div>
            <h5>
              {displayStartDate} - {displayEndDate}
            </h5>
          </div>
        )}
      </div>
    );
  };

  const TableColumn = () => {
    let headers = [];
    headers.push({
      name: 'Data',
      selector: 'Date',
      sortable: true,
      cell: (props: any) => <div>{props.Date}</div>,
    });
    grade.data.forEach((gradeItem) => {
      headers.push({
        name: 'Class ' + gradeItem.id,
        selector: gradeItem.id,
        cell: (props: any) => (
          <div style={{ width: 200 }}>{showSubject(props.routine, gradeItem)}</div>
        ),
      });
    });
    return headers;
  };

  const handleExamRoutine = () => {
    if (selectedExamYear !== '' && selectedExamType !== '') {
      fetchExamRoutine(selectedExamYear, selectedExamType);
      setShowRoutineButtonClicked(true);
    }
  };

  React.useEffect(() => {
    fetchExamType();
    fetchExamYear();
    fetchGrade();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (reloadExamRoutine && isShowRoutineButtonClicked) {
      fetchExamRoutine(selectedExamYear, selectedExamType);
      setReloadExamRoutine(false);
    }
  }, [reloadExamRoutine]);

  return (
    <ExamRoutineWrapper>
      <KPageHeader
        title="Exam Routine"
        rightButtonTitle="Add Exam Routine"
        onRightButtonClick={showDrawer}
      />
      <Drawer
        destroyOnClose
        title={<h2>Add Exam Routine</h2>}
        width={720}
        visible={visible}
        closable={false}
        onClose={onClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddExamRoutines
          onVisibleChange={setVisible}
          onSetReloadExamRoutine={setReloadExamRoutine}
          grade={grade.data}
          examType={examType.data}
          examYear={examYear.data}
        />
      </Drawer>

      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the exam routines of all grade."
      />
      <section className="searchSection">
        <Input.Search className="search" placeholder="Search from here..." />
      </section>

      <section className="section-wrapper">
        <section className="gradeDropdown">
          <Select
            defaultValue="Select Exam Year"
            style={{ width: '180px', marginRight: '10px' }}
            onSelect={(value) => {
              setSelectedExamYear(value);
            }}
          >
            {examYear.data.map((item) => (
              <Select.Option value={item.id}> {item.id}</Select.Option>
            ))}
          </Select>
          <Select
            defaultValue="Select Exam Type"
            style={{ width: '180px', marginRight: '10px' }}
            onSelect={(value) => {
              setSelectedExamType(value);
            }}
          >
            {examType.data.map((item) => (
              <Select.Option value={item.id}> {item.id}</Select.Option>
            ))}
          </Select>
          <KButton onClick={handleExamRoutine}>Show Routine</KButton>
        </section>
        {isShowRoutineButtonClicked && (
          <section className="dataTable">
            <>
              {examRoutine.status === 'loading' && <KSpinner />}
              {examRoutine.status === 'data' && (
                <DataTable
                  customStyles={tableCustomStyles}
                  className="data-table"
                  noHeader
                  highlightOnHover
                  responsive={true}
                  data={selectedExamRoutineByDate}
                  columns={TableColumn()}
                />
              )}

              {/* {examRoutine.status === 'data' && (
                <DataTable
                  customStyles={tableCustomStyles}
                  className="data-table"
                  noHeader
                  highlightOnHover
                  pagination
                  responsive={true}
                  data={selectedExamRoutineByDate}
                  columns={TableColumn()}
                  columns={grade.data.map((item) => {
                    return {
                      name: item.id,
                      selector: item.id,
                      cell: (row: ExamRoutineModel) => (
                        <div>
                          {setSelectedDate(row.date)}
                          {setSelectedGrade(item.id)}
                          {row.date}
                        </div>
                      ),
                    };
                  })}
                />
              )} */}
            </>
          </section>
        )}
      </section>
    </ExamRoutineWrapper>
  );
};

export default ExamRoutineView;
