import http_config from '../../../common/config/http_config';
import axiosInstance from '../../../common/helper/axiosInterceptor';
import { IssueBookModel, IssuedBooksModel } from './issueBook.model';

export const onSetIssueBook = (payload: IssueBookModel): Promise<IssueBookModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/library/issue-book', payload);
};

export const onGetIssuedBooks = (): Promise<IssuedBooksModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/library/issued-book');
};
