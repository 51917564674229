import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useExamYear } from 'features/exam-year/hooks/useExamYear';

interface Props {
  value?: string;
  onChange?: (val: string) => void;
}

function SelectExamYear({ value, onChange }: Props): JSX.Element {
  const exams = useSelector((state: RootState) => state.examYear);

  const { fetchExamYear } = useExamYear();

  const gradeList = React.useMemo(() => {
    return exams.data.map((item) => (
      <Select.Option key={`atten-class${item.id}`} value={item.id}>
        {item.id}
      </Select.Option>
    ));
  }, [exams.data]);

  React.useEffect(() => {
    if (exams.status === 'idle') fetchExamYear();
  }, [exams.status]);

  return (
    <>
      <Select
        loading={exams.status === 'loading'}
        style={{ width: '100%' }}
        value={value?.length ? value : undefined}
        placeholder="Select Exam Year"
        className="grade-select-field"
        onSelect={(val): void => {
          if (onChange) onChange(val);
        }}
      >
        {gradeList}
      </Select>
    </>
  );
}

export default SelectExamYear;
