import styled from 'styled-components';

export const AddRoutineFormWrapper = styled.div`
  input {
    width: 100%;
    padding: 10px;
    height: 38px;
    display: inline-block;
    background: var(--bg);
    border-radius: 4px;
    border: 1px solid var(--gray900);
  }

  .time {
    display: flex;
    justify-content: space-between;
  }

  .classSection {
    display: flex;
    justify-content: space-between;
  }
  button {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }
  .gap {
    height: var(--gap);
  }
`;

export const RoutineWrapper = styled.div`
  padding-right: 20px;
  .searchSection {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
    .search {
      width: 200px;
    }
  }
  .section-wrapper {

    .gradeDropdown {
      margin-bottom: 10px;
    }
    .routineOfWeek {
      width: 50%;
      padding: 20px;
      height: 475px;
      height: calc(100vh - 250px);
      display: inline-block;
      background: var(--white);
      border-radius: 2px;
      border: 1px solid var(--gray700);
      overflow: overlay;

      .page-tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: var(--gap) 0;
    
        .page-tabs-item {
          font-size: var(--font-base);
          font-weight: 500;
          margin-right: var(--gap-xl);
          color: var(--text-regular);
          cursor: pointer;
    
          &:hover,
          &.active {
            color: var(--link);
          }

          
        }

        
    }
    .routineList{
      display:flex;
      justify-content: space-between;
    }
    .timeline{
      width:inherit;
      margin-top:50px;
    }
    .subTeacher{
      padding:10px;
      width:300px;
      
    }
    .innerSubTeacher{
    
      padding:5px 10px 5px 10px;
      background: var(--gray500);
      border-radius: 2px;
      border: 1px solid var(--gray700);
    }
    .subName{
      font: var(--font-base);
      font-weight:700;
    }
    .teacherName{
      font:var(--font-sm);
      color:var(--text-label);
    }
    .mainTimeLabel{
      padding:10px 0px 0px 0px;
    }
    .timeLabel{ font-size: var(--font-xxs);
       font-weight: 500;
        color: var(--text-label); }

     .actions{
       display:flex;
       justify-content:space-between;
     }   
  }
`;
