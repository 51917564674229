import {
  onGetAttendance,
  onGetAttendanceByRange,
  onGetStaffAttendance,
} from 'data/attendance/attendance.service';
import { useDispatch } from 'react-redux';
import attendanceSlice from 'redux/slice/attendance.slice';
import staffAttendanceSlice from 'redux/slice/staff-attendance.slice';

export const useAttendance = () => {
  const dispatch = useDispatch();

  const fetchAttendance = async (grade: string, section: string, month: string, year: string) => {
    dispatch(attendanceSlice.actions.setLoading());
    try {
      const res = await onGetAttendance(grade, section, month, year);
      dispatch(attendanceSlice.actions.setData(res));
    } catch (err) {
      dispatch(attendanceSlice.actions.setError(err));
    }
  };

  const fetchAttendanceByRange = async (
    grade: string,
    section: string,
    from: string,
    to: string,
  ) => {
    dispatch(attendanceSlice.actions.setLoading());
    try {
      const res = await onGetAttendanceByRange(grade, section, from, to);
      dispatch(attendanceSlice.actions.setData(res));
    } catch (err) {
      dispatch(attendanceSlice.actions.setError(err));
    }
  };

  const fetchStaffAttendance = async (from: string, to: string) => {
    dispatch(staffAttendanceSlice.actions.setLoading());
    try {
      const res = await onGetStaffAttendance(from, to);
      dispatch(staffAttendanceSlice.actions.setData(res));
    } catch (err) {
      dispatch(staffAttendanceSlice.actions.setError(err));
    }
  };

  return { fetchAttendance, fetchStaffAttendance, fetchAttendanceByRange };
};
