import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useExamType } from 'features/exam-type/hooks/useExamType';

interface Props {
  examYear: string;
  value?: string;
  onChange?: (val: string) => void;
}

function SelectFinalExamType({ examYear, value, onChange }: Props): JSX.Element {
  const examFinalTypes = useSelector((state: RootState) => state.examFinalType);

  const { fetchfinalTestType } = useExamType();

  const [localSectionSelected, setSelectedSection] = React.useState<string | undefined>(undefined);

  const typeList = React.useMemo(() => {
    return examFinalTypes.data
      .filter((t) => t.exam_year.id === examYear)
      .map((item) => (
        <Select.Option key={`atten-class${item.id}`} value={item.id}>
          {item.id.split('|')[1]}
        </Select.Option>
      ));
  }, [examYear, examFinalTypes]);

  // if value exists, pre-populate
  React.useEffect(() => {
    if (value) setSelectedSection(value);
  }, [value]);

  // reset state value
  React.useEffect(() => {
    if (onChange) onChange('');
    setSelectedSection(undefined);
  }, [examYear]);

  React.useEffect(() => {
    if (examFinalTypes.status === 'idle') fetchfinalTestType();
  }, [examFinalTypes.status]);

  return (
    <>
      <Select
        loading={examFinalTypes.status === 'loading'}
        style={{ width: '100%' }}
        value={localSectionSelected}
        placeholder="Select Exam Final"
        className="grade-select-field"
        onSelect={(val): void => {
          setSelectedSection(val);
          if (onChange) onChange(val);
        }}
      >
        {typeList}
      </Select>
    </>
  );
}

export default SelectFinalExamType;
