import { StudentDetails } from 'data/result/result.model';
import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  OptionalSubjectModel,
  OptionalSubjectRequestModel,
  ResultSubjectModel,
  SubjectCreateModel,
  SubjectModel,
} from './subject.model';

export const onGetSubjects = (): Promise<SubjectModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/subject');
};

export const onSetSubject = (payload: SubjectCreateModel): Promise<SubjectModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/subject', payload);
};

export const onBulkSetSubject = (payload: any): Promise<SubjectModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/subject/bulk', payload);
};
export const onUpdateSubject = (
  subjectId: string,
  payload: SubjectCreateModel,
): Promise<SubjectModel> => {
  return axiosInstance.put(http_config.BASE_URL + `/subject/${subjectId}`, payload);
};

export const onGetOptionalSubjects = (): Promise<OptionalSubjectModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/optional-subject');
};

export const onSetOptionalSubject = (
  payload: OptionalSubjectRequestModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(http_config.BASE_URL + '/optional-subject', payload);
};

export const onPutAssignStudents = (
  optSubId: string,
  studentIds: string[],
): Promise<{ message: string }> => {
  return axiosInstance.put(`${http_config.BASE_URL}/optional-subject/assign-students/${optSubId}`, {
    studentIds,
  });
};

export const onGetAssignedStudents = (optSubId: string): Promise<StudentDetails[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/optional-subject/${optSubId}/students`);
};

export const onGetResultSubject = (
  examYearId: string,
  testTypeId: string,
  classId: string,
  sectionId: string,
): Promise<ResultSubjectModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/subject/result?gradeId=${classId}&sectionId=${sectionId}&examYearId=${examYearId}&testTypeId=${testTypeId}`,
  );
};
