import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import styled from 'styled-components';
import { Button, Drawer } from 'antd';
import AddClassDetails from './AddClassDetails';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { EditFilled } from '@ant-design/icons';
import EditClassDetails from './EditClassDetails';
import { GradeModel } from 'data/grade/grade.model';
import { formatTimeMinutesOnly } from 'common/helper/timeFormatter';

interface Props {}

const tableColumn = (onEdit: Function): TableColumn[] => {
  return [
    {
      name: 'Class',
      selector: 'grade',
    },
    {
      name: 'Start Time',
      cell: (row) => <>{formatTimeMinutesOnly(row.startTime)}</>,
    },
    {
      name: 'End Time',
      cell: (row) => <>{formatTimeMinutesOnly(row.endTime)}</>,
    },
    {
      name: 'Sections',
      cell: (row) => <>{row.section}</>,
    },
    {
      name: 'Edit',
      selector: 'actions',
      sortable: true,
      cell: (row: any) => (
        <Button onClick={() => onEdit(row)}>
          <EditFilled />
        </Button>
      ),
    },
  ];
};

const ClassView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const grades = useSelector((state: RootState) => state.grade);
  const [reloadGrade, setReloadGrade] = React.useState(true);
  const [showEditDrawer, setShowEditDrawer] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState<any>(null);
  const { fetchGrade } = useGrade();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const memoGradeReload = useMemo(() => reloadGrade, [reloadGrade]);

  React.useEffect(() => {
    if (memoGradeReload) {
      fetchGrade();
      setReloadGrade(false);
      setSelectedClass(null);
    }
  }, [memoGradeReload]);

  const gradesData = React.useMemo(() => {
    return grades.data.map((item) => ({
      grade: item.id,
      startTime: item.startTime,
      endTime: item.endTime,
      section: item.sections.map((_) => _.id.split('-')[1]).join(', '),
      sections: item.sections,
    }));
  }, [grades.data]);

  return (
    <ClassViewWrapper>
      <KPageHeader title="Class" rightButtonTitle="Add Class" onRightButtonClick={showDrawer} />
      <Drawer
        width={400}
        title={<h2 style={{ fontWeight: 700 }}>Edit Grade</h2>}
        placement="right"
        onClose={() => {
          setSelectedClass(null);
          setShowEditDrawer(false);
        }}
        visible={showEditDrawer}
        closable={false}
      >
        {selectedClass && (
          <EditClassDetails
            // eslint-disable-next-line react/jsx-props-no-spreading
            // {...selectedClass}
            value={selectedClass.grade}
            startTime={selectedClass.startTime}
            endTime={selectedClass.endTime}
            sections={selectedClass.sections}
            onVisibleChange={setShowEditDrawer}
            onSetReloadGrade={setReloadGrade}
          />
        )}
      </Drawer>
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Add Grade</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddClassDetails onVisibleChange={setVisible} onSetReloadGrade={setReloadGrade} />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the grades that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive={true}
          progressPending={grades.status === 'loading'}
          data={gradesData}
          columns={tableColumn((row: GradeModel) => {
            setSelectedClass(row);
            setShowEditDrawer(true);
          })}
        />
      </section>
    </ClassViewWrapper>
  );
};

export const ClassViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

// const ClassView = () => <h1>Hellop</h1>;

export default ClassView;
