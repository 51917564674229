import React from 'react';
import { Form, Button, Input, Select } from 'antd';
import { AddClassSectionWrapper } from './class-section.style';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { formatTime } from 'common/helper/timeFormatter';
import moment from 'moment';
import { useSection } from 'features/routine/hooks/useSection';

export const EditClassSectionDetails = (props: any) => {
  const { fetchSections, onUpdateSection } = useSection();
  // const [selectedGrade, setSelectedGrade] = React.useState<string>('');
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadGrade } = props;
  const { fetchGrade } = useGrade();
  const grade = useSelector((state: RootState) => state.grade);

  console.log({ props });
  const handleSubmit = () => {
    onUpdateSection(
      {
        id: props?.section,
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadGrade,
    );
  };
  React.useEffect(() => {
    fetchSections();
    fetchGrade();
  }, []);

  return (
    <AddClassSectionWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="name" label="Name">
          <Input defaultValue={props?.name} />
        </Form.Item>
        <Form.Item name="value" label="Section">
          <Input placeholder="A" defaultValue={props?.value} disabled />
        </Form.Item>
        <Form.Item name="gradeId" label="Class">
          <Select
            defaultValue={props?.gradeId || 'Select Class'}
            className="grade-select-field"
            disabled
            // onSelect={(val) => {
            //   setSelectedGrade(val);
            // }}
          >
            {grade.data.map((grade: any) => (
              <Select.Option key={`create-section${grade.id}`} value={grade.id}>
                {grade.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </AddClassSectionWrapper>
  );
};

export default EditClassSectionDetails;
