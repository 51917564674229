import { Spin } from 'antd';
import React from 'react';

const KSpinner = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '100px', paddingBottom: 100 }}>
      <div style={{ transform: 'scale(3)', display: 'inline-block' }}>
        <Spin size="large" />
      </div>
    </div>
  );
};

export default KSpinner;
