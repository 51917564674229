import React, { useEffect, useState } from 'react';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import Papa from 'papaparse';
import { useSave } from 'features/users/hooks/useSave';
import { Button } from 'antd';

const BulkAssign = (): JSX.Element => {
  const [csvData, setCsvData] = useState<any>(null);
  const [jsonData, setJsonData] = useState<any>(null);

  const { onStudentCodeUpdate } = useSave();

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setCsvData(e.target.result);
      };
      reader.readAsText(file);

      //   Papa.parse(csvData, {
      //     header: true,
      //     dynamicTyping: true,
      //     complete: (result) => {
      //       console.log({ result: result.data });
      //       setJsonData(result.data);
      //     },
      //     error: (error) => {
      //       console.error('CSV parsing error:', error);
      //     },
      //   });
    }
  };

  useEffect(() => {
    if (!csvData) return;
    convertCsvToJson();
  }, [csvData]);

  const convertCsvToJson = () => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        setJsonData(result.data);
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
      },
    });
  };

  const handleBulkUpload = async () => {
    if (!csvData) return;

    console.log({ jsonData });

    const userIdToCode = jsonData
      .filter((item: any) => Boolean(item.new_code))
      .map((item: any) => ({
        userId: item.id,
        code: item.new_code,
      }));

    await onStudentCodeUpdate(userIdToCode);
  };
  return (
    <>
      <div style={{ padding: '15px' }}>
        <KPageHeader title="Bulk user code assign" />
        <KProTip title="Page Hint" description="This page is for bulk upload" />

        <div style={{ marginTop: '20px' }}>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          {jsonData && <Button onClick={handleBulkUpload}>Bulk upload</Button>}
        </div>
      </div>
    </>
  );
};

export default BulkAssign;
