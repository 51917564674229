import React from 'react';
import { Form, Button, Input } from 'antd';

import { useAcademicYear } from '../hooks/useAcademicYear';
import { AcademicYearWrapper } from './academic-year.style';

export const AddAcademicYear = (props: any) => {
  const { onSaveAcademicYear } = useAcademicYear();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadAcademicYear } = props;

  const handleSubmit = () => {
    onSaveAcademicYear(
      {
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadAcademicYear,
    );
  };

  return (
    <AcademicYearWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="year" label="Year">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AcademicYearWrapper>
  );
};

export default AddAcademicYear;
