import React, { useEffect, useState } from "react";
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { useUser } from '../../users/hooks/useUser';

import { LeaveApplicationCard } from "./ component/LeaveApplicationCard";
import { onLeaveApplication } from "data/user/user.service";
import KSpinner from "common/component/KSpinner";

export function LeaveApplication(){
    const {updateLeaveApplication}= useUser();
    const [loading, setLoading]=useState<Boolean>(false);

    const [applications, setApplications]= useState([]);

    const fetchLeaveApplication = async()=>{
        try{
            setLoading(true);
            await onLeaveApplication().then(res=>{
                setApplications(res.data);
            }).catch(e=>{
                console.log(e);
                
            });
        }
        catch{
            console.log("error on fetching leave application");
            
        }
        finally{
            setLoading(false)
        }
      
    }
    const handleClick=(applicationId:string, response:string)=>{
        const payload={
            applicationId:applicationId,
            response:response
        }
        updateLeaveApplication(payload, fetchLeaveApplication);
    }

    useEffect(()=>{
        fetchLeaveApplication();
        
        },[])
      
    return(
        <div style={{padding:"15px"}}>
         <KPageHeader
            title="Leave Application"
            />
        <KProTip
            title="Page Hint"
            description="This page is for viewing and managing leave application"
        />

            {
            loading?<KSpinner/>:
            applications.map((item:any) => (
            <LeaveApplicationCard title={item.title} description={item.description} date={item.date} id={item.id} onClick={handleClick} status={item.status} />
            ))}
        </div>
 
    )
}