import React from 'react';
import styled from 'styled-components';
import { useUser } from 'features/users/hooks/useUser';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Button, Checkbox, Form, Modal, Select } from 'antd';
import DataTable, { TableColumn } from 'react-data-table-component';
import { StudentResponseModel } from 'data/student/student.model';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useSubject } from 'features/result/hooks/useSubject';
import useOptionalSubject from '../hooks/useOptionalSubject';

interface AssignStudentsProps {
  subjectId: string;
  onFinish: VoidFunction;
}

const AssignStudentsWrapper = styled.div`
  width: 100%;
`;

const OptionalAssignTableColumns = (
  values: string[],
  onSelect: (subId: string) => (ev: CheckboxChangeEvent) => void,
): TableColumn<StudentResponseModel>[] => [
  {
    name: 'Student',
    cell: (row): JSX.Element => {
      return (
        <p style={{ opacity: values.includes(row.id) ? 1 : 0.7, transitionDuration: '.2s' }}>
          {row?.__user__.full_name || ''}
        </p>
      );
    },
    grow: 2,
  },
  {
    name: 'Selection',
    cell: (row): JSX.Element => (
      <Checkbox checked={values.includes(row.id)} onChange={onSelect(row.id)} />
    ),
    right: true,
  },
];

const AssignStudents = ({ subjectId, onFinish }: AssignStudentsProps): JSX.Element => {
  const subjects = useSelector((state: RootState) => state.subject);
  const gradeStudents = useSelector((state: RootState) => state.student);
  const assignedStudents = useSelector((state: RootState) => state.assignedStudents);

  const [selectedOptSubject, setSelectedOptSub] = React.useState<string>('');
  const [selectedStudentIds, setSelectedStudentIds] = React.useState<string[]>([]);

  const { fetchGradeStudent } = useUser();
  const { onAssignStudents } = useSubject();
  const { fetchAssignedStudents } = useOptionalSubject();

  const concerned_subject = React.useMemo(() => {
    return subjects.data.find((sb) => sb.id === subjectId);
  }, [subjectId, subjects.data]);

  const updateStudentSelection = (stdId: string) => (ev: CheckboxChangeEvent): void => {
    if (ev.target.checked) {
      if (!selectedStudentIds.includes(stdId)) {
        setSelectedStudentIds([...selectedStudentIds, stdId]);
      }
    } else {
      if (selectedStudentIds.includes(stdId)) {
        setSelectedStudentIds(selectedStudentIds.filter((sId) => sId !== stdId));
      }
    }
  };

  React.useEffect(() => {
    if (concerned_subject) fetchGradeStudent(concerned_subject.gradeId);
  }, [concerned_subject]);

  React.useEffect(() => {
    if (selectedOptSubject) fetchAssignedStudents(selectedOptSubject);
  }, [selectedOptSubject]);

  const onSubmit = (): void => {
    Modal.confirm({
      style: {
        background: 'red',
      },
      centered: true,
      title: 'Confirm Approval!!!',
      content:
        "Changing optional subject of the student will cause issues with result for that student if the result has already been prepared Continue ahead only if you are 100% sure of what you're doing or contact dev support for the action,",
      okText: 'Yes, Approve!',
      cancelText: 'Cancel',
      onOk() {
        onAssignStudents(selectedOptSubject, selectedStudentIds, onFinish);
      },
      onCancel() {},
    });
  };

  React.useEffect(() => {
    setSelectedStudentIds(assignedStudents.data.map((std) => std.id));
  }, [assignedStudents.data]);

  return concerned_subject ? (
    <AssignStudentsWrapper>
      <Form layout="vertical" onFinish={onSubmit}>
        <Form.Item label="Select Optional Subject" required>
          <Select
            placeholder="Select Optional"
            onChange={(val): void => {
              setSelectedOptSub(val.toString());
            }}
          >
            {concerned_subject.optionalSubjects.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                [{item.code}] {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <section style={{ margin: '1em 0' }}>
          <DataTable
            noHeader
            responsive
            pointerOnHover
            data={selectedOptSubject?.length ? gradeStudents.data : []}
            progressPending={
              gradeStudents.status === 'loading' || assignedStudents.status === 'loading'
            }
            columns={OptionalAssignTableColumns(selectedStudentIds, updateStudentSelection)}
          />
        </section>

        <Button
          type="primary"
          htmlType="submit"
          disabled={!selectedStudentIds.length || !selectedOptSubject.length}
        >
          Assign {selectedStudentIds.length} students
        </Button>
      </Form>
    </AssignStudentsWrapper>
  ) : (
    <></>
  );
};

export default AssignStudents;
