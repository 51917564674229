import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SchoolConfigResponseModel } from 'data/config/school/school.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: SchoolConfigResponseModel;
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: {
    data: {
      attendance: {
        earlyIn: '',
        lateOut: '',
        notArrived: '',
        notExited: '',
      },
      gpaConfig: [],
      schoolDetails: {
        schoolName: '',
        principal: '',
        principalSignature: '',
        examCoordinator: '',
        examCoordinatorSignature: '',
        schoolLogo: '',
        schoolAddress: '',
      },
    },
  },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<SchoolConfigResponseModel>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (_, action) => ({
  error: action.payload,
  data: initState.data,
  status: 'error',
});

const schoolConfigSlice = createSlice({
  name: 'schoolConfig',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default schoolConfigSlice;
