import React from 'react';
import { Form, Button, Input, Select, Empty } from 'antd';
import { AddClassSectionWrapper } from './class-section.style';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { formatTime } from 'common/helper/timeFormatter';
import moment from 'moment';
import { useSection } from 'features/routine/hooks/useSection';
import { useAcademicYear } from 'features/academic-year/hooks/useAcademicYear';

export const AddClassSectionDetails = (props: any) => {
  const { onSaveSection, fetchSections } = useSection();
  // const [selectedGrade, setSelectedGrade] = React.useState<string>('');
  const { fetchAcademicYear } = useAcademicYear();
  const academicYears = useSelector((state: RootState) => state.academicYear);
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadGrade } = props;
  const { fetchGrade } = useGrade();
  const grade = useSelector((state: RootState) => state.grade);

  const handleSubmit = () => {
    onSaveSection(
      {
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadGrade,
    );
  };
  React.useEffect(() => {
    fetchSections();
    fetchGrade();
    fetchAcademicYear();
  }, []);

  return (
    <AddClassSectionWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="value" label="Section">
          <Input placeholder="A" />
        </Form.Item>
        <Form.Item name="gradeId" label="Class">
          <Select
            defaultValue="Select Class"
            className="grade-select-field"
            // onSelect={(val) => {
            //   setSelectedGrade(val);
            // }}
          >
            {grade.data.map((grade: any) => (
              <Select.Option key={`create-section${grade.id}`} value={grade.id}>
                {grade.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item name="startTime" label="Start Time">
          <Input type="time" placeholder="09:00:00 AM" />
        </Form.Item>
        <Form.Item name="endTime" label="End Time">
          <Input
            type="time"
            placeholder="04:00:00 PM"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          />
        </Form.Item> */}
        <Form.Item
          name="academicYear"
          label="Academic Year"
          required
          rules={[
            {
              required: true,
              message: 'Please select a year.',
            },
          ]}
        >
          <Select
            placeholder="Select A Year"
            loading={academicYears.status === 'loading'}
            notFoundContent={
              // eslint-disable-next-line
              <Empty>
                <p>
                  <b>First, add an academic year.</b>
                </p>
              </Empty>
            }
          >
            {academicYears.data.map((item) => (
              <Select.Option key={item.year} value={item.year}>
                {item.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            Add
          </Button>
        </Form.Item>
      </Form>
    </AddClassSectionWrapper>
  );
};

export default AddClassSectionDetails;
