import React from 'react';
import * as $ from 'lodash';
import { Button, Modal, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import {
  ResultGetModel,
  ResultUpdateForApprovalMarks,
  StudentDetails,
} from 'data/result/result.model';
import DataTable, { TableColumn } from 'react-data-table-component';
import { StudentResponseModel } from 'data/student/student.model';
import { SubjectModel } from 'data/subject/subject.model';
import { useGrade } from 'features/routine/hooks/useGrade';
import { useUser } from 'features/users/hooks/useUser';
import { RootState } from 'redux/store';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CustomHeader, ObtainedMarkInput, ResultStyle } from './result.style';
import { useResult } from '../hooks/useResult';

export interface ResultPublishFormProps {
  examYearId: string;
  testTypeId: string;
  classId: string;
  sectionId: string;
  localChanged: boolean;
  onLocalChange: (changed: boolean) => void;
  onApproval: VoidFunction;
}

interface SubjectResult {
  [subjectId: string]: ResultGetModel[];
}

interface MatrixRow {
  student: StudentResponseModel;
  subjects: SubjectResult;
  hidden: boolean;
}

type LocalResultUpdater = (
  studentId: string,
  subjectId: string,
) => React.ChangeEventHandler<HTMLInputElement>;

const tableColumns = (
  allSubjects: SubjectModel[],
  onMarksUpdate: LocalResultUpdater,
): TableColumn<MatrixRow>[] => [
  {
    name: 'Roll',
    maxWidth: '75px',
    minWidth: '10px',
    cell: (row): JSX.Element => (
      <div>
        <b>
          {row.student?.roll || (
            <small>
              <i>N/A</i>
            </small>
          )}
        </b>
      </div>
    ),
  },
  {
    name: 'Student',
    cell: (row): JSX.Element => (
      <div>
        <b>{row.student?.__user__.full_name}</b>
      </div>
    ),
  },
  ...allSubjects.map(
    (sub): TableColumn<MatrixRow> => ({
      name: (
        <CustomHeader className="subject-header">
          <h4>{sub.code}</h4>
          <p>FM: {parseInt(sub.full_mark, 10)}</p>
          <p>PM: {parseInt(sub.pass_mark, 10)}</p>
        </CustomHeader>
      ),
      cell: (row): JSX.Element => {
        return row.subjects[sub.id]?.length ? (
          <ObtainedMarkInput
            type="number"
            inputMode="numeric"
            value={row.subjects[sub.id][0].obtained_mark}
            onChange={onMarksUpdate(row.student.id, sub.id)}
            style={{
              color:
                Number(row.subjects[sub.id][0].obtained_mark) < Number(sub.pass_mark)
                  ? 'red'
                  : 'green',
            }}
          />
        ) : (
          <Tooltip title="Result hasn't been prepared." placement="top">
            <i>N/A</i>
          </Tooltip>
        );
      },
    }),
  ),
];

export const ResultPublishForm = (props: ResultPublishFormProps): JSX.Element => {
  const {
    testTypeId,
    examYearId,
    classId,
    sectionId,
    onApproval,
    localChanged,
    onLocalChange,
  } = props;

  const [localResult, setLocalResult] = React.useState<MatrixRow[]>([]);

  const { fetchResultForOldApproval, approveResult, saveResultForApproval } = useResult();
  const { fetchGrade, fetchGradeSubjects } = useGrade();
  const { fetchGradeStudent } = useUser();

  // all data
  const resultData = useSelector((state: RootState) => state.oldresult);
  const grades = useSelector((state: RootState) => state.grade);
  const subjects = useSelector((state: RootState) => state.gradeSubjects);
  const students = useSelector((state: RootState) => state.student);

  React.useEffect(() => {
    if (!classId.length) return;
    if (!sectionId.length) return;
    if (!examYearId.length) return;
    if (!testTypeId.length) return;

    fetchResultForOldApproval(classId, sectionId, examYearId, testTypeId);
    fetchGrade();
    fetchGradeSubjects(classId);
    fetchGradeStudent(classId, sectionId);
  }, [classId, sectionId, examYearId, testTypeId]);

  const results = React.useMemo(() => resultData.data, [resultData]) as any;

  const grade = React.useMemo(() => {
    return grades.data.find((item: any) => item.id === classId);
  }, [grades.data, classId]);

  const subs = React.useMemo(() => {
    if (grade) return subjects.data.subjects;
    return [];
  }, [grade, subjects.data]);

  const stds = React.useMemo(() => {
    if (!grade) return [];
    //   eslint-disable-next-line
    return [...students.data].sort(
      (a, b) => a.roll - b.roll,
      //   a?.__user__.full_name < b?.__user__.full_name ? -1 : 1,
    );
  }, [students, grade]);

  // data with result
  const studentsWithResult = React.useMemo(() => {
    const localstds: StudentDetails[] = [];
    const stdIds = new Set(results.map((item: any) => item.studentId));
    stdIds.forEach((stdId) => {
      const std = results.find((item: any) => item.studentId === stdId)?.student;
      if (std) localstds.push(std);
    });
    return localstds;
  }, [results]);

  const subjectsWithResult = React.useMemo(() => {
    const localsubs: SubjectModel[] = [];
    const sudIds = new Set(results.map((item: any) => item.subjectId));
    sudIds.forEach((stdId) => {
      const sub = results.find((item: any) => item.subjectId === stdId)?.subject;
      if (sub) localsubs.push(sub);
    });
    return localsubs;
  }, [results]);

  const canApprove = React.useMemo(() => {
    const matrixCellCount = stds.length * subs.length;
    const resCount = results.length;

    return matrixCellCount === resCount;
  }, [stds, subs, results]);

  const studentSubjectMatrix = React.useMemo(() => {
    const map = $.groupBy(results, (result) => result.studentId);

    const matrix: MatrixRow[] = stds.map(
      (item: any): MatrixRow => ({
        hidden: false,
        student: item,
        subjects: { ...$.groupBy(map[item.id], (re) => re.subjectId) },
      }),
    );

    return matrix;
  }, [subjectsWithResult, studentsWithResult, subs, stds]);

  const updateLocalResult: LocalResultUpdater = (studentId, subjectId) => (e): void => {
    const copyResult = [...localResult];

    //   find the student row
    const std = copyResult.findIndex((item: any) => item.student.id === studentId);
    if (std < 0) return;

    //   narrow down to subject result
    const ress = copyResult[std].subjects[subjectId];
    if (!ress?.length) return;

    //   update result mark
    ress[0] = { ...ress[0], obtained_mark: e.target.value };

    //   update the student row
    copyResult[std].subjects[subjectId] = ress;

    //   update result object
    onLocalChange(true);
    setLocalResult(copyResult);
  };

  const saveUpdatedResult = (): void => {
    const copyResult = [...localResult];

    const testResultAndMarkEntry: Array<ResultUpdateForApprovalMarks> = [];
    copyResult.forEach((r) => {
      const subIds = Object.keys(r.subjects);

      subIds.forEach((sId) => {
        const subResults = r.subjects[sId];

        if (subResults.length) {
          const subRes = subResults[0];

          testResultAndMarkEntry.push({
            testResultId: subRes.id,
            new_mark: subRes.obtained_mark,
          });
        }
      });
    });

    saveResultForApproval(
      {
        gradeId: classId,
        sectionId,
        examYearId,
        testTypeId,
        testResultAndMarkEntry,
      },
      (): void => {
        onLocalChange(false);
      },
    );
  };

  const approveResults = (): void => {
    Modal.confirm({
      title: 'Are you sure of the approval?',
      icon: <ExclamationCircleFilled />,
      centered: true,
      keyboard: false,
      closable: true,
      okText: 'Yes, Approve!',
      content: (
        <>
          <ul style={{ padding: 0 }}>
            <li>This action cannot be undone.</li>
            <li>
              Please, make sure all the marks entered are correct. Marks cannot be edited after
              this.
            </li>
          </ul>
        </>
      ),
      onOk() {
        approveResult(classId, sectionId, examYearId, testTypeId, onApproval);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const subHasResult = React.useCallback(
    (subId: string): boolean => {
      return !!results.find((r: any) => r.subjectId === subId);
    },
    [results],
  );

  React.useEffect(() => {
    setLocalResult(studentSubjectMatrix);
  }, [studentSubjectMatrix]);

  return (
    <ResultStyle>
      <main>
        <DataTable
          progressPending={
            resultData.status === 'loading' ||
            grades.status === 'loading' ||
            subjects.status === 'loading' ||
            students.status === 'loading'
          }
          className=""
          data={localResult}
          columns={tableColumns(subs, updateLocalResult)}
          dense
          highlightOnHover
        />
      </main>

      <div
        style={{
          width: '100%',
          marginTop: 20,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '2em',
        }}
      >
        <Button disabled={!localChanged} onClick={saveUpdatedResult}>
          Save Draft
        </Button>
        <Button disabled={localChanged || !canApprove} onClick={approveResults}>
          Approve
        </Button>
      </div>
    </ResultStyle>
  );
};

export default ResultPublishForm;
