import { Drawer, Input, Timeline, Modal } from 'antd';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import React, { useMemo } from 'react';
import { RoutineWrapper } from './routine.style';
import AddRoutineForm from './routineForm';
import AddRoutineEditForm from './routineEditForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from '../hooks/useGrade';
import { useRoutine } from '../hooks/useRoutine';
import { FaCircle, FaCopy, FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment';
import { KButton } from 'common/component/KButton';
import { weekDays } from '../../../common/constants/index';
import { RoutineModel } from 'data/routine/routine.model';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import SelectClass from 'common/component/Inputs/SelectClass';
import AddRoutineFormNew from './AddRoutineForm';

interface Props {}

const RoutineView: React.FC<Props> = () => {
  const routine = useSelector((state: RootState) => state.routine);
  const [visible, setVisible] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [visibleCopy, setVisibleCopy] = React.useState(false);
  const [isShowRoutineClicked, setIsShowRoutineClicked] = React.useState(false);
  const [editItem, setEditItem] = React.useState({});
  const [selectedGrade, setSelectedGrade] = React.useState('');
  const [selectedSection, setSelectedSection] = React.useState('');
  const [reloadRoutine, setReloadRoutine] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState({});
  const { fetchGrade } = useGrade();
  const [selectedDay, setSelectedDay] = React.useState(moment().weekday() + 1);

  const { fetchRoutine, deleteRoutine } = useRoutine();
  const filteredRoutine: RoutineModel[] = [];

  const showDeleteModal = () => {
    setIsModalVisible(true);
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const onEditClose = () => {
    setVisibleEdit(false);
  };
  const onCopyClose = () => {
    setVisibleCopy(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = (item: any) => {
    deleteRoutine(item, setReloadRoutine);
    setIsModalVisible(false);
  };

  const label = (start: string, end: string) => (
    <div className="mainTimeLabel">
      {/* {day_type.map((item) => item.value === day && <div className="timeLabel">{item.title}</div>)} */}
      <div className="timeLabel">{moment(start, 'kk:mm:ss').format('hh:mm a')}</div>
      <div className="timeLabel">to</div>
      <div className="timeLabel">{moment(end, 'kk:mm:ss').format('hh:mm a')}</div>
    </div>
  );

  const handleGetRoutine = () => {
    if (selectedGrade !== '' && selectedSection !== '') {
      fetchRoutine(selectedGrade, selectedSection);
      setIsShowRoutineClicked(true);
    }
  };

  const filteredRoutineAsc = useMemo(() => {
    const loc = routine.data.filter((weekdayRoutine) => weekdayRoutine.day === selectedDay);

    return loc.sort((a, b) => (a.start > b.start ? 1 : -1));
  }, [routine.data, selectedDay]);

  React.useEffect(() => {
    fetchGrade();
  }, []);

  React.useEffect(() => {
    if (reloadRoutine === true && isShowRoutineClicked) {
      fetchRoutine(selectedGrade, selectedSection);
      setReloadRoutine(false);
    }
  }, [reloadRoutine]);
  return (
    <RoutineWrapper>
      <KPageHeader title="Routine" rightButtonTitle="Add Routine" onRightButtonClick={showDrawer} />
      <Drawer
        title={<h2>Add Routine</h2>}
        width={1120}
        visible={visible}
        closable={false}
        onClose={onClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddRoutineFormNew onSetReloadRoutine={setReloadRoutine} onVisibleChange={setVisible} />
      </Drawer>
      <Drawer
        title={<h2>Edit Routine</h2>}
        width={720}
        visible={visibleEdit}
        closable={false}
        onClose={onEditClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddRoutineEditForm
          value={editItem}
          onVisibleChange={setVisibleEdit}
          onSetReloadRoutine={setReloadRoutine}
        />
      </Drawer>
      {/* copy routine */}
      <Drawer
        title={<h2>Copy Routine</h2>}
        width={720}
        visible={visibleCopy}
        closable={false}
        onClose={onCopyClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddRoutineEditForm
          copy
          value={editItem}
          onVisibleChange={setVisibleCopy}
          onSetReloadRoutine={setReloadRoutine}
        />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the routines of all grade."
      />
      <section
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '2em',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '30% 30% 30%',
            gap: '2em',
          }}
        >
          <SelectClass
            onChange={(value): void => {
              setSelectedGrade(value);
            }}
          />
          <SelectSection
            gradeId={selectedGrade}
            onChange={(val): void => {
              setSelectedSection(val);
            }}
          />
          <KButton onClick={handleGetRoutine}>Show Routine</KButton>
        </div>

        <section className="searchSection" style={{ justifySelf: 'flex-end' }}>
          <Input.Search className="search" placeholder="Search from here..." />
        </section>
      </section>
      <section className="section-wrapper">
        {isShowRoutineClicked && (
          <section className="routineOfWeek">
            <h3>Routine of the week</h3>
            <section className="page-tabs">
              {weekDays.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedDay(item.id);
                  }}
                  className={`page-tabs-item ${item.id === selectedDay ? 'active' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </section>

            <section className="timeline">
              <Timeline mode={'left'} className="timeline">
                {filteredRoutineAsc.map((item) => {
                  console.log('item ', item);
                  return (
                    <Timeline.Item label={label(item.start, item.end)} color="green">
                      <div className="subTeacher">
                        <div className="innerSubTeacher">
                          <div className="actions">
                            <div className="subName">{item.subject?.title || <i>No Title</i>}</div>

                            <div>
                              <FaCopy
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                  setVisibleCopy(true);
                                  setEditItem(item);
                                }}
                              />
                              <FaEdit
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                  setVisibleEdit(true);
                                  setEditItem(item);
                                }}
                              />
                              <FaRegTrashAlt
                                onClick={() => {
                                  setDeleteId(item);
                                  showDeleteModal();
                                }}
                              />
                              <Modal
                                title="Are you sure you want to delete?"
                                visible={isModalVisible}
                                onOk={() => handleOk(deleteId)}
                                okType="danger"
                                onCancel={handleCancel}
                              />
                            </div>
                          </div>
                          <div className="flex">
                            <FaCircle
                              style={{ color: 'var(--green)', margin: '3px 8px 3px 0px' }}
                            />
                            <div className="teacherName">{item.teacher.__user__.full_name}</div>
                          </div>
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </section>
          </section>
        )}
      </section>
    </RoutineWrapper>
  );
};

export default RoutineView;
