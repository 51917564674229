import { TableStyles } from 'react-data-table-component';

export const tableCustomStyles: TableStyles = {
  table: {
    style: {
      borderRadius: 'var(--br)',
      overflow: 'hidden',
    },
  },
  headRow: {
    style: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'rgba(0,0,0,0.09)',
    },
  },
  headCells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      // borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
      borderRight: '1px solid rgba(0, 0, 0, 0.15)',
      fontSize: '14px',
      fontWeight: 700,
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRight: '1px solid rgba(0, 0, 0, 0.15)',
      // border: '1px solid rgba(0, 0, 0, 0.15)',
      wordBreak: 'break-word',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
      // minHeight: '20px',
    },
  },
};
