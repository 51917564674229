import React from 'react';
import { Form, Button, Input, Select, Empty } from 'antd';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { AddClassWrapper } from './class.style';
import { useAcademicYear } from 'features/academic-year/hooks/useAcademicYear';

export const AddClassDetails = (props: any) => {
  const { onSaveGrade } = useGrade();
  const { fetchAcademicYear } = useAcademicYear();
  const academicYears = useSelector((state: RootState) => state.academicYear);
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadGrade } = props;
  const grade = useSelector((state: RootState) => state.grade);
  const { fetchGrade } = useGrade();
  const handleSubmit = () => {
    onSaveGrade(
      {
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadGrade,
    );
  };
  React.useEffect(() => {
    fetchGrade();
    fetchAcademicYear();
  }, []);

  return (
    <AddClassWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="value" label="Class">
          <Input />
        </Form.Item>
        <Form.Item name="startTime" label="Start Time">
          <Input type="time" placeholder="09:00:00 AM" />
        </Form.Item>
        <Form.Item name="endTime" label="End Time">
          <Input type="time" placeholder="04:00:00 PM" />
        </Form.Item>
        <Form.Item
          name="academicYear"
          label="Academic Year"
          required
          rules={[
            {
              required: true,
              message: 'Please select a year.',
            },
          ]}
        >
          <Select
            placeholder="Select A Year"
            loading={academicYears.status === 'loading'}
            notFoundContent={
              // eslint-disable-next-line
              <Empty>
                <p>
                  <b>First, add an academic year.</b>
                </p>
              </Empty>
            }
          >
            {academicYears.data.map((item) => (
              <Select.Option key={item.year} value={item.year}>
                {item.year}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddClassWrapper>
  );
};

export default AddClassDetails;
