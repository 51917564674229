import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { CreateExamRoutineModel, ExamRoutineModel } from './examRoutine.model';

export const onPostExamRoutine = (payload: CreateExamRoutineModel): Promise<ExamRoutineModel> => {
  return axiosInstance.post(`${http_config.BASE_URL}/exam-routine`, payload);
};
export const onGetExamRoutine = (
  examYear: string,
  examType: string,
): Promise<ExamRoutineModel[]> => {
  return axiosInstance.get(
    `${http_config.BASE_URL}/exam-routine?exam-year=${examYear}&exam-type=${examType}`,
  );
};
