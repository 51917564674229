import { Button, Form, Input, message, Select } from 'antd';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import SelectSubjectCascaded from 'common/component/Inputs/SelectSubjectCascaded';
import React from 'react';
import styled from 'styled-components';
import { weekDays } from 'common/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useUser } from 'features/users/hooks/useUser';
import { CreateRoutineModel } from 'data/routine/routine.model';
import { useRoutine } from '../hooks/useRoutine';

interface AddRoutineFormNewProps {
  onVisibleChange: React.Dispatch<React.SetStateAction<boolean>>;
  onSetReloadRoutine: React.Dispatch<React.SetStateAction<boolean>>;
}

const RoutineFormWrapper = styled.div`
  width: 100%;
`;
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .routine-field {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr) repeat(2, 3fr) 3fr;
    gap: 0.4em;
    margin-top: 0.5em;
  }
`;

const AddRoutineFormNew = ({
  onVisibleChange,
  onSetReloadRoutine,
}: AddRoutineFormNewProps): JSX.Element => {
  const [selections, setSelection] = React.useState({ class: '', section: '' });
  const [routines, setRoutines] = React.useState<CreateRoutineModel[]>([
    {
      start: '',
      end: '',
      subjectId: '',
      teacherId: '',
    },
  ]);

  const teachers = useSelector((state: RootState) => state.teacher);

  const [form] = Form.useForm();
  const { fetchTeacher } = useUser();

  const { publishRoutine } = useRoutine();

  const addRoutineField = (): void => {
    setRoutines([
      ...routines,
      {
        start: '',
        end: '',
        subjectId: '',
        teacherId: '',
      },
    ]);
  };

  const updateRoutineField = (index: number) => (
    field: 'start' | 'end' | 'subjectId' | 'teacherId' | 'note',
  ) => (value: string): void => {
    const copied_routines = [...routines];
    const routine_field = copied_routines[index];

    routine_field[field] = value;
    copied_routines[index] = routine_field;

    setRoutines(copied_routines);
  };

  const updateRoutineSubjectField = (index: number) => (value: string[]): void => {
    const [main, opt] = value;

    const copied_routines = [...routines];
    let routine_field = copied_routines[index];

    // eslint-disable-next-line
    const { optionalSubjectId, ...rest } = routine_field;

    routine_field = { ...rest, subjectId: main };
    if (opt) routine_field = { ...routine_field, optionalSubjectId: opt };

    copied_routines[index] = routine_field;

    setRoutines(copied_routines);
  };

  const routineFields = React.useMemo(() => {
    const fields = routines.map((rt, index) => (
      <div className="routine-field">
        <Form.Item required label="Start Time">
          <Input
            type="time"
            value={rt.start}
            onChange={(val): void => updateRoutineField(index)('start')(val.target.value)}
          />
        </Form.Item>
        <Form.Item required label="End Time">
          <Input
            type="time"
            value={rt.end}
            onChange={(val): void => updateRoutineField(index)('end')(val.target.value)}
          />
        </Form.Item>
        <Form.Item required label="Subject">
          <SelectSubjectCascaded
            value={[rt.subjectId, rt.optionalSubjectId || '']}
            gradeId={selections.class}
            onChange={(val): void => updateRoutineSubjectField(index)(val)}
          />
        </Form.Item>
        <Form.Item required label="Teacher">
          <Select
            value={rt.teacherId.length ? rt.teacherId : undefined}
            placeholder="Select Teacher"
            onChange={(val): void => updateRoutineField(index)('teacherId')(val)}
          >
            {teachers.data.data.map((teach) => (
              <Select.Option key={teach.id} value={teach.id}>
                {teach?.__user__.full_name || 'N/A'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Note (Optional)">
          <Input
            value={rt.note || undefined}
            placeholder="Any note or remarks"
            onChange={(val): void => updateRoutineField(index)('note')(val.target.value)}
          />
        </Form.Item>
      </div>
    ));

    return fields;
  }, [routines, selections]);

  React.useEffect(() => {
    fetchTeacher(1, 10000);
  }, []);

  const onFinish = (): void => {
    if (!selections.class.length) {
      message.error('Please, select a class.');
      return;
    }
    if (!selections.section.length) {
      message.error('Please, select a section.');
      return;
    }

    const { days = [] } = form.getFieldsValue();

    if (!days?.length) {
      message.error('Please, select effective days.');
      return;
    }

    let hasAllData = true;
    routines.forEach((rt): void => {
      Object.values(rt).forEach((val) => {
        if (!val.length) {
          hasAllData = false;
        }
      });
    });

    if (!hasAllData) {
      message.error('Please, provide all the routine informations.');
      return;
    }

    publishRoutine(
      {
        days: days.map((d: string) => Number(d)),
        routines,
        gradeId: selections.class,
        sectionId: selections.section,
      },
      form,
      onVisibleChange,
      onSetReloadRoutine,
    );
  };

  return (
    <RoutineFormWrapper>
      <StyledForm form={form} layout="vertical" onFinish={onFinish}>
        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '5fr 5fr', gap: '1em' }}>
          <Form.Item required label="Class">
            <SelectClass
              onChange={(classId: string): void => setSelection({ ...selections, class: classId })}
            />
          </Form.Item>
          <Form.Item required label="Section">
            <SelectSection
              gradeId={selections.class}
              onChange={(section: string): void => {
                setSelection({ ...selections, section });
              }}
            />
          </Form.Item>
        </div>
        <Form.Item name="days" label="Day(s)" required>
          <Select mode="multiple" placeholder="Select days of effect">
            {weekDays.map((day) => (
              <Select.Option key={day.id} value={day.value}>
                {day.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="fields">{routineFields}</div>

        <Button onClick={addRoutineField}>Add Another Routine Field</Button>

        <hr />

        <Button htmlType="submit" type="primary">
          Save Routines
        </Button>
      </StyledForm>
    </RoutineFormWrapper>
  );
};

export default AddRoutineFormNew;
