import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import attendanceSlice from './slice/attendance.slice';
import cardSlice from './slice/card.slice';
import managerSlice from './slice/manager.slice';
import parentSlice from './slice/parent.slice';
import supportPersonSlice from './slice/supportPerson.slice';
import resultSlice from './slice/result.slice';
import staffAttendanceSlice from './slice/staff-attendance.slice';
import studentSlice from './slice/student.slice';
import supportSlice from './slice/support.slice';
import subjectSlice from './slice/subject.slice';
import systemSlice from './slice/system.slice';
import teacherSlice from './slice/teacher.slice';
import otherstaffSlice from './slice/otherStaffs.slice';
import announcementSlice from './slice/announcement.slice';
import announcementCategorySlice from './slice/announcementCategory.slice';
import routineSlice from './slice/routine.slice';
import gradeSlice from './slice/grade.slice';
import examTypeSlice from './slice/examType.slice';
import examYearSlice from './slice/examYear.slice';
import examRoutineSlice from './slice/examRoutine.slice';
import bookSlice from './slice/book.slice';
import IssuedBooksSlice from './slice/issuedBooks.slice';
import transactionSlice from './slice/transaction.slice';
import studentDataSlice from './slice/studentData.slice';
import assignParentToStudentSlice from './slice/asignParentToStudent.slice';
import userSlice from './slice/userByPhoneNumber.slice';
import balanceSlice from './slice/balance.slice';
import alertSlice from './slice/alert.slice';
import studentResultSlice from './slice/studentResult.slice';
import gradeSubjectsSlice from './slice/gradeSubject.slice';
import absentSlice from './slice/absent.slice';
import absentByGradeSlice from './slice/absentByGrade.slice';
import sectionSlice from './slice/section.slice';
import academicYearSlice from './slice/academic-year.slice';
import eventSlice from './slice/event.slice';
import upComingEventSlice from './slice/upcoming-events.slice';
import holidaySlice from './slice/holiday.slice';
import upComingHolidaySlice from './slice/upcoming-holiday.slice';
import academicYearActiveSlice from './slice/academic-year-active.slice';
import activeSupportSlice from './slice/activeSupport.slice';
import gradeAttendanceSlice from './slice/gradeAttendance.slice';
import gradeAttendanceCheckSlice from './slice/gradeAttendanceCheck.slice';
import resultStatusSlice from './slice/resultStatus.slice';
import optionalSubjectSlice from './slice/optionalSubject.slice';
import examTerminalTypeSlice from './slice/examTerminalType.slice';
import resultAggregationStatusSlice from './slice/resultAggregationStatus.slice';
import examFinalTypeSlice from './slice/examFinalType.slice';
import schoolConfigSlice from './slice/schoolConfig.slice';
import termAggregatedResultsSlice from './slice/termAggregatedResults.slice';
import finalAggregatedResultsSlice from './slice/finalAggregatedResults.slice';
import assignedStudentsSlice from './slice/assignedStudent.slice';
import resultSubjectStatusSlice from './slice/resultSubjectStatus.slice';
import oldResultSlice from './slice/oldresult.slice';

const store = configureStore({
  reducer: {
    schoolConfig: schoolConfigSlice.reducer,
    system: systemSlice.reducer,
    student: studentSlice.reducer,
    attendance: attendanceSlice.reducer,
    gradeAttendance: gradeAttendanceSlice.reducer,
    gradeAttendanceCheckSlice: gradeAttendanceCheckSlice.reducer,
    staff_attendance: staffAttendanceSlice.reducer,
    assignedStudents: assignedStudentsSlice.reducer,
    teacher: teacherSlice.reducer,
    manager: managerSlice.reducer,
    otherstaff: otherstaffSlice.reducer,
    supportPerson: supportPersonSlice.reducer,
    parent: parentSlice.reducer,
    card: cardSlice.reducer,
    subject: subjectSlice.reducer,
    optionalSubject: optionalSubjectSlice.reducer,
    academicYear: academicYearSlice.reducer,
    academicYearActive: academicYearActiveSlice.reducer,
    result: resultSlice.reducer,
    oldresult: oldResultSlice.reducer,
    resultStatus: resultStatusSlice.reducer,
    resultAggregationStatus: resultAggregationStatusSlice.reducer,
    termAggregatedResults: termAggregatedResultsSlice.reducer,
    finalAggregatedResults: finalAggregatedResultsSlice.reducer,
    announcement: announcementSlice.reducer,
    announcementCategory: announcementCategorySlice.reducer,
    activeSupport: activeSupportSlice.reducer,
    support: supportSlice.reducer,
    book: bookSlice.reducer,
    issuedBooks: IssuedBooksSlice.reducer,
    routine: routineSlice.reducer,
    grade: gradeSlice.reducer,
    section: sectionSlice.reducer,
    resultSubjectStatus: resultSubjectStatusSlice.reducer,
    examTerminalType: examTerminalTypeSlice.reducer,
    examFinalType: examFinalTypeSlice.reducer,
    examType: examTypeSlice.reducer,
    examYear: examYearSlice.reducer,
    examRoutine: examRoutineSlice.reducer,
    transaction: transactionSlice.reducer,
    studentData: studentDataSlice.reducer,
    assignParentToStudent: assignParentToStudentSlice.reducer,
    user: userSlice.reducer,
    balance: balanceSlice.reducer,
    alert: alertSlice.reducer,
    studentResult: studentResultSlice.reducer,
    gradeSubjects: gradeSubjectsSlice.reducer,
    absent: absentSlice.reducer,
    absentByGrade: absentByGradeSlice.reducer,
    event: eventSlice.reducer,
    upComingEvent: upComingEventSlice.reducer,
    holiday: holidaySlice.reducer,
    upComingHoliday: upComingHolidaySlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
