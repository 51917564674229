import { StudentResponseModel } from 'data/student/student.model';
import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { ParentDataModel, ParentResponseModel } from './parent.model';

export const onGetParents = (page: number, limit: number): Promise<ParentDataModel> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/parent?page=' + page + '&limit=' + limit + '&delay=1',
  );
};

export const onSetParents = (payload: ParentResponseModel): Promise<ParentResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/parent', payload);
};

export const getStudentParents = (studentId: string): Promise<ParentResponseModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/student/${studentId}/parents`);
};

export const onGetChildren = (parentId: string): Promise<StudentResponseModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/parent/${parentId}/students`);
};

export const onUpdateParent = (
  parentId: string,
  payload: ParentResponseModel,
): Promise<ParentResponseModel> => {
  return axiosInstance.put(http_config.BASE_URL + `/parent/${parentId}`, payload);
};

export const onUpdateTeacher = (
  teacherId: string,
  payload: ParentResponseModel,
): Promise<ParentResponseModel> => {
  return axiosInstance.put(http_config.BASE_URL + `/teacher/${teacherId}`, payload);
};
export const onUpdateStaff = (
  staffId: string,
  payload: ParentResponseModel,
): Promise<ParentResponseModel> => {
  return axiosInstance.put(http_config.BASE_URL + `/non-teaching/${staffId}`, payload);
};
