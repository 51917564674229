import { OtherStaffResponseModel } from 'data/otherStaff/otherStaff.model';
import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { UserModel } from './user.model';
import { UserRoleModel } from './user.model';

export const onGetUserByPhoneNumber = (phone_number: string): Promise<UserModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/users?role_code=777&search=' + phone_number);
};

export const onGetTeacherBySearch = (value: string): Promise<UserModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/users?role_code=888&search=' + value);
};

export const onGetUsers = (): Promise<UserModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/users');
};

export const onGetUsersRole = (value: number): Promise<any> => {
  return axiosInstance.get(http_config.BASE_URL + `/users?role_code=${value}`);
};

export const onLeaveApplication = () => {
  return axiosInstance.get(http_config.BASE_URL + `/leave-application`);
};

export const onUpdateUserRole = (userId: string, roles: number[]): Promise<void> => {
  const body = {
    roles: roles,
  };
  return axiosInstance.put(http_config.BASE_URL + `/users/role/${userId}`, body);
};

export const onUpdateLeaveApplication = (applicationID: string, response: string) => {
  const body = {
    status: response,
  };
  return axiosInstance.put(http_config.BASE_URL + `/leave-application/${applicationID}`, body);
};
