import React from 'react';
import { Button, Form, Input } from 'antd';
import { AddLoadAmountFormWrapper, Label } from './user.style';
import { useSave } from '../hooks/useSave';
import { useUser } from '../hooks/useUser';
import { BalanceModel, StudentResponseModel } from 'data/student/student.model';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import AddCardForm from './useCardForm';
import IssueBookForm from './issueBookForm';

export const LoadAmountForm = (props: any) => {
  const { value, onSetTitle, onVisibleChange, onSetReloadAfterDrawer } = props;

  const [form] = Form.useForm();
  const { onLoadBalance } = useSave();
  const { fetchBalance } = useUser();
  const balance = useSelector((state: RootState) => state.balance);
  const availableCardBalance = value.__user__.card_number;

  const onFinish = () => {
    const payload: BalanceModel = {
      balance: form.getFieldValue('balance'),
    };
    onLoadBalance(
      value.__user__.card_number,
      payload,
      form,
      onVisibleChange,
      onSetReloadAfterDrawer,
    ).then(() => {
      fetchBalance(value.userId);
    });
  };

  React.useEffect(() => {
    fetchBalance(value.userId);
    // eslint-disable-next-line
  }, []);

  return (
    <AddLoadAmountFormWrapper>
      <Form form={form} layout="vertical" size="large">
        <Form.Item label="Student Name">
          <Label>{value.__user__.full_name}</Label>
        </Form.Item>

        {!availableCardBalance && (
          <button onClick={() => onSetTitle('Assign Card')}>Register Card</button>
        )}
        {availableCardBalance && (
          <>
            <Form.Item label="Card Number" initialValue={value.__user__.card_number}>
              <Label>{value.__user__.card_number}</Label>
            </Form.Item>

            <Form.Item label="Available Balance">
              <Label>{balance.data.balance}</Label>
            </Form.Item>
            <Form.Item label="Add Balance" name={'balance'}>
              <Input />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" onClick={onFinish}>
                SAVE
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </AddLoadAmountFormWrapper>
  );
};

export default LoadAmountForm;
