import React from 'react';

import { Button, Card, Divider, Modal, Tag } from 'antd';
import DataTable, { TableColumn } from 'react-data-table-component';
import {
  GradeAttendanceDetail,
  GradeWiseAttendanceResponseModel,
} from 'data/attendance/attendance.model';

import KSpinner from 'common/component/KSpinner';
import { ReloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { AbsentViewWrapper } from '../dashboard.style';
import { cleanGradeSection } from 'common/utils/functions';

const attendanceTableModel: TableColumn<GradeWiseAttendanceResponseModel>[] = [
  {
    name: 'Class',
    selector: (row): string => cleanGradeSection(row.grade),
  },
  {
    name: 'Total',
    selector: (row): number => row.total,
    compact: true,
  },
  {
    name: 'Present',
    cell: (row): JSX.Element => (
      <span style={{ color: row.absent === 0 ? 'var(--green)' : 'red' }}>{row.present}</span>
    ),
    compact: true,
  },
  {
    name: 'Absent',
    cell: (row): JSX.Element => (
      <span style={{ color: row.absent === 0 ? 'var(--green)' : 'red' }}>{row.absent}</span>
    ),
    compact: true,
  },
  {
    name: 'Exited',
    cell: (row): JSX.Element => <span>{'exited' in row ? (row as any).exited : 0}</span>,
    compact: true,
  },
];

const attendaceDetailTableModel: TableColumn<GradeAttendanceDetail>[] = [
  { name: 'Student', selector: 'name' },
  { name: 'In Time', selector: 'inTime' },
  { name: 'Out Time', selector: 'outTime' },
];

const AttendanceWidget = (): JSX.Element => {
  //   const { fetchCurrentAcademicYear } = useDashboard();
  const currentAcademicYear = useSelector((state: RootState) => state.academicYearActive);
  const [studentAttendanceShow, setStudentAttendanceShow] = React.useState<
    'absent' | 'present' | 'all'
  >('absent');

  const { fetchAttendances, fetchAbsentStudents, clearAttendanceCheck } = useDashboard();

  const gradeAttendance = useSelector((state: RootState) => state.gradeAttendance);
  const gradeAttendanceCheck = useSelector((state: RootState) => state.gradeAttendanceCheckSlice);

  const sortedAttendace = React.useMemo(
    // kindly, don't ask me why I used map() here :D
    () =>
      gradeAttendance.data
        .map((a) => a)
        .sort((a, b) => +cleanGradeSection(a.grade) - +cleanGradeSection(b.grade)),
    [gradeAttendance.data],
  );

  React.useEffect(() => {
    if (currentAcademicYear?.data?.year) {
      fetchAttendances(currentAcademicYear?.data?.year);
    }
  }, [currentAcademicYear?.data?.year]);

  const reload = (): void => {
    if (currentAcademicYear?.data?.year) {
      fetchAttendances(currentAcademicYear?.data?.year);
    }
  };

  const closeModal = (): void => {
    clearAttendanceCheck();
    setStudentAttendanceShow('absent');
  };

  const attendees = React.useMemo(() => {
    if (studentAttendanceShow === 'all') return gradeAttendanceCheck.data.list;
    if (studentAttendanceShow === 'absent') {
      return gradeAttendanceCheck.data.list.filter((i) => !i.present);
    }
    return gradeAttendanceCheck.data.list.filter((i) => i.present);
  }, [studentAttendanceShow, gradeAttendanceCheck.data]);

  return (
    <>
      <AbsentViewWrapper>
        <div className="title">
          Attendance
          <Button
            onClick={reload}
            type="link"
            disabled={gradeAttendance.status === 'loading'}
            icon={<ReloadOutlined />}
          />
        </div>
        <Divider style={{ marginTop: 'var(--gap)', marginBottom: 'var(--gap)' }} />
        <DataTable
          dense
          data={sortedAttendace}
          columns={attendanceTableModel}
          pointerOnHover
          onRowClicked={(row): void => {
            fetchAbsentStudents(row.grade);
          }}
          progressComponent={<KSpinner />}
          progressPending={gradeAttendance.status === 'loading'}
        />
      </AbsentViewWrapper>
      <Modal
        title="Student Attendance Details"
        className="event-details-modal"
        visible={gradeAttendanceCheck.status !== 'idle'}
        centered
        closable
        maskClosable
        destroyOnClose
        footer={false}
        onCancel={(): void => {
          closeModal();
        }}
      >
        {gradeAttendanceCheck.status === 'loading' ? (
          <KSpinner />
        ) : (
          <>
            <Card title={`Grade ${cleanGradeSection(gradeAttendanceCheck.data.grade)}: Today`}>
              <div className="statuses">
                {['Absent', 'Present', 'All'].map((item) => (
                  <Tag
                    key={item}
                    style={{ cursor: 'pointer' }}
                    color={studentAttendanceShow === item.toLowerCase() ? 'green' : ''}
                    onClick={(): void => setStudentAttendanceShow(item.toLowerCase() as any)}
                  >
                    {item}
                  </Tag>
                ))}
              </div>
              <ul style={{ marginTop: 20, paddingLeft: 5 }}>
                <DataTable
                  data={attendees}
                  columns={attendaceDetailTableModel}
                  dense
                  noDataComponent={<pre>&gt; No one here.</pre>}
                  conditionalRowStyles={[
                    {
                      when: (row): boolean => row.present,
                      style: {
                        color: 'green',
                      },
                    },
                    {
                      when: (row): boolean => !row.present,
                      style: {
                        color: 'red',
                      },
                    },
                  ]}
                />
              </ul>
            </Card>
          </>
        )}
      </Modal>
    </>
  );
};

export default AttendanceWidget;
