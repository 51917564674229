import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  AnnouncementResponseModel,
  AnnouncementCategoryResponseModel,
  AnnouncementDataModel,
} from './announcement.model';

export const onGetAnnouncementCategory = (): Promise<AnnouncementCategoryResponseModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/announcement-category');
};
export const onGetAnnouncement = (page: number, limit: number): Promise<AnnouncementDataModel> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/announcement?page=' + page + '&limit=' + limit + '&delay=1',
  );
};
export const onSetAnnouncementCategory = (
  payload: AnnouncementCategoryResponseModel,
): Promise<AnnouncementCategoryResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/announcement-category', payload);
};

export const onSetAnnouncement = (
  payload: AnnouncementResponseModel,
): Promise<AnnouncementResponseModel> => {
  console.log('announcement ' + payload.title + payload.category + payload.description);

  return axiosInstance.post(http_config.BASE_URL + '/announcement', payload);
};
