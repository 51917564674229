import React from 'react';
import { AnnouncementResponseModel } from 'data/announcement/announcement.model';
import RichTextView from 'common/component/RichTextView';

const TableColumn = (): any => {
  return [
    {
      name: 'Title',
      selector: 'title',
      sortable: true,
      width: '300px',
      cell: (row: AnnouncementResponseModel) => (
        <div>
          <div>
            <h4 data-tag="allowRowEvents">{row.title}</h4>
          </div>
        </div>
      ),
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row: AnnouncementResponseModel) => (
        <div>
          <RichTextView value={row.description} />
        </div>
      ),
    },
  ];
};

export default TableColumn;
