import styled from 'styled-components';

export const SupportModalMessages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  height: 200px;
  overflow-y: auto;

  .message {
    width: 100%;
    padding: 0 5px;
    display: flex;
    justify-content: flex-start;

    p {
      width: 100%;
      max-width: 75%;
      padding: 10px;
      background: #ededed;

      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &.self {
      justify-content: flex-end;

      p {
        border-top-left-radius: 15px;
        border-top-right-radius: 0px;

        background: var(--green);
        color: white;
      }
    }
  }
`;

export default SupportModalMessages;
