import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ExamTypeModel } from 'data/exam/exam.model';
import { useGrade } from 'features/routine/hooks/useGrade';
import { cleanGradeSection } from 'common/utils/functions';

interface Props {
  value?: string;
  onChange?: (val: string) => void;
  examType?: ExamTypeModel | null;
}

function SelectClass({ value, onChange, examType }: Props): JSX.Element {
  const grade = useSelector((state: RootState) => state.grade);

  const { fetchGrade } = useGrade();

  const gradeList = React.useMemo(() => {
    const list = examType ? examType.grades : grade.data;

    if (!list || list.length === 0) return [];

    return list.map((item) => (
      <Select.Option key={`atten-class${item.id}`} value={item.id}>
        {cleanGradeSection(item.id)}
      </Select.Option>
    ));
  }, [grade.data, examType]);

  React.useEffect(() => {
    if (grade.status === 'idle') fetchGrade();
  }, [grade.data]);

  return (
    <>
      <Select
        loading={grade.status === 'loading'}
        style={{ width: '100%' }}
        value={value?.length ? value : undefined}
        placeholder="Select Class"
        className="grade-select-field"
        onSelect={(val): void => {
          if (onChange) onChange(val);
        }}
      >
        {gradeList}
      </Select>
    </>
  );
}

export default SelectClass;
