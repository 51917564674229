import React from 'react';
import { Button, Modal, Radio } from 'antd';
import { useAuthInfo } from 'features/auth/hooks/useAuthInfo';
import { RoleType } from 'redux/slice/system.slice';
import styled from 'styled-components';

const RoleListStyled = styled.ul`
  width: 100%;
  padding: 0;
  margin: 1em 0;

  list-style: none;

  border: 1px solid lightgrey;

  li {
    padding: 15px 20px;
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    border-top: 1px solid lightgrey;

    &:first-child {
      border-top-color: white;
    }

    small {
      color: var(--green);
    }

    .ant-radio-inner {
      width: 28px;
      height: 28px;
      border-color: var(--green);

      &:after {
        border-radius: 50%;
        top: 3px;
        left: 2px;
        width: 21px;
        height: 21px;
        background-color: var(--green);
      }
    }
  }
`;

const ControlStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;

  button {
    background-color: var(--green);
    border: none;
  }
`;

const WarningStyled = styled.small`
  color: var(--blue);
`;

const RoleHandle = (): JSX.Element => {
  const [roleModal, setRoleModal] = React.useState<boolean>(false);
  const [selectedRole, setSelectedRole] = React.useState<RoleType>();

  const openRoleModal = (): void => {
    setRoleModal(true);
  };

  const closeRoleModal = (): void => {
    setRoleModal(false);
  };

  const user = useAuthInfo();

  React.useEffect(() => {
    setSelectedRole(user.activeRole);
    closeRoleModal();
  }, [user.activeRole]);

  return (
    <>
      <section className="user-selected-role">
        {/* eslint-disable-next-line */}
        <p onClick={openRoleModal}>{user.activeRole.role_description}</p>
      </section>
      <Modal
        closable
        centered
        destroyOnClose
        footer={null}
        title={<h2>User Role</h2>}
        visible={roleModal}
        onCancel={closeRoleModal}
      >
        <small>
          You will have access to different assets of the dashboard according to the role you have
          chosen.
        </small>

        <RoleListStyled>
          {user.roles.map((role) => (
            /* eslint-disable-next-line */
            <li key={role.role_code} onClick={(): void => setSelectedRole(role)}>
              <span>
                {role.role_description}
                {user.activeRole.role_code === role.role_code ? (
                  <i>
                    <small>&nbsp; (Active)</small>
                  </i>
                ) : (
                  <></>
                )}
              </span>

              <Radio checked={selectedRole?.role_code === role.role_code} />
            </li>
          ))}
        </RoleListStyled>

        <ControlStyled>
          <WarningStyled>Please, make sure you save any changes before the switch.</WarningStyled>

          <Button
            type="primary"
            disabled={selectedRole?.role_code === user.activeRole.role_code}
            onClick={(): void => {
              if (selectedRole) {
                user.switchRole(selectedRole);
              }
            }}
          >
            Switch Role
          </Button>
        </ControlStyled>
      </Modal>
    </>
  );
};

export default RoleHandle;
