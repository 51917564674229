import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';

import { SectionModel } from './section.model';

export const onGetSections = (): Promise<[SectionModel]> => {
  return axiosInstance.get(http_config.BASE_URL + '/section');
};

export const onSetSection = (payload: { value: string }): Promise<{ message: string }> => {
  return axiosInstance.post(http_config.BASE_URL + '/section', payload);
};
export const handleUpdateSection = (payload: {
  id: string;
  name: string;
}): Promise<{ message: string }> => {
  return axiosInstance.put(http_config.BASE_URL + `/section/${payload.id}`, payload);
};
export const handleDeleteSection = (payload: { id: string }): Promise<{ message: string }> => {
  return axiosInstance.delete(http_config.BASE_URL + `/section/${payload.id}`);
};
