import { DatePicker, Popover } from 'antd';
import { KButton } from 'common/component/KButton';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import KSpinner from 'common/component/KSpinner';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import SelectMonth from 'common/component/Inputs/SelectMonth';
import { useAttendance } from '../hooks/useAttendance';
import tableColumn from './user.table';
import { useGrade } from '../../routine/hooks/useGrade';
import Calendar from 'common/component/KCalendar';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';

interface Props {}

const StaffAttendanceView: React.FC<Props> = () => {
  //   const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [selectedMonth, setSelectedMonth] = React.useState<string>('');
  const [selectedYear, setSelectedYear] = React.useState<string>('');
  const [isGetAttendanceIsClicked, setIsGetAttendanceIsClicked] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<string>('');
  const attendance = useSelector((state: RootState) => state.staff_attendance);
  const { fetchStaffAttendance } = useAttendance();

  const [from, setFrom] = React.useState<string>('');
  const [to, setTo] = React.useState<string>('');

  const handleGetAttendance = (): void => {
    const month = new Date(date).getMonth() + 1;

    const year = new Date(date).getFullYear();

    setSelectedMonth(month.toString());
    setSelectedYear(year.toString());

    fetchStaffAttendance(from.split('T')[0], to.split('T')[0]);
    setIsGetAttendanceIsClicked(true);
  };

  //   React.useEffect(() => {
  //     fetchStaffAttendance();
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <AttendanceViewWrapper>
      <KPageHeader
        title="Staff Attendance"
        rightButtonTitle="Create New"
        onRightButtonClick={() => {}}
      />
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing attendance of staffs"
      />
      {/* <span style={{ color: 'red', fontStyle: 'italic' }}>
        Note: Whole months attendance will be shown no matter the date
      </span> */}
      <section
        className="section-wrapper"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 10,
          gap: 12,
        }}
      >
        <section
          className="page-tabs"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}
        >
          From:
          <Calendar
            style={{ width: 250 }}
            // dateFormat="YYYY"
            theme="green"
            language="en"
            onChange={({ adDate }: any) => {
              setFrom(new Date(adDate).toISOString());
            }}
            placeholder="Select Date of Issue"
          />
        </section>

        <section
          className="page-tabs"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}
        >
          To:
          <Calendar
            style={{ width: 250 }}
            // dateFormat="YYYY"
            theme="green"
            language="en"
            onChange={({ adDate }: any) => {
              setTo(new Date(adDate).toISOString());
            }}
            placeholder="Select Date of Issue"
          />
        </section>
        <KButton onClick={handleGetAttendance}>Get Attendance</KButton>
      </section>

      <section className="page-table">
        {attendance.status === 'loading' && <KSpinner />}
        {attendance.status === 'data' && (
          <DataTable
            customStyles={tableCustomStyles}
            className="data-table"
            noHeader
            highlightOnHover
            responsive={true}
            data={attendance.data as any}
            columns={tableColumn(from, to)}
          />
        )}
      </section>
    </AttendanceViewWrapper>
  );
};

export const AttendanceViewWrapper = styled.div`
  padding-right: 20px;
  .page-tabs {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;
    .grade-select-field {
      min-width: 250px;
    }
  }
`;

export default StaffAttendanceView;
