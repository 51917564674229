import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Button, Col, Drawer, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import { ConsoleSqlOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';
import { useSection } from 'features/routine/hooks/useSection';
import AddClassSectionDetails from './AddClassSectionDetails';
import EditClassSectionDetails from './EditSectionView';

interface Props {}

const ClassSectionView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const section = useSelector((state: RootState) => state.section);
  const [reloadGrade, setReloadGrade] = React.useState(true);
  const [showEditDrawer, setShowEditDrawer] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState<any>(null);
  const { fetchSections, onDeleteSection } = useSection();
  const [reloadSection, setReloadSection] = React.useState(true);

  const memoSectionReload = useMemo(() => reloadSection, [reloadSection]);

  React.useEffect(() => {
    if (memoSectionReload) {
      fetchSections();
      setReloadSection(false);
      // setSelectedClass(null);
    }
  }, [memoSectionReload]);

  const handleDelete = (id: string) => {
    onDeleteSection(
      {
        id,
      },
      setReloadSection,
    );
  };

  const TableColumn = () => [
    {
      name: 'Section',
      selector: 'section',
      sortable: true,
    },
    // {
    //   name: 'Name',
    //   selector: 'name',
    //   sortable: true,
    // },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: true,
      cell: (row: any) => (
        <Row gutter={16}>
          {/* <Col> */}
          {/* <Button
              onClick={() => {
                handleDelete(row.section);

                // onDeleteSection({ id: row.id });
                // fetchSections();
                // setSelectedClass(row);
                // setShowEditDrawer(true);
              }}
            >
              <DeleteFilled color="" />
            </Button> */}
          {/* </Col> */}
          <Col>
            <Button
              onClick={() => {
                // handleEdit(row.section);
                // onDeleteSection({ id: row.id });
                // fetchSections();
                // setSelectedClass(row);
                setSelectedSection(row);
                setShowEditDrawer(true);
              }}
            >
              <EditFilled color="" />
            </Button>
          </Col>
        </Row>
      ),
    },
    // {
    //   name: 'Pass Mark',
    //   selector: 'pass_mark',
    //   sortable: true,
    // },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const memoGradeReload = useMemo(() => reloadGrade, [reloadGrade]);

  React.useEffect(() => {
    if (memoGradeReload) {
      fetchSections();
      setReloadGrade(false);
      // setSelectedClass(null);
    }
  }, [memoGradeReload]);

  // console.log({ selectedClass: moment(selectedClass?.startTime).format('hh:mm a') });
  // console.log({ selectedClass: selectedClass?.startTime });
  return (
    <ClassSectionViewWrapper>
      <KPageHeader title="Section" rightButtonTitle="Add Section" onRightButtonClick={showDrawer} />
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Edit Grade</h2>}
        placement="right"
        onClose={() => {
          setSelectedSection(null);
          setShowEditDrawer(false);
        }}
        visible={showEditDrawer}
        closable={false}
      >
        {selectedSection && (
          <EditClassSectionDetails
            // eslint-disable-next-line react/jsx-props-no-spreading
            // {...selectedClass}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectedSection}
            onVisibleChange={setShowEditDrawer}
            onSetReloadGrade={setReloadGrade}
          />
        )}
      </Drawer>
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Add Section</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddClassSectionDetails onVisibleChange={setVisible} onSetReloadGrade={setReloadGrade} />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the sections that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive={true}
          data={section.data.map((item) => ({
            section: item.id,
            name: item.name,
          }))}
          columns={TableColumn()}
        />
      </section>
    </ClassSectionViewWrapper>
  );
};

export const ClassSectionViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

// const ClassSectionView = () => <h1>Hellop</h1>;

export default ClassSectionView;
