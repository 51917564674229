/* eslint-disable no-underscore-dangle */
import getDaysInMonth from 'common/utils/generateDaysInMonth';
import dateFormater from 'common/utils/dateFormater';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { Attendances, NewAttendanceResponseModel } from 'data/attendance/attendance.model';
import { Popover } from 'antd';
import moment from 'moment';
import { getNepaliDays } from 'features/result/utils/utils';

const formatDate = (timeStamp?: string): string => {
  if (!timeStamp) return '--:-- --';

  const [hr, min] = timeStamp.slice(0, 5).split(':');

  const dt = new Date();
  dt.setHours(Number(hr), Number(min));

  return moment(dt).format('hh:mm A');
};

const getAttendanceStatus = (
  attendances: Attendances[],
  day: string,
  month: string,
  year: string,
): JSX.Element => {
  const mnt = moment(new Date(`${year}/${month}/${day}`));

  //   console.log({ mnt: `${year}/${month}/${day}` });

  if (mnt.isAfter(new Date())) return <>-</>;

  let attendanceStatus = <b style={{ color: 'red' }}>A</b>;
  attendances.forEach((attendance) => {
    if (attendance.date.slice(8, 10) === day) {
      attendanceStatus = (
        <Popover
          content={
            // eslint-disable-next-line
            <div>
              <p style={{ display: 'grid', gap: '.4em', gridTemplateColumns: '30px 8fr' }}>
                <span>In:</span>
                <span>{formatDate(attendance.inTime)}</span>
              </p>
              <p style={{ display: 'grid', gap: '.4em', gridTemplateColumns: '30px 8fr' }}>
                <span>Out:</span>
                <span>{formatDate(attendance.outTime)}</span>
              </p>
            </div>
          }
        >
          <p style={{ width: '100%', height: '100%' }}>
            <b style={{ color: 'green' }}>P</b>
          </p>
        </Popover>
      );
    }
  });
  return attendanceStatus;
};

const tableColumn = (from: string, to: string): TableColumn<NewAttendanceResponseModel>[] => {
  const dates = getNepaliDays(from, to);
  return [
    {
      name: 'Name',
      selector: 'name',
      width: '200px',

      // sortable: true,
      cell: (row): JSX.Element => <b>{row.__user__.full_name}</b>,
    },
    ...Array.from({ length: dates.length }, (_, i) => i + 1).map((item, index) => {
      //   const day = dateFormater(`${item}`);

      // get date, month, year

      const { nepali, english } = dates[index];

      const [year, month, date] = nepali.split('-');

      return {
        name: date,
        width: '30px',
        //   sortable: true,
        cell: (row: NewAttendanceResponseModel): JSX.Element => {
          // get date, month, year from english date

          const [year, month, date] = english.split('-');

          return (
            <div>
              <div>{getAttendanceStatus(row.__attendances__, date, month, year)}</div>
            </div>
          );
        },
      };
    }),
  ];
};

export default tableColumn;
