import KSpinner from 'common/component/KSpinner';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError } from 'common/helper/notifications';
import { useSubject } from 'features/result/hooks/useSubject';
import React from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import MarkSheet from '../MarkSheet';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import { SectionResults } from 'data/result/result.model';

interface RecordType {}

interface PrintBulkMarkSheetsProps {
  issueDate: Date | string;
  testsandContribution: Array<{
    id: string;
    name: string;
    contribution?: string;
  }>;
  records: SectionResults[];
  onFinish: VoidFunction;
  examYearId: string;
  terminalExamId?: string;
  metaSchool: {
    schoolName: string;
    principal: string;
    principalSignature: string;
    examCoordinator: string;
    examCoordinatorSignature: string;
    schoolLogo: string;
    schoolAddress: string;
  };
}

const BulkPrintWrapper = styled.div`
  display: none;

  @media print {
    display: block;

    @page {
      size: A4 portrait;
    }
  }
`;
const nf = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const PrintBulkMarkSheets = ({
  examYearId,
  terminalExamId,
  records,
  testsandContribution,
  issueDate,
  onFinish,
  metaSchool,
}: PrintBulkMarkSheetsProps): JSX.Element => {
  const allSubjects = useSelector((state: RootState) => state.subject);

  const tableRef = React.useRef(null);

  const { configData } = useSchoolConfig();
  const { fetchSubject } = useSubject();

  const print = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: onFinish,
  });

  React.useEffect(() => {
    fetchSubject();
  }, []);

  React.useEffect(() => {
    setTimeout(print, 600);
  }, []);

  return (
    <>
      <div className="loader">
        <KSpinner />
      </div>
      <BulkPrintWrapper ref={tableRef}>
        {records.map((rec, index) => {
          return (
            <MarkSheet
              metaSchool={metaSchool}
              issueDate={issueDate}
              records={rec.results}
              studentName={rec.name}
              studentRoll={rec.roll?.toString()}
              studentSection={rec.section}
              key={index}
              testsandContribution={testsandContribution}
              examYearId={examYearId}
              terminalExamId={terminalExamId!!}
            />
          );
        })}
      </BulkPrintWrapper>
    </>
  );
};

export default PrintBulkMarkSheets;
