import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import academicYearSlice from 'redux/slice/academic-year.slice';
import {
  onGetAllAcademicYear,
  handleAcademicYearSave,
  handleSetAcademicYearActive,
} from 'data/academic-year/academic-year.service';
import { toast } from 'react-hot-toast';

export const useAcademicYear = () => {
  const dispatch = useDispatch();

  const fetchAcademicYear = async () => {
    dispatch(academicYearSlice.actions.setLoading());
    try {
      const res = await onGetAllAcademicYear();
      dispatch(academicYearSlice.actions.setData(res));
    } catch (err) {
      dispatch(academicYearSlice.actions.setError(err));
    }
  };

  const onSaveAcademicYear = async (
    payload: { year: string },
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleAcademicYearSave(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new Academic Year.',
        success: 'Academic Year successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleAcademicYearSave(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'AcademicYear successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onSetAcademicYearActive = async (payload: { year: string }, onReload: any) => {
    toast.promise(
      handleSetAcademicYearActive(payload).then(() => {
        onReload(true);
      }),
      {
        loading: 'Updating Academic Year.',
        success: 'AcademicYear updated successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleSetAcademicYearActive(payload).then(() => {
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'AcademicYear updated successfully.');
    // } catch (err) {
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  //   const onDeleteAcademicYear = async (id: string, onVisibleChange: any, onReload: any) => {
  //     try {
  //       await handleAcademicYearDelete(id).then(() => {
  //         onVisibleChange(false);
  //         onReload(true);
  //       });
  //       notifySuccess('Success', 'AcademicYear deleted successfully.');
  //     } catch (err) {
  //       onVisibleChange(true);
  //       onReload(false);
  //       notifyError('Error', decodeApiMessage(err));
  //     }
  //   };

  return {
    fetchAcademicYear,
    onSetAcademicYearActive,
    onSaveAcademicYear,
  };
};
