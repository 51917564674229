import { CreateStudentDto } from 'data/manager/manager.model';
import React from 'react';
import AddParentForm from '../useParentForm';

interface CreateStudentAndAssignNewParentProps {
  studentDetails: CreateStudentDto;
  onSetReloadAfterDrawer: Function;
  onVisibleChange: Function;
}

export default function CreateStudentAndAssignNewParent(
  props: CreateStudentAndAssignNewParentProps,
): JSX.Element {
  const { studentDetails } = props;
  return (
    <div>
      <AddParentForm
        onSetReloadAfterDrawer={props.onSetReloadAfterDrawer}
        onVisibleChange={props.onVisibleChange}
        useCase="SchoolManager"
        studentDetails={studentDetails}
      />
    </div>
  );
}
