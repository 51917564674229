import styled from 'styled-components';

export const AddStudentFormWrapper = styled.div`
  input {
    width: 100%;
    padding: 10px;
    height: 38px;
    display: inline-block;
    background: var(--bg);
    border-radius: 4px;
    border: 1px solid var(--gray900);
  }

  .gradeRollSection {
    display: flex;
    justify-content: flex-start;
  }

  button {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }
`;
export const AssignParentFormWrapper = styled.div`
  .saveButton {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
    color: var(--white);
    font-weight: 700;
    width: 213px;
    height: 40px;
    border: none;
    font-size: var(--font-base);
    :hover {
      box-shadow: var(--shadow-base);
      background: var(--grey);
      color: var(--white);
    }
  }
  .search {
    width: 250px;
    margin-top: 20px;
  }

  .parentTable {
    margin-top: 20px;
  }
`;

export const SystemUserWrapper = styled.div`
  padding-right: 20px;

  .page-table {
    margin-top: 10px;
  }

  .page-tabs {
    display: flex;
    justify-content: space-between;
    margin: var(--gap-xl) 0;
  }
  .tabs {
    display: flex;
    align-items: center;

    .page-tabs-item {
      font-size: var(--font-base);
      font-weight: 500;
      margin-right: var(--gap-xl);
      color: var(--text-regular);
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--link);
      }
    }
  }
`;

export const AddTeacherFormWrapper = styled(AddStudentFormWrapper)``;
export const EditStudentFromWrapper = styled(AddStudentFormWrapper)``;
export const AlertNoticeWrapper = styled(AddStudentFormWrapper)``;

export const AddManagerFormWrapper = styled(AddStudentFormWrapper)``;

export const AddSupportPersonFormWrapper = styled(AddStudentFormWrapper)``;

export const AddParentFormWrapper = styled(AddStudentFormWrapper)``;

export const ViewIssueBooksWrapper = styled(AddStudentFormWrapper)`
  .dataTable {
    margin-top: 30px;
  }
`;
export const ViewTransactionsWrapper = styled(AddStudentFormWrapper)`
  .dataTable {
    margin-top: 30px;
  }
`;
export const AddCardWrapper = styled(AddStudentFormWrapper)``;
export const AddIssueBookFormWrapper = styled(AddStudentFormWrapper)`
  .issuedAt,
  .issuedBy,
  .dueDate {
    display: flex;
    margin-left: 5px;
  }
  .footerLabel {
    padding: 10px 10px 10px 0px;
  }

  .addBookButton {
    display: flex;
    justify-content: flex-end;
  }

  .footer {
    margin-top: 30px;
  }
`;
export const Label = styled.div`
  width: 100%;
  padding: 8px 10px 10px 10px;
  height: 40px;
  display: inline-block;
  background: var(--white);
  border-radius: 2px;
  border: 1px solid var(--gray800);
  font-size: var(--font-base);
`;

export const AddLoadAmountFormWrapper = styled(AddStudentFormWrapper)``;
