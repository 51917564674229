import React from 'react';
import { Form, Button, Input } from 'antd';
import { AddHolidayWrapper } from 'features/holiday/view/holiday.style';
import { useExamType } from '../hooks/useExamType';

export const AddExamType = (props: any) => {
  const { onSaveExamType } = useExamType();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadExamType } = props;

  const handleSubmit = () => {
    onSaveExamType(
      {
        ...form.getFieldsValue(),
      },
      form,
      onVisibleChange,
      onSetReloadExamType,
    );
  };

  return (
    <AddHolidayWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="value" label="Name">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddHolidayWrapper>
  );
};

export default AddExamType;
