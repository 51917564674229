import React from 'react';
import styled from 'styled-components';
import KSpinner from 'common/component/KSpinner';
import { useReactToPrint } from 'react-to-print';
import { SectionResults, TestTypeAndContributionPair } from 'data/result/result.model';
import GroupingTable, { Column } from './GroupingTable';
import { Section } from 'data/grade/grade.model';

interface ResultsMetaProps {
  examYearId: string;
  examId: string;
  classId: string;
  sections?: Section[];
}

interface PrintResultsProps {
  issueDate: string | Date;
  data: Array<SectionResults[]>;
  columns: Column[];
  test_types: string[];
  isLoading?: boolean;
  meta: ResultsMetaProps;
  metaSchool: {
    schoolName: string;
    principal: string;
    principalSignature: string;
    examCoordinator: string;
    examCoordinatorSignature: string;
    schoolLogo: string;
    schoolAddress: string;
  };
  onFinish: VoidFunction;
}

const PrintHouse = styled.main`
  display: none;

  @page {
    size: A4 Landscape;
  }

  @media print {
    display: block;
    size: landscape;
  }
`;

const PrintWrapper = styled.section`
  .printHeader {
    .school__info {
      width: 100%;
      text-align: center;
      h6 {
        font-size: 12px;
      }
    }

    display: none;
  }
  .test__info {
    margin-top: 10px;
    width: 100%;
    display: flex;
    gap: 2em;
  }
  section {
    width: 100%;
    overflow-x: auto;
  }

  .signature {
    padding: 0 2em;

    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    .signature__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .line {
        height: 0.75px;
        width: 180px;
        background-color: black;
      }

      .name__designation {
        text-align: center;

        .name {
          font-size: 15px;
        }
        .designation {
          font-size: 13px;
        }
      }
    }
  }

  @media print {
    padding: 0.5em;
    height: 791px;

    .printHeader {
      display: block;
    }

    .test__info {
      font-size: 12px;
    }

    table {
      height: 100%;

      thead {
        td {
          font-weight: 400;
        }
      }
    }
  }
`;

const PrintResults = ({
  columns,
  data,
  meta,
  test_types,
  isLoading,
  onFinish,
  metaSchool,
}: PrintResultsProps): JSX.Element => {
  const tableRef = React.useRef(null);

  const print = useReactToPrint({
    content: () => tableRef.current,
    onAfterPrint: onFinish,
  });

  React.useEffect(() => {
    setTimeout(print, 200);
  }, []);

  return (
    <>
      <div className="loader">
        <KSpinner />
      </div>
      <PrintHouse ref={tableRef}>
        {data.map((dt) => (
          <PrintWrapper>
            <div className="printHeader">
              <div className="school__info">
                <h6>{metaSchool.schoolName}</h6>
                <h4>
                  {meta.examId}, {meta.examYearId}
                </h4>
              </div>
              <p>Class: {dt[0].section}</p>
            </div>

            <section>
              <GroupingTable data={dt} columns={columns} tests={test_types} loading={isLoading} />
              <div className="test__info">{}</div>
            </section>

            <div className="signature">
              <div className="signature__item">
                <div className="line" />
                <div className="name__designation">
                  <div className="designation">Exam Controller Officer</div>
                </div>
              </div>
            </div>
          </PrintWrapper>
        ))}
      </PrintHouse>
    </>
  );
};

export default PrintResults;
