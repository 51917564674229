import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { LoginRequestModel, LoginResponseModel } from './auth.model';

export const onLogin = (payload: LoginRequestModel): Promise<LoginResponseModel> => {
  return axiosInstance.post(`${http_config.BASE_URL}/auth/login`, payload);
};

export const onLogout = (): Promise<LoginResponseModel> => {
  const userId = localStorage.getItem('userId');
  //   if (!userId) throw new Error('Local configuration invalid. Login again.');

  return axiosInstance.post(`${http_config.BASE_URL}/auth/logout`, { userId });
};

export const onRefresh = (userId: string): Promise<{ message: string }> => {
  //   if (!userId) throw new Error('Local configuration invalid. Login again.');

  return axiosInstance.get(`${http_config.BASE_URL}/auth/refresh?userId=${userId}`);
};
