import React from 'react';
import styled from 'styled-components';
import { Avatar, Image, List } from 'antd';
import { ParentResponseModel } from 'data/parent/parent.model';
import { StudentResponseModel } from 'data/student/student.model';
import { TeacherResponseModel } from 'data/teacher/teacher.model';
import { getStudentParents, onGetChildren } from 'data/parent/parent.service';
import { IoLocationSharp } from 'react-icons/io5';
import { MdEmail, MdPhone } from 'react-icons/md';
import { TiGroup } from 'react-icons/ti';
import { SiGoogleclassroom } from 'react-icons/si';
import { RiParentFill } from 'react-icons/ri';
import { notifyError } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import http_config from 'common/config/http_config';

interface StudentDetailsProps {
  details: StudentResponseModel | ParentResponseModel | TeacherResponseModel;
  type?: 'student' | 'parent';
}

const StudentDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;

  .info__wrapper {
    width: 100%;
    height: 100%;
    margin-top: 1.5em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5em;

    .row {
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: 6fr 4fr;
      gap: 1em;
    }

    .image {
      display: flex;
      flex-direction: column;
    }

    .info {
      width: 70%;
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    h3 {
      color: var(--primary);
    }

    .value {
      padding-left: 1.9em;
      margin-bottom: 0.5em;
      color: #5e5e5e;
    }
  }

  svg {
    margin-right: 0.3em;
    transform: translateY(2px);

    color: var(--primary);
  }
`;

const StudentDetails = ({ details, type }: StudentDetailsProps): JSX.Element => {
  const [freshDetails, setFreshDetails] = React.useState<{
    status: 'idle' | 'loading' | 'data' | 'error';
    data: StudentResponseModel[] | ParentResponseModel[];
    // eslint-disable-next-line
    error: any;
  }>({
    data: [],
    status: 'idle',
    error: '',
  });

  const notSet = React.useMemo(() => <i>Not Set!</i>, []);

  React.useEffect(() => {
    const fetcher = async (id: string, user_type: 'student' | 'parent'): Promise<void> => {
      setFreshDetails({ ...freshDetails, status: 'loading' });
      try {
        let res = [];
        if (user_type === 'student') res = await getStudentParents(id);
        else res = await onGetChildren(id);
        setFreshDetails({ status: 'data', data: res, error: '' });
      } catch (error) {
        notifyError('Fetching more details', decodeApiMessage(error));
        setFreshDetails({
          data: [],
          status: 'error',
          error,
        });
      }
    };

    if (type) fetcher(details.id, type);
  }, [details.id, type]);

  return (
    <StudentDetailsWrapper>
      <div className="name">
        <h1>{details?.__user__.full_name || notSet}</h1>
      </div>
      <div className="info__wrapper">
        <div className="info">
          <div className="row">
            <div className="contact">
              <small>Contact</small>
              <h3>
                <MdEmail /> Email
              </h3>
              <p className="value">{details?.__user__.email || notSet}</p>

              <h3>
                <MdPhone /> Phone
              </h3>
              <p className="value">{details?.__user__.phone_number || notSet}</p>
            </div>

            {type === 'student' ? (
              <div className="class">
                <small>Academics</small>
                <h3>
                  <SiGoogleclassroom /> Class
                </h3>
                <p className="value">{(details as StudentResponseModel).gradeId || notSet}</p>

                <h3>
                  <TiGroup /> Section
                </h3>
                <p className="value">{(details as StudentResponseModel).sectionId || notSet}</p>
              </div>
            ) : null}
          </div>

          <div className="address">
            <small>Places</small>
            <h3>
              <IoLocationSharp /> Address
            </h3>
            <p className="value">{details?.__user__.address || notSet}</p>
          </div>

          {type ? (
            <div className="related">
              <small>Related</small>
              <h3>
                <RiParentFill /> {type === 'parent' ? 'Students' : 'Parents'}
              </h3>
              <div className="value">
                <List
                  loading={freshDetails.status === 'loading'}
                  itemLayout="horizontal"
                  dataSource={freshDetails.data}
                  renderItem={(item): JSX.Element => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={(item as any)?.image || http_config.DEFAULT_IMAGE} />}
                        title={<b>{(item as any)?.full_name || notSet}</b>}
                        description={
                          // eslint-disable-next-line
                          <>
                            {(item as any)?.email || notSet} -
                            {(item as any)?.phone_number || notSet}
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="image">
          <small>Photo</small>
          <Image
            alt={`profile photo of ${details?.__user__.full_name || notSet}`}
            src={details?.__user__.image || http_config.DEFAULT_IMAGE}
            width={175}
          />
        </div>
      </div>
    </StudentDetailsWrapper>
  );
};

export default StudentDetails;
