import React from 'react';
import { Form, Button, Input, Select, Checkbox, List, Row, Col, Modal } from 'antd';

import { useGrade } from 'features/routine/hooks/useGrade';
import { useSubject } from 'features/result/hooks/useSubject';
import SelectClass from 'common/component/Inputs/SelectClass';
import { AddSubjectWrapper } from './subject.style';
import useOptionalSubject from '../hooks/useOptionalSubject';

export const EditSubjectDetails = (props: any) => {
  const { onVisibleChange, onSetReloadSubject, selectedSubject } = props;
  console.log(selectedSubject);

  //   const [isOptional, setOptional] = React.useState<boolean>(selectedSubject.optional);
  //   const [showAddOptional, setShowAddOptional] = React.useState<boolean>(false);
  //   const [optionalSubjectsID, setOptionalSubjectsID] = React.useState<string[]>(
  //     selectedSubject?.optionalSubjects?.map((item: any) => item.id),
  //   );

  const { onEditSubject } = useSubject();
  //   const { fetchOptionalSubject } = useOptionalSubject();
  const { fetchGrade } = useGrade();
  const [form] = Form.useForm();

  //   const optionalSubject = useSelector((state: RootState) => state.optionalSubject);

  const handleSubmit = (): void => {
    let payload = {
      ...form.getFieldsValue(),
      //   optional: isOptional,
    };

    // if (isOptional) {
    //   payload = {
    //     ...payload,
    //     attachedOptionalSubjects: optionalSubjectsID,
    //   };
    // }

    onEditSubject(selectedSubject.id, payload, form, onVisibleChange, onSetReloadSubject);
  };

  //   const addOptionalSubject = (optSubId: string): void => {
  //     if (!optionalSubjectsID.includes(optSubId)) {
  //       setOptionalSubjectsID([...optionalSubjectsID, optSubId]);
  //     }
  //   };

  //   const removeOptionalSubject = (optSubId: string): void => {
  //     if (optionalSubjectsID.includes(optSubId)) {
  //       const cpIds = [...optionalSubjectsID];
  //       const idIndex = cpIds.findIndex((id) => id === optSubId);

  //       if (idIndex >= 0) {
  //         cpIds.splice(idIndex, 1);
  //         setOptionalSubjectsID(cpIds);
  //       }
  //     }
  //   };

  //   const selectedOptionalSubjects = React.useMemo(() => {
  //     return optionalSubject.data.filter((oSub) => optionalSubjectsID.includes(oSub.id));
  //   }, [optionalSubject.data, optionalSubjectsID]);

  //   const optionalSubjectField = React.useMemo(() => {
  //     return (
  //       <>
  //         <Row gutter={15}>
  //           <Col span={18}>
  //             <Form.Item>
  //               <Select
  //                 style={{ margin: '1em 0' }}
  //                 value="Select Optional Subject"
  //                 loading={optionalSubject.status === 'loading'}
  //                 onSelect={(selected): void => {
  //                   addOptionalSubject(selected.toString());
  //                 }}
  //               >
  //                 {optionalSubject.data
  //                   .filter((oS) => !optionalSubjectsID.includes(oS.id))
  //                   .map((optSub) => (
  //                     <Select.Option key={optSub.id} value={optSub.id}>
  //                       [{optSub.code}] {optSub.title}
  //                     </Select.Option>
  //                   ))}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //           <Col span={6}>
  //             <Button
  //               onClick={(): void => {
  //                 setShowAddOptional(true);
  //               }}
  //               style={{ width: '100%' }}
  //             >
  //               Add New
  //             </Button>
  //           </Col>
  //         </Row>

  //         <div className="selectedOptionalSubjects">
  //           <h3>Chosen Optional Subjects</h3>
  //           <List
  //             bordered
  //             dataSource={selectedOptionalSubjects}
  //             loading={optionalSubject.status === 'loading'}
  //             renderItem={(item): JSX.Element => (
  //               <List.Item key={item.id}>
  //                 <List.Item.Meta
  //                   title={item.title}
  //                   description={`Opt. Subject Code: ${item.code}`}
  //                 />
  //                 <Button
  //                   icon={<CloseOutlined />}
  //                   onClick={(): void => removeOptionalSubject(item.id)}
  //                 />
  //               </List.Item>
  //             )}
  //           />
  //         </div>
  //       </>
  //     );
  //   }, [isOptional, selectedOptionalSubjects]);

  //   const onNewOptionalSubjectAdded = (): void => {
  //     setShowAddOptional(false);
  //     fetchOptionalSubject();
  //   };

  //   React.useEffect(() => {
  //     if (isOptional) fetchOptionalSubject();
  //   }, [isOptional]);

  React.useEffect(() => {
    fetchGrade();
  }, []);
  return (
    <>
      <AddSubjectWrapper>
        <Form
          form={form}
          size="large"
          layout="vertical"
          initialValues={{
            title: selectedSubject.title,
            code: selectedSubject.code,
            full_mark: selectedSubject.full_mark,
            pass_mark: selectedSubject.pass_mark,
            gradeId: selectedSubject.gradeId,
          }}
        >
          <Row gutter={15}>
            <Col span={16}>
              <Form.Item name="title" label="Title">
                <Input placeholder="Subject Title" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="code" label="Course Code">
                <Input placeholder="ENG101" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={6}>
              <Form.Item name="full_mark" label="Full Mark">
                <Input type="number" max={100} min={0} placeholder="100" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="pass_mark" label="Pass Mark">
                <Input type="number" max={100} min={0} placeholder="20" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="gradeId"
                label="Class"
                style={{
                  cursor: 'not-allowed',
                }}
              >
                <SelectClass />
              </Form.Item>
            </Col> */}
          </Row>
          {/* <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item> */}

          {/* <Form.Item name="optional" label="Optional">
            <Checkbox
              checked={isOptional}
              value={isOptional}
              onChange={(checked): void => {
                setOptional(checked.target.checked);
              }}
            >
              Is Optional Subject?
            </Checkbox>
          </Form.Item>

          {isOptional ? optionalSubjectField : <></>} */}

          <Form.Item>
            <Button htmlType="submit" onClick={handleSubmit}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </AddSubjectWrapper>
      {/* <Modal
        centered
        title="Add New Optional Subject"
        visible={showAddOptional}
        onCancel={(): void => {
          setShowAddOptional(false);
        }}
        footer={null}
      >
        <AddNewOptionalSubject onFinish={onNewOptionalSubjectAdded} />
      </Modal> */}
    </>
  );
};

export default EditSubjectDetails;
