import { SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Modal } from 'antd';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import { KButton } from 'common/component/KButton';
import useDebounce from 'common/hooks/useDebounce';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import { StudentResponseModel } from 'data/student/student.model';
import usePagination from 'features/users/hooks/usePagination';
import { useUser } from 'features/users/hooks/useUser';
import ViewCard from 'features/users/view/components/ViewCard';
import AddCardForm from 'features/users/view/useCardForm';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import StudentDetails from '../modals/StudentDetails';
import KSpinner from 'common/component/KSpinner';
import CreateStudentAndAssignParent from 'features/users/view/components/CreateStudentAndAssignParent';
import AssignParent from 'features/users/view/components/AssignParent';

type ActionFunction = (row: StudentResponseModel) => void;

const studentViewTable = (
  onAssignCard: ActionFunction,
  onViewCard: ActionFunction,
  onAssignParent: ActionFunction,
): TableColumn<StudentResponseModel>[] => [
  {
    name: 'Full name',
    cell: (row): string => row?.__user__.full_name || '',
    grow: 1,
    maxWidth: '500px',
  },
  {
    name: 'Class/Section',
    cell: (row): JSX.Element => <>{row.sectionId.replace('-', ', ')}</>,
  },
  {
    name: 'Card',
    cell: (row): JSX.Element => (
      <>
        {row?.__user__.card_number ? (
          <Button onClick={(): void => onViewCard(row)} type="link">
            View Card Details
          </Button>
        ) : null}

        <Button onClick={(): void => onAssignCard(row)} type="link">
          Assign Card
        </Button>
        <Button onClick={(): void => onAssignParent(row)} type="link">
          Assign Parent
        </Button>
      </>
    ),
  },
];

const Students = (): JSX.Element => {
  const [modalHandler, setModalHandler] = React.useState<{
    modal: 'assign-card' | 'view-card' | 'view-student' | 'assign-parent' | null;
    student: StudentResponseModel | null;
  }>({
    modal: null,
    student: null,
  });
  const [filters, setFilters] = React.useState<{
    class: string;
    section: string;
  }>({
    class: '',
    section: '',
  });

  const [searchValue, setSearchValue] = React.useState<string>('');

  const students = useSelector((state: RootState) => state.studentData);

  const openAssignCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'assign-card',
      student: row,
    });
  };

  const openViewCard: ActionFunction = (row) => {
    setModalHandler({
      modal: 'view-card',
      student: row,
    });
  };
  const openAssignParent: ActionFunction = (row) => {
    setModalHandler({
      modal: 'assign-parent',
      student: row,
    });
  };

  const openViewDetails: ActionFunction = (row) => {
    setModalHandler({
      modal: 'view-student',
      student: row,
    });
  };

  const closeModal = (): void => {
    setModalHandler({
      ...modalHandler,
      modal: null,
    });
  };

  const { fetchStudent } = useUser();

  const handleFiltersUpdate = (field: 'class' | 'section') => (value: string): void => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const db_search = useDebounce<string>(searchValue.toLowerCase(), 500);

  const searchFilteredStudents = React.useMemo(() => {
    if (!db_search.length) return students.data.data;

    return [...students.data.data].filter(
      (item) => item?.__user__.full_name.toLowerCase().search(db_search) > -1,
    );
  }, [students.data.data, db_search]);

  const filteredStudents = React.useMemo(() => {
    return [...searchFilteredStudents].sort((a, b) => {
      // eslint-disable-next-line
      //   return a?.__user__.full_name < b?.__user__.full_name ? -1 : 1;
      return a.roll - b.roll;
    });
  }, [searchFilteredStudents]);

  const { handlePageChange, handlePerRowsChange, paginatedData, rowsPerPage } = usePagination(
    filteredStudents,
  );

  const reload = (): void => {
    fetchStudent(1, 10000, filters.class, filters.section);
  };

  React.useEffect(() => {
    fetchStudent(1, 10000, filters.class, filters.section);
    handlePageChange(1);
  }, [filters]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px 0',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <section style={{ marginRight: 35 }}>
            <h2 style={{ margin: 0 }}>Students</h2>
          </section>
          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <SelectClass onChange={handleFiltersUpdate('class')} />
          </section>
          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <SelectSection gradeId={filters.class} onChange={handleFiltersUpdate('section')} />
          </section>
          <section className="page-tabs" style={{ width: 200, margin: 0, marginRight: 15 }}>
            <Input
              placeholder="Search By Name"
              value={searchValue}
              onChange={(ev): void => setSearchValue(ev.target.value)}
              addonAfter={<SearchOutlined />}
            />
          </section>
        </div>
        <div className="relaod">
          <KButton onClick={reload}>Reload</KButton>
        </div>
      </div>
      {students.data?.data ? (
        <>
          <DataTable
            noHeader
            responsive
            pointerOnHover
            highlightOnHover
            pagination
            paginationServer
            customStyles={tableCustomStyles}
            data={paginatedData}
            columns={studentViewTable(openAssignCard, openViewCard, openAssignParent)}
            onRowClicked={openViewDetails}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={students.data.data.length}
            progressPending={students.status === 'loading'}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </>
      ) : (
        <KSpinner />
      )}

      {modalHandler.student ? (
        <>
          <Drawer
            destroyOnClose
            width={500}
            title="Assign Card"
            visible={modalHandler.modal === 'assign-card'}
            onClose={closeModal}
          >
            <AddCardForm
              onFinish={(): void => {
                closeModal();
                reload();
              }}
              user={modalHandler.student}
            />
          </Drawer>
          <Drawer
            width={500}
            title="View Card Details"
            visible={modalHandler.modal === 'view-card'}
            onClose={closeModal}
          >
            <ViewCard user={modalHandler.student} />
          </Drawer>
          <Drawer
            width={500}
            title="Assign Parent"
            visible={modalHandler.modal === 'assign-parent'}
            onClose={closeModal}
          >
            {/* <ViewCard user={modalHandler.student} /> */}
            <AssignParent
              studentDetails={modalHandler.student as any}
              onSetReloadAfterDrawer={reload}
              onVisibleChange={closeModal}
            />
          </Drawer>
          <Modal
            destroyOnClose
            width={750}
            visible={modalHandler.modal === 'view-student'}
            onCancel={closeModal}
            footer={null}
          >
            <StudentDetails type="student" details={modalHandler.student} />
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default Students;
