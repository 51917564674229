import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { Reply, SupportResponseModel } from './support.model';

export const onGetSupport = (): Promise<SupportResponseModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/support');
};
export const onGetSupportReplies = (supportId: string): Promise<SupportResponseModel> => {
  return axiosInstance.get(`${http_config.BASE_URL}/support/reply/${supportId}`);
};

export const onSetSupportReply = (payload: { id: string; description: string }): Promise<Reply> => {
  const { id, description: payloadDesc } = payload;
  return axiosInstance.post(`${http_config.BASE_URL}/support/reply/${id}`, {
    description: payloadDesc,
  });
};
