import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  AssignParentToStudentModel,
  CreateStudentAndAssignNewParentModel,
  CreateStudentAndAssignParentModel,
  ManagerResponseModel,
} from './manager.model';

export const onGetManagers = (): Promise<ManagerResponseModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/school-manager');
};

export const onSetManagers = (payload: ManagerResponseModel): Promise<ManagerResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/school-manager', payload);
};

export const onAssignParentToStudent = (
  payload: AssignParentToStudentModel,
): Promise<AssignParentToStudentModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/student/assign-parent-to-student', payload);
};
export const onAssignParentsToStudent = (payload: {
  studentId: string;
  parentIds: string[];
}): Promise<AssignParentToStudentModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/student/assign-parents-to-student', payload);
};

export const onSetStudentAndAssignParent = (
  payload: CreateStudentAndAssignParentModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(
    http_config.BASE_URL + '/student/create-student-and-assign-parent',
    payload,
  );
};
// export const onAssignParent = (payload: {
//   studentId: string;
//   parentId: string;
// }): Promise<{ message: string }> => {
//   return axiosInstance.post(http_config.BASE_URL + '/student/assign-parent-to-student', payload);
// };

export const onSetStudentAndAssignNewParent = (
  payload: CreateStudentAndAssignNewParentModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(
    http_config.BASE_URL + '/student/create-student-and-assign-new-parent',
    payload,
  );
};
