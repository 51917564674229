import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Button, Col, Drawer, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { BulbOutlined } from '@ant-design/icons';
import AddExamType from './AddExamType';
import { useExamType } from '../hooks/useExamType';

interface Props {}

const ExamTypeView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const examTypes = useSelector((state: RootState) => state.examType);
  const [reloadExamType, setReloadExamType] = React.useState(true);
  const { fetchExamType } = useExamType();

  const TableColumn = () => [
    {
      name: 'Exam Type',
      selector: 'year',
      sortable: true,
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const memoExamTypeReload = useMemo(() => reloadExamType, [reloadExamType]);

  React.useEffect(() => {
    if (memoExamTypeReload) {
      fetchExamType();
      setReloadExamType(false);
    }
  }, [memoExamTypeReload]);

  return (
    <ExamTypeViewWrapper>
      <KPageHeader
        title="ExamType"
        rightButtonTitle="Add ExamType"
        onRightButtonClick={showDrawer}
      />
      {/* <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Edit ExamType</h2>}
        placement="right"
        onClose={() => {
          setSelectedExamType(null);
          setShowEditDrawer(false);
        }}
        visible={showEditDrawer}
        closable={false}
      >
        {selectedExamType && (
          <EditExamType
            {...selectedExamType}
            onVisibleChange={setShowEditDrawer}
            onSetReloadExamType={setReloadExamType}
          />
        )}
      </Drawer> */}
      <Drawer
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Add ExamType</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddExamType onVisibleChange={setVisible} onSetReloadExamType={setReloadExamType} />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the examTypes that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive={true}
          data={examTypes?.data.map((item) => ({
            // id: item.id,
            // title: item.title,
            // date: new Date(item.date).toLocaleDateString(),
            // description: item.description,
            // forAll: item.forAll ? 1 : 0,
            // sections: item.sections.map((_) => _.id).join(', '),
            // grades: item.grades.map((_) => _.id).join(', '),
            year: item.id,
          }))}
          columns={TableColumn()}
        />
      </section>
    </ExamTypeViewWrapper>
  );
};

export const ExamTypeViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

// const ExamTypeView = () => <h1>Hellop</h1>;

export default ExamTypeView;
