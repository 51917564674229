import React from 'react';
import { Button, Form, Input } from 'antd';
import { AlertNoticeWrapper, Label } from './user.style';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import moment from 'moment';
import { StudentResponseModel } from 'data/student/student.model';
import { useSave } from '../hooks/useSave';

export const AlertNoticeForm = (props: any) => {
  const { value, onVisibleChange } = props;
  const data: StudentResponseModel = value;
  const [form] = Form.useForm();
  const { onAlertNoticeSave } = useSave();

  return (
    <AlertNoticeWrapper>
      <Form form={form} layout="vertical" size="large">
        <Form.Item label="Student Name">
          <Label>{data.__user__.full_name}</Label>
        </Form.Item>
        <Form.Item label="Note" name="note">
          <Input.TextArea />
        </Form.Item>

        <Form.Item hidden={true} name="userId" initialValue={data.userId} />
        <Form.Item>
          <Button
            htmlType="submit"
            onClick={() => {
              onAlertNoticeSave({ ...form.getFieldsValue() }, form, onVisibleChange);
            }}
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AlertNoticeWrapper>
  );
};

export default AlertNoticeForm;
