import React, { useCallback } from 'react';
import { Button, Form, TimePicker, Select } from 'antd';
import { AddRoutineFormWrapper } from './routine.style';
import { day_type } from 'common/constants/index';
import { useSubject } from 'features/result/hooks/useSubject';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useUser } from 'features/users/hooks/useUser';
import { useRoutine } from '../hooks/useRoutine';
import { useGrade } from '../hooks/useGrade';
import moment from 'moment';
import { RoutineModel } from 'data/routine/routine.model';
import { UserModel } from 'data/user/user.model';
import _ from 'lodash';
import { GradeModel } from 'data/grade/grade.model';

export const AddRoutineEditForm = (props: any) => {
  console.log('values ', props);
  const { value, onVisibleChange, onSetReloadRoutine, copy } = props;
  const formValue: RoutineModel = value;
  const [form] = Form.useForm();
  const { fetchSubject } = useSubject();
  const { editRoutine, publishRoutine, fetchTeacherBySearch } = useRoutine();
  const { fetchGrade } = useGrade();
  const users = useSelector((state: RootState) => state.user);
  const subjects = useSelector((state: RootState) => state.subject);
  const grade = useSelector((state: RootState) => state.grade);
  const [result, setResult] = React.useState<UserModel[]>([]);
  const [gradeData, setGradeData] = React.useState<GradeModel>({
    id: '',
    startTime: '',
    endTime: '',
    sections: [],
  });
  const [endValue, setEndValue] = React.useState(moment(formValue.end, 'HH:mm:ss').format('HH:mm'));
  const [startValue, setStartValue] = React.useState(
    moment(formValue.start, 'HH:mm:ss').format('HH:mm'),
  );
  const [dayValue, setDayValue] = React.useState(formValue.day);
  const [gradeValue, setGradeValue] = React.useState(formValue.gradeId);
  const [subjectValue, setSubjectValue] = React.useState(formValue.subjectId);
  const [teacherValue, setTeacherValue] = React.useState(formValue.teacherId);
  const [sectionValue, setSectionValue] = React.useState(formValue.sectionId);

  console.log('gradeValue ' + formValue.gradeId);
  const onChangeStart = (value: any) => {
    let start: any = moment(value).format('HH:mm');
    setStartValue(start);
  };

  const onChangeEnd = (value: any) => {
    let end: any = moment(value).format('HH:mm');
    setEndValue(end);
  };

  const handleSearch = (value: string) => {
    debounce(value);
    let res: UserModel[] = [];
    if (!value) {
      res = [];
    } else {
      users.data.map((user) => {
        res.push(user);
      });
    }
    setResult(res);
  };
  const debounce = useCallback(
    _.debounce((value: string) => {
      fetchTeacherBySearch(value);
    }, 800),
    [],
  );
  const handleSelect = (value: any) => {
    users.data.map((user) => {
      if (user.full_name === value) {
        console.log('teacher', user);
        setTeacherValue(user.teacherId);
      }
    });
  };

  // const defaultStart = () => {
  //   setStartValue(moment(formValue.start).format('HH:MM'));
  //   return moment(formValue.start, 'kk:mm:ss');
  // };
  // const defaultEnd = () => {
  //   setEndValue(moment(formValue.end).format('HH:MM'));
  //   return moment(formValue.end, 'kk:mm:ss');
  // };
  React.useEffect(() => {
    fetchSubject();
    fetchGrade();
    grade.data.map((item) => {
      if (item.id === gradeValue) {
        setGradeData(item);
      }
    });
    //eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    if (copy) {
      publishRoutine(
        {
          ...form.getFieldsValue(),
          start: startValue,
          end: endValue,
          teacherId: teacherValue?.length ? teacherValue : formValue.teacherId,
          gradeId: gradeValue,
          subjectId: subjectValue,
          sectionId: sectionValue,
        },
        form,
        onVisibleChange,
        onSetReloadRoutine,
      );
    } else {
      editRoutine(
        {
          ...form.getFieldsValue(),
          end: endValue,
          start: startValue,
          day: dayValue,
          gradeId: gradeValue,
          subjectId: subjectValue,
          teacherId: teacherValue,
          sectionId: sectionValue,
        },
        formValue.id,
        onVisibleChange,
        onSetReloadRoutine,
      );
    }
  };

  return (
    <AddRoutineFormWrapper>
      <Form form={form} name="nest-messages" layout="vertical" size="large">
        <div className="time">
          <Form.Item label="Start Time">
            <TimePicker
              bordered={false}
              size="small"
              format="HH:mm "
              onOk={(value) => onChangeStart(value)}
              defaultValue={moment(startValue, 'HH:mm')}
              style={{ padding: '0px', border: '0px', width: '200px' }}
            />
          </Form.Item>
          <Form.Item label="End Time">
            <TimePicker
              bordered={false}
              size="small"
              format="HH:mm "
              onOk={(value) => onChangeEnd(value)}
              defaultValue={moment(endValue, 'HH:mm')}
              style={{ padding: '0px', border: '0px', width: '200px' }}
            />
          </Form.Item>

          <Form.Item label="Day" name={'day'}>
            <Select
              defaultValue={dayValue}
              style={{ width: '200px' }}
              onChange={(value) => setDayValue(value)}
            >
              {day_type.map((item) => (
                <Select.Option value={item.value}>{item.title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item label="Subject" name={'subjectId'}>
          <Select defaultValue={subjectValue} onChange={(value) => setSubjectValue(value)}>
            {subjects.data.map((item) => (
              <Select.Option value={item.id}>{item.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="classSection">
          <Form.Item label="Class" name="gradeId">
            <Select
              style={{ width: 250 }}
              defaultValue={gradeValue}
              onChange={(value) => {
                setGradeValue(value);
                grade.data.map((item) => {
                  if (item.id === value) {
                    setGradeData(item);
                  }
                });
              }}
            >
              {grade.data.map((item) => (
                <Select.Option value={item.id}>{item.id}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Section" name="sectionId">
            <Select
              style={{ width: 250 }}
              defaultValue={sectionValue}
              onChange={(value) => setSectionValue(value)}
            >
              {gradeData.sections.map((section) => (
                <Select.Option value={section.id}>{section.id}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {/* <Form.Item label="Teacher" name={'teacherId'}>
          <Select defaultValue={formValue.teacherId} onChange={(value) => setTeacherValue(value)}>
            {teachers.data.data.map((item) => (
              <Select.Option value={item.id}>{item.__user__.full_name}</Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item label="Teacher">
          <Select
            showSearch
            clearIcon
            onSearch={handleSearch}
            defaultValue={formValue.teacher.__user__.full_name}
            style={{ width: 200 }}
            onSelect={(key) => handleSelect(key)}
          >
            {result.map((teacher) => (
              <Select.Option key={teacher.teacherId} value={teacher.full_name}>
                {teacher.full_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddRoutineFormWrapper>
  );
};

export default AddRoutineEditForm;
