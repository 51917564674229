import React, { useState } from 'react';
import { Card, Button, Tag, Select, Badge } from 'antd';
import KProTip from 'common/component/KProTip';
import { UserOutlined } from '@ant-design/icons';
import { UserModel } from 'data/user/user.model';

interface RoleCardProps {
  users: UserModel;
  role_code: number;
  onRoleChange: (userId: string, newRoleCode: number[]) => Promise<void>;
}

interface CustomTagProps {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
}

const RoleCard: React.FC<RoleCardProps> = ({ users, onRoleChange }) => {
  const { full_name, email, phone_number, address, __roles__ } = users;
  const roleDescriptions = __roles__.map((role) => role.role_description);

  const [selectedRoles, setSelectedRoles] = useState<string[]>(roleDescriptions);

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const roleCodeMap: Record<string, number> = {
    'Super Admin': 111,
    Support: 444,
    Parent: 777,
    Teacher: 888,
    'General Admin': 222,
    'Other Staff': 999,
  };

  const handleRoleSelection = (selectedRoles: string[]) => {
    setSelectedRoles(selectedRoles);
  };

  const handleSubmit = () => {
    const newRoleCode = selectedRoles.map((selectedRole) => roleCodeMap[selectedRole]);
    onRoleChange(users.id, newRoleCode);
  };

  // Filter existing user roles from the options
  const options = [
    { value: 'Super Admin' },
    { value: 'Support' },
    { value: 'Parent' },
    { value: 'Teacher' },
    { value: 'General Admin' },
    { value: 'Other Staff' },
  ].filter((option) => !roleDescriptions.includes(option.value));

  return (
    <Card
      style={{ width: 500 }}
      cover={
        <div
          style={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UserOutlined style={{ fontSize: 50 }} />
        </div>
      }
    >
      <Card.Meta
        title={full_name}
        description={
          <>
            <p>
              <strong>Email:</strong> {email}
            </p>
            <p>
              <strong>Phone Number:</strong> {phone_number}
            </p>
            <p>
              <strong>Address:</strong> {address}
            </p>

            <p style={{ marginBottom: 0 }}>
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                style={{ width: '100%' }}
                options={options}
                value={selectedRoles}
                onChange={handleRoleSelection}
                placeholder="select roles"
              />
            </p>
          </>
        }
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginTop: 10 }} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Card>
  );
};

export default RoleCard;
