import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import React, { useMemo } from 'react';
import { Drawer, Modal, Popover } from 'antd';
import moment from 'moment';
import { BookOutlined, EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import { useUser } from '../hooks/useUser';
import { SystemUserWrapper } from './user.style';
import { PopOverContent } from '../component/PopOverContent';
import ViewDrawer from '../component/ViewDrawer';
import { useGrade } from 'features/routine/hooks/useGrade';
import { useParams } from 'react-router-dom';
import SupportStaffsTable from '../component/SupportStaffsTable';
import ManagersTable from '../component/ManagersTable';
import Students from '../component/tables/Students';
import Teachers from '../component/tables/Teachers';
import Parents from '../component/tables/Parents';
// import OtherStaffs from '../component/tables/OtherStaffs';
// import AddRole from '../component/tables/AddRole';
import StudentDetails from '../component/modals/StudentDetails';
import IdsView from '../component/tables/IdsView';
import OtherStaffs from '../component/tables/OtherStaffs';

interface Props {}

type SelectedUser = 'students' | 'teachers' | 'managers' | 'supports' | 'parents';

const UsersView: React.FC<Props> = () => {
  const { type = '' } = useParams();

  const [selectedUser, setSelectedUser] = React.useState('All');
  const [title, setTitle] = React.useState('');
  const [visibleDrawer, setVisibleDrawer] = React.useState(false);
  const [eachRow, setEachRow] = React.useState({});
  const {
    fetchStudent,
    fetchTeacher,
    fetchParent,
    fetchManager,
    fetchSupportPerson,
    fetchUsers,
    fetchOtherStaff,
  } = useUser();
  const [page, setPage] = React.useState({
    All: 1,
    Student: 1,
    Teacher: 1,
    Parent: 1,
    OtherStaff: 1,
  });
  const [limit, setLimit] = React.useState(10);
  const [reloadAfterDrawer, setReloadAfterDrawer] = React.useState(false);
  const [reload, setReload] = React.useState({
    All: true,
    Student: true,
    Manager: true,
    Teacher: true,
    Parent: true,
    Support: true,
    Ids: true,
    OtherStaff: true,
  });

  const [showUserDetailsModal, setShowUserDetailsModal] = React.useState(false);
  const [selectedViewUser, setSelectedViewUser] = React.useState<any>(null);

  const [selectedGrade, setSelectedGrade] = React.useState<string>('');
  const [selectedSection, setSelectedSection] = React.useState<string>('');

  React.useEffect(() => {
    if (type != null) setSelectedUser(type as string);
  }, [type]);

  const showDrawer = (title: string) => {
    setTitle(title);
    setTimeout(() => {
      setVisibleDrawer(true);
    }, 0);
  };

  const onClose = () => {
    setVisibleDrawer(false);
  };

  /*const TableColumn = (role: String) => [
    {
      name: 'Full Name',
      selector: 'fullName',
      grow: 2,
      sortable: true,
      cell: (row: any) => <div>{row.__user__.full_name}</div>,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      omit: role === 'students',
      grow: 2,
      cell: (row: any) => <div>{row.__user__.email}</div>,
    },
    {
      name: 'Class',
      selector: 'gradeId',
      omit: role !== 'students',
    },
    {
      name: 'Section',
      selector: 'sectionId',
      omit: role !== 'students',
    },
    {
      name: 'Role',
      center: true,
      grow: 1,
      omit: true,
      cell: () => (
        <div>
          <div
            style={{
              background: 'var(--blue)',
              borderRadius: 40,
              padding: '5px 20px 5px 20px',
              color: 'var(--white)',
              fontSize: 'var(--font-sm)',
            }}
          >
            {role}
          </div>
        </div>
      ),
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      omit: true,
      cell: (row: any) => <div>{moment(row.__user__.created_at).format('Do MMM, YYYY')}</div>,
    },
    {
      name: 'View Details',
      selector: 'viewDetails',
      omit: true,
      cell: (row: any) => (
        <>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedViewUser(row);
              setTimeout(() => {
                setShowUserDetailsModal(true);
              }, 0);
            }}
          >
            <EyeOutlined style={{ marginRight: 15 }} />
            View
          </div>
        </>
      ),
    },
    {
      name: 'Actions',
      // selector: 'email',
      // sortable: true,
      cell: (row: any) => (
        <div>
          <BookOutlined style={{ marginRight: 15 }} />
          <a
            onClick={() => {
              setEachRow(row);
              setTitle('Assign Card');
              setVisibleDrawer(true);
            }}
          >
            Assign Card
          </a>
        </div>
      ),
    },
    {
      name: '',
      right: true,
      maxWidth: '20px',
      cell: (row: any) => (
        <Popover
          content={
            <PopOverContent
              role={role}
              onSetTitle={setTitle}
              rowData={row}
              onSetRow={setEachRow}
              onSetVisibleDrawer={setVisibleDrawer}
            />
          }
          trigger="click"
          overlayStyle={{ padding: 0 }}
        >
          <EllipsisOutlined rotate={90} style={{ fontSize: '20px' }} />
        </Popover>
      ),
    },
  ];*/

  const { All, Student, Teacher, Parent, Manager, Support, Ids } = reload;

  const memReload = useMemo(() => reload, [All, Student, Teacher, Parent, Manager, Support, Ids]);
  const { fetchGrade } = useGrade();
  React.useEffect(() => {
    fetchGrade();
  }, []);

  React.useEffect(() => {
    if (selectedUser === 'all' && memReload.All) {
      fetchUsers();
      setReload((prevReload) => ({ ...prevReload, All: false }));
    }
    if (selectedUser === 'students' && memReload.Student) {
      fetchStudent(page.Student, limit, selectedGrade, selectedSection);
      setReload((prevReload) => ({ ...prevReload, Student: false }));
    }
    if (selectedUser === 'teachers' && memReload.Teacher) {
      fetchTeacher(page.Teacher, limit);
      setReload((prevReload) => ({ ...prevReload, Teacher: false }));
    }
    if (selectedUser === 'managers' && memReload.Manager) {
      fetchManager();
      setReload((prevReload) => ({ ...prevReload, Manager: false }));
    }
    if (selectedUser === 'supports' && memReload.Support) {
      fetchSupportPerson();
      setReload((prevReload) => ({ ...prevReload, Support: false }));
    }
    if (selectedUser === 'parents' && memReload.Parent) {
      fetchParent(page.Parent, limit);
      setReload((prevReload) => ({ ...prevReload, Parent: false }));
    }
    if (selectedUser === 'other-staffs' && memReload.Parent) {
      fetchOtherStaff(page.OtherStaff, limit);
      setReload((prevReload) => ({ ...prevReload, OtherStaff: false }));
    }
    if (selectedUser === 'ids' && memReload.Parent) {
      fetchParent(page.Student, limit);
      setReload((prevReload) => ({ ...prevReload, Ids: false }));
    }
  }, [selectedUser, memReload, selectedGrade, selectedSection]);

  React.useEffect(() => {
    if (reloadAfterDrawer && selectedUser === 'students') {
      fetchStudent(page.Student, limit);
      setReloadAfterDrawer(false);
    }
    if (reloadAfterDrawer && selectedUser === 'teachers') {
      fetchTeacher(page.Teacher, limit);
      setReloadAfterDrawer(false);
    }
    if (reloadAfterDrawer && selectedUser === 'other-staffs') {
      fetchOtherStaff(page.OtherStaff, limit);
      setReloadAfterDrawer(false);
    }
    if (reloadAfterDrawer && selectedUser === 'parents') {
      fetchParent(page.Parent, limit);
      setReloadAfterDrawer(false);
    }
    if (reloadAfterDrawer && selectedUser === 'managers') {
      fetchManager();
      setReloadAfterDrawer(false);
    }
    if (reloadAfterDrawer && selectedUser === 'supports') {
      fetchSupportPerson();
      setReloadAfterDrawer(false);
    }
  }, [reloadAfterDrawer]);

  React.useEffect(() => {
    if (selectedUser === 'Student') {
      fetchStudent(page.Student, limit);
    }
    if (selectedUser === 'Teacher') {
      fetchTeacher(page.Teacher, limit);
    }
    if (selectedUser === 'Parent') {
      fetchParent(page.Parent, limit);
    }
    if (selectedUser === 'other-staffs') {
      fetchParent(page.OtherStaff, limit);
    }
  }, [page, limit]);

  const onRowClicked = (row: any): void => {
    setSelectedViewUser(row);
    setTimeout(() => {
      setShowUserDetailsModal(true);
    }, 0);
  };

  return (
    <SystemUserWrapper>
      {selectedUser === 'All' && <KPageHeader title="Sytstem Users" />}
      {selectedUser !== 'All' && (
        <KPageHeader
          title="Users"
          rightButtonTitle="Add New"
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          onRightButtonClick={() => showDrawer(`Add ${selectedUser}`)}
        />
      )}
      <Modal
        width={750}
        visible={showUserDetailsModal}
        onCancel={(): void => setShowUserDetailsModal(false)}
        footer={null}
      >
        <StudentDetails details={selectedViewUser} />
      </Modal>
      <Drawer
        destroyOnClose
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>{title}</h2>}
        placement="right"
        onClose={onClose}
        visible={visibleDrawer}
        closable={false}
      >
        <ViewDrawer
          view={selectedUser}
          title={title}
          setTitle={setTitle}
          eachRow={eachRow}
          setVisibleDrawer={setVisibleDrawer}
          setReloadAfterDrawer={setReloadAfterDrawer}
        />
      </Drawer>
      <KProTip
        title="Page Hint"
        description={`This page is for viewing and managing all the ${type} with various role that are currently in this system`}
      />

      <section className="page-table">
        {selectedUser === 'students' && <Students />}
        {selectedUser === 'ids' && <IdsView />}
        {selectedUser === 'teachers' && <Teachers />}
        {selectedUser === 'other-staffs' && <OtherStaffs />}
        {/* {selectedUser === 'managers' && (
          <ManagersTable table={TableColumn(selectedUser)} onRowClicked={onRowClicked} />
        )} */}
        {/* {selectedUser === 'supports' && (
          <SupportStaffsTable table={TableColumn(selectedUser)} onRowClicked={onRowClicked} />
        )} */}
        {selectedUser === 'parents' && <Parents />}
      </section>
    </SystemUserWrapper>
  );
};

export default UsersView;
