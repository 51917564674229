import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import examYearSlice from 'redux/slice/examYear.slice';
import examTypeSlice from 'redux/slice/examType.slice';
import { handleSaveExamYear, onGetExamType, onGetExamYear } from 'data/exam/exam.service';
import { toast } from 'react-hot-toast';

export const useExamYear = () => {
  const dispatch = useDispatch();

  const fetchExamYear = async () => {
    dispatch(examYearSlice.actions.setLoading());
    try {
      const res = await onGetExamYear();
      dispatch(examYearSlice.actions.setData(res));
    } catch (err) {
      dispatch(examYearSlice.actions.setError(err));
    }
  };
  const fetchExamType = async () => {
    dispatch(examTypeSlice.actions.setLoading());
    try {
      const res = await onGetExamType();
      dispatch(examTypeSlice.actions.setData(res));
    } catch (err) {
      dispatch(examTypeSlice.actions.setError(err));
    }
  };

  const onSaveExamYear = async (
    payload: { value: string },
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleSaveExamYear(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new exam year',
        success: 'Exam year successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleSaveExamYear(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Exam Year successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  return {
    fetchExamYear,
    onSaveExamYear,
  };
};
