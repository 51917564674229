import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RoleType = {
  role_code: number;
  role_description: string;
};

export type SystemSliceState = {
  id: string;
  roles: RoleType[];
  activeRole: RoleType;
  image: string;
  email: string;
  username: string;
  isLogged: boolean;
};

const initState: SystemSliceState = {
  id: '',
  roles: [],
  activeRole: {
    role_code: 0,
    role_description: '---',
  },
  image: '',
  email: '',
  username: '',
  isLogged: false,
};

const resetState = (): SystemSliceState => initState;

const setState: CaseReducer<SystemSliceState, PayloadAction<SystemSliceState>> = (
  state,
  action,
) => ({
  ...state,
  ...action.payload,
});

const setRole: CaseReducer<SystemSliceState, PayloadAction<RoleType>> = (state, action) => {
  // eslint-disable-next-line
  state.activeRole = action.payload;
};

const systemSlice = createSlice({
  name: 'system',
  initialState: initState,
  reducers: {
    resetState,
    setState,
    setRole,
  },
});

export default systemSlice;
