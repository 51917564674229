import React, { useState } from 'react';
import styled from 'styled-components';
import { Avatar, Button, Drawer, Form, Image, Input, List } from 'antd';
import { ParentResponseModel } from 'data/parent/parent.model';
import { StudentResponseModel } from 'data/student/student.model';
import { TeacherResponseModel } from 'data/teacher/teacher.model';
import { getStudentParents, onGetChildren } from 'data/parent/parent.service';
import { IoLocationSharp } from 'react-icons/io5';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';
import { TiGroup } from 'react-icons/ti';
import { SiGoogleclassroom } from 'react-icons/si';
import { RiParentFill } from 'react-icons/ri';
import { notifyError } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import http_config from 'common/config/http_config';

import { AiOutlineEdit } from 'react-icons/ai';
import { EditStudentFromWrapper } from '../view/user.style';
import { useSave } from '../hooks/useSave';

interface StudentDetailsProps {
  details: StudentResponseModel;
  type?: 'student' | 'parent';
  reload: Function;
}

const StudentDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;

  .info__wrapper {
    width: 100%;
    height: 100%;
    margin-top: 1.5em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5em;

    .row {
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: 6fr 4fr;
      gap: 1em;
    }

    .image {
      display: flex;
      flex-direction: column;
    }

    .info {
      width: 70%;
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    h3 {
      color: var(--primary);
    }

    .value {
      padding-left: 1.9em;
      margin-bottom: 0.5em;
      color: #5e5e5e;
    }
  }

  svg {
    margin-right: 0.3em;
    transform: translateY(2px);

    color: var(--primary);
  }
`;

const StudentIdCard = ({ details, type, reload }: StudentDetailsProps): JSX.Element => {
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<StudentResponseModel | null>(null);
  const [form] = Form.useForm();

  const { onStudentEdit, onStudentParentUnlink } = useSave();

  const [freshDetails, setFreshDetails] = React.useState<{
    status: 'idle' | 'loading' | 'data' | 'error';
    data: StudentResponseModel[] | ParentResponseModel[];
    // eslint-disable-next-line
    error: any;
  }>({
    data: [],
    status: 'idle',
    error: '',
  });

  const handleStudentEdit = (details: any): void => {
    setShowEditDrawer(true);

    setSelectedStudent(details);

    form.setFieldsValue({
      full_name: details?.__user__?.full_name,
      email: details?.__user__?.email,
      phone_number: details?.__user__?.phone_number,
      address: details?.__user__?.address,
      ...Object.assign(
        {},
        ...Array.from({ length: details.__parents__.length }).map((item, idx) => ({
          [`parent_full_name_${idx}`]: details.__parents__[idx].__user__.full_name,
          [`parent_phone_number_${idx}`]: details.__parents__[idx].__user__.phone_number,
        })),
      ),
    });
  };

  const handleAfterMath = () => {
    setShowEditDrawer(false);
    setSelectedStudent(null);
    reload();
  };

  const handleSubmit = () => {
    if (!selectedStudent) return;
    const parents = Array.from({ length: selectedStudent.__parents__.length }).map((item, idx) => ({
      userId: selectedStudent.__parents__[idx].__user__.id,
      fullName: form.getFieldValue(`parent_full_name_${idx}`),
      phoneNumber: form.getFieldValue(`parent_phone_number_${idx}`),
    }));

    if (!selectedStudent) return;
    return onStudentEdit(
      {
        id: selectedStudent.id,
        full_name: form.getFieldValue('full_name'),
        email: form.getFieldValue('email'),
        phone_number: form.getFieldValue('phone_number'),
        address: form.getFieldValue('address'),
        parents,
      },
      form,
      handleAfterMath,
    );
  };

  const handleUnlink = (parentUserId: string, studentId: string) => {
    return onStudentParentUnlink(parentUserId, studentId, form, handleAfterMath);
  };

  const notSet = React.useMemo(() => <i>Not Set!</i>, []);

  React.useEffect(() => {
    const fetcher = async (id: string, user_type: 'student' | 'parent'): Promise<void> => {
      setFreshDetails({ ...freshDetails, status: 'loading' });
      try {
        let res = [];
        if (user_type === 'student') res = await getStudentParents(id);
        else res = await onGetChildren(id);
        setFreshDetails({ status: 'data', data: res, error: '' });
      } catch (error) {
        notifyError('Fetching more details', decodeApiMessage(error));
        setFreshDetails({
          data: [],
          status: 'error',
          error,
        });
      }
    };

    if (type) fetcher(details.id, type);
  }, [details.id, type]);

  /*
full name
email
phone_number
address
parents name
parents phonenumber
photo
*/

  return (
    <StudentDetailsWrapper
      style={{
        margin: '10px 0',
        padding: '20px',
        background: '#ffffff',
        boxShadow: '20px 20px 60px #d9d9d9,\n             -20px -20px 60px #ffffff',
        position: 'relative',
      }}
    >
      <Drawer
        destroyOnClose
        width={705}
        title={<h2 style={{ fontWeight: 700 }}>Edit Student</h2>}
        placement="right"
        onClose={() => setShowEditDrawer(false)}
        visible={showEditDrawer}
        closable={false}
      >
        <EditStudentFromWrapper>
          <Form form={form} name="nest-messages" layout="vertical" size="large">
            <Form.Item label="Full Name" name={'full_name'}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name={'email'}
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name={'phone_number'}>
              <Input />
            </Form.Item>
            <Form.Item label="Address" name={'address'}>
              <Input />
            </Form.Item>

            <div
              style={{
                padding: '5px',
              }}
            >
              <h1>Parents</h1>

              {details.__parents__?.map((parent, idx) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span>
                      {parent.__user__.full_name} - {parent.__user__.phone_number}
                    </span>
                    <Button
                      style={{
                        width: '80px',
                        height: '35px',
                        marginTop: '0',
                        marginLeft: '5px',
                      }}
                      onClick={() => {
                        handleUnlink(parent.__user__.id, details.id);
                      }}
                    >
                      Unlink
                    </Button>
                  </div>
                </>
              ))}
            </div>
            {/* {details.__parents__?.map((parent, idx) => (
              <div className="parent__wrapper">
                <div className="parent">
                  <div className="row">
                    <Form.Item label={`Parent ${idx + 1} Name`} name={`parent_full_name_${idx}`}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={`Parent ${idx + 1} Phone Number`}
                      name={`parent_phone_number_${idx}`}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </div>
            ))} */}

            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                style={{
                  width: '100%',
                }}
              >
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </EditStudentFromWrapper>
      </Drawer>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 10,
          cursor: 'pointer',
        }}
        onClick={() => handleStudentEdit(details)}
      >
        <AiOutlineEdit />
      </div>
      <div className="name">
        <h1>{details?.__user__.full_name || notSet}</h1>
      </div>
      <div className="info__wrapper">
        <div className="info">
          <div className="row">
            <div className="contact">
              <small>Contact</small>
              <h3>
                <MdEmail /> Email
              </h3>
              <p className="value">{details?.__user__.email || notSet}</p>

              <h3>
                <MdPhone /> Phone
              </h3>
              <p className="value">{details?.__user__.phone_number || notSet}</p>
            </div>

            {type === 'student' ? (
              <div className="class">
                <small>Academics</small>
                <h3>
                  <SiGoogleclassroom /> Class
                </h3>
                <p className="value">{(details as StudentResponseModel).gradeId || notSet}</p>

                <h3>
                  <TiGroup /> Section
                </h3>
                <p className="value">{(details as StudentResponseModel).sectionId || notSet}</p>
              </div>
            ) : null}
          </div>

          <div className="address">
            <small>Places</small>
            <h3>
              <IoLocationSharp /> Address
            </h3>
            <p className="value">{details?.__user__.address || notSet}</p>
          </div>

          {type ? (
            <div className="related">
              <small>Related</small>
              <h3>
                <RiParentFill /> {type === 'parent' ? 'Students' : 'Parents'}
              </h3>
              <div className="value">
                <List
                  loading={freshDetails.status === 'loading'}
                  itemLayout="horizontal"
                  dataSource={freshDetails.data}
                  renderItem={(item): JSX.Element => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={(item as any)?.image || http_config.DEFAULT_IMAGE} />}
                        title={<b>{(item as any)?.full_name || notSet}</b>}
                        description={
                          // eslint-disable-next-line
                          <>
                            {/* {(item as any)?.email || notSet} - */}
                            {(item as any)?.phone_number || notSet}
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>

        <div className="image">
          <small>Photo</small>
          <Image
            alt={`profile photo of ${details?.__user__.full_name || notSet}`}
            src={details?.__user__.image || http_config.DEFAULT_IMAGE}
            width={175}
          />
        </div>
      </div>
    </StudentDetailsWrapper>
  );
};

export default StudentIdCard;
