import React, { useEffect, useState } from 'react';
import RoleCard from './components/roleCard';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { onGetUsersRole, onUpdateUserRole } from 'data/user/user.service';
import { UserRoleModel } from 'data/user/user.model';
import { useUser } from '../hooks/useUser';
import KSpinner from 'common/component/KSpinner';
import { OtherStaffResponseModel } from 'data/otherStaff/otherStaff.model';

export function UserRole() {
  const { updateUserRole } = useUser();
  const [teacher, setTeacher] = useState<UserRoleModel[]>([]);
  const [superAdmin, setSuperAdmin] = useState<UserRoleModel[]>([]);
  const [generalAdmin, setGeneralAdmin] = useState<UserRoleModel[]>([]);
  const [support, setSupport] = useState<UserRoleModel[]>([]);
  const [otherStaff, setOtherStaff] = useState<any>([]);

  const [parent, setParent] = useState<UserRoleModel[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        getSuperAdmin,
        getGeneralAdmin,
        getTeacher,
        getParent,
        getSupport,
        getOtherStaff,
      ] = await Promise.all([
        onGetUsersRole(111),
        onGetUsersRole(222),
        onGetUsersRole(888),
        onGetUsersRole(777),
        onGetUsersRole(444),
        onGetUsersRole(999),
      ]);

      setSuperAdmin(getSuperAdmin);
      setGeneralAdmin(getGeneralAdmin);
      setParent(getParent);
      setTeacher(getTeacher);
      setSupport(getSupport);
      setOtherStaff(getOtherStaff);
    } catch (error) {
      console.error('Failed to fetch user roles:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRoleChange = async (userId: string, newRoleCode: number[]) => {
    const payload = {
      userId,
      newRoleCode,
    };

    return await updateUserRole(payload, fetchData);
  };
  const content = (
    <div style={{ paddingTop: '15px' }}>
      <h2>Super Admin</h2>
      <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap', padding: '30px 0px 30px 0px' }}>
        {superAdmin?.map((item) =>
          item.__users__.map((elements) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={superAdmin[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>

      <h2>General Admin</h2>
      <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '15px 0px 15px 0px' }}>
        {generalAdmin.map((item) =>
          item.__users__.map((elements) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={generalAdmin[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>

      <h2>Support</h2>
      <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '30px 0px 30px 0px' }}>
        {support.map((item) =>
          item.__users__.map((elements) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={support[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>
      <h2>Other Staff</h2>
      <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '30px 0px 30px 0px' }}>
        {otherStaff.map((item: any) =>
          item.__users__.map((elements: any) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={otherStaff[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>

      <h2>Teacher</h2>
      <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '15px 0px 15px 0px' }}>
        {teacher.map((item) =>
          item.__users__.map((elements) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={teacher[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>

      <h2>Parents</h2>
      <div style={{ display: 'flex', gap: '25px', flexWrap: 'wrap', padding: '15px 0px 15px 0px' }}>
        {parent.map((item) =>
          item.__users__.map((elements) => (
            <RoleCard
              key={elements.id}
              users={elements}
              role_code={parent[0]?.role_code}
              onRoleChange={handleRoleChange}
            />
          )),
        )}
      </div>
    </div>
  );

  return (
    <div>
      <KPageHeader title="Manage user role" />
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the users with various role that are currently in this system"
      />
      {loading ? <KSpinner /> : content}
    </div>
  );
}

export default UserRole;
