import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { CreateParentDto, CreateStudentDto } from 'data/manager/manager.model';
import { AddParentFormWrapper } from './user.style';
import { useSave } from '../hooks/useSave';

interface AddParentFormProps {
  useCase?: 'SchoolManager';
  studentDetails?: CreateStudentDto;
  onSetReloadAfterDrawer: Function;
  onVisibleChange: Function;
}

type ParentDetailsUpdater = (
  field: 'address' | 'email' | 'full_name' | 'password' | 'phone_number',
) => (ev: React.ChangeEvent<HTMLInputElement>) => void;

export const AddParentForm = ({
  onVisibleChange,
  onSetReloadAfterDrawer,
  studentDetails,
  useCase,
}: AddParentFormProps): JSX.Element => {
  const [formDetails, setFormDetails] = React.useState<CreateParentDto>({
    email: '',
    full_name: '',
    phone_number: '',
    address: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { onParentSave, onCreateStudentAndAssignNewParent } = useSave();

  const onFormDetailsChange: ParentDetailsUpdater = (field) => (ev): void => {
    setFormDetails({
      ...formDetails,
      [field]: ev.target.value,
    });
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);
    let parentDetails = { ...form.getFieldsValue() };

    const { email: pEmail, ...rst } = parentDetails;

    parentDetails = { ...rst };

    if (pEmail) parentDetails = { ...parentDetails, email: pEmail };

    if (useCase === 'SchoolManager' || true) {
      if (studentDetails) {
        const { email, phone_number, ...rest } = studentDetails;

        let stdDetails: CreateStudentDto = {
          ...rest,
        };

        if (email?.trim().length) stdDetails = { ...stdDetails, email };
        if (phone_number?.trim().length) stdDetails = { ...stdDetails, phone_number };

        const {} = parentDetails;

        await onCreateStudentAndAssignNewParent(
          { parentDetails, studentDetails: stdDetails },
          form,
          onVisibleChange,
          onSetReloadAfterDrawer,
        ).finally(() => setLoading(false));
      } else {
        await onParentSave(
          parentDetails,
          form,
          onVisibleChange,
          onSetReloadAfterDrawer,
        ).finally(() => setLoading(false));
      }
    }
  };

  const canSubmit = React.useMemo(() => {
    const { full_name, address, phone_number, password } = formDetails;

    if (!full_name.length) return false;
    if (!address?.length) return false;
    if (!phone_number.length) return false;
    if (!password?.length) return false;

    return true;
  }, [formDetails]);

  return (
    <AddParentFormWrapper>
      <Form form={form} name="nest-messages" layout="vertical" size="large" onFinish={onSubmit}>
        <Form.Item label="Full Name" name="full_name" required>
          <Input value={formDetails.full_name} onChange={onFormDetailsChange('full_name')} />
        </Form.Item>
        <Form.Item label="Address" name="address" required>
          <Input value={formDetails.address} onChange={onFormDetailsChange('address')} />
        </Form.Item>
        <Form.Item
          label="Email (optional)"
          name="email"
          rules={[{ type: 'email', message: 'Please input a valid email address.' }]}
        >
          <Input value={formDetails.email} onChange={onFormDetailsChange('email')} />
        </Form.Item>
        <Form.Item label="Phone Number" name="phone_number" required>
          <Input value={formDetails.phone_number} onChange={onFormDetailsChange('phone_number')} />
        </Form.Item>

        <Form.Item label="Password" name="password" required>
          <Input.Password value={formDetails.password} onChange={onFormDetailsChange('password')} />
        </Form.Item>

        <Form.Item>
          <Button disabled={!canSubmit || loading} htmlType="submit">
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddParentFormWrapper>
  );
};

export default AddParentForm;
