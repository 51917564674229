import React from 'react';
import { Button, Form, Input } from 'antd';
import { AddAnnouncementFormWrapper } from './announcement.style';
import { useAnnouncement } from '../hooks/useAnnouncement';

export const AnnouncementCategoryForm = (props: any) => {
  const [form] = Form.useForm();
  const { onVisibleChange, onReload } = props;
  const { onSaveAnnouncementCategory } = useAnnouncement();
  const handleSubmit = () => {
    onSaveAnnouncementCategory({ ...form.getFieldsValue() }, form, onVisibleChange, onReload);
  };

  return (
    <AddAnnouncementFormWrapper>
      <Form form={form} layout="vertical" size="large">
        <Form.Item label="Category" name={'title'}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddAnnouncementFormWrapper>
  );
};

export default AnnouncementCategoryForm;
