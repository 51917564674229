import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable, { TableColumn } from 'react-data-table-component';
import { RootState } from 'redux/store';
import { CustomHeader, ObtainedMarkInput, ResultStyle } from './result.style';
import { useResult } from '../hooks/useResult';
import { Subject } from '../data';
import { compareAndCombine, sortedSubjects } from '../utils/utils';
import PrintBulk from './components/TestMarksheet/PrintBulk';
import { ResultTestModel } from 'data/result/result.model';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import PrintResults from './PrintResults';
import { Column } from './GroupingTable';
import PrintTestLedgerResults from './PrintTestLedgerResults';
import { Input } from 'antd';
import { ExportToCsv } from 'export-to-csv';

export interface ResultViewFormProps {
  examYearId: string;
  testTypeId: string;
  classId: string;
  sectionId: string;
}

const tableColumns = (allSubjects: any): TableColumn<any>[] => [
  {
    name: 'Student',
    cell: (row): JSX.Element => (
      <div>
        <b>{row.studentName}</b>
      </div>
    ),
  },
  ...allSubjects.map(
    (sub: Subject): TableColumn<any> => ({
      name: (
        <CustomHeader className="subject-header">
          <h4>{sub.title}</h4>
          <p>FM: {parseInt(sub.full_mark, 10)}</p>
          <p>PM: {parseInt(sub.pass_mark, 10)}</p>
        </CustomHeader>
      ),
      cell: (row): JSX.Element => {
        const obtained_mark = row.subjects.find((subs: any) => subs.subjectId === sub.id)
          .obtained_mark;
        return (
          // row.subjects[sub.id]?.length ? (
          <ObtainedMarkInput
            type="number"
            inputMode="numeric"
            value={parseInt(obtained_mark, 10)}
            readOnly
            style={{
              color: parseInt(obtained_mark, 10) < Number(sub.pass_mark) ? 'red' : 'green',
            }}
          />
          // ) : (
          //   <Tooltip title="Result hasn't been prepared." placement="top">
          //     <i>N/A</i>
          //   </Tooltip>
          // );
        );
      },
    }),
  ),
];
const tableColumnsLedger = (allSubjects: any): TableColumn<any>[] => [
  {
    name: 'Student',
    cell: (row): JSX.Element => {
      console.log({ row });
      return (
        <div style={{ fontSize: '10px' }}>
          <b>{row.studentName}</b>
        </div>
      );
    },
  },
  ...allSubjects.map(
    (sub: Subject): TableColumn<any> => ({
      name: (
        <CustomHeader className="subject-header">
          <div style={{ fontSize: '14px !important' }}>{sub.title}</div>
          <p style={{ fontSize: '12px !important' }}>FM: {parseInt(sub.full_mark, 10)}</p>
          <p style={{ fontSize: '12px !important' }}>PM: {parseInt(sub.pass_mark, 10)}</p>
        </CustomHeader>
      ),
      cell: (row): JSX.Element => {
        const obtained_mark = row.subjects.find((subs: any) => subs.subjectId === sub.id)
          .obtained_mark;
        return (
          // row.subjects[sub.id]?.length ? (
          <Input
            value={obtained_mark}
            readOnly
            style={{
              outline: 'none',
              border: 'none',
              fontSize: '10px',
            }}
          />
          // ) : (
          //   <Tooltip title="Result hasn't been prepared." placement="top">
          //     <i>N/A</i>
          //   </Tooltip>
          // );
        );
      },
    }),
  ),
  {
    name: 'Total',
    cell: (row): JSX.Element => (
      <div>
        <b>{row.totalOM}</b>
      </div>
    ),
  },
  {
    name: 'Percentage',
    cell: (row): JSX.Element => (
      <div>
        <b>{Number(row.percentage)?.toFixed(2)}</b>
      </div>
    ),
  },
  {
    name: 'GPA',
    cell: (row): JSX.Element => (
      <div>
        <b>{row.overAllGpa}</b>
      </div>
    ),
  },
  {
    name: 'Position',
    cell: (row): JSX.Element => (
      <div>
        <b>{row.position}</b>
      </div>
    ),
  },
];

const ResultViewForm = (props: ResultViewFormProps): JSX.Element => {
  const { testTypeId, examYearId, classId, sectionId } = props;
  const [localResult, setLocalResult] = React.useState<{
    status: 'idle' | 'loading' | 'data' | 'error';
    data: ResultTestModel;
    error: any;
  }>();
  const { configData, fetchSchoolConfig, isLoading } = useSchoolConfig();
  const { fetchResultForApproval } = useResult();
  const [data, setData] = React.useState<any>();

  const resultData = useSelector((state: RootState) => state.result);
  const grades = useSelector((state: RootState) => state.grade);
  const [subjects, setSubjects] = React.useState<any>([]);
  const [showPrintMarkSheet, setShowPrintMarkSheet] = useState(false);
  const [showPrintLedger, setShowPrintLedger] = useState(false);

  React.useEffect(() => {
    if (!classId.length && sectionId.length && !examYearId.length && !testTypeId.length) return;
    fetchResultForApproval(classId, sectionId, examYearId, testTypeId);
  }, [props]);
  React.useEffect(() => {
    if (configData) return;
    fetchSchoolConfig();
  }, []);

  React.useEffect(() => {
    setLocalResult(resultData);
    if (resultData?.status === 'data') {
      const tempData = compareAndCombine(resultData?.data.subjects, resultData?.data.results);
      setData(tempData);
    }
  }, [resultData]);

  React.useEffect(() => {
    if (resultData?.status === 'data') {
      const tempSubjects = sortedSubjects(resultData?.data.subjects);
      setSubjects(tempSubjects);
    }
  }, [resultData]);

  const tempIssueDate = new Date().toDateString();
  console.log(data);

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  const handleDownloadCsv = async () => {
    const formattedData = data.map((item: any) => ({
      studentName: item.studentName,
      ...Object.assign(
        {},
        ...item.subjects.map((sub: any) => ({
          [sub.title]: sub.obtained_mark,
        })),
      ),

      total: item.totalOM,
      percentage: item.percentage,
      gpa: item.overAllGpa,
      position: item.position,
    }));

    const res = formattedData;
    csvExporter.generateCsv(res);
  };

  return (
    <ResultStyle>
      <main>
        <button onClick={() => setShowPrintMarkSheet(true)}>Print</button>
        <button
          onClick={() => setShowPrintLedger(true)}
          style={{ marginLeft: '10px', marginBottom: '10px' }}
        >
          Print Ledger
        </button>
        <button onClick={handleDownloadCsv} style={{ marginLeft: '10px', marginBottom: '10px' }}>
          Download CSV
        </button>
        {showPrintMarkSheet ? (
          <PrintBulk
            data={{
              metaSchool: {
                principal: configData?.data.schoolDetails.principal,
                principalSignature: configData?.data.schoolDetails.principalSignature,
                examCoordinator: '',
                examCoordinatorSignature: '',
                schoolName: configData?.data.schoolDetails.schoolName,
                schoolLogo: configData?.data.schoolDetails.schoolLogo,
                schoolAddress: configData?.data.schoolDetails.schoolAddress,
              },
              issueDate: tempIssueDate,
              examYearId: examYearId,
              //   sectionId: sectionId,
              testExamId: testTypeId,
              records: data.map(
                (d: {
                  totalOM: any;
                  subjects: any;
                  studentName: any;
                  studentRoll: any;
                  position: any;
                  percentage: any;
                  remarks: any;
                  overAllGpa: any;
                  totalFM: any;
                }) => {
                  return {
                    studentName: d.studentName,
                    studentRoll: d.studentRoll,
                    overAllGpa: d.overAllGpa,
                    position: d.position,
                    percentage: d.percentage,
                    totalFM: d.totalFM,
                    totalOM: d.totalOM,
                    remarks: d.remarks,
                    subjects: d.subjects.map((sub: any) => {
                      return {
                        subjectId: sub.subjectId,
                        title: sub.title,
                        full_mark: sub.full_mark,
                        pass_mark: sub.pass_mark,
                        obtained_mark: sub.obtained_mark,
                        gpa: sub.gpa,
                      };
                    }),
                  };
                },
              ),
            }}
            onFinish={(): void => {
              setShowPrintMarkSheet(false);
            }}
          />
        ) : null}
        {showPrintLedger && localResult?.status === 'data' ? (
          <PrintTestLedgerResults
            metaSchool={{
              principal: '',
              principalSignature: 'string',
              examCoordinator: 'string',
              examCoordinatorSignature: '',
              schoolName: configData?.data.schoolDetails.schoolName,
              schoolLogo: configData?.data.schoolDetails.schoolLogo,
              schoolAddress: configData?.data.schoolDetails.schoolAddress,
            }}
            data={data}
            issueDate={tempIssueDate}
            columns={tableColumnsLedger(subjects)}
            test_types={[]}
            isLoading={data.length === 0}
            meta={{
              classId,
              examYearId,
              examId: testTypeId,
              //   sections: [sectionId],
            }}
            onFinish={(): void => {
              setShowPrintLedger(false);
            }}
          />
        ) : null}

        {localResult?.status === 'data' ? (
          <DataTable
            progressPending={resultData.status === 'loading' || grades.status === 'loading'}
            data={data}
            columns={tableColumns(subjects)}
            dense
            highlightOnHover
          />
        ) : null}
      </main>
    </ResultStyle>
  );
};

export default ResultViewForm;
