import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { TeacherDataModel, TeacherResponseModel } from './teacher.model';

export const onGetTeachers = (page: number, limit: number): Promise<TeacherDataModel> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/teacher?page=' + page + '&limit=' + limit + '&delay=1',
  );
};

export const onSetTeachers = (payload: TeacherResponseModel): Promise<TeacherResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/teacher', payload);
};
