import { onGetAbsentStudentByGrade } from 'data/absent/absent.service';
import { onGetCurrentAcademicYear } from 'data/academic-year/academic-year.service';
import {
  onGetGradeWiseAttendance,
  onGradeAttendanceCheck,
} from 'data/attendance/attendance.service';
import { useDispatch } from 'react-redux';
import absentByGradeSlice from 'redux/slice/absentByGrade.slice';
import academicYearActiveSlice from 'redux/slice/academic-year-active.slice';
import gradeAttendanceSlice from 'redux/slice/gradeAttendance.slice';
import gradeAttendanceCheckSlice from 'redux/slice/gradeAttendanceCheck.slice';

export const useDashboard = () => {
  const dispatch = useDispatch();

  const fetchAttendances = async (academicYear: string): Promise<void> => {
    dispatch(gradeAttendanceSlice.actions.setLoading());
    try {
      const res = await onGetGradeWiseAttendance(academicYear);
      dispatch(gradeAttendanceSlice.actions.setData(res));
    } catch (err) {
      dispatch(gradeAttendanceSlice.actions.setError(err));
    }
  };

  const fetchAbsentStudents = async (gradeId: number): Promise<void> => {
    dispatch(gradeAttendanceCheckSlice.actions.setLoading());
    try {
      const res = await onGradeAttendanceCheck(gradeId);
      dispatch(gradeAttendanceCheckSlice.actions.setData({ list: res, grade: gradeId }));
    } catch (err) {
      dispatch(gradeAttendanceCheckSlice.actions.setError(err));
    }
  };

  const fetchAbsentStudentsByGrade = async (grade: string, section: string) => {
    dispatch(absentByGradeSlice.actions.setLoading());
    try {
      const res = await onGetAbsentStudentByGrade(grade, section);
      dispatch(absentByGradeSlice.actions.setData(res));
    } catch (err) {
      dispatch(absentByGradeSlice.actions.setError(err));
    }
  };

  const fetchCurrentAcademicYear = async () => {
    dispatch(academicYearActiveSlice.actions.setLoading());
    try {
      const res = await onGetCurrentAcademicYear();
      dispatch(academicYearActiveSlice.actions.setData(res.current));
    } catch (err) {
      dispatch(academicYearActiveSlice.actions.setError(err));
    }
  };

  const clearAttendanceCheck = () => {
    dispatch(gradeAttendanceCheckSlice.actions.clear());
  };

  return {
    clearAttendanceCheck,
    fetchAttendances,
    fetchAbsentStudents,
    fetchAbsentStudentsByGrade,
    fetchCurrentAcademicYear,
  };
};

export default useDashboard;
