import { Button, Form } from 'antd';
import { CreateStudentAndAssignParentModel, CreateStudentDto } from 'data/manager/manager.model';
import { getStudentParents } from 'data/parent/parent.service';
import ParentTable from 'features/users/component/ParentTable';
import { useSave } from 'features/users/hooks/useSave';
import { useUser } from 'features/users/hooks/useUser';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Label } from '../user.style';
import FilterComponent from './FilterComponent';

interface CreateStudentAndAssignParentProps {
  studentDetails: CreateStudentDto;
  onSetReloadAfterDrawer: Function;
  onVisibleChange: Function;
}

export default function CreateStudentAndAssignParent(
  props: CreateStudentAndAssignParentProps,
): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [isSearchClicked, setSearchClicked] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState('');

  const [form] = Form.useForm();
  const { fetchUserByPhonenumber } = useUser();
  const { onCreateStudentAndAssignParent } = useSave();
  const users = useSelector((state: RootState) => state.user);
  const [parentId, setParentId] = React.useState('');

  const handleOnFilter = (value: string): void => {
    setSearchKeyword(value);
    fetchUserByPhonenumber(value);
    setSearchClicked(true);
  };
  const handleSubmit = (): void => {
    setLoading(true);
    const { email, phone_number, ...rest } = props.studentDetails;

    let stdDetails: CreateStudentDto = {
      ...rest,
    };

    if (email?.trim().length) stdDetails = { ...stdDetails, email };
    if (phone_number?.trim().length) stdDetails = { ...stdDetails, phone_number };

    const payload: CreateStudentAndAssignParentModel = {
      studentDetails: stdDetails,
      parentId,
    };
    onCreateStudentAndAssignParent(
      payload,
      form,

      props.onVisibleChange,
      () => {
        props.onSetReloadAfterDrawer(true);
        setSearchClicked(false);
      },
    ).finally(() => {
      setLoading(false);
    });
  };

  const TableColumn = () => [
    {
      name: 'Full Name',
      selector: 'full_name',
      sortable: true,
      cell: (row: any) => <div>{row.full_name}</div>,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      cell: (row: any) => <div>{row.email}</div>,
    },
    {
      name: 'Phone Number',
      selector: 'phone_number',
      sortable: true,
      cell: (row: any) => <div>{row.phone_number}</div>,
    },
  ];
  return (
    <Form form={form} layout="vertical" size="large">
      <Form.Item label="Student Name">
        <Label>{props.studentDetails.full_name}</Label>
      </Form.Item>
      <Form.Item label="Search Parent" className="search">
        <FilterComponent onFilter={handleOnFilter} />
      </Form.Item>
      {/* <ParentTable
        table={TableColumn()}
        data={users}
        eachData={users.data}
        selectable
        parentId={setParentId}
        pagination={false}
      /> */}
      {isSearchClicked && (
        <p
          style={{
            fontWeight: 500,
            color: 'grey',
            margin: '14px 0',
          }}
        >
          Search: {searchKeyword}
        </p>
      )}
      {isSearchClicked && (
        <div className="parentTable">
          <ParentTable
            table={TableColumn()}
            data={users}
            eachData={users.data}
            selectable
            parentId={setParentId}
            pagination={false}
          />
        </div>
      )}

      <Form.Item>
        <Button className="saveButton" onClick={handleSubmit} disabled={loading}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}
