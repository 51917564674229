import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { KButton } from 'common/component/KButton';
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import KSpinner from 'common/component/KSpinner';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ExamTypeModel } from 'data/exam/exam.model';
import { useExamType } from 'features/exam-type/hooks/useExamType';
import { useExamYear } from '../hooks/useExamYear';
import AddExamYearType from './AddExamType';
import AddExamYear from './AddExamYear';

interface TableRowObject extends ExamTypeModel {
  type: string;
}

const typeColors: Record<string, string> = {
  AGGREGATE: 'green',
  'TEST TYPE': 'teal',
  FINAL: 'geekblue',
};

const examTypeTableModel: TableColumn<TableRowObject>[] = [
  {
    name: 'Type Name',
    cell: (row): JSX.Element => <>{row.id}</>,
    grow: 1,
  },
  {
    name: 'Type',
    cell: (row): JSX.Element => <Tag color={typeColors[row.type]}>{row.type}</Tag>,
  },
  {
    name: 'Associated Classes',
    cell: (row): JSX.Element => <>{row.grades.map((gd) => gd.id).join(', ')}</>,
  },
];

interface Props {}

const ExamYearView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const [typeVisible, setTypeVisible] = React.useState(false);
  const examYears = useSelector((state: RootState) => state.examYear);
  const terminalTypes = useSelector((state: RootState) => state.examTerminalType);
  const finalTypes = useSelector((state: RootState) => state.examFinalType);

  const [activeYear, setActiveYear] = React.useState<string | null>(null);
  const [typeFilters, setTypeFilters] = React.useState<string[]>([]);

  const { fetchExamYear } = useExamYear();
  const { fetchTerminalTestType, fetchfinalTestType } = useExamType();

  const showDrawer = (): void => {
    setVisible(true);
  };
  const onClose = (): void => {
    setVisible(false);
  };

  const showTypeDrawer = (): void => {
    setTypeVisible(true);
  };
  const onTypeClose = (): void => {
    setTypeVisible(false);
  };

  React.useEffect(() => {
    fetchExamYear();
    fetchTerminalTestType();
    fetchfinalTestType();
  }, []);

  const examTerminalTypes = useMemo(() => {
    return terminalTypes.data.filter((term) => term.exam_year.id === activeYear);
  }, [activeYear, terminalTypes.data]);
  const examFinalTypes = useMemo(() => {
    return finalTypes.data.filter((term) => term.exam_year.id === activeYear);
  }, [activeYear, finalTypes.data]);

  const years = useMemo(() => {
    return [...examYears.data].reverse();
  }, [examYears.data]);

  const tpes = useMemo(() => {
    const year = years.find((y) => y.id === activeYear);
    if (!year) return [];
    return year.test_types;
  }, [activeYear, years]);

  const combined_types = useMemo(() => {
    const tpes_arr =
      !typeFilters.length || typeFilters.includes('TEST TYPE')
        ? tpes.map((t) => ({ ...t, type: 'TEST TYPE' }))
        : [];
    const terms_arr =
      !typeFilters.length || typeFilters.includes('AGGREGATE')
        ? examTerminalTypes.map((t) => ({
            id: t.id,
            grades: t.grades,
            type: 'AGGREGATE',
          }))
        : [];
    const final_arr =
      !typeFilters.length || typeFilters.includes('FINAL')
        ? examFinalTypes.map((t) => ({
            id: t.id,
            grades: t.grades,
            type: 'FINAL',
          }))
        : [];

    return tpes_arr.concat(terms_arr).concat(final_arr);
  }, [tpes, examTerminalTypes, examFinalTypes, typeFilters]);

  const noDataComponent = useMemo(() => {
    return (
      <div className="noContent">
        <p>No record found.</p>
        <KButton onClick={showTypeDrawer}>Add exam type</KButton>
      </div>
    );
  }, []);

  const addOrRemoveFilter = (filter: string): void => {
    if (typeFilters.includes(filter)) setTypeFilters(typeFilters.filter((tp) => tp !== filter));
    else setTypeFilters([...typeFilters, filter]);
  };

  React.useEffect(() => {
    if (!activeYear && years.length) setActiveYear(years[0].id);
  }, [years]);

  return (
    <ExamYearViewWrapper>
      <KPageHeader title="Exam Year & Type" />
      <Drawer
        destroyOnClose
        width={500}
        title={<h2 style={{ fontWeight: 700 }}>Add Exam Year</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddExamYear
          onVisibleChange={setVisible}
          onSetReloadExamYear={(): void => {
            fetchExamYear();
            fetchTerminalTestType();
            fetchfinalTestType();
          }}
        />
      </Drawer>

      <Drawer
        destroyOnClose
        width={500}
        title={<h2 style={{ fontWeight: 700 }}>Add Exam Type</h2>}
        placement="right"
        onClose={onTypeClose}
        visible={typeVisible}
        closable={false}
      >
        <AddExamYearType
          examYear={activeYear || ''}
          onVisibleChange={setTypeVisible}
          onSetReloadExamYear={(): void => {
            fetchExamYear();
            fetchTerminalTestType();
            fetchfinalTestType();
          }}
        />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the examYears that are currently in this system"
      />
      <section className="viewTable">
        <section className="yearList">
          <h3>
            <div>
              Exam Year
              <Button
                disabled={examYears.status === 'loading'}
                onClick={fetchExamYear}
                icon={<ReloadOutlined style={{ fontSize: 14 }} />}
                type="link"
                style={{ marginLeft: 5 }}
              />
            </div>
            {/* <KButton onClick={showDrawer}>Add year</KButton> */}
            <KButton onClick={showTypeDrawer}>Add type</KButton>
          </h3>
          {examYears.status === 'loading' ? <KSpinner /> : <></>}
          {examYears.status === 'error' ? <p>{examYears.error}</p> : <></>}
          <ul>
            {years.map((year, index) => (
              // eslint-disable-next-line
              <li
                key={index}
                className={year.id === activeYear ? 'active' : ''}
                onClick={(): void => setActiveYear(year.id)}
              >
                {year.id}
              </li>
            ))}
            <li className="control" onClick={showDrawer}>
              <PlusOutlined />
            </li>
          </ul>
        </section>
        <section className="typeView">
          <h3>
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}
            >
              Exam Types
              <Button
                disabled={examYears.status === 'loading'}
                onClick={(): void => {
                  fetchTerminalTestType();
                  fetchfinalTestType();
                }}
                icon={<ReloadOutlined style={{ fontSize: 14 }} />}
                type="link"
              />
            </div>
            <div>
              <section className="filter">
                {['TEST TYPE', 'AGGREGATE', 'FINAL'].map((typ) => (
                  <Tag
                    onClick={(): void => addOrRemoveFilter(typ)}
                    style={{ cursor: 'pointer' }}
                    color={typeFilters.includes(typ) ? typeColors[typ] : ''}
                  >
                    {typ}
                  </Tag>
                ))}
              </section>
            </div>
          </h3>

          <DataTable
            progressPending={
              terminalTypes.status === 'loading' ||
              finalTypes.status === 'loading' ||
              examYears.status === 'loading'
            }
            progressComponent={<KSpinner />}
            noDataComponent={noDataComponent}
            data={combined_types}
            columns={examTypeTableModel}
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 330px)"
            // custom styles for height management
            customStyles={{
              table: {
                style: {
                  height: '100%',
                },
              },
              noData: {
                style: {
                  height: '100%',
                },
              },
              tableWrapper: {
                style: {
                  height: 'calc(100vh - 274px)',
                },
              },
            }}
          />
        </section>
      </section>
    </ExamYearViewWrapper>
  );
};

export const ExamYearViewWrapper = styled.div`
  width: 100%;
  padding-right: 20px;

  .viewTable {
    margin-top: 20px;
    width: 100%;
    height: calc(100vh - 200px - 15px);

    display: grid;
    grid-template-columns: 350px 7fr;
    gap: 20px;

    .typeView {
      width: 100%;
      height: 100%;

      padding: 0 0.8em;
      padding-bottom: 0.8em;
      position: relative;

      background: white;
      border-radius: 5px;
      overflow: hidden;
      overflow-y: auto;

      h3 {
        margin: 0;
        padding: 0.5em 0;
        background: white;
        position: sticky;
        z-index: 999;
        top: 0;
        left: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .noContent {
        width: 100%;
        height: 100%;

        border-radius: 10px;
        border: 2px dashed var(--gray900);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
      }
    }

    .yearList {
      width: 100%;
      height: 100%;

      padding: 0 0.8em;
      position: relative;

      background: white;
      border-radius: 5px;
      overflow: hidden;
      overflow-y: auto;

      h3 {
        margin: 0;
        padding: 0.3em 0;
        padding-top: 1em;
        background: white;
        position: sticky;
        z-index: 99;
        top: 0;
        left: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      ul {
        padding: 0;

        margin-top: 0.5em;
        list-style: none;

        font-size: 1.5em;
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        li {
          cursor: pointer;
          padding: 0.5em 1em;
          padding-left: 1em;
          background: var(--gray100);
          border: 1px solid var(--gray100);

          border-radius: 5px;
          overflow: hidden;

          transition: 0.2s ease-out;

          &.control {
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-style: dashed !important;

            color: grey;
            transition: 0.2s ease-out;

            &.hover {
              color: #00315d;
            }
          }

          &:hover {
            color: #00315d;
            border: 1px solid #00315d;
          }

          &.active {
            background: white;

            color: var(--green);
            border: 1px solid var(--green);
          }
        }
      }
    }
  }
`;

// const ExamYearView = () => <h1>Hellop</h1>;

export default ExamYearView;
