import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { SupportPersonResponseModel } from './supportPerson.model';

export const onGetSupportPerson = (): Promise<SupportPersonResponseModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/support-person');
};

export const onSetSupportPerson = (payload: SupportPersonResponseModel): Promise<SupportPersonResponseModel> => {

  return axiosInstance.post(http_config.BASE_URL + '/support-person', payload);
};
