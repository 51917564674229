import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'data/user/user.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: UserModel[];
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: [],
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<UserModel[]>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: [],
  status: 'error',
});

const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default userSlice;
