import routeFromRole from 'common/helper/routeFromRole';
import { Payload } from 'data/auth/auth.model';
import { onLogin } from 'data/auth/auth.service';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import systemSlice from 'redux/slice/system.slice';
import { useLogout } from './useLogout';

interface KSubmit {
  email: string;
  password: string;
}

export const useLogin = () => {
  const [error, setError] = React.useState<string | null | boolean>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { onLogout } = useLogout();

  const pushToState = (payload: Payload): void => {
    let activeRole = payload?.__roles__[0];

    const rl = localStorage.getItem('activeRole');

    if (rl) activeRole = JSON.parse(rl);
    else localStorage.setItem('activeRole', JSON.stringify(activeRole));

    dispatch(systemSlice.actions.setRole(activeRole));
    dispatch(
      systemSlice.actions.setState({
        ...payload,
        roles: payload?.__roles__ || [],
        image: payload.image || '',
        isLogged: true,
        activeRole,
      }),
    );
    history.push(`/${routeFromRole(payload?.__roles__[0].role_code)}`);
  };

  const onLog = async (payload: KSubmit): Promise<void> => {
    setLoading(true);
    try {
      const { payload: responsePayload } = await onLogin(payload);
      localStorage.setItem('userId', responsePayload.id);
      localStorage.setItem('user_details', JSON.stringify(responsePayload));
      pushToState(responsePayload);
      setLoading(false);
    } catch (err) {
      localStorage.clear();
      setError(err);
      setLoading(false);
    }
  };

  const onUnLog = async (): Promise<void> => {
    setLoading(true);
    try {
      onLogout();
      localStorage.clear();
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return { error, loading, onLogin: onLog, onLogout: onUnLog };
};

export default useLogin;
