import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { BalanceModel } from './balance.model';

export const onUpdateBalance = (
  cardNumber: string,
  payload: BalanceModel,
): Promise<BalanceModel> => {
  return axiosInstance.put(http_config.BASE_URL + '/balance/' + cardNumber, payload);
};

export const getBalance = (userId: string): Promise<BalanceModel> => {
  return axiosInstance.get(http_config.BASE_URL + '/balance?userId=' + userId);
};
