import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
// import { OtherStaffDataModel, OtherStaffResponseModel } from './otherStaff.model';
import { OtherStaffDataModel, OtherStaffResponseModel } from './otherStaff.model';

export const onGetOtherStaffs = (page: number, limit: number): Promise<OtherStaffDataModel> => {
  return axiosInstance.get(
    http_config.BASE_URL + '/users/non-teaching?page=' + page + '&limit=' + limit + '&delay=1',
  );
};

export const onSetOtherStaffs = (
  payload: OtherStaffResponseModel,
): Promise<OtherStaffResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/users/non-teaching', payload);
};
