import React, { useEffect, useState } from "react";
import { Card, Button, Badge} from "antd";

export function LeaveApplicationCard(props: any) {
  const [color, setColor]=useState<string>();
  useEffect(()=>{
    props.status==='accept'?setColor('#52c41a'):setColor('#f5222d');
  },[]);
  
  const handleAccept = () => {
    props.onClick(props.id, "accept");
  };

  const handleReject = () => {
    props.onClick(props.id, "reject");
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  };

  const formattedDate = formatDate(props.date);

  return (
    <Card title={props.title} style={{  marginTop: "20px" }}>
      <p style={{ color: "#8E8E8E" }}>{formattedDate}</p>
      <p>{props.description}</p>
      <br />
      <div >
        {
          props.status==='idle'?<div style={{display:'flex', gap:'20px'}}>
             <Button type="primary" onClick={handleAccept}>
          Accept
        </Button>
        <Button type="primary" onClick={handleReject}>
          Reject
        </Button>
          </div>:<Badge count={props.status} style={{backgroundColor:color}} />
        }
       
      </div>
    </Card>
  );
}
