import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import examTypeSlice from 'redux/slice/examType.slice';
import {
  handleSaveExamType,
  handleSaveGenericExamType,
  handleSaveTerminalExamType,
  onGetExamType,
  onGetFinalExamType,
  onGetTerminalExamType,
  onGetTestTypeByClass,
} from 'data/exam/exam.service';
import { CreateExamTypeModel } from 'data/exam/exam.model';
import examTerminalTypeSlice from 'redux/slice/examTerminalType.slice';
import examFinalTypeSlice from 'redux/slice/examFinalType.slice';
import { toast } from 'react-hot-toast';

export const useExamType = () => {
  const dispatch = useDispatch();

  const fetchExamType = async () => {
    dispatch(examTypeSlice.actions.setLoading());
    try {
      const res = await onGetExamType();
      dispatch(examTypeSlice.actions.setData(res));
    } catch (err) {
      dispatch(examTypeSlice.actions.setError(err));
    }
  };

  const fetchTerminalTestType = async () => {
    dispatch(examTerminalTypeSlice.actions.setLoading());
    try {
      const res = await onGetTerminalExamType();
      dispatch(examTerminalTypeSlice.actions.setData(res));
    } catch (err) {
      dispatch(examTerminalTypeSlice.actions.setError(err));
    }
  };
  const fetchfinalTestType = async () => {
    dispatch(examFinalTypeSlice.actions.setLoading());
    try {
      const res = await onGetFinalExamType();
      dispatch(examFinalTypeSlice.actions.setData(res));
    } catch (err) {
      dispatch(examFinalTypeSlice.actions.setError(err));
    }
  };

  const fetchTestTypeByClass = async (classId: string) => {
    dispatch(examTypeSlice.actions.setLoading());
    try {
      const res = await onGetTestTypeByClass(classId);
      dispatch(examTypeSlice.actions.setData(res));
    } catch (err) {
      dispatch(examTypeSlice.actions.setError(err));
    }
  };

  const onSaveExamType = async (
    payload: CreateExamTypeModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleSaveExamType(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Deleting new exam type',
        success: 'Exam Type successfully deleted.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleSaveExamType(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Exam Type successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  const onSaveGenericExamType = async (
    payload: CreateExamTypeModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleSaveGenericExamType(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new exam type',
        success: 'Exam Type successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleSaveGenericExamType(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Exam Type successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  const onSaveTerminalExamType = async (
    payload: CreateExamTypeModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleSaveGenericExamType(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new exam type',
        success: 'Exam Type successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleSaveTerminalExamType(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Terminal Exam Type successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };

  return {
    fetchExamType,
    fetchTestTypeByClass,
    fetchTerminalTestType,
    fetchfinalTestType,
    onSaveGenericExamType,
    onSaveExamType,
    onSaveTerminalExamType,
  };
};
