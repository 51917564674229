import React, { useState } from 'react';
import moment from 'moment';
import { Form, Button, Input, Select, DatePicker, Radio } from 'antd';

import TextEditor from 'common/component/Inputs/TextEditor';
import { useSection } from 'features/routine/hooks/useSection';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Option } from 'antd/lib/mentions';
import { AddHolidayWrapper } from './holiday.style';
import { useHoliday } from '../hooks/useHoliday';
import { getBSDate } from 'features/result/utils/utils';
import Calendar from 'common/component/KCalendar';

export const EditHoliday = (props: any) => {
  const { description: pDescription } = props;

  const { onUpdateHoliday } = useHoliday();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadHoliday } = props;
  const [disabledSelectSections, setDisabledSelectSections] = useState(Boolean(props?.forAll));
  const [forAll, setForAll] = useState(Boolean(props?.forAll));
  const { fetchSections } = useSection();
  const section = useSelector((state: RootState) => state.section);
  //   const [description, setDescription] = React.useState<string>('');

  const [title, setTitle] = React.useState<string>(props?.title);
  const [description, setDescription] = React.useState<string>(props?.description);
  const [date, setDate] = React.useState<string>(props?.date);

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  const handleSubmit = async () => {
    await onUpdateHoliday(
      props.id,
      {
        ...form.getFieldsValue(),
        title,
        description,
        forAll,
        date,
      },
      form,
      onVisibleChange,
      onSetReloadHoliday,
    );
  };

  React.useEffect(() => {
    fetchSections();
  }, []);
  React.useEffect(() => {
    setDisabledSelectSections(forAll);
  }, [forAll]);

  React.useEffect(() => {
    if (pDescription) setDescription(pDescription);
  }, [pDescription]);

  return (
    <AddHolidayWrapper>
      <Form
        form={form}
        size="large"
        layout="vertical"
        initialValues={{
          title: props?.title,
          description: props?.description,
          date: getBSDate(props?.date),
        }}
      >
        {/* <Form.Item name="title" label="Holiday">
          <Input defaultValue={props?.title} />
        </Form.Item> */}
        <Form.Item name="title" label="Name">
          <Input defaultValue={props?.title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Description">
          <div className="quill__field">
            <TextEditor value={description} onChange={onDescriptionChange} />
          </div>
        </Form.Item>
        <Form.Item name="date" label="Date">
          <Calendar
            defaultDate={getBSDate(props?.date)}
            onChange={({ adDate }: any) => {
              setDate(new Date(adDate).toISOString());
            }}
            language="en"
            style={{ width: 250 }}
            placeholder="Select Date of Issue"
          />
        </Form.Item>
        <Form.Item name="forAll" label="For All">
          <Radio
            checked={forAll}
            value={forAll}
            onClick={() => {
              setForAll((prev: any) => !prev);
            }}
          >
            For All
          </Radio>
        </Form.Item>
        {!disabledSelectSections && (
          <Form.Item name="sectionIds" label="Sections">
            <Select
              mode="multiple"
              size="middle"
              placeholder="Please select"
              defaultValue={props?.sections.split(', ')}
              value={section.data.map((item) => item.id)}
              style={{ width: '100%' }}
            >
              {section.data.map((item: any) => (
                <Option key={item.id}>{item.id}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            Update Holiday
          </Button>
        </Form.Item>
      </Form>
    </AddHolidayWrapper>
  );
};

export default EditHoliday;
