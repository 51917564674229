import React from 'react';
import { Button, Form, Input } from 'antd';

interface FilterComponentProps {
  onFilter: Function;
}

export default function FilterComponent(props: FilterComponentProps) {
  return (
    <Input.Search
      placeholder="Input Phone Number"
      size="large"
      onSearch={(value) => props.onFilter(value)}
    />
  );
}
