import { notifySuccess } from 'common/helper/notifications';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import systemSlice, { RoleType, SystemSliceState } from 'redux/slice/system.slice';
import { RootState } from 'redux/store';

interface UseAuthInfoHook extends SystemSliceState {
  switchRole: (role: RoleType) => void;
}

export const useAuthInfo = (): UseAuthInfoHook => {
  const dispatch = useDispatch();

  const sys = useSelector((state: RootState) => state.system);

  const switchRole = (role: RoleType): void => {
    localStorage.setItem('activeRole', JSON.stringify(role));
    dispatch(systemSlice.actions.setRole(role));
    toast.success('User Role - Role switched successfully.');
  };

  return { ...sys, switchRole };
};

export default {};
