import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportResponseModel } from 'data/support/support.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: { replied: SupportResponseModel[]; notReplied: SupportResponseModel[] };
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: { replied: [], notReplied: [] },
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<
  SliceState,
  PayloadAction<{ replied: SupportResponseModel[]; notReplied: SupportResponseModel[] }>
> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: { replied: [], notReplied: [] },
  status: 'error',
});

const supportSlice = createSlice({
  name: 'support',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default supportSlice;
