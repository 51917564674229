export const formatTime = (time: string) =>
  +time.slice(0, 2) > 12 ? `${time}:00 PM` : `${time}:00 AM`;

export const formatTimeMinutesOnly = (time: string) => {
  let [hrs, mins] = time.split(':');
  let daytime = 'AM';
  if (Number(hrs) > 12) {
    hrs = `${Number(hrs) - 12}`;
    daytime = 'PM';
  }

  return `${hrs.padStart(2, '0')}:${mins} ${daytime}`;
};
