import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import { Holiday, HolidayPost } from './holiday.modle';

export const onGetAllHolidays = (): Promise<Holiday[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/holiday');
};

export const onGetUpcomingHolidays = (): Promise<Holiday[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/holiday/upcoming');
};

export const onCreateHoliday = (payload: HolidayPost): Promise<unknown> => {
  return axiosInstance.post(http_config.BASE_URL + '/holiday', payload);
};
export const handleHolidayUpdate = (
  id: string,
  payload: Partial<HolidayPost & { forAll: boolean }>,
): Promise<unknown> => {
  return axiosInstance.put(http_config.BASE_URL + `/holiday/${id}`, payload);
};
export const handleHolidayDelete = (id: string): Promise<unknown> => {
  return axiosInstance.delete(http_config.BASE_URL + `/holiday/${id}`);
};
