import React from 'react';
import { Form, Button, Input } from 'antd';

import moment from 'moment';
import TextEditor from 'common/component/Inputs/TextEditor';
import { useSection } from 'features/routine/hooks/useSection';
import { AddEventWrapper } from './event.style';
import { useEvent } from '../hooks/useEvent';
import Calendar from 'common/component/KCalendar';
import { addNumberToMonth, getBSDate } from 'features/result/utils/utils';
const { convertToBS } = require('nepali-date-converter/dist/lib/nepali-date-helper');

export const EditEvent = (props: any) => {
  console.log({ props });
  const { description: pDescription } = props;

  const { onUpdateEvent } = useEvent();
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadEvent } = props;
  const [title, setTitle] = React.useState<string>(props?.title);
  const [description, setDescription] = React.useState<string>(props?.description);
  const [date, setDate] = React.useState<string>(props?.date);

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  //   const { fetchSections } = useSection();

  const handleSubmit = async () => {
    await onUpdateEvent(
      props.id,
      {
        // ...form.getFieldsValue(),
        title,
        description,
        date,
      },
      form,
      onVisibleChange,
      onSetReloadEvent,
    );
  };

  //   React.useEffect(() => {
  //     fetchSections();
  //   }, []);

  React.useEffect(() => {
    if (pDescription) setDescription(pDescription);
  }, [pDescription]);

  return (
    <AddEventWrapper>
      <Form
        form={form}
        size="large"
        layout="vertical"
        initialValues={{
          title: props?.title,
          description: props?.description,
          date: getBSDate(props?.date),
        }}
      >
        <Form.Item name="title" label="Event">
          <Input value={props?.title} onChange={(e) => setTitle(e.target.value)} />
        </Form.Item>
        <Form.Item label="Description">
          <div className="quill__field">
            <TextEditor value={description} onChange={onDescriptionChange} />
          </div>
        </Form.Item>
        <Form.Item name="date" label="Date">
          <Calendar
            defaultDate={getBSDate(props?.date)}
            onChange={({ adDate }: any) => {
              setDate(new Date(adDate).toISOString());
            }}
            language="en"
            style={{ width: 250 }}
            placeholder="Select Date of Issue"
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            Update Event
          </Button>
        </Form.Item>
      </Form>
    </AddEventWrapper>
  );
};

export default EditEvent;
