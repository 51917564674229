import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { AddTeacherFormWrapper } from './user.style';
import { useSave } from '../hooks/useSave';

const AddNonTeachingForm = (props: any) => {
  const { onVisibleChange, onSetReloadAfterDrawer } = props;
  const [form] = Form.useForm();
  const { onNonTeachingSave } = useSave();

  return (
    <AddTeacherFormWrapper>
      <Form form={form} name="nest-messages" layout="vertical" size="large">
        <Form.Item label="Full Name" name={'full_name'}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name={'email'}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Phone Number" name={'phone_number'}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Input.Password />
        </Form.Item>
        <Form.Item label="Address" name={'address'}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            onClick={() => {
              onNonTeachingSave(
                { ...form.getFieldsValue(), roles: [999] },
                form,
                onVisibleChange,
                onSetReloadAfterDrawer,
              );
            }}
          >
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddTeacherFormWrapper>
  );
};

export default AddNonTeachingForm;
