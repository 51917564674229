import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import holidaySlice from 'redux/slice/holiday.slice';
import {
  handleHolidayDelete,
  handleHolidayUpdate,
  onCreateHoliday,
  onGetAllHolidays,
  onGetUpcomingHolidays,
} from 'data/holiday/holiday.service';
import { HolidayPost } from 'data/holiday/holiday.modle';
import upComingHolidaySlice from 'redux/slice/upcoming-holiday.slice';
import { toast } from 'react-hot-toast';

export const useHoliday = () => {
  const dispatch = useDispatch();

  const fetchHoliday = async () => {
    dispatch(holidaySlice.actions.setLoading());
    try {
      const res = await onGetAllHolidays();
      dispatch(holidaySlice.actions.setData(res));
    } catch (err) {
      dispatch(holidaySlice.actions.setError(err));
    }
  };

  const fetchUpComingHolidays = async () => {
    dispatch(upComingHolidaySlice.actions.setLoading());
    try {
      const res = await onGetUpcomingHolidays();
      dispatch(upComingHolidaySlice.actions.setData(res));
    } catch (err) {
      dispatch(upComingHolidaySlice.actions.setError(err));
    }
  };

  const onSaveHoliday = async (
    payload: HolidayPost,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      onCreateHoliday(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding new holiday',
        success: 'Holiday successfully added.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await onCreateHoliday(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Holiday successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onUpdateHoliday = async (
    id: string,
    payload: Partial<HolidayPost>,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ) => {
    toast.promise(
      handleHolidayUpdate(id, payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Updating new holiday',
        success: 'Holiday updated successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
    // try {
    //   await handleHolidayUpdate(id, payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Holiday updated successfully.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
  };
  const onDeleteHoliday = async (id: string, onVisibleChange: any, onReload: any) => {
    // try {
    //   await handleHolidayDelete(id).then(() => {
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Holiday deleted successfully.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      handleHolidayDelete(id).then(() => {
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Deleting new holiday',
        success: 'Holiday deleted successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  return {
    fetchHoliday,
    onSaveHoliday,
    onUpdateHoliday,
    onDeleteHoliday,
    fetchUpComingHolidays,
  };
};
