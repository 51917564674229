import React from 'react';
import { Cascader } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useGrade } from 'features/routine/hooks/useGrade';
import KSpinner from '../KSpinner';

interface Props {
  gradeId?: string;
  value?: string[];
  disabled?: string[];
  onChange?: (subIds: string[]) => void;
}

interface Option {
  value: string | number;
  label: string;
  disabled?: boolean;
  children?: Option[];
}

function SelectSubjectCascaded({ disabled, gradeId, value, onChange }: Props): JSX.Element {
  const gradeSubject = useSelector((state: RootState) => state.gradeSubjects);
  const resultSubjectsStatus = useSelector((state: RootState) => state.resultSubjectStatus);

  const { fetchGradeSubjects } = useGrade();

  const options = React.useMemo(() => {
    return gradeSubject.data.subjects.map(
      (sub): Option => ({
        label: `[${sub.code}] ${sub.title}`,
        value: sub.id,
        disabled: disabled?.includes(sub.id),
        children: sub.optional
          ? sub.optionalSubjects.map(
              (oSub): Option => ({
                label: `[${oSub.code}] ${oSub.title}`,
                value: oSub.id,
                disabled: disabled?.includes(oSub.id),
              }),
            )
          : undefined,
      }),
    );
  }, [gradeSubject.data.subjects]);

  const [localSubject, setLocalSubject] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (gradeId) {
      if (onChange) onChange([]);
      setLocalSubject([]);
      fetchGradeSubjects(gradeId);
    }
  }, [gradeId]);

  React.useEffect(() => {
    if (value) {
      const [main, opt] = value;
      if (!main?.length) return;
      const lcSub = [main];
      if (opt?.length) lcSub.push(opt);
      setLocalSubject(lcSub);
    }
  }, [value]);

  return resultSubjectsStatus.status === 'loading' || gradeSubject.status === 'loading' ? (
    <KSpinner />
  ) : (
    <Cascader
      value={localSubject.length ? localSubject : undefined}
      placeholder="Select Subject"
      showSearch
      options={options}
      onChange={(val): void => {
        const [main, opt] = val as string[];
        if (onChange) onChange([main, opt]);
        if (main) setLocalSubject([main, opt]);
        else setLocalSubject([]);
      }}
    />
  );
}

export default SelectSubjectCascaded;
