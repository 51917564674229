import { FormInstance } from 'antd/lib/form';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { notifyError, notifySuccess } from 'common/helper/notifications';
import { OptionalSubjectRequestModel } from 'data/subject/subject.model';
import {
  onGetAssignedStudents,
  onGetOptionalSubjects,
  onSetOptionalSubject,
} from 'data/subject/subject.service';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import assignedStudentsSlice from 'redux/slice/assignedStudent.slice';
import optionalSubjectSlice from 'redux/slice/optionalSubject.slice';

const useOptionalSubject = () => {
  const dispatch = useDispatch();

  const fetchOptionalSubject = async (): Promise<void> => {
    dispatch(optionalSubjectSlice.actions.setLoading());
    try {
      const res = await onGetOptionalSubjects();
      dispatch(optionalSubjectSlice.actions.setData(res));
    } catch (err) {
      dispatch(optionalSubjectSlice.actions.setError(err));
    }
  };

  const onSaveOptionalSubject = async (
    payload: OptionalSubjectRequestModel,
    form: FormInstance,
    onVisibleChange: any,
    onReload: any,
  ): Promise<void> => {
    // try {
    //   await onSetOptionalSubject(payload).then(() => {
    //     form.resetFields();
    //     onVisibleChange(false);
    //     onReload(true);
    //   });
    //   notifySuccess('Success', 'Optional Subject successfully added.');
    // } catch (err) {
    //   onVisibleChange(true);
    //   onReload(false);
    //   notifyError('Error', decodeApiMessage(err));
    // }
    toast.promise(
      onSetOptionalSubject(payload).then(() => {
        form.resetFields();
        onVisibleChange(false);
        onReload(true);
      }),
      {
        loading: 'Adding optional subject.',
        success: 'Optional Subject successfully added.',
        error: (error) => {
          onReload(false);
          return decodeApiMessage(error);
        },
      },
    );
  };

  const fetchAssignedStudents = async (optionalSubjectId: string): Promise<void> => {
    dispatch(assignedStudentsSlice.actions.setLoading());
    try {
      const res = await onGetAssignedStudents(optionalSubjectId);
      dispatch(assignedStudentsSlice.actions.setData(res));
    } catch (err) {
      dispatch(assignedStudentsSlice.actions.setError(err));
    }
  };

  return { fetchAssignedStudents, fetchOptionalSubject, onSaveOptionalSubject };
};

export default useOptionalSubject;
