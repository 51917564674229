import AttendanceView from 'features/attendance/view/AttendanceView';
import StaffAttendanceView from 'features/attendance/view/StaffAttendanceView';
import UsersView from 'features/users/view/UsersView';
import BookView from 'features/book/view/BookView';
import RoutineView from 'features/routine/view/RoutineView';
import AnnouncementView from 'features/announcement/view/AnnouncementView';
import SupportView from 'features/support/view/SupportView';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ExamRoutineView from 'features/examRoutine/view/ExamRoutineView';
import DashboardView from 'features/dashboard/view/DashboardView';
import SubjectView from 'features/subject/view/SubjectView';
import ClassView from 'features/class/view/ClassView';
import ClassSectionView from 'features/class-section/view/ClassSectionView';
import EventView from 'features/event/view/EventView';
import HolidayView from 'features/holiday/view/HolidayView';
import AcademicYearView from 'features/academic-year/view/AcademicYearView';
import ExamYearView from 'features/exam-year/view/ExamYearView';
import ExamTypeView from 'features/exam-type/view/ExamTypeView';
import NewResultView from 'features/result/view/NewResultView';
import TerminalResultView from 'features/result/view/TerminalResultView';
import FinalResultView from 'features/result/view/FinalResultView';
import { UserRole } from 'features/users/view/userRole';
import { LeaveApplication } from 'features/actions/view/LeaveApplication';
import Sidebar from '../component/Sidebar/Sidebar';
import { HomeViewWrapper } from './home.style';
import UpgradeStudents from 'features/upgradeStudents/view';
import { BulkCodes } from 'features/bulk-upload/view/BulkCodes';
import BulkAssign from 'features/bulk-upload/view/BulkAssign';
import { BulkUpload } from 'features/bulk-upload/view/BulkUpload';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import { BulkSubject } from 'features/bulk-upload/view/BulkSubject';

export default function HomeView(): JSX.Element {
  const { configData, fetchSchoolConfig, isLoading } = useSchoolConfig();

  useEffect(() => {
    if (!configData) {
      fetchSchoolConfig();
    }
  }, []);

  return (
    <HomeViewWrapper>
      <div className="main-container">
        <Sidebar />
        <div className="content-area">
          <Switch>
            <Route path="/" exact component={DashboardView} />
            <Route path="/dashboard" exact component={DashboardView} />
            <Route path="/users/role" exact component={UserRole} />
            <Route path="/leave-application" exact component={LeaveApplication} />
            <Route path="/bulk-codes" exact component={BulkCodes} />
            <Route path="/bulk-subjects" exact component={BulkSubject} />
            <Route path="/bulk-assign" exact component={BulkAssign} />
            <Route path="/bulk-upload" exact component={BulkUpload} />
            <Route path="/users/:type" exact component={UsersView} />
            <Route path="/attendance" exact component={AttendanceView} />
            <Route path="/upgrade-students" exact component={UpgradeStudents} />
            <Route path="/attendance/staff" exact component={StaffAttendanceView} />
            <Route path="/result/test" exact component={NewResultView} />
            <Route path="/result/terminal" exact component={TerminalResultView} />
            <Route path="/result/final" exact component={FinalResultView} />
            <Route path="/announcement" exact component={AnnouncementView} />
            <Route path="/attendance/support" exact component={SupportView} />
            <Route path="/book" exact component={BookView} />
            <Route path="/routine" exact component={RoutineView} />
            <Route path="/examRoutine" exact component={ExamRoutineView} />
            <Route path="/subject" exact component={SubjectView} />
            <Route path="/class" exact component={ClassView} />
            <Route path="/section" exact component={ClassSectionView} />
            <Route path="/events" exact component={EventView} />
            <Route path="/holiday" exact component={HolidayView} />
            <Route path="/academic-year" exact component={AcademicYearView} />
            <Route path="/exam-year" exact component={ExamYearView} />
            <Route path="/exam-type" exact component={ExamTypeView} />
          </Switch>
        </div>
      </div>
    </HomeViewWrapper>
  );
}
