import styled from 'styled-components';

export const AuthViewWrapper = styled.div`
  background-color: var(--blue);
  height: 100vh;
  position: relative;
`;

export const AuthControl = styled.div`
  background-color: #fff;
  box-shadow: var(--shadow);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 20px;
  width: 380px;
  max-width: 90%;

  header h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: var(--gap);
  }

  .gap {
    height: var(--gap);
  }

  .link {
    margin-top: var(--gap);
    text-align: center;
    font-weight: 500;
  }
`;
