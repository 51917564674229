import { cleanGradeSection } from 'common/utils/functions';
import { getRangeDescAndRemarks } from 'features/result/utils/utils';
import { toInteger } from 'lodash';
import React from 'react';
import { MarkSheetWrapper } from '../MarkSheet/styles';

interface Subject {
  subjectId: string;
  title: string;
  full_mark: string;
  pass_mark: string;
  obtained_mark: string;
  gpa: string;
}

interface RecordType {
  subjects: Subject[];
  position: string;
  percentage: string;
  remarks: string;
  totalFM: string;
  totalOM: string;
  overAllGpa: string;
}

interface TestMarksheetProps {
  issueDate: string;
  examYearId: string;
  sectionId: string;
  testExamId: string;
  studentName: string;
  studentRoll: string;
  metaSchool: {
    schoolName: string;
    principal: string;
    principalSignature: string;
    examCoordinator: string;
    examCoordinatorSignature: string;
    schoolLogo: string;
    schoolAddress: string;
  };
  record: RecordType;
}

const TestMarksheet = ({
  record,
  metaSchool,
  issueDate,
  examYearId,
  testExamId,
  studentName,
  sectionId,
  studentRoll,
}: TestMarksheetProps) => {
  //   if (record.position === '-') {
  //     console.log('PRINT FAILLLLLLL');
  //     console.log({
  //       record,
  //       metaSchool,
  //       issueDate,
  //       examYearId,
  //       testExamId,
  //       studentName,
  //       sectionId,
  //       studentRoll,
  //     });
  //   }
  return (
    <MarkSheetWrapper>
      <div className="wrapper">
        <div className="dates">
          <span className="printed">Printed On: {new Date().toDateString()}</span>
          <span className="printed">Issued On: {issueDate}</span>
        </div>

        {/* <img
          className="watermark__logo"
          src="https://cdn.discordapp.com/attachments/792669461873688587/1134053024730464296/output-onlinepngtools.png"
        /> */}

        <div className="container">
          <header>
            <img className="school__logo" src={metaSchool.schoolLogo} />
            <h1 className="school__name">{metaSchool.schoolName}</h1>
            <h2 className="school__location">{metaSchool.schoolAddress}</h2>
            <h3 className="school__sub__location">Province 1, Nepal</h3>
          </header>
          <main>
            <h1>Grade-Sheet</h1>
            <div className="declaration">
              <div className="line">
                <div className="label">The Grade(s) secured by:</div>
                <div className="value">{studentName}</div>
              </div>
              <div className="line">
                <div className="label">Exam</div>
                <div className="value">{testExamId.split('|')[1]}</div>
              </div>
              <div className="line">
                <div className="label">Roll No.</div>
                <div className="value">{studentRoll}</div>
                <div className="label">Class:</div>
                <div className="value">{cleanGradeSection(sectionId)}</div>
              </div>
              <div className="line">
                <div className="label">in the annual examination conducted in:</div>
                <div className="value">{examYearId}</div>
              </div>
              <div className="line">
                <div className="label">Are given below.</div>
              </div>
            </div>
            <div className="grade__table">
              <table>
                <thead>
                  <tr>
                    <td>S.N.</td>
                    <td className="subject">Subject</td>
                    <td className="marks">FM</td>
                    <td className="marks">PM</td>
                    <td className="marks">OM</td>
                    <td className="marks">GPA</td>
                  </tr>
                </thead>
                <tbody>
                  {record.subjects.map((subject, index) => {
                    return (
                      <tr key={subject.title}>
                        <td>{index + 1}</td>
                        <td className="subject">{subject.title}</td>
                        <td className="marks">{parseInt(subject.full_mark, 10)}</td>
                        <td className="marks">{parseInt(subject.pass_mark, 10)}</td>
                        <td className="marks">{parseInt(subject?.obtained_mark, 10)}</td>
                        <td className="marks">{subject.gpa}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <div className="footer">
                  <tr>
                    <td className="subject">Total</td>
                    <td className="marks">{record.totalFM}</td>
                    <td className="marks"></td>
                    <td className="marks">{Number(record.totalOM)?.toFixed(2)}</td>
                    <td className="marks">{record.overAllGpa}</td>
                  </tr>
                </div>
              </table>
            </div>
          </main>

          <div className="result__remarks">
            <div className="f-container">
              <div className="f-container">
                <div>Percentage:</div>
                <div>
                  {Number(record.percentage)?.toFixed(2)} %{' '}
                  {record.position === 'N/A'
                    ? 'FAIL'
                    : getRangeDescAndRemarks(toInteger(record.percentage)).rangeDesc}
                </div>
              </div>
              <div className="f-container">
                <div>Position:</div>
                <div>{record?.position}</div>
              </div>
            </div>
            <div className="f-container">
              <div className="f-container">
                <div>Attendance:</div>
                <div>...... / ......</div>
              </div>
            </div>
            <div className="f-container">
              <div className="f-container">
                <div>Remarks:</div>
                <div>{record.remarks}</div>
              </div>
            </div>
          </div>

          <footer>
            <div className="signature">
              <img
                height={50}
                src="https://cdn.discordapp.com/attachments/792669461873688587/1134057387330916382/signature.png"
              />
              <div className="line" />
              {/* <div className="name">Ujwal Basnet</div> */}
              <div className="act">Principal</div>
            </div>
            <div className="signature">
              <img
                height={50}
                src="https://cdn.discordapp.com/attachments/792669461873688587/1134056387509485578/exam_coordinator.png"
              />
              <div className="line" />
              {/* <div className="name">Dipesh Bhattarai</div> */}
              <div className="act">Exam Coordinator</div>
            </div>

            <div className="signature" style={{ marginTop: '50px' }}>
              <div className="line" />
              {/* <div className="name">Dipesh Bhattarai</div> */}
              <div className="act">Class Teacher</div>
            </div>
          </footer>
        </div>
      </div>
    </MarkSheetWrapper>
  );
};

export default TestMarksheet;
