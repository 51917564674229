import { notification } from 'antd';

export const notifySuccess = (title: any, message: string = ''): void => {
  notification.success({
    message: title,
    description: message,
    placement: 'bottomLeft',
    duration: 5,
  });
};

export const notifyError = (title: any, message: string = ''): void => {
  notification.error({
    message: title,
    description: message,
    placement: 'bottomLeft',
    duration: 5,
  });
};
