import http_config from '../../common/config/http_config';
import axiosInstance from '../../common/helper/axiosInterceptor';
import {
  CreateExamTypeModel,
  ExamTypeModel,
  ExamYearModel,
  FinalExamTypeModel,
  TerminalExamTypeModel,
} from './exam.model';

export const onGetExamType = (): Promise<ExamTypeModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/test-type');
};
export const onGetTerminalExamType = (): Promise<TerminalExamTypeModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/terminal-exam');
};
export const onGetFinalExamType = (): Promise<FinalExamTypeModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/final-exam');
};

export const onGetTestTypeByClass = (classId: string): Promise<ExamTypeModel[]> => {
  return axiosInstance.get(`${http_config.BASE_URL}/test-type?grade=${classId}`);
};

export const onGetExamYear = (): Promise<ExamYearModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/exam-year');
};

export const handleSaveExamYear = (payload: { value: string }): Promise<ExamYearModel[]> => {
  return axiosInstance.post(http_config.BASE_URL + '/exam-year', payload);
};
export const handleSaveGenericExamType = (
  payload: CreateExamTypeModel,
): Promise<ExamYearModel[]> => {
  return axiosInstance.post(http_config.BASE_URL + '/exam-type', payload);
};
export const handleSaveExamType = (payload: CreateExamTypeModel): Promise<ExamYearModel[]> => {
  return axiosInstance.post(http_config.BASE_URL + '/test-type', payload);
};
export const handleSaveTerminalExamType = (
  payload: CreateExamTypeModel,
): Promise<{ message: string }> => {
  return axiosInstance.post(http_config.BASE_URL + '/terminal-exam', payload);
};
