import { Button, Form, Input } from 'antd';
import { OptionalSubjectRequestModel } from 'data/subject/subject.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useOptionalSubject from '../hooks/useOptionalSubject';
import { AddSubjectWrapper } from './subject.style';

interface AddNewOptionalSubjectProps {
  onFinish?: VoidFunction;
}

const AddNewOptionalSubject = ({ onFinish }: AddNewOptionalSubjectProps): JSX.Element => {
  const [form] = Form.useForm<OptionalSubjectRequestModel>();

  const optionalSubject = useSelector((state: RootState) => state.optionalSubject);

  const { onSaveOptionalSubject } = useOptionalSubject();

  const onSubmit = (): void => {
    onSaveOptionalSubject(
      {
        ...form.getFieldsValue(),
      },
      form,
      (): void => {},
      onFinish,
    );
  };

  return (
    <AddSubjectWrapper>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item style={{ marginTop: 15 }} name="title" label="Subject Title" required>
          <Input placeholder="Optional Subject Title" />
        </Form.Item>
        <Form.Item style={{ marginTop: 15 }} name="code" label="Subject Code" required>
          <Input placeholder="Optional Subject Code" />
        </Form.Item>
        <Form.Item style={{ marginTop: 15 }} shouldUpdate>
          {(): JSX.Element => (
            <Button
              htmlType="submit"
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
              loading={optionalSubject.status === 'loading'}
              style={{ width: '100%' }}
            >
              Save Optional Subject
            </Button>
          )}
        </Form.Item>
      </Form>
    </AddSubjectWrapper>
  );
};

export default AddNewOptionalSubject;
