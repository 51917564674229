import http_config from '../../../common/config/http_config';
import axiosInstance from '../../../common/helper/axiosInterceptor';
import { BookResponseModel } from './book.model';

export const onSetBook = (payload: BookResponseModel): Promise<BookResponseModel> => {
  return axiosInstance.post(http_config.BASE_URL + '/book', payload);
};

export const onGetBook = (): Promise<BookResponseModel[]> => {
  return axiosInstance.get(http_config.BASE_URL + '/book');
};
