import { useDispatch } from 'react-redux';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { FormInstance } from 'antd/lib/form/hooks/useForm';

import { toast } from 'react-hot-toast';
import { onUpgradeStudents } from 'data/upgrade-students/upgrade-students.service';

export const useUpgradeStudents = () => {
  const onUpgrade = async (payload: any, onReload: any) => {
    toast.promise(
      onUpgradeStudents(payload).then(() => {
        onReload(true);
      }),
      {
        loading: 'Upgrading Students',
        success: 'Students upgraded successfully.',
        error: (error) => decodeApiMessage(error),
      },
    );
  };

  return {
    onUpgrade,
  };
};
