import React from 'react';

import { Button, Card, Divider, Modal } from 'antd';
import { Event } from 'data/event/event.modle';
import { useEvent } from 'features/event/hooks/useEvent';
import DataTable, { TableColumn } from 'react-data-table-component';
import KSpinner from 'common/component/KSpinner';
import { ReloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import moment from 'moment';

import { AbsentViewWrapper } from '../dashboard.style';

const upcomingEventTableModel = (): TableColumn<Event>[] => [
  {
    name: 'Event',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row): string => moment(new Date(row.date)).format('MMM Do, YYYY'),
    sortable: true,
  },
  {
    name: 'From',
    cell: (row): string => moment(new Date(row.date)).format('hh:mm a'),
  },
];

const UpcomingWidget = (): JSX.Element => {
  const { fetchUpComingEvents } = useEvent();
  const upComingEvent = useSelector((state: RootState) => state.upComingEvent);

  const [activeEvent, setActiveEvent] = React.useState<Event | null>(null);

  React.useEffect(() => {
    fetchUpComingEvents();
  }, []);

  const reload = (): void => {
    fetchUpComingEvents();
  };

  const events = React.useMemo(() => {
    return upComingEvent.data
      .map((a) => a)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [upComingEvent.data]);

  const modalImageCover = React.useMemo(() => {
    if (!activeEvent || !activeEvent.images || !activeEvent.images.length) return false;

    return <img src={activeEvent.images} alt={activeEvent.title} />;
  }, [activeEvent]);

  return (
    <>
      <AbsentViewWrapper style={{ height: 'auto' }}>
        <div className="title">
          Up Coming Events
          <Button
            onClick={reload}
            type="link"
            disabled={upComingEvent.status === 'loading'}
            icon={<ReloadOutlined />}
          />
        </div>
        <Divider style={{ marginTop: 'var(--gap)', marginBottom: 'var(--gap)' }} />

        <DataTable
          // className="data-table"
          noHeader
          dense
          highlightOnHover
          responsive
          progressPending={upComingEvent.status === 'loading'}
          progressComponent={<KSpinner />}
          data={events}
          columns={upcomingEventTableModel()}
          onRowClicked={(ev): void => setActiveEvent(ev)}
        />
      </AbsentViewWrapper>

      <Modal
        title="Event Details"
        className="event-details-modal"
        visible={!!activeEvent}
        centered
        closable
        maskClosable
        destroyOnClose
        footer={false}
        onCancel={(): void => {
          setActiveEvent(null);
        }}
      >
        {activeEvent && (
          <>
            <Card title={activeEvent.title} cover={modalImageCover}>
              <p>{activeEvent.description}</p>

              <br />

              <Card.Meta
                title={`${moment(new Date(activeEvent.date)).format('DD MMM YYYY')}, ${moment(
                  new Date(activeEvent.date),
                ).fromNow()}`}
              />
            </Card>
          </>
        )}
      </Modal>
    </>
  );
};

export default UpcomingWidget;
