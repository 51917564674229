import React from 'react';
import KSpinner from 'common/component/KSpinner';
import { ViewIssueBooksWrapper, Label } from './user.style';
import { StudentResponseModel } from 'data/student/student.model';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import { useUser } from '../hooks/useUser';

export const ViewIssuedBook = (props: any) => {
  const { value } = props;
  const data: StudentResponseModel = value;
  const issuedBooks = useSelector((state: RootState) => state.issuedBooks);
  const { fetchIssuedBooks } = useUser();
  const booksData: any = [];
  const TableColumn = () => [
    {
      name: 'Book Name',
      selector: 'title',
      sortable: true,
      cell: (row: any) => <div>{row.book.title}</div>,
    },
    {
      name: 'Due Date',
      selector: 'dueDate',
      sortable: true,
      cell: (row: any) => <div>{row.dueDate}</div>,
    },
    {
      name: 'Issued By',
      selector: 'issuedBy',
      sortable: true,
      cell: () => <div>{data.__user__.full_name}</div>,
    },
  ];

  console.log('dataId' + data.id);
  issuedBooks.data.map((item) => {
    if (item.studentId === data.id) {
      console.log('booksData ' + item.book.title);
      return booksData.push(item);
    }
    return booksData;
  });

  React.useEffect(() => {
    fetchIssuedBooks();
    // eslint-disable-next-line
  }, []);

  return (
    <ViewIssueBooksWrapper>
      <label>Student Name</label>
      <Label>{data.__user__.full_name}</Label>

      <section className="dataTable">
        {issuedBooks.status === 'loading' && <KSpinner />}
        {issuedBooks.status === 'data' && (
          <DataTable
            customStyles={tableCustomStyles}
            highlightOnHover
            responsive={true}
            data={booksData}
            columns={TableColumn()}
          />
        )}
      </section>
    </ViewIssueBooksWrapper>
  );
};

export default ViewIssuedBook;
