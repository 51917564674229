import React from 'react';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import DataTable, { RowRecord } from 'react-data-table-component';
import { useUser } from '../hooks/useUser';
import KSpinner from 'common/component/KSpinner';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const ParentTable = (props: any) => {
  const {
    data,
    table,
    selectable,
    parentId,
    pagination,
    eachData,
    setParentLimit,
    setParentPage,
    defaultPage,
  } = props;

  const handlePageChange = (page: number) => {
    setParentPage(page);
  };
  const handlePerRowsChange = (newLimit: number) => {
    setParentLimit(newLimit);
  };
  const handleChange = (row: RowRecord[]) => {
    console.log({ row });
    row.forEach((item) => {
      console.log({ item });
      parentId(item.parentId);
      // parents.data.data.forEach((parent) => {
      //   if (parent.userId === item.id) {
      //     parentId(parent.id);
      //     console.log('parent ', parent.id);
      //   }
      // });
    });
  };

  return (
    <>
      {data.status === 'loading' && <KSpinner />}

      {data.status === 'data' && (
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          data={eachData}
          columns={table}
          pagination={pagination}
          paginationServer
          paginationTotalRows={data.totalCount}
          paginationDefaultPage={defaultPage}
          onChangePage={(page) => handlePageChange(page)}
          onChangeRowsPerPage={(newLimit) => handlePerRowsChange(newLimit)}
          paginationComponentOptions={{ noRowsPerPage: true }}
          responsive={true}
          selectableRows={selectable}
          onSelectedRowsChange={(value) => handleChange(value.selectedRows)}
        />
      )}
    </>
  );
};

export default ParentTable;
