import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionalSubjectModel } from 'data/subject/subject.model';

type SliceState = {
  status: 'idle' | 'loading' | 'data' | 'error';
  data: OptionalSubjectModel[];
  error: any;
};

const initState: SliceState = {
  status: 'idle',
  data: [],
  error: false,
};

const setLoading = (): SliceState => ({ ...initState, status: 'loading' });

const setData: CaseReducer<SliceState, PayloadAction<OptionalSubjectModel[]>> = (_, action) => ({
  error: false,
  data: action.payload,
  status: 'data',
});

const setError: CaseReducer<SliceState, PayloadAction<any>> = (state, action) => ({
  error: action.payload,
  data: [],
  status: 'error',
});

const optionalSubjectSlice = createSlice({
  name: 'optionalSubject',
  initialState: initState,
  reducers: {
    setLoading,
    setData,
    setError,
  },
});

export default optionalSubjectSlice;
