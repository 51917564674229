import React from 'react';
import { Card } from 'antd';
import { SupportResponseModel } from 'data/support/support.model';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
  list: SupportResponseModel[];
  onAction: (support: SupportResponseModel) => void;
}

const SupportCards: React.FC<Props> = ({ list, onAction }: Props) => {
  return (
    <div className="support__cards">
      {!list.length ? (
        <div
          style={{
            width: '100%',
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InfoCircleOutlined style={{ fontSize: 28 }} />
          <p>Everything covered up.</p>
          <small>Refresh to check if there are any updates.</small>
        </div>
      ) : (
        list.map((item) => (
          <Card
            hoverable
            style={{ width: 300, marginBottom: 10, cursor: 'pointer' }}
            key={item.id}
            title={item.subject}
            onClick={(): void => onAction(item)}
          >
            <Card.Meta description={item.description} />
          </Card>
        ))
      )}
    </div>
  );
};

export default SupportCards;
