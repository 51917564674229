import {
  AlertOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Modal } from 'antd';
import SelectExamType from 'common/component/Inputs/SelectExamType';
import SelectExamYear from 'common/component/Inputs/SelectExamYear';
import { KButton } from 'common/component/KButton';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { ResultStatusText } from 'data/result/result.model';
import { useExamYear } from 'features/exam-year/hooks/useExamYear';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import { useResult } from '../hooks/useResult';
import ResultPrepareForm from './resultPrepareForm';
import ResultPublishForm from './resultPublishForm';
import ResultViewForm from './ResultViewForm';

export interface Student {
  student: string;
  studentId: string;
  percentage: number;
  status: number;
  totalSubjects: number;
  subjects: number;
}

export const ResultWrapper = styled.div`
  padding-right: 20px;
  height: 100%;

  .section-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
  }

  .page-controls {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  .page-tabs {
    width: 100%;
    display: grid;
    grid-template-columns: 200px 200px;
    gap: 20px;
  }

  .viewField {
    width: 100%;
    height: calc(100% - 200px - 36px);
    overflow-y: auto;
    border-radius: 10px;
    background: white;

    .noContent {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;
    }
  }
`;

const CustomButton = styled(Button)`
  background: ${({ disabled }): string => (!disabled ? '#763535' : 'var(--gray700)')};
  color: ${({ disabled }): string => (!disabled ? 'white' : 'black')};
  border-color: ${({ disabled }): string => (!disabled ? '#763535' : 'var(--gray700)')};
  padding: 0.75em 2.2em;
  margin: 0.5em 0;
  height: auto;
  width: auto;
`;

type ActionFunction = (classId: string, sectionId: string) => void;

const colors: Record<ResultStatusText, string> = {
  Preparing: '#FF0000',
  Prepared: '#FFA500',
  Approved: '#008000',
  Published: '#008000',
};
const statusToInfo: Record<ResultStatusText, string> = {
  Published: 'Print Ready, Available in App',
  Approved: 'Ready to Print & Publish',
  Prepared: 'Ready for Approval',
  Preparing: 'Collecting Results',
};

const ResultListTableModel = (
  onApprove: ActionFunction,
  onView: ActionFunction,
  onPrint: ActionFunction,
  onPublish: ActionFunction,
  onPrepare: ActionFunction,
): TableColumn<{
  class: string;
  section: string;
  status: ResultStatusText;
}>[] => [
  {
    name: 'Class',
    cell: (row): JSX.Element => <>{row.class}</>,
    grow: 0,
  },
  {
    name: 'Section',
    cell: (row): JSX.Element => <>{row.section}</>,
  },
  {
    name: '',
    cell: (row): JSX.Element => (
      <>
        {['Approved', 'Published'].includes(row.status) ? (
          <i>Already {row.status}</i>
        ) : (
          <CustomButton onClick={(): void => onApprove(row.class, row.section)}>
            Verify
          </CustomButton>
        )}
      </>
    ),
  },
  {
    name: '',
    cell: (row): JSX.Element => (
      <CustomButton
        disabled={row.status !== 'Approved'}
        onClick={(): void => onPublish(row.class, row.section)}
        style={{ width: 132 }}
      >
        {row.status === 'Published' ? <span style={{ color: 'green' }}>Published</span> : 'Publish'}
      </CustomButton>
    ),
  },
  {
    name: 'Status',
    cell: (row): JSX.Element => (
      <span
        style={{
          color: colors[row.status],
          fontWeight: 600,
        }}
      >
        {statusToInfo[row.status]}
      </span>
    ),
  },
  {
    name: '',
    cell: (row): JSX.Element => (
      <>
        {row.status === 'Preparing' ? (
          <KButton onClick={(): void => onPrepare(row.class, row.section)}>Prepare Result</KButton>
        ) : null}
        {['Approved', 'Published'].includes(row.status) ? (
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={(): void => onView(row.class, row.section)}
          >
            View
          </Button>
        ) : (
          <></>
        )}
      </>
    ),
  },
];

const NewResultView = (): JSX.Element => {
  const [selected, setSelected] = React.useState({
    selectedExamYear: '',
    selectedExamType: '',
  });
  const [resultVerification, setResultVerification] = React.useState<{
    examYearId: string;
    testTypeId: string;
    classId: string;
    sectionId: string;
  } | null>(null);

  const [resultView, setResultView] = React.useState<{
    examYearId: string;
    testTypeId: string;
    classId: string;
    sectionId: string;
  } | null>(null);

  const [visible, setVisible] = React.useState<{
    examYearId: string;
    testTypeId: string;
    classId: string;
    sectionId: string;
  } | null>(null);
  const [localChanged, setLocalChanged] = React.useState<boolean>(false);

  const examYear = useSelector((state: RootState) => state.examYear);
  const exams = useSelector((state: RootState) => state.examYear);
  const resultStatus = useSelector((state: RootState) => state.resultStatus);

  const { fetchExamYear } = useExamYear();
  const { fetchResultStatus, publishResultForApp, resetStates } = useResult();
  const [showPrintMarkSheet, setShowPrintMarkSheet] = React.useState<boolean>(false);
  const showDrawer = (classId: string, sectionId: string): void => {
    setVisible({
      examYearId: selected.selectedExamYear,
      testTypeId: selected.selectedExamType,
      classId,
      sectionId,
    });
  };
  const onClose = (): void => {
    setVisible(null);
  };
  const clearResultVerification = (): void => {
    if (localChanged) {
      Modal.confirm({
        title: 'Discard result changes?',
        content:
          'There are unsaved result changes. They will be lost if this drawer is closed without saving.',
        onOk() {
          setLocalChanged(false);
          setResultVerification(null);
        },
        okText: 'Discard changes!',
        cancelText: 'Do not discard!!',
        centered: true,
      });
    } else {
      setResultVerification(null);
    }
  };

  const closeResultView = (): void => {
    setResultView(null);
  };

  const onLocalChanged = (changed: boolean): void => {
    setLocalChanged(changed);
  };

  const publishDrawerVisible = React.useMemo(() => !!resultVerification, [resultVerification]);

  React.useEffect(() => {
    fetchExamYear();
    resetStates();
  }, []);

  React.useEffect(() => {
    if (selected.selectedExamYear.length && selected.selectedExamType.length) {
      fetchResultStatus(selected.selectedExamYear, selected.selectedExamType);
    }
  }, [selected]);

  const reload = (): void => {
    fetchResultStatus(selected.selectedExamYear, selected.selectedExamType);
  };
  const onApproval = (): void => {
    reload();
    clearResultVerification();
  };

  const classes = React.useMemo(() => {
    if (!selected.selectedExamYear.length) return [];
    if (!selected.selectedExamType.length) return [];

    // console.log(examYear)
    const year = examYear.data.find((yr) => yr.id === selected.selectedExamYear);
    if (!year) return [];

    const tp = year.test_types.find((t) => t.id === selected.selectedExamType);
    if (!tp) return [];

    return tp.grades;
  }, [selected, examYear.data, exams.data]);

  const onVerify = (classId: string, sectionId: string): void => {
    setResultVerification({
      examYearId: selected.selectedExamYear,
      testTypeId: selected.selectedExamType,
      classId,
      sectionId,
    });
  };
  const onPrint = (classId: string, sectionId: string): void => {
    console.log('Print: ', classId, sectionId);
  };
  const onPublish = (classId: string, sectionId: string): void => {
    Modal.confirm({
      title: 'Confirm Result Publish',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      keyboard: false,
      closable: true,
      okText: 'Yes, Publish!',
      width: 600,
      content: (
        <>
          <ul style={{ padding: 0 }}>
            <li>This action will publish results to mobile application.</li>
            <li>This action cannot be undone.</li>
          </ul>
        </>
      ),
      onOk() {
        publishResultForApp(
          classId,
          sectionId,
          selected.selectedExamYear,
          selected.selectedExamType,
          reload,
        );
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const onView = (classId: string, sectionId: string): void => {
    setResultView({
      classId,
      sectionId,
      examYearId: selected.selectedExamYear,
      testTypeId: selected.selectedExamType,
    });
  };

  const fields = React.useMemo(() => {
    const flds: Array<{
      class: string;
      section: string;
      status: ResultStatusText;
    }> = [];
    if (resultStatus.status === 'data') {
      // provide data according to sorted order with grouping the same grade from top to bottom
      resultStatus.data.map((result) => {
        flds.push({
          class: result?.grade?.id,
          section: result?.section?.id,
          status: result?.status,
        });
      });
    }

    return flds;
  }, [classes, resultStatus]);

  return (
    <>
      <ResultWrapper>
        {/* header */}
        <KPageHeader title="Test Results" />
        <KProTip
          title="Page Hint"
          description="This page is for viewing and managing all the tests results of students that are currently in this system"
        />

        <section className="section-wrapper">
          {/* controls */}
          <section className="page-tabs">
            <SelectExamYear
              onChange={(value): void => {
                setSelected((prevSelected) => ({ ...prevSelected, selectedExamYear: value }));
              }}
            />
            <SelectExamType
              examYear={selected.selectedExamYear}
              onChange={(value): void => {
                setSelected((prevSelected) => ({ ...prevSelected, selectedExamType: value }));
              }}
            />
          </section>

          <section className="page-controls">
            <Button
              type="link"
              loading={resultStatus.status === 'loading'}
              onClick={reload}
              icon={<ReloadOutlined />}
            />
          </section>
        </section>

        {/* content */}
        <main className="viewField">
          {!selected.selectedExamYear.length || !selected.selectedExamType.length ? (
            <div className="noContent">
              <AlertOutlined style={{ fontSize: 32 }} />
              <p>Please, select an exam year and type to get started.</p>
            </div>
          ) : (
            <>
              <DataTable
                data={fields}
                columns={ResultListTableModel(onVerify, onView, onPrint, onPublish, showDrawer)}
                progressPending={resultStatus.status === 'loading'}
              />
            </>
          )}
        </main>
      </ResultWrapper>

      {/* portals */}
      <Drawer
        title={<h2>Prepare Result</h2>}
        width={720}
        visible={!!visible}
        closable
        maskClosable={false}
        onClose={onClose}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {visible && (
          <ResultPrepareForm
            onFinish={(): void => {
              onClose();
            }}
            // eslint-disable-next-line
            resultIdentifiers={visible}
          />
        )}
      </Drawer>
      <Drawer
        title={<h2>Verify Result</h2>}
        width={1120}
        visible={publishDrawerVisible}
        closable
        maskClosable={false}
        onClose={clearResultVerification}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {resultVerification ? (
          <ResultPublishForm
            onApproval={onApproval}
            localChanged={localChanged}
            onLocalChange={onLocalChanged}
            // eslint-disable-next-line
            {...resultVerification}
          />
        ) : (
          <></>
        )}
      </Drawer>

      <Drawer
        title={<h2>View Results</h2>}
        width={1120}
        visible={!!resultView}
        closable
        onClose={closeResultView}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {resultView ? <ResultViewForm {...resultView} /> : <></>}
      </Drawer>
    </>
  );
};

export default NewResultView;
