import { Button, Form, Input } from 'antd';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import { ErrorMessageView } from 'common/style/error_component';
import React from 'react';
import { useLogin } from '../hooks/useLogin';
import { AuthControl, AuthViewWrapper } from './auth.style';

interface Props {}

const LoginView: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const { onLogin, loading, error } = useLogin();

  console.log(error);
  return (
    <AuthViewWrapper>
      <AuthControl>
        <header>
          <h1>School Enhancer</h1>
        </header>
        <Form form={form} onFinish={onLogin}>
          <Form.Item
            label="Email address"
            className="form-full-width"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Enter a valid email address',
              },
            ]}
          >
            <Input placeholder="9805311953" />
          </Form.Item>

          <Form.Item
            label="Password"
            className="form-full-width"
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value && value.length > 5) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Password must be of atleast 6 character');
                },
              }),
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <div className="gap"></div>
          {error && (
            <React.Fragment>
              <ErrorMessageView center size="12px">
                {decodeApiMessage(error)}
              </ErrorMessageView>
              <div className="gap"></div>
            </React.Fragment>
          )}
          <Button loading={loading} htmlType="submit" block type="primary">
            Login
          </Button>
        </Form>
      </AuthControl>
    </AuthViewWrapper>
  );
};

export default LoginView;
