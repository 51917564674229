import React from 'react';
import KSpinner from 'common/component/KSpinner';
import { Prettify } from 'common/helper/typeHelper';
import styled from 'styled-components';
import { SectionResults } from 'data/result/result.model';

export interface Result {
  testTypeId: string;
  full_mark: number;
  pass_mark: number;
  obtained_mark: number;
}
export interface Subject {
  subject: string;
  subjectId: string;
  subResults: Result[];
  totalOM: number;
  totalFM: number;
  gpa: string;
}
export interface Results {
  subjects: Subject[];
  totalOM: number;
  totalFM: number;
  percentage: number;
  overAllGpa: string;
  position: number;
}

export interface Column {
  id: string;
  label: string;
  children: Prettify<Omit<Column, 'children'>>[];
  maxWidth?: number;
  align: 'left' | 'center' | 'right';
}

export interface GroupingTableProps {
  data: SectionResults[];
  columns: Column[];
  tests: string[];
  loading?: boolean;
}

const StyledTable = styled.table<{ cols: Column[]; tests: string[] }>`
  font-size: 10px !important;
  width: 100%;
  display: block;
  min-width: ${(props): number => (props.cols.length - 1) * (props.tests.length + 1) * 30 + 200}px;

  td {
    padding: 0.3em 0;
  }

  thead {
    background: #e9e9e9;
    width: 100%;
    display: grid;
    grid-template-columns: 60px 200px repeat(${(props): number => props.cols.length - 5}, 1fr) 80px 60px 60px;

    th {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-right: 1px solid lightgray;

      &:last-child {
        border-right: 1px solid transparent;
      }
    }

    tr {
      width: 100%;
      display: flex;
      justify-content: center;

      td {
        text-align: center;
        width: minmax(60px, auto);
        text-transform: uppercase;
      }

      &.group {
        border-top: 1px solid lightgray;
        justify-content: space-evenly;
      }
    }
  }

  tbody {
    width: 100%;
    display: block;
    background: white;

    tr.wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(${(props): number => props.cols.length}, 1fr);
      grid-template-columns: 60px 200px repeat(${(props): number => props.cols.length - 5}, 1fr) 80px 60px 60px;
      border-bottom: 1px solid lightgray;

      &:last-child {
        border-bottom: 1px solid transparent;
      }
    }

    td.wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-right: 1px solid lightgray;

      &:last-child {
        border-right: 1px solid transparent;
      }
    }

    tr.value {
      width: 100%;
      justify-content: space-evenly;
      display: flex;

      td.value {
        width: minmax(10px, auto);
        text-align: center;
      }
    }
  }
`;

const GroupingTable = ({
  data: tableData,
  columns: tableColumns,
  tests,
  loading,
}: GroupingTableProps): JSX.Element => {
  const data = React.useMemo(() => tableData, [tableData]);
  const columns = React.useMemo(() => tableColumns, [tableColumns]);

  return loading ? (
    <KSpinner />
  ) : (
    <StyledTable cols={columns} tests={tests}>
      <thead>
        {columns.map((col) => (
          <th key={col.id}>
            <tr style={{ fontSize: '10px' }}>
              <td>{col.label}</td>
            </tr>

            {col.children ? (
              <tr style={{ fontSize: '10px' }} className="group">
                {col.children.map((cCol) => (
                  <td key={`${col.id}--${cCol.id}`}>{cCol?.label.slice(0, 4)}</td>
                ))}
              </tr>
            ) : null}
          </th>
        ))}
      </thead>
      <tbody>
        {data.map((dt, index) => {
          return (
            <tr className="wrapper">
              {columns.map((col) => {
                return (
                  <td className="wrapper" key={`${index}--${col.id}`}>
                    {col.children ? (
                      <tr className="value">
                        {col.children.map((cCol, index) => {
                          const { label, id } = col;
                          const subject = dt.results.subjects.find((res) => res.subjectId == id);
                          return (
                            <td
                              style={{ fontSize: '10px' }}
                              className="value"
                              key={`${index}--${col.id}--${cCol.id}`}
                            >
                              {cCol.label == 'Total'
                                ? subject?.totalOM
                                : subject?.subResults.map((data) => {
                                    if (data.testTypeId == cCol.id) return data.obtained_mark;
                                  })}
                            </td>
                          );
                        })}
                      </tr>
                    ) : col.id === 'studentRoll' ? (
                      dt.roll
                    ) : col?.id === 'studentName' ? (
                      dt.name
                    ) : col?.id === 'sum-total' ? (
                      dt.results?.totalOM?.toFixed(2)
                    ) : col?.id === 'percent-total' ? (
                      dt.results?.percentage?.toFixed(2)
                    ) : (
                      dt.results.position
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default GroupingTable;
