import styled from 'styled-components';

interface EType {
  center?: boolean;
  bold?: boolean;
  size?: string;
}

export const ErrorMessageView = styled.div<EType>`
  color: red;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-weight: ${(props) => (props.bold ? 500 : 'normal')};
  font-size: ${(props) => (props.size ? props.size : '14px')};
`;
