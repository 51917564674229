import { SupportResponseModel } from 'data/support/support.model';
import { useAuthInfo } from 'features/auth/hooks/useAuthInfo';
import React from 'react';
import SupportModalMessages from './SupportCards/styles';

interface Props {
  support: SupportResponseModel;
}

const MessageBox: React.FC<Props> = ({ support }: Props) => {
  const { id: authID } = useAuthInfo();

  const boxRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!boxRef || !boxRef.current) return;

    boxRef.current.scrollTo(
      0,
      Math.abs(boxRef.current.clientHeight - boxRef.current.scrollHeight) + 2,
    );
  }, []);

  return (
    <SupportModalMessages ref={boxRef}>
      <div className="message">
        <p>{support.description}</p>
      </div>
      {/* eslint-disable-next-line */}
      {support.__replies__.map((rep) => {
        return (
          <div key={rep.id} className={`message ${rep.userId === authID ? 'self' : ''}`}>
            <p>{rep.description}</p>
          </div>
        );
      })}
    </SupportModalMessages>
  );
};

export default MessageBox;
