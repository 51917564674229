import { Col, Row } from 'antd';
import KPageHeader from 'common/component/KPageHeader';
import React from 'react';
import AttendanceWidget from './components/AttendanceWidget';
import UpcomingHolidaysWidget from './components/UpcomingHolidaysWidget';
import UpcomingWidget from './components/UpcomingWidget';
import { DashboardWrapper } from './dashboard.style';

const DashBoardView: React.FC<{}> = () => {
  return (
    <DashboardWrapper>
      <KPageHeader title="DashBoard" />
      <Row gutter={[16, 16]}>
        <Col>
          <AttendanceWidget />
        </Col>
        <>
          <UpcomingWidget />
          <UpcomingHolidaysWidget />
        </>
      </Row>
    </DashboardWrapper>
  );
};

export default DashBoardView;
